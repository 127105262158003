import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'MODAL_CONTACTUS', {
  TITLE: 'How can we help you?',
  EMAIL: 'Email',
  COMPANY: 'Company',
  REASON: 'Reason for contact',
  MESSAGE: 'Type your message',
  BUTTON_SEND: 'Send',
  SUCCESS_MESSAGE: 'Your message has been sent successfully, we will be in touch very soon.',
  TECHNICAL_SUPPORT: 'Technical & Support',
  DATA_CONTENT: 'Data & Content',
});
