export enum PillarScores {
  ENVIRONMENT,
  SOCIAL,
  GOVERNANCE,
  GHG_INTENSITY,
  WASTE_MANAGEMENT,
  WATER_EFFICIENCY,
  ANNUAL_EMPLOYEE_TURNOVER,
  TRAINING_COSTS_PER_EMPLOYEE,
  RENEWABLE_ENERGY_TARGETS,
  SUSTAINABILITY_PLANS_INTEGRATED,
  BIODIVERSITY_CONSERVATION_PROGRAMS,
  BOARD_OF_DIRECTORS_WOMAN_OR_MINORITIES,
  WOMAN_OR_MINORITIES_REPRESENT,
  SUPPLIER_CODE_OF_CONDUCT,
  PROVIDES_TRAINING_ON_SUPPLIER_CODE,
  CONDUCTS_INSPECTION_OF_SUPPLIERS,
  MONITORS_IMPLEMENTATION_OF_HR_POLICIES,
  EXECUTIVE_COMPENSATION_LINKED_TO_ESG_PERFORMANCE,
  INDUSTRY_RANK,
  TOTAL_SCOPE_EMISSIONS,
  PARTICIPATES_IN_CARBON_DISCLOSURE_PROJECT,
  HAS_A_WATER_MANAGEMENT_STRATEGY,
  EMPLOYS_WATER_RECYCLING_REUSE,
  HAS_AN_ENVIRONMENTAL_PROTECTION_POLICY_COMMITTEE,
  SIGNATORY_TO_UN_GLOBAL_COMPACT,
  HAZARDOUS_WASTE_INTENSITY,
  FOLLOWS_ISO14001_OR_EMAS_GUIDELINES,
  RECYCLED_OR_RECYCLABLE_RAW_MATERIALS,
  STATEMENT_OF_COMPREHENSIVE_ENVIRONMENT_POLICY,
  TOTAL_ENERGY_INTENSITY,
  REPORTS_ON_COMMUNITY_DEVELOPMENT_PROGRAMS,
  HAS_PROGRAMS_FOR_EDUCATION_ACCESS,
  HAS_PROGRAMS_FOR_HEALTH_CARE_ACCESS,
  LOST_DAYS_DUE_TO_INJURY,
  INJURY_INTENSITY,
  MANAGERIAL_POSITIONS_HELD_BY_WOMEN,
  VOLUNTARY_PRINCIPLES_ON_SECURITY_AND_HUMAN_RIGHTS_SIGNATORY,
  SUPPORTS_UNIVERSAL_DECLARATION_OF_HUMAN_RIGHTS,
  HUMAN_RIGHTS_TRAINING_FOR_EMPLOYEES,
  POLICY_ON_CUSTOMER_DATA_PRIVACY,
  POLICY_ON_SUPPLIER_DATA_PRIVACY,
  PREVENTS_THIRD_PARTY_ACCESS_TO_PERSONAL_INFORMATION,
  EXTERNAL_VERIFICATION_OF_CSR_ESG_REPORTING,
  QUALITY_MANAGEMENT_SYSTEM_CERTIFICATIONS,
  PRODUCT_SAFETY_AND_QUALITY_ASSURANCE_INITIATIVES,
  COMMUNICATES_CHEMICAL_CONSTITUENTS_IN_PRODUCTS,
  WASTE_INTENSITY,
}

export const PillarScoreToScoreKey = {
  [PillarScores.ENVIRONMENT]: 'environmentScore',
  [PillarScores.SOCIAL]: 'socialScore',
  [PillarScores.GOVERNANCE]: 'governanceScore',
  [PillarScores.GHG_INTENSITY]: 'GHCIntensity',
  [PillarScores.WASTE_MANAGEMENT]: 'wasteManagement',
  [PillarScores.WATER_EFFICIENCY]: 'waterEfficiency',
  [PillarScores.ANNUAL_EMPLOYEE_TURNOVER]: 'annualEmployedTurnover',
  [PillarScores.TRAINING_COSTS_PER_EMPLOYEE]: 'trainingCostPerEmployee',
  [PillarScores.RENEWABLE_ENERGY_TARGETS]: 'renewableEnergyTargets',
  [PillarScores.SUSTAINABILITY_PLANS_INTEGRATED]: 'sustainabilityPlans',
  [PillarScores.BIODIVERSITY_CONSERVATION_PROGRAMS]: 'biodiversityConservation',
  [PillarScores.BOARD_OF_DIRECTORS_WOMAN_OR_MINORITIES]: 'boardDirectorWoman',
  [PillarScores.WOMAN_OR_MINORITIES_REPRESENT]: 'minoritiesPercentage',
  [PillarScores.SUPPLIER_CODE_OF_CONDUCT]: 'supplierCodeOfConduct',
  [PillarScores.PROVIDES_TRAINING_ON_SUPPLIER_CODE]: 'providesTrainingOnSCC',
  [PillarScores.CONDUCTS_INSPECTION_OF_SUPPLIERS]: 'conductsInspectionsOfSuppliers',
  [PillarScores.MONITORS_IMPLEMENTATION_OF_HR_POLICIES]: 'monitorsImplementationHumanPolicies',
  [PillarScores.EXECUTIVE_COMPENSATION_LINKED_TO_ESG_PERFORMANCE]: 'executiveCompensationLinkedESG',
  [PillarScores.TOTAL_SCOPE_EMISSIONS]: 'totalScopeEmissions',
  [PillarScores.PARTICIPATES_IN_CARBON_DISCLOSURE_PROJECT]: 'participatesInCarbonDisclosureProject',
  [PillarScores.HAS_A_WATER_MANAGEMENT_STRATEGY]: 'hasAWaterManagementStrategy',
  [PillarScores.EMPLOYS_WATER_RECYCLING_REUSE]: 'employsWaterRecyclingOrReuse',
  [PillarScores.HAS_AN_ENVIRONMENTAL_PROTECTION_POLICY_COMMITTEE]:
    'hasAnEnvironmentalProtectionOrPolicyCommittee',
  [PillarScores.SIGNATORY_TO_UN_GLOBAL_COMPACT]: 'signatoryToUnGlobalCompact',
  [PillarScores.HAZARDOUS_WASTE_INTENSITY]: 'hazardousWasteIntensity',
  [PillarScores.FOLLOWS_ISO14001_OR_EMAS_GUIDELINES]: 'followsIso14001OrEcmasGuidelines',
  [PillarScores.RECYCLED_OR_RECYCLABLE_RAW_MATERIALS]: 'recycledOrRecyclableRawMaterials',
  [PillarScores.STATEMENT_OF_COMPREHENSIVE_ENVIRONMENT_POLICY]:
    'statementOfComprehensiveEnvironmentPolicy',
  [PillarScores.TOTAL_ENERGY_INTENSITY]: 'totalEnergyIntensity',
  [PillarScores.REPORTS_ON_COMMUNITY_DEVELOPMENT_PROGRAMS]: 'reportsOnCommunityDevelopmentPrograms',
  [PillarScores.HAS_PROGRAMS_FOR_EDUCATION_ACCESS]: 'hasProgramsForEducationAccess',
  [PillarScores.HAS_PROGRAMS_FOR_HEALTH_CARE_ACCESS]: 'hasProgramsForHealthCareAccess',
  [PillarScores.LOST_DAYS_DUE_TO_INJURY]: 'lostDaysDueToInjury',
  [PillarScores.INJURY_INTENSITY]: 'injuryIntensity',
  [PillarScores.MANAGERIAL_POSITIONS_HELD_BY_WOMEN]: 'managerialPositionsHeldByWomen',
  [PillarScores.VOLUNTARY_PRINCIPLES_ON_SECURITY_AND_HUMAN_RIGHTS_SIGNATORY]:
    'voluntaryPrinciplesOnSecurityAndHumanRightsSignatory',
  [PillarScores.SUPPORTS_UNIVERSAL_DECLARATION_OF_HUMAN_RIGHTS]:
    'supportsUniversalDeclarationOfHumanRights',
  [PillarScores.HUMAN_RIGHTS_TRAINING_FOR_EMPLOYEES]: 'humanRightsTrainingForEmployees',
  [PillarScores.POLICY_ON_CUSTOMER_DATA_PRIVACY]: 'policyOnCustomerDataPrivacy',
  [PillarScores.POLICY_ON_SUPPLIER_DATA_PRIVACY]: 'policyOnSupplierDataPrivacy',
  [PillarScores.PREVENTS_THIRD_PARTY_ACCESS_TO_PERSONAL_INFORMATION]:
    'preventsThirdPartyAccessToPersonalInformation',
  [PillarScores.EXTERNAL_VERIFICATION_OF_CSR_ESG_REPORTING]:
    'externalVerificationOfCsrOrEsgReporting',
  [PillarScores.QUALITY_MANAGEMENT_SYSTEM_CERTIFICATIONS]: 'qualityManagementSystemCertifications',
  [PillarScores.PRODUCT_SAFETY_AND_QUALITY_ASSURANCE_INITIATIVES]:
    'productSafetyAndQualityAssuranceInitiatives',
  [PillarScores.COMMUNICATES_CHEMICAL_CONSTITUENTS_IN_PRODUCTS]:
    'communicatesChemicalConstituentsInProducts',
  [PillarScores.WASTE_INTENSITY]: 'wasteIntensity',
  [PillarScores.INDUSTRY_RANK]: 'industryRank',
};

export enum Materialities {
  GHG = 'ghg',
  WATER = 'water',
  BIODIVERSITY = 'biodiversity',
  WASTE = 'waste',
  NATURAL_RESOURCES = 'naturalResources',
  ENERGY = 'energy',
  COMMUNITY = 'community',
  HEALTH = 'healthSafety',
  EMPLOYMENT = 'employment',
  SUPPLY_CHAIN = 'supplyChain',
  HUMAN_RIGHTS = 'humanRights',
  DATA_PROTECTION = 'dataProtection',
  INTEGRATED_ESG = 'integratedESG',
  QUALITY_SAFETY = 'productQualitySafety',
}

export interface IPillarScoreMetadata {
  Identifier: PillarScores;
  ValueType: 'boolean' | 'number';
  SortBy: 'desc' | 'asc';
  Materiality?: Materialities[];
}

export enum MaterialityType {
  POSITIVE,
  NEGATIVE,
  NEUTRAL,
}
