import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from 'src/redux/store';

import history from 'src/helpers/history';

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { DefaultTheme } from './styles/themes/default';

ReactDOM.render(
  <ReduxProvider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={DefaultTheme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </ReduxProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
