export const APP_DOMAIN = process.env.REACT_APP_APP_DOMAIN;

export const APP_URL = process.env.REACT_APP_APP_URL;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const SEGMENT_ANALYTICS_KEY = process.env.REACT_APP_SEGMENT_ANALYTICS_KEY;

export const APP_ENV = process.env.REACT_APP_ENV;

export const MAX_NOTICE_IN_NEWS_PAGE = 5;
