import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';

import { UserMessageType } from 'src/enums/userMessage';
import { SIZES } from 'src/helpers/devices';

interface IUserMessageType {
  messageType: UserMessageType | null;
}

interface ILoginRoute {
  isLogin: boolean;
}

export const MessageWrapper = styled.div<IUserMessageType & ILoginRoute>`
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    align-items: flex-start;
  }
  background-color: ${(props) =>
    props.messageType === UserMessageType.SUCCESS ? '#32CC6FD9' : '#CC3244D9'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1250;
  position: sticky;
  top: 56px;
  border-radius: ${(props) => (props.isLogin ? '10px' : '0')};
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  padding: 10px 0 10px 10px;
`;

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IConSuccess = styled(CheckCircleIcon)`
  width: 100%;
  height: 100%;
  color: #ffffff80;
`;

export const IConFail = styled(CancelIcon)`
  width: 100%;
  height: 100%;
  color: #ffffff80;
`;

export const Title = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 16px;
  line-height: 16px;
  margin: 0 5px 0 10px;
`;

export const Message = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 14px;
  line-height: 16px;
`;

export const WrapperHeader = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-bottom: 6px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;
