import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'PILLAR_DETAILS', {
  BEST_COMPANY_boolean: 'Yes',
  WORST_COMPANY_boolean: 'No',
  BEST_COMPANY_number: 'Best company',
  WORST_COMPANY_number: 'Worst company',
  PILLAR_NOT_APPLY: 'No companies in your industry apply to this pillar',
  NOT_APLICABLE: 'Not Applicable',
  VALUE_NOT_APLICABLE: '100.00%',
});
