import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'LOGIN_INPUT', {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot password',
});
