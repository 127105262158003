import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
  min-width: 400px;
  background-color: ${(props) => props.theme.palette.secondary};
`;

export const TooltipImage = styled.div`
  width: 400px;
  height: 225px;
  margin: 12px 0;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const TooltipHeader = styled.div`
  width: 400px;
  position: relative;
`;

export const CloseIcon = styled(CancelIcon)`
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 0;
  left: 96%;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.common.grey};
  }
`;

export const TooltipTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 16px;
  margin: 20px 0 10px;
`;

export const TooltipDescription = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 14px;
  line-height: 18px;
`;
