import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import userForgotPasswordSelector from 'src/redux/selectors/user/userForgotPassword';
import userLoadingSelector from 'src/redux/selectors/user/userLoading';
import userMessageSelector from 'src/redux/selectors/userMessage/userMessage';

import { useTypedSelector } from 'src/hooks/typeSelector';
import ForgotPassword from 'src/views/ForgotPassword';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();

  const loading: boolean = useTypedSelector(userLoadingSelector());
  const messageUser = useTypedSelector(userMessageSelector());
  const isSendEmail = useTypedSelector(userForgotPasswordSelector());

  const onInitializeForgotPasswordView = useCallback(() => {
    dispatch(userActions.initializeForgotPasswordView());
  }, [dispatch]);

  useEffect(() => {
    onInitializeForgotPasswordView();
  }, [onInitializeForgotPasswordView]);

  const onSubmit = (email: string) => {
    dispatch(userActions.forgotPasswordRequested(email));
  };

  const onRedirectToLogin = () => {
    dispatch(userActions.redirectToLogin());
  };

  return (
    <ForgotPassword
      loading={loading}
      userMessage={messageUser}
      onBackSignIn={onRedirectToLogin}
      onSubmit={onSubmit}
      isSendEmail={isSendEmail}
      onSubmitSuccessModal={onRedirectToLogin}
    />
  );
};

export default ForgotPasswordContainer;
