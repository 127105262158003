import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import userInsightsSelector from 'src/redux/selectors/insights/userInsights.selector';

import { useTypedSelector } from 'src/hooks/typeSelector';
import Insights from 'src/views/Insights';

import LayoutContainer from './Layout';

const InsightsContainer = () => {
  const dispatch = useDispatch();
  const userInsights = useTypedSelector(userInsightsSelector());

  const onInitializeInsightsView = useCallback(() => {
    dispatch(userActions.initializeInsightsView());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeInsightsView();
  }, [onInitializeInsightsView]);

  return (
    <LayoutContainer>
      <Insights userInsights={userInsights} />
    </LayoutContainer>
  );
};
export default InsightsContainer;
