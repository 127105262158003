import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const HelpWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    overflow: auto;
    max-height: 600px;
  }
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.septenary};
  border-radius: 20px;
`;

export const Title = styled.p`
  font-size: 28px;
  line-height: 38px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTitle = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 90%;
    height: 50px;
    top: 0;
    display: flex;
    align-items: flex-end;
    position: fixed;
    background-color: ${(props) => props.theme.palette.septenary};
  }
`;

export const CardsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);

    margin-top: 40px;
  }
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 20px;
`;

export const PillarScoreWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    height: 50px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    &:hover {
      height: 160px;
    }
  }
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  transition: all 0.3s;
  &:hover {
    flex-direction: column;
    background-color: ${(props) => props.theme.palette.lightSecondary};
    align-items: baseline;
    justify-content: flex-start;
  }
`;

export const IconWrapper = styled(ReactSVG)`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 50px;
    height: 50px;
  }
  width: 75px;
  height: 75px;
  transition: 0.6s;
  ${PillarScoreWrapper}:hover & {
    width: 30px;
    height: 30px;
  }
`;

export const PillarName = styled.p`
  @media (max-width: ${SIZES.smallTablet}px) {
    font-size: 18px;
  }
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  transition: 0.6s;
  ${PillarScoreWrapper}:hover & {
    color: ${(props) => props.theme.palette.common.white};
  }
`;

export const PillarHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const PillarDescription = styled.p`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const WrapperText = styled.p`
  padding: 20px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;
