import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import SearchBarModule from 'src/components/SearchBarModule';
import { PillarScores } from 'src/enums/pillarScores';
import { pillarScoreTexts } from 'src/helpers/pillarsScores';
import { getPillarScoresByMateriality } from 'src/helpers/scores';

import MaterialityComparisonChart from './components/MaterialityComparisonChart';
import MaterialityComparisonTable from './components/MaterialityComparisonTable';
import './i18n';
import {
  ChartIcon,
  ContentWrapper,
  HeaderWrapper,
  LoadingIndicator,
  LoadingWrapper,
  ModuleSubtitle,
  ModuleTitle,
  ModuleTitleWrapper,
  TableIcon,
  Title,
  TitleWrapper,
} from './styles';
import { CompanyPerformViewTypes, IHowCompetitorPerformModuleProps } from './types';

const HowCompetitorPerformModule = (props: IHowCompetitorPerformModuleProps) => {
  const {
    competitors,
    materialities,
    onAddCompetitor,
    onRemoveCompetitor,
    onSearchCompetitor,
    onTrackEvent,
    searchedCompetitors,
    userCompany,
    positiveCompetitorsMaterialities,
    positiveMaterialities,
    isMobile,
  } = props;

  const [chartSelectedPillar, setChartSelectedPillar] = useState<PillarScores | null>(null);
  const [viewType, setViewType] = useState(CompanyPerformViewTypes.TABLE);

  const loadingModule = !materialities || !positiveCompetitorsMaterialities || !competitors;

  const handleViewTypeChange = () => {
    if (viewType === CompanyPerformViewTypes.TABLE) {
      setViewType(CompanyPerformViewTypes.CHART);
      onTrackEvent('Materiality comparison view change', {
        selectedView: CompanyPerformViewTypes.CHART,
      });
    } else {
      setViewType(CompanyPerformViewTypes.TABLE);
      onTrackEvent('Materiality comparison view change', {
        selectedView: CompanyPerformViewTypes.TABLE,
      });
    }
  };

  const handleChartPillarChange = (pillar: PillarScores) => {
    setChartSelectedPillar(pillar);
    onTrackEvent('Materiality comparison chart pillar change', {
      selectedPillar: pillarScoreTexts.find((pillarData) => pillarData.value === pillar)!.text,
    });
  };

  useEffect(() => {
    if (materialities && materialities.length !== 0) {
      setChartSelectedPillar(getPillarScoresByMateriality([materialities[0]])[0].Identifier);
    }
  }, [materialities]);

  return (
    <>
      <TitleWrapper>
        <Title>{i18next.t('HOW_COMPETITOR_PERFORM_MODULE:TITLE')}</Title>
      </TitleWrapper>
      <SearchBarModule
        currentCompetitors={competitors || []}
        onAddCompetitor={onAddCompetitor}
        onRemoveCompetitor={onRemoveCompetitor}
        onSearchCompetitor={onSearchCompetitor}
        searchCompetitors={searchedCompetitors}
      />
      {loadingModule && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {!loadingModule && (
        <ContentWrapper>
          <HeaderWrapper>
            <ModuleTitleWrapper>
              <ModuleTitle>
                {' '}
                {i18next.t(
                  `HOW_COMPETITOR_PERFORM_MODULE:${
                    viewType === CompanyPerformViewTypes.TABLE
                      ? 'MODULE_TITLE'
                      : 'CHART_MODULE_TITLE'
                  }`,
                )}{' '}
              </ModuleTitle>
              <ModuleSubtitle>
                {i18next.t(
                  `HOW_COMPETITOR_PERFORM_MODULE:${
                    viewType === CompanyPerformViewTypes.TABLE
                      ? userCompany.lastUpdatedScore
                        ? 'MODULE_SUBTITLE'
                        : 'MODULE_SUBTITLE_WITHOUT_SCORE'
                      : 'CHART_MODULE_SUBTITLE'
                  }`,
                )}
              </ModuleSubtitle>
            </ModuleTitleWrapper>
            {viewType === CompanyPerformViewTypes.TABLE ? (
              <TableIcon onClick={handleViewTypeChange} />
            ) : (
              <ChartIcon onClick={handleViewTypeChange} />
            )}
          </HeaderWrapper>
          {viewType === CompanyPerformViewTypes.TABLE ? (
            <MaterialityComparisonTable
              competitors={competitors}
              userCompany={userCompany}
              materiality={materialities}
              positiveCompetitorsMaterialities={positiveCompetitorsMaterialities}
              positiveMaterialities={positiveMaterialities}
              isMobile={isMobile}
            />
          ) : (
            <MaterialityComparisonChart
              materialities={materialities}
              selectedPillar={chartSelectedPillar}
              userCompany={userCompany}
              competitors={competitors}
              onChangePillar={handleChartPillarChange}
            />
          )}
        </ContentWrapper>
      )}
    </>
  );
};

export default HowCompetitorPerformModule;
