import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { IPillarProps } from './types';

export const ComparisonChartWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    max-height: unset;
  }
  display: flex;
  height: 100%;
  max-height: 380px;
`;

export const MaterialityListWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    max-height: 270px;
  }
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  overflow-x: hidden;
  direction: rtl;
  padding-left: 10px;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.palette.septenary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.palette.lightSecondary};
    border-radius: 10px;
    &:hover {
      background-color: ${(props) => props.theme.palette.lightSecondary};
    }
  }
`;

export const PillarChartWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
    max-height: unset;
    margin: 20px auto 0;
    flex: unset;
    padding: 0;
  }
  flex: 3;
  margin: 0 auto;
  padding: 0 40px;
  max-height: 350px;
`;

export const MaterialityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  direction: ltr;
`;

export const Materiality = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: #fdfdfd;
  font-size: 14px;
  line-height: 14px;
  background-color: ${(props) => props.theme.palette.lightSecondary};
  border-radius: 10px;
  padding: 20px;
`;

export const MaterialityPillarsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  font-size: 18px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.grey};
  text-align: center;
  margin-bottom: 20px;
`;

export const Pillar = styled.div<IPillarProps>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.palette.quinternary : props.theme.palette.septenary};
  border-radius: 10px;
  padding: 20px;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.palette.common.grey};
  ${(props) =>
    !props.isSelected &&
    `
  &:hover {
      cursor: pointer;
    background-color: ${props.theme.palette.quinternary};
  }
  `}
`;
