import { AnyAction, MiddlewareAPI } from 'redux';

import { APP_DOMAIN, APP_ENV } from 'src/configs/configs';
import { removeCookie, setCookie } from 'src/helpers/cookies';

import * as appConstants from '../constants/app';
import * as userConstants from '../constants/user';

const saveAuthToken = (authToken: string) => {
  setCookie(APP_ENV === 'production' ? `token` : `token_${APP_ENV}`, authToken, {
    expires: 30,
    domain: APP_DOMAIN,
  });
};

const removeAuthToken = () => {
  removeCookie(APP_ENV === 'production' ? `token` : `token_${APP_ENV}`, {
    domain: APP_DOMAIN,
  });
};

const cookiesMiddleware =
  (state: MiddlewareAPI) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      case userConstants.USER_ON_LOGIN_SUCCEEDED:
        saveAuthToken(action.authToken);
        break;
      case userConstants.USER_ON_SIGN_OUT:
        removeAuthToken();
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          removeAuthToken();
        }
        break;
      default:
        break;
    }
    next(action);
  };

export default cookiesMiddleware;
