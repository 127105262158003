import i18next from 'i18next';
import React from 'react';

import './i18n';
import { CommentContent, CommentSignature, CommentTitle, CommentWrapper } from './styles';
import { ICommentProps } from './types';

const Comment = (props: ICommentProps) => {
  const { comment, disableTitle = false } = props;

  return (
    <CommentWrapper>
      {!disableTitle && <CommentTitle>{i18next.t('COMMENT:TITLE')}</CommentTitle>}
      <CommentContent>"{comment.description}"</CommentContent>
      <CommentSignature>
        {comment.signatureName}
        {comment.signaturePosition !== '' && ` - ${comment.signaturePosition}`}
      </CommentSignature>
    </CommentWrapper>
  );
};

export default Comment;
