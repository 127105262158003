import {
  ArgumentAxis,
  CommonAxisSettings,
  ConstantLine,
  DataPrepareSettings,
  Label,
  Series,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import i18next from 'i18next';
import React from 'react';

import { DefaultTheme } from 'src/styles/themes/default';

import './i18n';
import {
  GraphWrapper,
  HeaderWrapper,
  LoadingIndicator,
  LoadingWrapper,
  Subtitle,
  Title,
  Wrapper,
} from './styles';
import { IRealScoreChartData, IRealScoreRankingModuleProps } from './types';

const customizePoint = (point: any) => {
  if (point.value > 0) {
    return { color: '#32CC6F', hoverStyle: { color: '#32CC6F' } };
  }
  if (point.value < 0) {
    return { color: '#F79C33', hoverStyle: { color: '#F79C33' } };
  }
  return {};
};

const renderCompanyLabel = (point: any, userCompanyName: string) => {
  if (point.valueText === userCompanyName) {
    return (
      <text fill={DefaultTheme.palette.secondary} fontFamily={DefaultTheme.fontFamilies.bold}>
        {point.valueText}
      </text>
    );
  }
  return (
    <text fill={DefaultTheme.palette.common.grey} fontFamily={DefaultTheme.fontFamilies.regular}>
      {point.valueText}
    </text>
  );
};

const RealScoreRankingModule = (props: IRealScoreRankingModuleProps) => {
  const { userCompany, competitors } = props;
  let chartData: IRealScoreChartData[] = [];
  if (userCompany.lastUpdatedScore) {
    chartData = chartData.concat({
      company: userCompany.name,
      realScore: Number(userCompany.lastUpdatedScore.realScore.toFixed(0)),
    });
  }
  chartData = chartData.concat(
    competitors?.map((competitor) => ({
      company: competitor.name,
      realScore: Number(competitor.lastUpdatedScore!.realScore.toFixed(0)),
    })) || [],
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title> {i18next.t('REAL_SCORE_RANKING_MODULE:TITLE')} </Title>
        <Subtitle> {i18next.t('REAL_SCORE_RANKING_MODULE:SUBTITLE')} </Subtitle>
      </HeaderWrapper>
      {!competitors && (
        <LoadingWrapper data-testid="loading-block">
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {competitors && (
        <GraphWrapper
          id="real-score-ranking-chart"
          animation={{ enabled: false }}
          rotated
          customizePoint={customizePoint}
          dataSource={chartData}
        >
          <DataPrepareSettings
            sortingMethod={(a: IRealScoreChartData, b: IRealScoreChartData) => {
              if (a.realScore > b.realScore) {
                return 1;
              }
              return -1;
            }}
          />
          <CommonAxisSettings tick={{ width: 0 }} />
          <Series
            argumentField="company"
            type="bar"
            cornerRadius="2px"
            showInLegend={false}
            valueField="realScore"
          />
          <Tooltip
            enabled
            zIndex={1400}
            color={DefaultTheme.palette.secondary}
            font={{
              color: DefaultTheme.palette.common.white,
              family: DefaultTheme.fontFamilies.bold,
              size: 22,
            }}
          />
          <CommonAxisSettings grid={{ color: DefaultTheme.palette.quaternary, opacity: 0.5 }} />
          <ValueAxis position="top">
            <ConstantLine
              width={1}
              value={0}
              label={{ text: '' }}
              color={DefaultTheme.palette.tertiary}
            />
          </ValueAxis>
          <ArgumentAxis>
            <Label
              render={(point: any) => renderCompanyLabel(point, userCompany.name)}
              alignment="left"
              visible
            />
          </ArgumentAxis>
        </GraphWrapper>
      )}
    </Wrapper>
  );
};

export default RealScoreRankingModule;
