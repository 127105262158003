import i18next from 'i18next';
import React from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';

import './i18n.ts';
import {
  InputsWrapper,
  LogoWrapper,
  MotiveLogo,
  SubtitleWrapper,
  TextWrapper,
  TitleWrapper,
  WelcomeWrapper,
} from './styles';
import { IModalSuccessProps } from './types';

const ModalSuccessSignUp = (props: IModalSuccessProps) => {
  const { onSubmitSuccessModal } = props;
  return (
    <InputsWrapper>
      <LogoWrapper data-testid={'logo-motive-modal-success'}>
        <MotiveLogo src={Logo} alt="Motive Logo" />
      </LogoWrapper>

      <TitleWrapper data-testid={'message-modal-success-title'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:CONGRATULATIONS')}
      </TitleWrapper>
      <SubtitleWrapper data-testid={'message-modal-success-subtitle'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:MESSAGE_1')}
      </SubtitleWrapper>
      <TextWrapper data-testid={'message-modal-success-msg1'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:MESSAGE_2')}
      </TextWrapper>
      <TextWrapper data-testid={'message-modal-success-msg2'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:MESSAGE_3')}
      </TextWrapper>
      <WelcomeWrapper data-testid={'message-modal-success-msg3'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:MESSAGE_4')}
      </WelcomeWrapper>
      <TextWrapper data-testid={'message-modal-success-motive'}>
        {i18next.t('SIGN_UP_MODAL_SUCCESS:MOTIVE')}
      </TextWrapper>
      <TextButton
        onSubmit={onSubmitSuccessModal}
        isDisabled={false}
        isLoading={false}
        text={i18next.t('SIGN_UP_MODAL_SUCCESS:BUTTON_COPY')}
      />
    </InputsWrapper>
  );
};
export default ModalSuccessSignUp;
