import i18next from 'i18next';
import React from 'react';

import ModuleHelp from 'src/components/ModuleHelp';
import { HelpModules } from 'src/enums/help';
import { DefaultTheme } from 'src/styles/themes/default';

import InsightsModule from './components/InsightsModule';
import { LoadingSkeleton } from './components/InsightsModule/styles';
import NewsModule from './components/NewsModule';
import RatingCoverageModule from './components/RatingCoverageModule';
import RealScoreModule from './components/RealScoreModule';
import './i18n';
import {
  InsightsModuleWrapper,
  NewsModuleWrapper,
  RatingCoverageModuleWrapper,
  ScoreModuleWrapper,
  SummaryWrapper,
} from './styles';
import { ISummaryProps } from './types';

const Summary = (props: ISummaryProps) => {
  const {
    news,
    scores,
    company,
    industryRank,
    insights,
    helpModule,
    onCloseHelp,
    onNextHelp,
    onBackHelp,
    handleTrackEvent,
  } = props;

  return (
    <SummaryWrapper>
      <RatingCoverageModuleWrapper>
        {company ? (
          <ModuleHelp
            onClose={() => onCloseHelp(HelpModules.SUMMARY_RATING_COVERAGE)}
            onNext={() => onNextHelp(HelpModules.SUMMARY_RATING_COVERAGE)}
            onBack={() => onBackHelp(HelpModules.SUMMARY_RATING_COVERAGE)}
            enabled={helpModule === HelpModules.SUMMARY_RATING_COVERAGE}
            title={i18next.t('SUMMARY_HELP_RATING_COVERAGE:TITLE')}
            description={i18next.t('SUMMARY_HELP_RATING_COVERAGE:DESCRIPTION')}
          >
            <RatingCoverageModule scores={company.scores} handleTrackEvent={handleTrackEvent} />
          </ModuleHelp>
        ) : (
          <LoadingSkeleton height={350} />
        )}
      </RatingCoverageModuleWrapper>
      <ScoreModuleWrapper>
        {scores && company ? (
          <ModuleHelp
            isFirst
            onClose={() => onCloseHelp(HelpModules.SUMMARY_REAL_SCORE)}
            onNext={() => onNextHelp(HelpModules.SUMMARY_REAL_SCORE)}
            onBack={() => onBackHelp(HelpModules.SUMMARY_REAL_SCORE)}
            enabled={helpModule === HelpModules.SUMMARY_REAL_SCORE}
            title={i18next.t('SUMMARY_HELP_REAL_SCORE:TITLE')}
            description={i18next.t('SUMMARY_HELP_REAL_SCORE:DESCRIPTION')}
          >
            <RealScoreModule
              scores={scores}
              industry={company.industry}
              industryRank={industryRank}
              handleTrackEvent={handleTrackEvent}
            />
          </ModuleHelp>
        ) : (
          <LoadingSkeleton height={350} />
        )}
      </ScoreModuleWrapper>
      <InsightsModuleWrapper>
        <ModuleHelp
          onClose={() => onCloseHelp(HelpModules.SUMMARY_INSIGHTS)}
          onNext={() => onNextHelp(HelpModules.SUMMARY_INSIGHTS)}
          onBack={() => onBackHelp(HelpModules.SUMMARY_INSIGHTS)}
          enabled={helpModule === HelpModules.SUMMARY_INSIGHTS}
          backgroundColor={DefaultTheme.palette.common.white}
          tooltipPosition="right-start"
          title={i18next.t('SUMMARY_HELP_INSIGHTS:TITLE')}
          description={i18next.t('SUMMARY_HELP_INSIGHTS:DESCRIPTION')}
        >
          <InsightsModule insights={insights} />
        </ModuleHelp>
      </InsightsModuleWrapper>
      <NewsModuleWrapper>
        <ModuleHelp
          isLast
          onClose={() => onCloseHelp(HelpModules.SUMMARY_NEWS)}
          onNext={() => onNextHelp(HelpModules.SUMMARY_NEWS)}
          onBack={() => onBackHelp(HelpModules.SUMMARY_NEWS)}
          enabled={helpModule === HelpModules.SUMMARY_NEWS}
          tooltipPosition="left-start"
          title={i18next.t('SUMMARY_HELP_NEWS:TITLE')}
          description={i18next.t('SUMMARY_HELP_NEWS:DESCRIPTION')}
        >
          <NewsModule news={news} />
        </ModuleHelp>
      </NewsModuleWrapper>
    </SummaryWrapper>
  );
};

export default Summary;
