import i18next from 'i18next';

import { locales } from 'src/configs/i18n';
import { PillarScores } from 'src/enums/pillarScores';

i18next.addResources(locales.EN, `REAL_SCORE_HELP`, {
  TITLE: 'Pillar scores',
  MESSAGE:
    'For a detailed view of all data points factored into the pillar scores, please navigate to the Explorer tab in the side-menu of your control center.',
});

i18next.addResources(locales.EN, `REAL_SCORE_HELP_${PillarScores.INDUSTRY_RANK}`, {
  TITLE: 'Industry Rank',
  DESCRIPTION:
    'Your Industry Rank is a measure of how your RealScore™ compares to other companies within the same industry sector.',
});

i18next.addResources(locales.EN, `REAL_SCORE_HELP_${PillarScores.GOVERNANCE}`, {
  TITLE: 'Governance Score',
  DESCRIPTION:
    'This aggregate score reflects your company’s policies, disclosures, and performance on issues such as Board Composition, Executive Structure & Accountability, Shareholder Relations, and Regulatory Compliance.',
});

i18next.addResources(locales.EN, `REAL_SCORE_HELP_${PillarScores.SOCIAL}`, {
  TITLE: 'Social Score',
  DESCRIPTION:
    'This aggregate score reflects your company’s policies, disclosures, and performance on issues such as Employment Relations, Diversity & Inclusion, Human Rights, Community and Product Responsibility.',
});

i18next.addResources(locales.EN, `REAL_SCORE_HELP_${PillarScores.ENVIRONMENT}`, {
  TITLE: 'Environment Score',
  DESCRIPTION:
    'This aggregate score reflects your company’s policies, disclosures, and performance on issues such as Climate Action & Emissions, Resource Use, Materials & Waste Management, and Environmental Innovations.',
});
