import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'MATERIALITY_PERFORMANCE_MODULE', {
  TITLE: 'How {{company}} Performs',
  SUBTITLE: 'Key performance indicators relative to what matters most to your client.',
  POSITIVE_true: 'Positive',
  POSITIVE_false: 'Negative',
  TITLE_WIHOUT_SCORES: 'How Your Industry Performs On These Issues ',
  SUBTITLE_WIHOUT_SCORES:
    'See the performance ranges of companies in your industry across the pillars that matter most to your client.',
  EXPLANAITION:
    'Motive does not have complete data on your company performance at this time. Compare your internal data to the industry data provided above to understand your relative performance.',
});
