import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { ITabProps } from './types';

export const ScoresModulesWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  height: 100%;
`;

export const ScoresModulesTabs = styled.div<ITabProps>`
  text-align: center;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  width: 32%;
  flex-direction: column;
  height: 100%;
  background-color: ${(props) => props.scoreColors.firstColor};
`;

export const TabHeader = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: column;
`;

export const ScoreIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const ScoreIconWrapper = styled.div`
  width: 45px;
  height: 45px;
`;

export const ScoreTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.white};
  font-size: 28px;
  margin-top: 10px;
`;

export const DataWrapper = styled.div<ITabProps>`
  width: 80%;
  background-color: ${(props) => props.scoreColors.secondColor};
  margin: 20px 0;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const RealScoreTitleWrapper = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  color: ${(props) => props.theme.palette.common.white};
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ViewMoreWrapper = styled.a`
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: 20px;
  text-decoration: underline;
`;

export const ItemTitle = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    margin: 20px 0 10px;
  }

  text-align: left;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 20px;
  display: flex;
  white-space: nowrap;
  line-height: 27px;
  align-items: flex-end;
  color: ${(props) => props.theme.palette.common.white};
`;

export const TabList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const RealScoreValue = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-bottom: 20px;
  }
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 40px;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.palette.common.white};
`;

export const IndustryRankValue = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    margin-bottom: 20px;
  }

  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 34px;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.palette.common.white};
`;
