import { Help } from '@mui/icons-material';
import styled from 'styled-components';

export const RatingCoverageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 9;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px 0;
  margin: 0 20px;
`;

export const HelpIcon = styled(Help)`
  width: 25px;
  height: 25px;
  color: ${(props) => props.theme.palette.tertiary};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.lightSecondary};
  }
`;

export const Title = styled.p`
  font-size: 20px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ScoresWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 0;
`;

export const MultipleScoresWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
`;

export const Score = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;

export const ScoreLabel = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  flex: 1;
`;

export const ScoreNumber = styled.p`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-size: 18px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  text-align: center;
`;
