import i18next from 'i18next';
import React from 'react';

import './i18n';
import {
  ExportButton,
  HelpIcon,
  WrapperButton,
  WrapperHelp,
  WrapperText,
  Title,
  Subtitle,
  BackArrow,
  TitleWrapper,
} from './styles';
import { IHeaderModuleProps } from './types';

const HeaderModule = (props: IHeaderModuleProps) => {
  const {
    handleOpenHelp,
    handleExportData,
    renderExportData,
    backArrow = false,
    handleBackArrowClick = () => {},
  } = props;

  return (
    <>
      <WrapperText>
        <TitleWrapper>
          {backArrow && <BackArrow onClick={handleBackArrowClick} />}
          <Title data-testid={'title-sales-page'}>{i18next.t('HEADER_SALES:TITLE')}</Title>
        </TitleWrapper>
        <Subtitle data-testid={'subtitle-sales-page'}>
          {i18next.t('HEADER_SALES:SUBTITLE')}
          <WrapperHelp>
            <HelpIcon data-testid={'help-icon-sales-page'} onClick={handleOpenHelp} />
          </WrapperHelp>
        </Subtitle>
      </WrapperText>
      {renderExportData && (
        <WrapperButton>
          <ExportButton data-testid={'button-export-sales-page'} onClick={handleExportData}>
            {i18next.t('HEADER_SALES:BUTTON_EXPORT')}{' '}
          </ExportButton>
        </WrapperButton>
      )}
    </>
  );
};

export default HeaderModule;
