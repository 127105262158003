import i18next from 'i18next';
import React from 'react';

import LottiePlayer from '../../../LottiePlayer';
import '../../i18n';
import { NavigateButton, TooltipFooter } from '../../styles';
import {
  CloseIcon,
  TooltipDescription,
  TooltipHeader,
  TooltipImage,
  TooltipTitle,
  TooltipWrapper,
} from './styles';
import { IAnimationToolTipProps } from './types';

const AnimationToolTip = (props: IAnimationToolTipProps) => {
  const { title, animationPath, description, isLast, isFirst, onBack, onClose, onNext } = props;

  return (
    <TooltipWrapper>
      <TooltipHeader>
        <CloseIcon data-testid="close-icon-animation-tool-tip" onClick={onClose} />
        <TooltipImage data-testid="json-animation-tool-tip">
          <LottiePlayer animationJsonPath={animationPath} loop autoplay />
        </TooltipImage>
      </TooltipHeader>
      <TooltipTitle data-testid="title-animation-tool-tip">{title}</TooltipTitle>
      <TooltipDescription data-testid="description-animation-tool-tip">
        {description}
      </TooltipDescription>
      <TooltipFooter isFirstModule={isFirst}>
        {!isFirst && (
          <NavigateButton data-testid="navigation-back-animation-tool-tip" onClick={onBack}>
            {i18next.t('MODULE_HELP:BACK')}
          </NavigateButton>
        )}
        <NavigateButton data-testid="navigation-next-animation-tool-tip" onClick={onNext}>
          {isLast ? i18next.t('MODULE_HELP:GOT_IT') : i18next.t('MODULE_HELP:NEXT')}
        </NavigateButton>
      </TooltipFooter>
    </TooltipWrapper>
  );
};

export default AnimationToolTip;
