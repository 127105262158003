import i18next from 'i18next';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Modal from 'src/components/Modal';
import ModalExport from 'src/components/ModalExport';
import ModuleHelp from 'src/components/ModuleHelp';
import { HelpModules } from 'src/enums/help';
import { Materialities } from 'src/enums/pillarScores';
import { SIZES } from 'src/helpers/devices';
import { DefaultTheme } from 'src/styles/themes/default';

import HeaderModule from './components/Header';
import HelpSales from './components/Helps/HelpsSales';
import TargetHelp from './components/Helps/TargetHelp';
import HowCompetitorPerformModule from './components/HowCompetitorPerformModule';
import MaterialityPerformanceMobileModule from './components/MaterialityPerformanceMobileModule';
import MaterialityPerformanceModule from './components/MaterialityPerformanceModule';
import MaterialityRankingModule from './components/MaterialityRankingModule';
import SalesTargetModule from './components/SalesTarget';
import './i18n';
import {
  HeaderWrapper,
  SalesGrid,
  SalesWrapper,
  MaterialityRankingWrapper,
  MaterialityPerformanceWrapper,
  HowCompetitorPerformWrapper,
  TargetSalesGrid,
  MaterialityRankingModuleWrapper,
} from './styles';
import { ISalesProps } from './types';

const Sales = (props: ISalesProps) => {
  const {
    targetIndustry,
    targetName,
    allMaterialities,
    positiveMaterialities,
    userCompany,
    competitors,
    searchedCompetitors,
    onAddCompetitor,
    onRemoveCompetitor,
    onSearchCompetitor,
    onSalesBack,
    industryScores,
    handleTrackEvent,
    positiveCompetitorsMaterialities,
    onSubmit,
    searchedCompetitorsWithoutCompetitor,
    helpModule,
    onBackHelp,
    onCloseHelp,
    onNextHelp,
  } = props;
  const [isOpenModalExport, setIsOpenModalExport] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isTargetHelpOpen, setIsTargetHelpOpen] = useState(false);

  const handleExportData = () => {
    handleTrackEvent('Export sales open');
    setIsOpenModalExport(true);
  };
  const handleCloseModalExport = () => {
    setIsOpenModalExport(false);
  };

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
    handleTrackEvent('Open Sales help');
  };

  const handleOpenTargetHelp = () => {
    setIsTargetHelpOpen(true);
    handleTrackEvent('Open Target Sales help');
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
    handleTrackEvent('Close Sales help');
  };

  const handleCloseTargetHelp = () => {
    setIsTargetHelpOpen(false);
    handleTrackEvent('Close Target Sales help');
  };

  const handleMaterialityTabChange = (materiality: Materialities) => {
    handleTrackEvent('Materiality tab change', { materiality });
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.bigPhone}px)`,
  });

  return (
    <SalesWrapper>
      <Modal open={isHelpOpen} onClose={handleCloseHelp} width={773}>
        <HelpSales hasScore={!userCompany.lastUpdatedScore} />
      </Modal>
      <Modal open={isTargetHelpOpen} onClose={handleCloseTargetHelp} width={773}>
        <TargetHelp />
      </Modal>
      {!targetIndustry && !targetName && (
        <TargetSalesGrid>
          <HeaderWrapper>
            <HeaderModule
              renderExportData={false}
              handleExportData={handleExportData}
              handleOpenHelp={handleOpenTargetHelp}
            />
          </HeaderWrapper>
          <SalesTargetModule
            onSearchCompetitor={onSearchCompetitor}
            searchedCompetitors={searchedCompetitorsWithoutCompetitor}
            onSubmit={onSubmit}
          />
        </TargetSalesGrid>
      )}
      {targetIndustry && (
        <SalesGrid>
          <ModalExport
            open={isOpenModalExport}
            title={i18next.t('SALES:TITLE_MODAL_EXPORT')}
            onClose={handleCloseModalExport}
          />
          <HeaderWrapper>
            <HeaderModule
              renderExportData={!isMobile}
              backArrow={true}
              handleBackArrowClick={onSalesBack}
              handleExportData={handleExportData}
              handleOpenHelp={handleOpenHelp}
            />
          </HeaderWrapper>
          <MaterialityRankingWrapper>
            <ModuleHelp
              isFirst
              onClose={() => onCloseHelp(HelpModules.SALES_MATERIALITY_RANKING)}
              onNext={() => onNextHelp(HelpModules.SALES_MATERIALITY_RANKING)}
              onBack={() => onBackHelp(HelpModules.SALES_MATERIALITY_RANKING)}
              enabled={helpModule === HelpModules.SALES_MATERIALITY_RANKING}
              modalWidth={500}
              arrow={false}
              tooltipPosition="bottom"
              backgroundColor={DefaultTheme.palette.common.white}
              title={i18next.t(
                `${
                  userCompany.lastUpdatedScore ? 'TUTORIAL_SALES' : 'TUTORIAL_SALES_WITHOUT_SCORES'
                }:TITLE_FIRST_MODULE`,
              )}
              description={i18next.t(
                `${
                  userCompany.lastUpdatedScore ? 'TUTORIAL_SALES' : 'TUTORIAL_SALES_WITHOUT_SCORES'
                }:DESCRIPTION_FIRST_MODULE`,
              )}
            >
              <MaterialityRankingModuleWrapper>
                <MaterialityRankingModule
                  materiality={allMaterialities}
                  targetIndustry={decodeURIComponent(targetIndustry as string)}
                  targetName={targetName ? decodeURIComponent(targetName as string) : ''}
                />
              </MaterialityRankingModuleWrapper>
            </ModuleHelp>
            {!isMobile && (
              <ModuleHelp
                onClose={() => onCloseHelp(HelpModules.SALES_MATERIALITY_PERFORMANCE)}
                onNext={() => onNextHelp(HelpModules.SALES_MATERIALITY_PERFORMANCE)}
                onBack={() => onBackHelp(HelpModules.SALES_MATERIALITY_PERFORMANCE)}
                enabled={helpModule === HelpModules.SALES_MATERIALITY_PERFORMANCE}
                modalWidth={500}
                arrow={false}
                tooltipPosition="top"
                backgroundColor={DefaultTheme.palette.common.white}
                title={i18next.t(
                  `${
                    userCompany.lastUpdatedScore
                      ? 'TUTORIAL_SALES'
                      : 'TUTORIAL_SALES_WITHOUT_SCORES'
                  }:TITLE_SECOND_MODULE`,
                )}
                description={i18next.t(
                  `${
                    userCompany.lastUpdatedScore
                      ? 'TUTORIAL_SALES'
                      : 'TUTORIAL_SALES_WITHOUT_SCORES'
                  }:DESCRIPTION_SECOND_MODULE`,
                )}
              >
                <MaterialityPerformanceWrapper>
                  <MaterialityPerformanceModule
                    allMaterialities={allMaterialities}
                    positiveMaterialities={positiveMaterialities}
                    userCompany={userCompany}
                    industryScores={industryScores}
                    handleTabChange={handleMaterialityTabChange}
                  />
                </MaterialityPerformanceWrapper>
              </ModuleHelp>
            )}
            {isMobile && (
              <MaterialityPerformanceWrapper>
                <MaterialityPerformanceMobileModule
                  allMaterialities={allMaterialities}
                  positiveMaterialities={positiveMaterialities}
                  userCompany={userCompany}
                  industryScores={industryScores}
                  handleTabChange={handleMaterialityTabChange}
                />
              </MaterialityPerformanceWrapper>
            )}
          </MaterialityRankingWrapper>
          <ModuleHelp
            isLast
            onClose={() => onCloseHelp(HelpModules.SALES_HOW_COMPETITOR_PERFORM)}
            onNext={() => onNextHelp(HelpModules.SALES_HOW_COMPETITOR_PERFORM)}
            onBack={() => onBackHelp(HelpModules.SALES_HOW_COMPETITOR_PERFORM)}
            enabled={helpModule === HelpModules.SALES_HOW_COMPETITOR_PERFORM}
            modalWidth={500}
            arrow={false}
            tooltipPosition="bottom"
            backgroundColor={DefaultTheme.palette.common.white}
            title={i18next.t(
              `${
                userCompany.lastUpdatedScore ? 'TUTORIAL_SALES' : 'TUTORIAL_SALES_WITHOUT_SCORES'
              }:TITLE_THIRD_MODULE`,
            )}
            description={i18next.t(
              `${
                userCompany.lastUpdatedScore ? 'TUTORIAL_SALES' : 'TUTORIAL_SALES_WITHOUT_SCORES'
              }:DESCRIPTION_THIRD_MODULE`,
            )}
          >
            <HowCompetitorPerformWrapper>
              <HowCompetitorPerformModule
                materialities={allMaterialities}
                competitors={competitors}
                onAddCompetitor={onAddCompetitor}
                onRemoveCompetitor={onRemoveCompetitor}
                onSearchCompetitor={onSearchCompetitor}
                onTrackEvent={handleTrackEvent}
                searchedCompetitors={searchedCompetitors}
                userCompany={userCompany}
                positiveCompetitorsMaterialities={positiveCompetitorsMaterialities}
                positiveMaterialities={positiveMaterialities}
                isMobile={isMobile}
              />
            </HowCompetitorPerformWrapper>
          </ModuleHelp>
        </SalesGrid>
      )}
    </SalesWrapper>
  );
};

export default Sales;
