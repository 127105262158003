import { enum2array } from 'enum2array';
import i18next from 'i18next';

import { ISelectOption } from 'src/components/Inputs/SelectInput/types';
import { IPillarScoreMetadata, PillarScores } from 'src/enums/pillarScores';

import '../views/Benchmark/i18n';
import { formatNumber, isNumber, isPercentage } from './data';
import { IDataItem } from './types/data';

export const BenchmarkPillars = [
  PillarScores.ENVIRONMENT,
  PillarScores.SOCIAL,
  PillarScores.GOVERNANCE,
  PillarScores.GHG_INTENSITY,
  PillarScores.WASTE_MANAGEMENT,
  PillarScores.WATER_EFFICIENCY,
  PillarScores.ANNUAL_EMPLOYEE_TURNOVER,
  PillarScores.TRAINING_COSTS_PER_EMPLOYEE,
  PillarScores.RENEWABLE_ENERGY_TARGETS,
  PillarScores.SUSTAINABILITY_PLANS_INTEGRATED,
  PillarScores.BIODIVERSITY_CONSERVATION_PROGRAMS,
  PillarScores.BOARD_OF_DIRECTORS_WOMAN_OR_MINORITIES,
  PillarScores.SUPPLIER_CODE_OF_CONDUCT,
  PillarScores.WOMAN_OR_MINORITIES_REPRESENT,
  PillarScores.CONDUCTS_INSPECTION_OF_SUPPLIERS,
  PillarScores.MONITORS_IMPLEMENTATION_OF_HR_POLICIES,
  PillarScores.EXECUTIVE_COMPENSATION_LINKED_TO_ESG_PERFORMANCE,
  PillarScores.PROVIDES_TRAINING_ON_SUPPLIER_CODE,
];

export const pillarScoreTexts: ISelectOption[] = enum2array(PillarScores).map((item) => ({
  text: i18next.t(`PILLAR_SCORES:${item.title}`),
  value: item.value,
}));

export const isPositiveNumberPillar = (
  companyScore: number,
  industryScores: number[],
  sortBy: 'desc' | 'asc',
) => {
  const industryPosition = industryScores
    .sort((a, b) => {
      if (sortBy === 'desc') return b - a;
      else return a - b;
    })
    .findIndex((score) => score === companyScore);
  return ((industryPosition + 1) * 100) / industryScores.length < 50;
};

export const sortIndustryScores = (
  industryScore: (number | boolean)[],
  pillarMetadata: IPillarScoreMetadata,
) => {
  return industryScore.sort((a, b) => {
    const firstScore = a as number;
    const nextScore = b as number;
    if (pillarMetadata.SortBy === 'desc') return nextScore - firstScore;
    else return firstScore - nextScore;
  });
};

export const parseValueAccordingToType = (data: IDataItem) => {
  if (isNumber(data.value)) {
    return formatNumber(data.value) + ' ' + data.unit;
  } else if (isPercentage(data.value)) {
    return Math.round(Number(parseFloat(data.value))) + '%';
  } else {
    return data.value + ' ' + data.unit;
  }
};

export const filterNotTrackedPillarValues = (value: string | number | boolean) => {
  if (value !== 'In-conclusive' && value !== 'Not Available' && value !== 'Not Applicable') {
    return true;
  } else {
    return false;
  }
};
