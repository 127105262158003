import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SUMMARY_HELP_REAL_SCORE', {
  TITLE: 'RealScore™ section',
  DESCRIPTION:
    'The RealScore™ is a reflection of your company’s ESG profile as measured by external stakeholders. It ranges from -100 to +100, and your Industry Rank demonstrates how it compares to others in the same industry. Individual E, S, and G pillar scores are provided for additional detail.',
});

i18next.addResources(locales.EN, 'SUMMARY_HELP_RATING_COVERAGE', {
  TITLE: 'Rating coverage section',
  DESCRIPTION:
    'Scores from leading ESG rating agencies are reproduced here for your convenience, making it easy for you to see who is rating your company and how you fare.',
});

i18next.addResources(locales.EN, 'SUMMARY_HELP_INSIGHTS', {
  TITLE: 'Insights section',
  DESCRIPTION:
    'Here you’ll find relevant tips, thoughtful insights, and quick updates curated by our Sustainability and ESG experts specifically for you and your company.',
});

i18next.addResources(locales.EN, 'SUMMARY_HELP_NEWS', {
  TITLE: 'Latest News section',
  DESCRIPTION:
    'Here you’ll find recent news and research relevant to your company, your industry sector, and your sustainability journey. Your unique feed is personally curated by research analysts at Motive.',
});
