import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SIGN_UP_MODAL_SUCCESS', {
  MESSAGE_1:
    'You just took the first step to addressing the sustainability issues that truly matter to your prospect!',
  MESSAGE_2:
    "Motive’s Sustainability Sales Assistant will give you a competitive advantage when you're talking to customers that prioritize their sustainability efforts.",
  MESSAGE_3:
    'Your account is in the process of being created. When you account is ready we’ll email you the details.',
  MESSAGE_4: 'Welcome to the future of sales engagement!',
  MOTIVE: 'Motive',
  CONGRATULATIONS: 'Congratulations!',
  BUTTON_COPY: 'TAKE ME BACK TO ESGMOTIVE.COM',
});
