export enum TipsCategory {
  ENVIRONMENT = 'Environment',
  SOCIAL = 'Social',
  GOVERNANCE = 'Governance',
  URGENT = 'Urgent',
  NEWS = 'News',
  CONSUMERS = 'Consumers',
  INVESTORS = 'Investors',
  REGULATIONSFRAMEWORKS = 'Regulations & Frameworks',
  COMPETITORS = 'Competitors',
  MOTIVEUPDATE = 'Motive Update',
  INDUSTRY = 'Industry',
}

export enum InsightTypes {
  TIP = 'Tip',
  COMMENT = 'Comment',
  VIDEO = 'Video',
}

export interface ITip {
  category: TipsCategory;
  description: string;
}

export interface IComment {
  description: string;
  signatureName: string;
  signaturePosition: string;
}

export interface IVideo {
  title: string;
  description: string;
  subCategory: InsightTypes;
  link: string;
  videoImage: string;
}

export interface IInsight {
  id: string;
  type: InsightTypes;
  data: IComment | ITip | IVideo;
}
