import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

interface IDataTable {
  columnHeaderHeight: number;
  marginTopDataRows: number;
}
export const DataTable = styled(DataGrid)<IDataTable>`
  border: none !important;
  .MuiDataGrid-columnHeaders {
    min-height: ${(props) => props.columnHeaderHeight}px !important;
    max-height: ${(props) => props.columnHeaderHeight}px !important;
    border-radius: 50px;
    border: none;
    background-color: ${(props) => props.theme.palette.lightSecondary};
  }
  .MuiDataGrid-columnHeaderTitle {
    font-family: ${(props) => props.theme.fontFamilies.bold};
    font-size: 14px;
    color: ${(props) => props.theme.palette.common.white};
    text-overflow: clip;
    white-space: unset;
    text-align: center;
  }
  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    justify-content: center;
    white-space: unset !important;
    line-height: 20px;
  }
  .MuiDataGrid-cell {
    border: none !important;
    outline: none !important;
    white-space: unset !important;
    text-align: center;
  }
  .Mui-selected {
    background-color: ${(props) => props.theme.palette.septenary};
    border-radius: 50px;
    font-size: 16px !important;
    font-family: ${(props) => props.theme.fontFamilies.bold}!important;
    color: ${(props) => props.theme.palette.lightSecondary}!important;
  }
  .MuiDataGrid-row {
    font-size: 16px;
    font-family: ${(props) => props.theme.fontFamilies.regular};
    color: ${(props) => props.theme.palette.lightSecondary};
    &:hover {
      border-radius: 50px;
    }
  }
  .MuiDataGrid-virtualScrollerContent {
    margin-top: ${(props) => props.marginTopDataRows}px;
  }
`;
