import { GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React from 'react';

import Table from 'src/components/Table';

import '../../i18n';
import {
  DataGridWrapper,
  ArrowUpIcon,
  ArrowDownIcon,
  LoadingWrapper,
  LoadingIndicator,
} from './styles';
import { IChartDataScore, IMaterialityChartData, IMaterialityComparisonTableProps } from './types';

const MaterialityComparisonTable = (props: IMaterialityComparisonTableProps) => {
  const {
    userCompany,
    competitors,
    materiality,
    positiveCompetitorsMaterialities,
    positiveMaterialities,
    isMobile,
  } = props;

  let chartData: any[] = [];

  if (competitors) {
    competitors.forEach((competitor) => {
      chartData = chartData.concat([
        {
          id: competitor.id,
          company: competitor.name,
          data: materiality.map((element) => {
            if (!positiveCompetitorsMaterialities) {
              return { materiality: null, isPositive: false };
            }
            const positiveMaterialCompetitor = positiveCompetitorsMaterialities.find(
              (materiality) => materiality.industry === competitor.industry,
            );
            if (!positiveMaterialCompetitor) {
              return [];
            }
            return positiveMaterialCompetitor?.positiveMateriality.includes(element)
              ? { materiality: element, isPositive: true }
              : { materiality: element, isPositive: false };
          }),
        },
      ]);
    });
  }
  if (userCompany.lastUpdatedScore && positiveMaterialities) {
    const userData: IMaterialityChartData = {
      id: userCompany.id,
      company: userCompany.name,
      data: materiality.map((element) => {
        if (positiveMaterialities.includes(element)) {
          return { materiality: element, isPositive: true };
        } else {
          return { materiality: element, isPositive: false };
        }
      }),
    };
    chartData.unshift(userData);
  }
  const columns: GridColumns = materiality
    ? materiality.map((material) => {
        return {
          field: material,
          headerName: i18next.t(`SALES_MATERIALITY:${material}`),
          flex: 1,
          align: 'center',
          sortable: false,
          description: '',
          minWidth: isMobile ? 100 : undefined,
          renderCell: (cellValues) => {
            const value = cellValues.row.data.find(
              (element: IChartDataScore) => element.materiality === material,
            );
            if (!value) {
              return (
                <LoadingWrapper>
                  <LoadingIndicator />
                </LoadingWrapper>
              );
            }
            return value.isPositive ? <ArrowUpIcon /> : <ArrowDownIcon />;
          },
        };
      })
    : [];

  columns.unshift({
    field: 'company',
    headerName: 'Company',
    flex: 1,
    align: 'center',
    minWidth: isMobile ? 200 : undefined,
    description: 'Company',
    sortable: false,
  });

  return (
    <DataGridWrapper>
      <Table
        columns={columns}
        columnHeaderHeight={52}
        rowHeight={312 / (competitors.length + 1)}
        error={null}
        rows={chartData}
        loading={false}
        autoHeight={true}
        marginTopDataRows={5}
        selectionModel={chartData.filter((r) => r.id === userCompany.id).map((r) => r.id)}
      />
    </DataGridWrapper>
  );
};

export default MaterialityComparisonTable;
