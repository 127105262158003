import { Roles } from './roles';

export enum MenuRoutes {
  SUMMARY = 'summary',
  BENCHMARK = 'benchmark',
  INSIGHTS = 'insights',
  NEWS = 'news',
  EXPLORE = 'explore',
  SALES = 'sales',
}

export const userRoutesAuthorize = {
  [Roles.ADMIN]: [
    MenuRoutes.SUMMARY,
    MenuRoutes.BENCHMARK,
    MenuRoutes.INSIGHTS,
    MenuRoutes.NEWS,
    MenuRoutes.EXPLORE,
    MenuRoutes.SALES,
  ],
  [Roles.USER]: [
    MenuRoutes.SUMMARY,
    MenuRoutes.BENCHMARK,
    MenuRoutes.INSIGHTS,
    MenuRoutes.NEWS,
    MenuRoutes.EXPLORE,
    MenuRoutes.SALES,
  ],
  [Roles.SALES]: [MenuRoutes.SALES],
  // [Roles.NEWS]: [MenuRoutes.NEWS],
};

export const defaultUserRoutes = {
  [Roles.ADMIN]: MenuRoutes.SUMMARY,
  [Roles.USER]: MenuRoutes.SUMMARY,
  [Roles.SALES]: MenuRoutes.SALES,
  // [Roles.NEWS]: MenuRoutes.NEWS,
};
