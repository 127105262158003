import { Tab, Tabs } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

interface ITabSelected {
  isTabSelected: boolean;
}

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TabsHeader = styled(Tabs)`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  margin-bottom: 20px;
  .MuiTabs-indicator {
    background-color: ${(props) => props.theme.palette.quaternary}!important;
  }
`;

export const Step = styled(Tab)<ITabSelected>`
  @media (max-width: ${SIZES.bigPhone}px) {
    font-size: 26px !important;
  }
  color: ${(props) =>
    props.isTabSelected
      ? props.theme.palette.secondary
      : props.theme.palette.common.grey}!important;
  font-family: ${(props) => props.theme.fontFamilies.bold}!important;
`;
