import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { MaterialityType } from 'src/enums/pillarScores';

import { IMaterialityProps } from './types';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 20px;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 427px;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-sizing: border-box;
`;

export const Materiality = styled.div<IMaterialityProps>`
  min-height: 60px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  ${(props) =>
    props.isSelected &&
    `
    padding: 20px 20px  0 20px;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 10px 10px 0 0;
    background-color: ${
      props.materialityType === MaterialityType.POSITIVE ||
      props.materialityType === MaterialityType.NEUTRAL
        ? props.theme.palette.septenary
        : '#99A3AF'
    }}
  `}
  ${(props) =>
    !props.isSelected &&
    `
    background-color: ${
      props.materialityType === MaterialityType.POSITIVE ||
      props.materialityType === MaterialityType.NEUTRAL
        ? props.theme.palette.quaternary
        : props.theme.palette.common.grey
    };
      box-shadow: ${
        props.materialityType === MaterialityType.POSITIVE ||
        props.materialityType === MaterialityType.NEUTRAL
          ? 'inset 0px -10px 10px #A4D7D9'
          : 'inset 0px -10px 10px #5E6F83'
      };
      &:hover {
    cursor: pointer;
    filter: brightness(120%);
  }
  `}
`;

export const MaterialityName = styled.div`
  color: ${(props) => props.theme.palette.lightSecondary};
  line-height: 16px;
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const MaterialityPerformance = styled.div`
  color: ${(props) => props.theme.palette.lightSecondary};
  line-height: 14px;
  font-size: 24px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ExplanaitionWrapper = styled.div`
  margin: 20px 0;
  border-radius: 10px;
`;

export const Explanaition = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;
