import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'PILLAR_SCORE_TABLE_MODULE', {
  TITLE: 'Pillar Score Comparison',
  SUBTITLE: 'Customize your own data comparison table by selecting up-to 7 metrics at a time.',
  PLACEHOLDER: 'Select pillars to show',
  BUTTON_SUBMIT: 'APPLY',
  ERROR_MESSAGE: 'You can only select up to 7 pillars here',
});
