import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as companyActions from 'src/redux/actions/company';
import * as userActions from 'src/redux/actions/user';
import * as companyConstants from 'src/redux/constants/company';
import * as userConstants from 'src/redux/constants/user';

import { IFilterResponse } from 'src/types/http';
import IUserSettings from 'src/types/userSettings';

import { getBy } from '../services/company';
import { getUserSettings } from '../services/user';

function* initializeBenchmark() {
  try {
    const [searchResult, userSettings]: [IFilterResponse, IUserSettings] = yield all([
      call(getBy, { name: '' }),
      call(getUserSettings),
    ]);
    yield put(userActions.initializeBenchmarkViewSucceeded(searchResult.items, userSettings));
  } catch (error: any) {
    yield put(userActions.initializeBenchmarkViewFailed(error.data.message));
  }
}

function* searchCompanies(action: AnyAction) {
  try {
    const searchResult: IFilterResponse = yield call(getBy, { name: action.search });
    yield put(companyActions.onSearchCompanySucceeded(searchResult.items));
  } catch (error: any) {
    yield put(companyActions.onSearchCompanyFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW, initializeBenchmark),
  takeLatest(companyConstants.ON_SEARCH_COMPANY_BY_QUERY_REQUESTED, searchCompanies),
]);
