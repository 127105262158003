import styled from 'styled-components';

import { MaterialityType } from 'src/enums/pillarScores';
import { SIZES } from 'src/helpers/devices';

import { IMaterialityDetailsWrapperProps } from './types';

export const MaterialityDetailsWrapper = styled.div<IMaterialityDetailsWrapperProps>`
  @media (max-width: ${SIZES.bigPhone}px) {
    padding: 0 20px 20px;
    margin-bottom: 20px;
    border-radius: 0 0 10px 10px;
  }
  padding: 20px;
  background-color: ${(props) =>
    props.materialityType === MaterialityType.POSITIVE ||
    props.materialityType === MaterialityType.NEUTRAL
      ? props.theme.palette.septenary
      : '#99A3AF'};
`;

export const Title = styled.p`
  @media (max-width: ${SIZES.bigPhone}px) {
    border-top: 1px solid ${(props) => props.theme.palette.common.grey};
    padding: 10px 0;
  }
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 20px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const TitleInsight = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 20px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const ContentWrapper = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: column;
  }
  display: flex;
  justify-content: space-between;
`;

export const PillarsWrapper = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    flex-direction: row;
    margin-bottom: 20px;
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  margin-top: 20px;
`;

export const PillarWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    justify-content: space-between;
    gap: unset;
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MaterialityPillarDetailsWrapper = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    grid-template-columns: repeat(1, 1fr);
  }
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
`;

export const PillarDetailsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-top: 20px;
  }
  flex: 1;
`;

export const PillarName = styled.div`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const PillarScore = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-top: 10px;
  }
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 26px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const DetailsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin-top: 20px;
  }
  flex: 3;
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 10px;
  padding: 20px;
`;

export const TitleWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const ExplanaitionWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.quinternary};
`;

export const Explanaition = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;
