import { InputAdornment } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { EyeIcon, Input, LockIcon, SlashedEyeIcon, ToggleButton } from './styles';
import { IPasswordInputProps } from './types';

const PasswordInput = (props: IPasswordInputProps) => {
  const { isDisabled, error, id, name, placeholder, value, onChange } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      data-testid="text-field"
      inputProps={{ 'data-testid': 'input' }}
      fullWidth
      error={error !== ''}
      id={id}
      name={name}
      label={placeholder}
      helperText={error}
      disabled={isDisabled}
      value={value}
      type={showPassword ? 'text' : 'password'}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {' '}
            <ToggleButton
              aria-label="toggle password visibility"
              data-testid="toggle-password-button"
              onClick={handleShowPassword}
            >
              {showPassword ? <SlashedEyeIcon /> : <EyeIcon />}
            </ToggleButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
