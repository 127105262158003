import { TextField } from '@mui/material';
import styled from 'styled-components';

import { IInputProps } from './types';

export const Input = styled(TextField)<IInputProps>`
  .MuiInputLabel-root {
    color: ${(props) =>
      props.labelColor === 'grey'
        ? props.theme.palette.common.grey
        : props.theme.palette.secondary};
    font-family: ${(props) => props.theme.fontFamilies.regular};
  }
  .MuiOutlinedInput-root {
    font-family: ${(props) => props.theme.fontFamilies.regular};
    line-height: 24px;
    color: ${(props) => props.theme.palette.secondary};
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.quinternary};
    border-radius: 15px;
  }
  &.internal-autofill-selected {
    background-color: red !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.palette.common.white} inset !important;
  }
`;
