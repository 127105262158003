import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'MATERIALITY_RANKING_MODULE', {
  COMPANY_NAME: 'Prospective Client:',
  COMPANY_INDUSTRY: 'Industry:',
  TITLE: 'What Matters Most to Your Client',
  SUBTITLE: 'Their top-5 sustainability pillars.',
});

i18next.addResources(locales.EN, 'SALES_MATERIALITY', {
  ghg: 'GHG',
  water: 'Water',
  biodiversity: 'Biodiversity',
  waste: 'Waste',
  naturalResources: 'Natural Resources',
  energy: 'Energy',
  community: 'Community',
  healthSafety: 'Health & Safety',
  employment: 'Employment',
  supplyChain: 'Supply Chain',
  humanRights: 'Human Rights',
  dataProtection: 'Data Protection',
  integratedESG: 'Integrated ESG',
  productQualitySafety: 'Product Quality & Safety',
});
