import { PillarScores } from 'src/enums/pillarScores';

import { get } from './api';

export const getScoresByIndustry = (pillarScores: PillarScores[], industry: string) => {
  return get(
    `scores/industry/${encodeURIComponent(industry)}?pillars=${JSON.stringify(pillarScores)}`,
  );
};

export const getIndustryRealScores = (industry: string) => {
  return get(`scores/industry/${encodeURIComponent(industry)}`);
};
