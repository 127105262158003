import { CardMedia } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as Player } from 'src/assets/player/player.svg';
import { SIZES } from 'src/helpers/devices';

export const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VideoHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const VideoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  line-height: 20px;
  font-size: 14px;
`;

export const VideoTitle = styled.div`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  line-height: 22px;
`;

export const ImageVideo = styled(CardMedia)`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 250px;
    height: 140px;
  }
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 285px;
  height: 160px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const PlayIcon = styled(Player)`
  display: flex;
  width: 58px;
  height: 58px;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
  ${ImageVideo}:hover & {
    transition: 0.5s;
    transform: scale(1.5);
  }
`;

export const WrapperPlayIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  ${ImageVideo}:hover & {
    transition: 0.3s;
    background-color: rgba(44, 68, 92, 0.9);
  }
`;

export const WrapperText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 14px;
  line-height: 20px;
}
`;

export const WrapperImageVideo = styled.div`
  width: 100%;
  height: 200px;
  margin: 10px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color:${(props) => props.theme.palette.quinternary};
}
`;
