import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const userInsightsSelector = () =>
  createSelector(
    [(state: IReduxState) => state.insights.userInsights],
    (userInsights) => userInsights,
  );

export default userInsightsSelector;
