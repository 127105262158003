import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'RATING_COVERAGE_EXPLORE_MODULE', {
  TITLE: 'Rating Coverage',
  SP: 'S&P Global',
  SUSTAINALYTICS: 'Sustainalytics',
  REFINITIV: 'Refinitiv',
  MSCI: 'MSCI',
  ISS: 'ISS',
  ARABESQUE: 'Arabesque',
});
