import { Slide } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import '../SidebarMenu/i18n';
import {
  CloseIcon,
  FooterItem,
  IconButtonWrapper,
  MenuBackdrop,
  MenuIcon,
  MenuItem,
  MenuLink,
  MenuWrapper,
  ScrollWrapper,
  SelectedRouteOverlay,
  SeparateMenu,
  WrapperFooter,
} from './styles';
import { IMobileMenuProps } from './types';

const MobileMenu = (props: IMobileMenuProps) => {
  const { menuRoutes, currentPage, isExpanded, onExpandChange, onClickopenContactUs } = props;

  return (
    <ScrollLock isActive={isExpanded}>
      <ScrollWrapper>
        {isExpanded && <MenuBackdrop data-testid="mobile-menu-backdrop" onClick={onExpandChange} />}
        <TouchScrollable>
          <Slide direction="right" in={isExpanded} timeout={{ enter: 300, exit: 300 }}>
            <MenuWrapper data-testid="mobile-menu-block">
              <IconButtonWrapper>
                <CloseIcon data-testid="mobile-menu-close-icon" onClick={onExpandChange} />
              </IconButtonWrapper>
              {menuRoutes.map(({ icon: Icon, route, value, label }) => {
                return (
                  <MenuItem
                    key={value}
                    data-testid={`mobile-menu-button-${value.toLowerCase()}`}
                    to={route}
                  >
                    <MenuIcon>
                      <Icon />
                    </MenuIcon>
                    <MenuLink data-testid="mobile-menu-link">{label}</MenuLink>
                    {currentPage === route && (
                      <SelectedRouteOverlay data-testid={`mobile-menu-selected-overlay-${route}`} />
                    )}
                  </MenuItem>
                );
              })}
              <WrapperFooter>
                <SeparateMenu>
                  <FooterItem onClick={onClickopenContactUs}>
                    {i18next.t('SIDEBAR_MENU:CONTACT_US')}
                  </FooterItem>
                </SeparateMenu>
              </WrapperFooter>
            </MenuWrapper>
          </Slide>
        </TouchScrollable>
      </ScrollWrapper>
    </ScrollLock>
  );
};

export default MobileMenu;
