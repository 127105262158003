import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';

import { IFilterResponse } from 'src/types/http';

import { getBy } from '../services/company';

function* initializeSignUp() {
  try {
    const searchResult: IFilterResponse = yield call(getBy, { name: '' });
    yield put(userActions.initializeSignUpViewSucceeded(searchResult.items));
  } catch (error: any) {
    yield put(userActions.initializeSignUpViewFailed(error.data.message));
  }
}

export default all([takeLatest(userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW, initializeSignUp)]);
