import { AnyAction, MiddlewareAPI } from 'redux';

import { APP_URL } from 'src/configs/configs';
import { Roles } from 'src/enums/roles';
import { defaultUserRoutes, MenuRoutes, userRoutesAuthorize } from 'src/enums/routes';
import history from 'src/helpers/history';
import { getAuthToken } from 'src/helpers/user';

import * as appConstants from '../constants/app';
import * as userConstants from '../constants/user';

const redirectAfterLogin = () => {
  if (history.location.state?.from) {
    history.replace({
      pathname: history.location.state.from,
      search: history.location.state.params,
    });
  } else {
    history.push({
      pathname: '/summary',
    });
  }
};

const redirectToLogin = () => {
  history.replace({
    pathname: 'login',
    state: {
      from: history.location.pathname,
      params: history.location.search,
    },
    search: history.location.search,
  });
};

const redirectToESGMotive = () => {
  window.location.replace('https://www.esgmotive.com/');
};

const redirectMiddleware =
  (state: MiddlewareAPI) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
    switch (action.type) {
      // AUTH
      case userConstants.USER_ON_LOGIN_SUCCEEDED:
        redirectAfterLogin();
        break;
      default:
        break;
      case userConstants.USER_ON_SIGN_OUT:
      case userConstants.USER_ON_INITIALIZE_CHAGE_PASSWORD_FAILED:
        window.location.href = APP_URL + 'login';
        break;

      // ROUTES
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED:
        if (!getAuthToken()) {
          redirectToLogin();
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
        if (action.restricted && action.user) {
          history.push({
            pathname: `/${defaultUserRoutes[action.user.role as Roles]}`,
          });
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          redirectToLogin();
        }
        break;
      case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED:
        if (
          !userRoutesAuthorize[action.user.role as Roles].includes(
            history.location.pathname.slice(1) as MenuRoutes,
          )
        ) {
          history.push({
            pathname: `/${defaultUserRoutes[action.user.role as Roles]}`,
          });
        }
        break;
      case userConstants.USER_REDIRECT_TO_LOGIN:
        history.replace({
          pathname: '/login',
        });
        break;
      case userConstants.USER_REDIRECT_TO_ESGMOTIVE:
        redirectToESGMotive();
        break;
      case userConstants.USER_REDIRECT_TO_SIGNUP:
        history.replace({
          pathname: '/sign-up',
        });
        break;
      case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
        if (action.error.statusCode === 401) {
          history.go(0);
        }
        break;
      case userConstants.USER_ON_CLICK_FORGOT_PASSWORD:
        history.replace({
          pathname: '/forgot-password',
        });
        break;

      // SALES
      case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED:
      case userConstants.USER_ON_SALES_BACK:
        history.push({
          pathname: '/sales',
        });
        break;
      case userConstants.USER_ON_SUBMIT_TARGET_SALES_REQUESTED:
        history.push({
          pathname: `/sales`,
          search: action.queryParams,
        });
        break;
    }

    next(action);
  };

export default redirectMiddleware;
