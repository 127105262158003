import { TabsUnstyled } from '@mui/base';
import i18next from 'i18next';
import React from 'react';

import { handleScoreColors } from 'src/helpers/exploreTabsColors';

import './i18n';
import {
  TabList,
  ScoresModulesWrapper,
  ScoresModulesTabs,
  TabHeader,
  ScoreIcon,
  ScoreTitle,
  DataWrapper,
  RealScoreTitleWrapper,
  ViewMoreWrapper,
  ItemTitle,
  RealScoreValue,
  IndustryRankValue,
  ScoreIconWrapper,
} from './styles';
import { IPillarTabsModuleProps } from './types';

const PillarTabsModule = (props: IPillarTabsModuleProps) => {
  const { tabs, handleTabClick } = props;

  return (
    <TabsUnstyled>
      <TabList>
        <ScoresModulesWrapper>
          {tabs.map((tab) => {
            return (
              <ScoresModulesTabs
                scoreColors={handleScoreColors(tab.state)}
                onClick={() => {
                  handleTabClick(tab.tabValue);
                }}
              >
                <TabHeader>
                  <ScoreIconWrapper>
                    <ScoreIcon src={tab.icon} />
                  </ScoreIconWrapper>
                  <ScoreTitle>{tab.tabName}</ScoreTitle>
                </TabHeader>
                <DataWrapper scoreColors={handleScoreColors(tab.state)}>
                  <RealScoreTitleWrapper>
                    <ItemTitle>{i18next.t('PILLARS_TABS_MODULE:REAL_SCORE')}</ItemTitle>
                    <RealScoreValue>{tab.scoreValue}</RealScoreValue>
                  </RealScoreTitleWrapper>
                  <RealScoreTitleWrapper>
                    <ItemTitle>{i18next.t('PILLARS_TABS_MODULE:INDUSTRY_RANK')}</ItemTitle>
                    <IndustryRankValue>{tab.industryRank}</IndustryRankValue>
                  </RealScoreTitleWrapper>
                </DataWrapper>
                <ViewMoreWrapper>{i18next.t('PILLARS_TABS_MODULE:VIEW_MORE')}</ViewMoreWrapper>
              </ScoresModulesTabs>
            );
          })}
        </ScoresModulesWrapper>
      </TabList>
    </TabsUnstyled>
  );
};

export default PillarTabsModule;
