import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import BackArrowIcon from 'src/assets/menu/back-arrow.svg';
import AutocompleteInput from 'src/components/Inputs/AutocompleteInput';
import { sectorIndustry } from 'src/enums/sectorIndustry';

import './i18n';
import {
  TargetNameWrapper,
  TargetIndustryWrapper,
  WrapperText,
  TargetNameText,
  TargetIndustryText,
  WrapperInput,
  WrapperAutcomplete,
  HelpSubmitText,
} from './styles';
import { ISalesTargetModuleProps } from './types';

const SalesTargetModule = (props: ISalesTargetModuleProps) => {
  const { searchedCompetitors, onSearchCompetitor, onSubmit } = props;
  const [searchedCompany, setSearchedCompany] = useState('');
  const [searchedIndustry, setSearchedIndustry] = useState('');
  const [searchedSector, setSearchedSector] = useState('');
  const [renderIndustryInputs, setRenderIndustryInputs] = useState(false);
  const [clickOnSubmitTargetCompany, setClickOnSubmitTargetCompany] = useState(false);
  const [showHelpMessage, setShowHelpMessage] = useState(false);
  const searchedIndustries = sectorIndustry.filter((element) => element.sector === searchedSector);
  const handleInputChangeCompany = (searchValue: string) => {
    setSearchedCompany(searchValue);
    onSearchCompetitor(searchValue);
  };

  const handleSubmitTargetCompany = (searchValue: string) => {
    setSearchedCompany(searchValue);
    setClickOnSubmitTargetCompany(true);
  };

  useEffect(() => {
    const companiesName = searchedCompetitors.map((element) => element.name);
    const validationSubmit =
      companiesName.includes(searchedCompany) ||
      (searchedIndustries[0] && searchedIndustries[0].industry.includes(searchedIndustry));

    if (validationSubmit) {
      onSubmit({ companyName: searchedCompany, industry: searchedIndustry });
    }

    if (!companiesName.includes(searchedCompany) && clickOnSubmitTargetCompany) {
      setRenderIndustryInputs(true);
    }

    if (searchedCompany && searchedCompetitors && searchedCompetitors.length === 0) {
      setShowHelpMessage(true);
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        if (validationSubmit) {
          onSubmit({
            companyName: searchedCompany,
            industry: searchedIndustry,
          });
        }
        if (!companiesName.includes(searchedCompany)) {
          setRenderIndustryInputs(true);
        }
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  }, [
    searchedCompetitors,
    searchedCompany,
    searchedIndustries,
    searchedIndustry,
    clickOnSubmitTargetCompany,
    onSubmit,
  ]);

  return (
    <>
      <TargetNameWrapper>
        <WrapperText>
          <TargetNameText>{i18next.t('SALES_TARGET:TARGET_NAME_COPY')}</TargetNameText>
        </WrapperText>
        <WrapperInput>
          <WrapperAutcomplete>
            <AutocompleteInput
              id="search-companies"
              onInputChange={handleInputChangeCompany}
              onChange={setSearchedCompany}
              options={searchedCompetitors.map((element) => element.name)}
              pathNoOptionText={i18next.t('SEARCH_BAR_MODULE:NO_OPTIONS')}
              pathPlaceholder={i18next.t('SALES_TARGET:INPUT_LABEL_COMPANY')}
              valueAutocomplete={searchedCompany}
              freeSolo={true}
              adornmentPath={BackArrowIcon}
              onSubmit={handleSubmitTargetCompany}
            />
          </WrapperAutcomplete>
        </WrapperInput>
        {!renderIndustryInputs && showHelpMessage && (
          <HelpSubmitText>{i18next.t('SALES_TARGET:SUBMIT_COPY')}</HelpSubmitText>
        )}
      </TargetNameWrapper>
      {renderIndustryInputs && (
        <TargetIndustryWrapper>
          <WrapperText>
            <TargetIndustryText>
              {i18next.t('SALES_TARGET:TARGET_INDUSTRY_COPY')}
            </TargetIndustryText>
          </WrapperText>
          <WrapperInput>
            <WrapperAutcomplete>
              <AutocompleteInput
                id="search-sector-industry"
                onChange={setSearchedSector}
                onInputChange={setSearchedSector}
                options={sectorIndustry.map((element) => element.sector)}
                pathNoOptionText={i18next.t('SEARCH_BAR_MODULE:NO_OPTIONS')}
                pathPlaceholder={i18next.t('SALES_TARGET:INPUT_LABEL_SECTOR')}
                valueAutocomplete={searchedSector}
              />
            </WrapperAutcomplete>
          </WrapperInput>
          <WrapperInput>
            <WrapperAutcomplete>
              <AutocompleteInput
                id="search-industries"
                onChange={setSearchedIndustry}
                onInputChange={setSearchedIndustry}
                disabled={searchedIndustries.length === 0 ? true : false}
                options={searchedIndustries.length === 0 ? [] : searchedIndustries[0].industry}
                pathNoOptionText={i18next.t('SEARCH_BAR_MODULE:NO_OPTIONS')}
                pathPlaceholder={i18next.t('SALES_TARGET:INPUT_LABEL_INDUSTRY')}
                valueAutocomplete={searchedIndustry}
              />
            </WrapperAutcomplete>
          </WrapperInput>
        </TargetIndustryWrapper>
      )}
    </>
  );
};

export default SalesTargetModule;
