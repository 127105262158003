import i18next from 'i18next';
import React, { useState } from 'react';

import Modal from 'src/components/Modal';

import Help from './components/Help';
import './i18n';
import {
  ContentWrapper,
  HelpIcon,
  Industry,
  IndustryWrapper,
  RealScore,
  RealScoreLabel,
  RealScoreNumber,
  RealScoreWrapper,
  Score,
  ScoreLabel,
  ScoreNumber,
  ScoresWrapper,
} from './styles';
import { IRealScoreModuleProps } from './types';

const RealScoreModule = (props: IRealScoreModuleProps) => {
  const { scores, industry, industryRank, handleTrackEvent } = props;
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
    handleTrackEvent('Open Real Score help');
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
    handleTrackEvent('Close Real Score help');
  };

  return (
    <>
      <Modal open={isHelpOpen} onClose={handleCloseHelp} width={600}>
        <Help />
      </Modal>
      <RealScoreWrapper>
        <RealScore>
          <RealScoreLabel>{i18next.t('REAL_SCORE_MODULE:REAL_SCORE')}</RealScoreLabel>
          <RealScoreNumber data-testid="real-score-number">
            {scores.realScore.toFixed(0)}
          </RealScoreNumber>
        </RealScore>
        <IndustryWrapper>
          <Industry data-testid="real-score-industry">
            {i18next.t('REAL_SCORE_MODULE:INDUSTRY', { industry: industry.replace(/\//g, '\\') })}
          </Industry>
          <HelpIcon onClick={handleOpenHelp} />
        </IndustryWrapper>
        <ContentWrapper>
          <ScoresWrapper>
            <Score>
              <ScoreLabel>{i18next.t('REAL_SCORE_MODULE:INDUSTRY_RANK')}</ScoreLabel>
              <ScoreNumber data-testid="real-score-industry-rank">
                {industryRank.position}/{industryRank.total}
              </ScoreNumber>
            </Score>
            <Score>
              <ScoreLabel>{i18next.t('REAL_SCORE_MODULE:ENVIRONMENT_SCORE')}</ScoreLabel>
              <ScoreNumber data-testid="real-score-environment">
                {scores.environmentScore}
              </ScoreNumber>
            </Score>
            <Score>
              <ScoreLabel>{i18next.t('REAL_SCORE_MODULE:SOCIAL_SCORE')}</ScoreLabel>
              <ScoreNumber data-testid="real-score-social">
                {scores.socialScore.toFixed(0)}
              </ScoreNumber>
            </Score>
            <Score>
              <ScoreLabel>{i18next.t('REAL_SCORE_MODULE:GOVERNANCE_SCORE')}</ScoreLabel>
              <ScoreNumber data-testid="real-score-governance">
                {scores.governanceScore.toFixed(0)}
              </ScoreNumber>
            </Score>
          </ScoresWrapper>
        </ContentWrapper>
      </RealScoreWrapper>
    </>
  );
};

export default RealScoreModule;
