import styled from 'styled-components';

export const PillarDetailsWrapper = styled.div``;

export const HeaderWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const PillarRank = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 28px;
  line-height: 30px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const PillarName = styled.p`
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IndustryBreakdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PillarRankWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex: 1;
  padding-bottom: 10px;
`;

export const PillarIndustries = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const Breakdown = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;
