import React from 'react';
import ReactPlayer from 'react-player';

import { ModalWrapper, ModelContent } from './styles';
import { IGeneralPlayerProps } from './types';

const VideoPlayerModal = (props: IGeneralPlayerProps) => {
  const { url, controls = true, open, onClose } = props;

  return (
    <ModalWrapper data-testid="modal-block" open={open} onClose={onClose}>
      <ModelContent>
        <ReactPlayer url={url} controls={controls} width={'100%'} height={'100%'} />
      </ModelContent>
    </ModalWrapper>
  );
};

export default VideoPlayerModal;
