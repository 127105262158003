import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'BENCHMARK', {
  TITLE: 'Benchmark',
  SUBTITLE:
    'See key data points collected by ESG rating agencies and benchmark against up-to 5 other companies.',
  BUTTON_EXPORT: 'DOWNLOAD',
  TITLE_MODAL_EXPORT: 'Download benchmark',
  MESSAGE_MODAL_EXPLANIER:
    "In this section, we show you the data ESG rating agencies are collecting on your company. If you aren't seeing the results you expect, it might be because they aren't seeing the message you want to send. Compare your ESG and Sustainability goals against the market perception of your company and harness this opportunity to optimize alignment.",
});

i18next.addResources(locales.EN, 'BENCHMARK_HELP_SEARCH', {
  TITLE: 'Search company',
  DESCRIPTION:
    'Search the company you would like to benchmark against. Select from over 6,000 companies covered by Motive, and please note charts display up-to 5 additional companies at a time.',
});

i18next.addResources(locales.EN, 'BENCHMARK_REALSCORE', {
  TITLE: 'Total RealScore™',
  DESCRIPTION:
    'See how your company’s RealScore™ compares against those of the companies you have selected. Compare against up-to five companies at a time and select from any of the 6,000+ companies covered by Motive by using the search bar above.',
});

i18next.addResources(locales.EN, 'BENCHMARK_PILLAR_SCORE', {
  TITLE: 'Pillar score select',
  DESCRIPTION:
    'See how your company compares against others you have selected across a variety of ESG pillar scores. Select from 15 different ESG pillar scores from the dropdown menu. Compare against up-to five companies at a time and select from any of the 6,000+ companies covered by Motive by using the search bar above.',
});

i18next.addResources(locales.EN, 'BENCHMARK_PILLAR_SCORE_TABLE', {
  TITLE: 'Pillar Score Comparison',
  DESCRIPTION:
    'See how your company compares against others you have selected across a variety of ESG pillar scores. Customize your table by using the dropdown menu to view up-to seven metrics at a time. Compare against up-to five companies at a time and select from any of the 6,000+ companies covered by Motive by using the search bar above.',
});

i18next.addResources(locales.EN, 'PILLAR_SCORES', {
  ENVIRONMENT: 'Total Environment Score',
  SOCIAL: 'Total Social Score',
  GOVERNANCE: 'Total Governance Score',
  GHG_INTENSITY: 'GHG Intensity',
  WASTE_MANAGEMENT: '% Total Waste Diverted (Recycling/Reuse)',
  WATER_EFFICIENCY: 'Water Intensity',
  ANNUAL_EMPLOYEE_TURNOVER: 'Annual Employee Turnover',
  TRAINING_COSTS_PER_EMPLOYEE: 'Training Costs/Employee',
  RENEWABLE_ENERGY_TARGETS: 'Renewable Energy Targets',
  SUSTAINABILITY_PLANS_INTEGRATED: 'Sustainability Integrated in Business Activities',
  BIODIVERSITY_CONSERVATION_PROGRAMS: 'Biodiversity Programs & Targets',
  BOARD_OF_DIRECTORS_WOMAN_OR_MINORITIES: '≥ 20% of Directors are Women/Minorities',
  WOMAN_OR_MINORITIES_REPRESENT: '≥ 20% of Employees are Women/Minorities',
  SUPPLIER_CODE_OF_CONDUCT: 'Supplier Code of Conduct',
  PROVIDES_TRAINING_ON_SUPPLIER_CODE: 'Training on Supplier Code of Conduct',
  CONDUCTS_INSPECTION_OF_SUPPLIERS: 'Regular Supplier Audits & Inspections',
  MONITORS_IMPLEMENTATION_OF_HR_POLICIES: 'Enforces Human Rights Policies',
  EXECUTIVE_COMPENSATION_LINKED_TO_ESG_PERFORMANCE: 'Executive Compensation Tied to ESG',
  TOTAL_SCOPE_EMISSIONS: 'Total Scope 1 & 2 Emissions',
  PARTICIPATES_IN_CARBON_DISCLOSURE_PROJECT: 'Participates in Carbon Disclosure Project',
  HAS_A_WATER_MANAGEMENT_STRATEGY: 'Has a Water Management Strategy',
  EMPLOYS_WATER_RECYCLING_REUSE: 'Employs Water Recycling/Reuse',
  HAS_AN_ENVIRONMENTAL_PROTECTION_POLICY_COMMITTEE:
    'Has an Environmental Protection/Policy Committee',
  SIGNATORY_TO_UN_GLOBAL_COMPACT: 'Signatory to UN Global Compact',
  HAZARDOUS_WASTE_INTENSITY: 'Hazardous Waste Intensity',
  FOLLOWS_ISO14001_OR_EMAS_GUIDELINES: 'Follows ISO14001 or EMAS Guidelines',
  RECYCLED_OR_RECYCLABLE_RAW_MATERIALS: 'Recycled or Recyclable Raw Materials',
  STATEMENT_OF_COMPREHENSIVE_ENVIRONMENT_POLICY: 'Statement of Comprehensive Environment Policy',
  TOTAL_ENERGY_INTENSITY: 'Total Energy Intensity',
  REPORTS_ON_COMMUNITY_DEVELOPMENT_PROGRAMS: 'Reports on Community Development Programs',
  HAS_PROGRAMS_FOR_EDUCATION_ACCESS: 'Has Programs for Education Access',
  HAS_PROGRAMS_FOR_HEALTH_CARE_ACCESS: 'Has Programs for Health Care Access',
  LOST_DAYS_DUE_TO_INJURY: 'Lost Days Due to Injury',
  INJURY_INTENSITY: 'Injury Intensity',
  MANAGERIAL_POSITIONS_HELD_BY_WOMEN: 'Managerial Positions Held by Women',
  VOLUNTARY_PRINCIPLES_ON_SECURITY_AND_HUMAN_RIGHTS_SIGNATORY:
    'Voluntary Principles on Security and Human Rights Signatory',
  SUPPORTS_UNIVERSAL_DECLARATION_OF_HUMAN_RIGHTS: 'Supports Universal Declaration of Human Rights',
  HUMAN_RIGHTS_TRAINING_FOR_EMPLOYEES: 'Human Rights Training for Employees',
  POLICY_ON_CUSTOMER_DATA_PRIVACY: 'Policy on Customer Data Privacy',
  POLICY_ON_SUPPLIER_DATA_PRIVACY: 'Policy on Supplier Data Privacy',
  PREVENTS_THIRD_PARTY_ACCESS_TO_PERSONAL_INFORMATION:
    'Prevents Third-Party Access to Personal Information',
  EXTERNAL_VERIFICATION_OF_CSR_ESG_REPORTING: 'External Verification of CSR/ESG Reporting',
  QUALITY_MANAGEMENT_SYSTEM_CERTIFICATIONS: 'Quality Management System Certifications',
  PRODUCT_SAFETY_AND_QUALITY_ASSURANCE_INITIATIVES:
    'Product Safety and Quality Assurance Initiatives',
  COMMUNICATES_CHEMICAL_CONSTITUENTS_IN_PRODUCTS: 'Communicates Chemical Constituents in Products',
  WASTE_INTENSITY: 'Waste Intensity',
});
