import i18next from 'i18next';
import React, { useState } from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';
import TextInput from 'src/components/Inputs/TextInput';
import UserMessage from 'src/components/UserMessage';
import { validateEmail } from 'src/helpers/validators/email';

import ModalSuccessForgotPassword from './components/ModalSuccess';
import './i18n.ts';
import {
  Wrapper,
  TextWrapper,
  InputWrapper,
  TextLinkWrapper,
  TextLink,
  PersonIcon,
  InputsWrapper,
  LogoWrapper,
  MotiveLogo,
} from './styles';
import { IForgotPasswordProps } from './types';

const ForgotPassword = (props: IForgotPasswordProps) => {
  const { userMessage, loading, isSendEmail, onSubmit, onBackSignIn, onSubmitSuccessModal } = props;
  const [userEmail, setuserEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmailChange = (value: string) => {
    setuserEmail(value);
  };

  const handleSubmit = () => {
    if (validateEmail(userEmail)) {
      onSubmit(userEmail);
    } else {
      setEmailError(i18next.t('FORGOT_PASSWORD:EMAIL_ERROR'));
    }
  };

  return (
    <Wrapper>
      {isSendEmail && <ModalSuccessForgotPassword onSubmitSuccessModal={onSubmitSuccessModal} />}
      {!isSendEmail && (
        <InputsWrapper>
          <LogoWrapper>
            <MotiveLogo src={Logo} alt="Motive Logo" />
          </LogoWrapper>
          {userMessage.messageType !== null && (
            <UserMessage
              messageType={userMessage.messageType}
              message={userMessage.message}
              isLogin={true}
            />
          )}

          <TextWrapper>{i18next.t('FORGOT_PASSWORD:MESSAGE')}</TextWrapper>
          <InputWrapper>
            <TextInput
              isDisabled={false}
              error={emailError}
              id={i18next.t('FORGOT_PASSWORD:EMAIL').toLowerCase()}
              name={i18next.t('FORGOT_PASSWORD:EMAIL')}
              placeholder={i18next.t('FORGOT_PASSWORD:EMAIL')}
              value={userEmail}
              onChange={handleEmailChange}
              adornment={<PersonIcon />}
            />
          </InputWrapper>
          <TextButton
            onSubmit={handleSubmit}
            isDisabled={userEmail === ''}
            isLoading={loading}
            text={i18next.t('FORGOT_PASSWORD:BUTTON_COPY')}
          />
          <TextLinkWrapper>
            <TextLink onClick={onBackSignIn}> {i18next.t('FORGOT_PASSWORD:CANCEL')}</TextLink>
          </TextLinkWrapper>
        </InputsWrapper>
      )}
    </Wrapper>
  );
};
export default ForgotPassword;
