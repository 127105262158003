import React from 'react';

import { DataTable } from './styles';
import { ITableProps } from './types';

const Table = (props: ITableProps) => {
  const {
    columns,
    rows,
    loading,
    error = null,
    pageSize = 10,
    rowHeight = 52,
    autoHeight = false,
    disableVirtualization = false,
    columnHeaderHeight = 76,
    marginTopDataRows = 30,
    selectionModel,
  } = props;

  return (
    <DataTable
      disableVirtualization={disableVirtualization}
      columns={columns}
      rows={rows}
      rowHeight={rowHeight}
      pageSize={pageSize}
      loading={loading}
      error={error}
      columnHeaderHeight={columnHeaderHeight}
      rowsPerPageOptions={[pageSize]}
      autoHeight={autoHeight}
      hideFooter={true}
      disableColumnMenu={true}
      showColumnRightBorder={false}
      disableSelectionOnClick={true}
      disableColumnFilter={true}
      selectionModel={selectionModel}
      marginTopDataRows={marginTopDataRows}
    />
  );
};

export default Table;
