import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'TABS_SCORE_EXPLORE_MODULE', {
  SOCIAL: 'Social',
  GOVERNANCE: 'Governance',
  ENVIRONMENT: 'Environment',
  'M-EE21-3': 'GHG Intensity',
  'M-EE21-2': 'Total GHG',
  'M-EE21-5': 'Scope 1 Emissions',
  'M-EE21-6': 'Scope 2 Emissions (Location)',
  'M-EE21-7': 'Scope 2 Emissions (Market)',
  'M-EE21-8': 'Scope 3 Emissions',
  'M-EE21-10': 'Total Scope 1 & 2 Emissions',
  'M-EE21-11': 'Scope 1 & 2 Emission Intensity',
  'M-EE37-2': 'Waste Intensity',
  'M-EE10-2': '% Total Waste Diverted (Recycling/Reuse)',
  'M-EE38-2': 'Hazardous Waste Intensity',
  'M-EE17-2': 'Water Pollutant Intensity',
  'M-EE17-4': 'Water Intensity',
  'M-EE102-2': 'Total Energy Intensity',
  'M-ES113-3': 'Managerial Positions Held by Women',
  'M-ES37-2': 'Mean Salary & Benefits',
  'M-ES31-1': 'Annual Employee Turnover',
  'M-ES7-2': 'Mean Annual Training',
  'M-ES5-2': 'Mean Annual Training Costs',
  'M-EG6-2': 'CEO or Highest Total Salary',
  'M-ES119-1': 'Lost Days Due to Injury',
  'M-ES117-1': 'Injury Intensity',
  'M-EG120-1': 'Number of Board Members Exceeding 10 or Below 8',
  'M-ES1-1': 'Number of Women on the Board',
  'M-ES1-2': 'Number of Board Members of Foreign Cultures',
  'M-EG124-1': 'Number of Board Meetings per Year',
  'M-EG27-2': 'Independent Board Members',
  'M-EG13-3': 'Independent Board Members on Audit Committee',
  'M-EG5-3': 'Independent Board Members on Compensation Committee',
  'M-EG126-2': 'Non-Executive Board Members',
  'M-EG5-6': 'Non-Executive Board Members on Nomination Committee',
  'M-EG6-4': 'Highest Renumeration Package',
  'M-EG33-3': 'Members of Nomination Committee Who Are Significant Shareholders',
  E_Score: '[IdealRatings ESG] Environment Score',
  S_Score: '[IdealRatings ESG] Social Score',
  G_Score: '[IdealRatings ESG] Governance Score',
  ESG_TS: 'IdealRatings ESG - Total Score',
  ESG_R: 'IdealRatings ESG - Rating',
  'YN US': 'UNGC Signatory',
  EU1: 'Supports Protection of Human Internationally Proclaimed Human Rights',
  EU2: 'Monitors for Human Rights Abuses',
  EU3: 'Supports Freedom of Association and Collective Bargaining',
  EU4: 'Supports Elimination of Forced or Compulsory Labor',
  EU5: 'Supports Abolition of Child Labor',
  EU6: 'Supports Elimination of Discrimination',
  EU7: 'Applies Precautionary Approach to Environment',
  EU8: 'Promotes Greater Environmental Responsibility',
  EU9: 'Development and Diffusion of Environmentally Friendly Technologies',
  EU10: 'Opposes Corruption in all Forms',
  EE1: 'Statement of Comprehensive Environment Policy',
  EE2: 'Committee Oversight of Environmental Protection',
  EE3: 'Renewable Energy Targets',
  EE5: 'Sourcing From Respsonsibly Managed Forests',
  EE6: 'Recycled or Recyclable Raw Materials',
  EE7: 'Sustainability Plans Integrated in Business Activities',
  EE8: 'Biodiversity Programs & Targets',
  EE9: 'Environmental Management System Certification',
  EE10: 'Waste Management Programs',
  EE12: 'Waste Recycling',
  EE13: 'Hazardous Waste Reduction Programs',
  EE14: 'Reports Oil Spills',
  EE15: 'Involved in Environmental Legal Proceedings',
  EE16: 'Water Management Strategy',
  EE17: 'Reports Water Management Data',
  EE18: 'Reports Water Recycling/Reuse',
  EE19: 'Reports Air Quality Data',
  EE20: 'Air Emissions Reduction Strategy',
  EE21: 'Reports GHG Data',
  EE22: 'Participates in Carbon Disclosure Project',
  EE24: 'Strategy to Reduce Building Emissions',
  EE25: 'Strategy to Reduce Corporate Vehicle Emissions',
  EE27: 'Active Stakeholder Engagement',
  EE28: 'Product Impact Policy',
  EE32: 'Reports Environmental Committee Results',
  EE34: 'Transparency in Environrnental Targets and Outcomes',
  EE35: 'Follows ISO14001 or EMAS Guidelines',
  EE36: 'Reports Costs of Environmental Improvements',
  EE37: 'Reports Waste Data',
  EE38: 'Reports Hazardous Waste Data',
  EE39: 'Environmental Grievance Mechanism',
  ES1: '≥ 20% of Directors are Women/Minorities',
  ES2: 'Training on Supplier Code of Conduct',
  ES3: '≥ 20% of Employees are Women/Minorities',
  ES4: 'Has Been Involved in Customer-Related Legal Proceedings',
  ES5: 'Provides Relevant Training to Employees',
  ES6: 'Access to Medicines Programs',
  ES7: 'Reports on Employee Training',
  ES8: 'Programs for Development of Medicines for Neglected Diseases',
  ES9: 'Policies for Elimination of Discrimination',
  ES10: 'Programs for Health Care Access',
  ES11: 'Programs to Support Supplier Diversity',
  ES12: 'Programs for Food Access',
  ES13: 'Involved in Human Rights Legal Proceedings',
  ES14: 'Programs for Education Access',
  ES15: 'Reports Workplace Accident Rates',
  ES16: 'Programs for Community Development',
  ES17: 'Workplace Safety Training',
  ES18: 'Promotes Financial Services Access for Disadvantaged Peoples',
  ES20: 'Policy on Customer Data Privacy',
  ES21: 'Respects Employee Right to Unionization',
  ES22: 'Policy on Supplier Data Privacy',
  ES23: 'Policy Against Forced Labor',
  ES24: 'Respects Employee Privacy',
  ES25: 'Health and Safety Certifications',
  ES26: 'Prior Informed Consent for Processing Personal Data',
  ES27: 'Involved in Employee Safety and Health Legal Proceedings',
  ES28: 'Supplier Code of Conduct',
  ES29: 'Reports on Employee Volunteering',
  ES30: 'Inspections of Suppliers',
  ES31: 'Reports Employee Turnover rate',
  ES32: 'Reports Inspections of Suppliers and Violations',
  ES33: 'Enforces Human Rights Policies',
  ES34: 'Fair Trade Certification',
  ES35: 'Employs Individuals with Disabilities',
  ES36: 'Has Been Involved in Supply Chain Legal Proceedings',
  ES37: 'Employee Compensation Policy',
  ES38: 'Provides/Supports Organic Foods',
  ES39: 'Uses Biological Fertilizers and Insecticides',
  ES40: 'Communicates Chemical Constituents in Products',
  ES41: 'Product Testing on Animals',
  ES42: "Abides by Country's Animal Testign Regulations",
  ES43: 'Product Safety and Quality Assurance',
  ES44: 'Quality Management System Certifications',
  ES45: 'Charitable Donatiosn in Excess of $67K',
  ES46: 'Child Labor Policy',
  ES47: 'Community Porgrams Headed by a Senior Manager',
  ES48: 'ILO Core Labor Standards',
  ES49: 'UN Global Compact / SA8000 / OECD Guideliens Signatory',
  ES51: 'Supports Universal Declaration of Human Rights',
  ES52: 'Voluntary Principles on Security and Human Rights Signatory',
  ES54: 'Human Rights Training for Employees',
  ES56: 'Monitors Performance on Human Rights Policy',
  ES19: 'Health aned Safety Incident Reduction Programs',
  ES63: 'Uses Animals in Product Manufacturing',
  ES64: 'Social Sustainability Plans Intergated in Business Activities',
  ES65: 'Work-Life Balance Policy',
  ES66: 'Provides Medical Insurance',
  ES67: 'Affordable Housing Access Programs',
  ES68: 'Prevents Third-Party Access to Persoanl Information',
  ES69: 'Measures Customer Satisfaction',
  ES70: 'Measures Employee Satisfaction',
  ES71: 'Social Grievance Mechanism',
  EG1: 'Anti-Corruption Policy',
  EG2: 'Anti-Corruption Training to Employees',
  EG4: 'Has Been Involved in Corruption Legal Proceedings',
  EG5: 'Committee to Recommend Board Nomination and Compensation',
  EG6: 'Reports on Director Remuneration',
  EG7: 'Publishes Sustainability Reports',
  EG8: 'Discloses Accounting Policies',
  EG9: 'IFRS or GAAP Financial Statements',
  EG10: 'Anti-Competitive/Anti-Trust Policies',
  EG11: 'Has Been Involved in Anti-Competitive Legal Proceedings',
  EG12: 'Separate CEO and Chairperson Roles',
  EG13: 'Audit and Risk Committee to Monitor Auditors',
  EG14: 'External Verification of CSR Reporting',
  EG15: 'In-house Team for Responsible Investment',
  EG16: 'Executive Compensation Tied to ESG',
  EG17: 'Statement on Responsible Marketing',
  EG19: 'Has Been Involved in Patent Infringement Legal Proceeedings',
  EG20: 'Anti-Corruption Audits',
  EG21: 'Whistle-Blower Mechanisms',
  EG22: 'Procedures for Bribery-Related Investigations',
  EG24: 'Anti-Corruption Policy Communiated to Employees',
  EG25: 'Chairperson in an Independent Director',
  EG26: 'All Members of Audit Committee Have Financial Background Expertise',
  EG27: 'Majority of Directors are Independent',
  EG29: 'Code of Ethics for Senior Executives',
  EG28: 'Formal Corporate Governance Policy',
  EG30: 'Political Contributions Policy',
  EG31: 'Insider Trading Policy',
  EG33: 'Directors Have Stock Ownership',
});

i18next.addResources(locales.EN, 'TABS_SCORE_EXPLORE_PILLAR_DETAILS', {
  'DESCRIPTION_M-EE21-3':
    'This metric represents your total greenhouse gas (GHG) emissions per one million dollars of revenue (or net sales). It is a measure of GHG emissions intensity in the sense that it displays the volume of GHG emissions produced for every dollar of revenue. This is the metric most investors look to when trying to compare the emissions profiles of different companies.',
  'DESCRIPTION_M-EE21-2':
    'This metric represents your total greenhouse gas (GHG) emissions measured in tons. Total GHG emissions include carbon dioxide as well as methane, nitrous oxide, and fluorinated gases.',
  'DESCRIPTION_M-EE21-5':
    'Scope 1 emissions are the direct greenhouse gas (GHG) emissions that occur from sources your company owns or controls. These are the emissions resulting directly from your facilities and vehicles. GHG emissions include carbon dioxide as well as methane, nitrous oxide, and fluorinated gases. GHG emissions resulting from electricity, steam, heating , and cooling that you purchase are reported instead as Scope 2 emissions while emissions associated with your supply chain and value chain are reported as Scope 3 emissions. Emissions are reported on an annual basis.',
  'DESCRIPTION_M-EE21-6':
    'Scope 2 emissions are the indirect greenhouse gas (GHG) emissions you are responsible for through your purchase of electricity, steam, heating, and cooling energy. The location-based estimation relies on the average emissions of the regional grid you are buying energy from; it is less accurate than the market-based estimation but is often relied upon as many companies across the globe do not yet have reliable market-based estimation data.',
  'DESCRIPTION_M-EE21-7':
    'Scope 2 emissions are the indirect greenhouse gas (GHG) emissions you are responsible for through your purchase of electricity, steam, heating, and cooling energy. The market-based estimation relies on the emissions profiles of the energy provider you are actually purchasing energy from. It is more accurate than the location-based estimation, but requires that energy providers be able to report their actual emissions profiles.',
  'DESCRIPTION_M-EE21-8':
    'Scope 3 emissions are the induced greenhouse gas (GHG) emissions resulting throughout the supply chain leading to the production of your products/services(upstream) as well as from the value chain by which your products are used (downstream). Modelling Scope 3 emissions can be challenging as it requries knowledge of the emissions profiles of all suppliers and transport wihtin your supply chain as well as a consumer life cycle analysis of how your products/services are used.',
  'DESCRIPTION_M-EE21-10':
    'This is an aggregate metric combining Scope 1 & Scope 2 greenhouse gas (GHG) emissions. It is generally believed that companies have far greater control over their Scope 1 & 2 GHG emissions than they do over their Scope 3 GHG emissions.',
  'DESCRIPTION_M-EE21-11':
    'This metric represents your combined Scope 1 and Scope 2 greenhouse gas (GHG) emissions per one million dollars of revenue (or net sales). It is a measure of GHG emissions intensity in the sense that it displaysthe volume of GHG emissions produced for every dollar of revenue. It is generally believed that companies have far greater control over their Scope 1 & 2 GHG emissions than they do over their Scope 3 GHG emissions., and so this is a common metric investors look to when trying to compare the emissions profiles of different companies.',
  'DESCRIPTION_M-EE37-2':
    'This metric represents the total volume of waste produced in relation to your revenue (or net sales). It is a measure of waste intensity and is a metric investors commonly look to when comparing the waste profiles of different companies.',
  'DESCRIPTION_M-EE10-2':
    'This metric represents the propotion of your total waste which is recycled or reused instead of being sent to landfill. It provides insight into both waste manageemnt and resource management practices.',
  'DESCRIPTION_M-EE38-2':
    'This metric represents you total hazardous waste production relative to your revenue (or net sales). It is most commonly measured in tons of hazardous waste per $1 million in revenue (or net sales). It is a measure of hazardous watse intensity and is a metric investors commonly look to when assessing the hazardous waste profiels of different companies.',
  'DESCRIPTION_M-EE17-2':
    'This metric represents your water pollution intensity and is a metric investors commonly look to when assessing the waste management profiles of different companies. It is measured as the total weight of water pollutants emitted per $1 million in revenue (or net sales).',
  'DESCRIPTION_M-EE17-4':
    'This metric represents your water intensity. it is a measure of your water consumption relative to every $1 million in revenue (or net sales). Investors typically prefer lwoer water intensities as it signals lower costs of operations as well as reduced risk exposure in times of water shortages.',
  'DESCRIPTION_M-EE102-2':
    'This metric represents energy intensity and is measured as your total ennergy consumption, measured in gigajoules, per $1 million in revenue (or net sales). A lower energy intensity typically signals lower costs of oepration as well as reduced risk exposure during perios of energy scarcity.',
  'DESCRIPTION_M-ES113-3':
    'This metric is important in Diversity, Equity, and Inclusion performance. It represents the propotion of manager-level positions held by individuals who identify as women.',
  'DESCRIPTION_M-ES37-2': 'This metric represents average employee compensation, reported in $USD',
  'DESCRIPTION_M-ES31-1':
    "This metric represents the propotion of the company's labor force which turns-over at an annual basis. It is often looked to as a signal of the quality of employment relations.",
  'DESCRIPTION_M-ES7-2':
    'This metric reflects the average number of hours of training employees recieve every years. It is often looked to as a signal of the quality of employment relations, workplace safety, and productivity.',
  'DESCRIPTION_M-ES5-2':
    'This metric reflects the average training costs per employee per year. It is often looked to as a signal of the quality of employment relations, workplace safety, and productivity.',
  'DESCRIPTION_M-EG6-2':
    'This metric rerpesents the total annual compensation of the CEO or of the highest-compensated psoition at the company. It is often looked to as a signal of resource allocation within the organization.',
  'DESCRIPTION_M-ES119-1':
    'This metric reflects workplace safety. It is measured as the total number of lost workdays divided by the total number of workdays.',
  'DESCRIPTION_M-ES117-1':
    'This metric rerpesents injury intensity and is a reflection of workplace safety. It is measured as the number of workplace injuries relative to one million hours worked.',
  'DESCRIPTION_M-EG120-1':
    'This metric represents the deviation of the number of directors away from what is typically believed to be most effective, which is 8-10 directors.',
  'DESCRIPTION_M-ES1-1':
    'This metric represents the number of members of the Board of Directors who identify as women. It is a measure of board diversity.',
  'DESCRIPTION_M-ES1-2':
    'This metric represents the number of member sof the Board of Directors who identify as culturally as other than the predominant culture from where the company is organized. It is a measure of board diversity.',
  'DESCRIPTION_M-EG124-1':
    'This metric provides a count of the number of annual board meetings. It is often looked to as a signal of board accountability and oversight.',
  'DESCRIPTION_M-EG27-2':
    'This metric represnts the proportion of member sof the Board of Directors who are independent of the company. It is often looked to as a signal of board accountability, oversight, and diversity.',
  'DESCRIPTION_M-EG13-3':
    'This metric represents the number of independent members of the Board of Directors who are appointed to the Audit Committee of the Board of Directors. It is often looked to as a signal of board accountability and oversight.',
  'DESCRIPTION_M-EG5-3':
    'This metric represents the number of independent members of the Board of Directors who are appointed to the Compensation Committee of the Board of Directors. It is often looked to as a signal of board accountability and oversight.',
  'DESCRIPTION_M-EG126-2':
    'This metric represents the proportion of the members of the Board of Directors who are not also employees of the company. It is oftern looked to as a signal of Board independence.',
  'DESCRIPTION_M-EG5-6':
    'This metric represents the number of non-executive members of the Board of Directors who are appointed to the Nomination Committee of the Board of Directors. It is often looked to as a signal of board accountability and oversight.',
  'DESCRIPTION_M-EG6-4':
    'This metric represents the total highest annual compensation package wihtin the company, measured in $USD.',
  'DESCRIPTION_M-EG33-3':
    "This metric represnts the number of members of the Board of Directors who serve on the Nomination Committee and who are also significant shareholders (meaning they control 10% or more of voting rights). It is often looke dto as a signal of potential 'Board capture'.",
  DESCRIPTION_E_Score:
    'This is an aggregated metric providing a summary overview of all metrics wihtin the Environmental pillar of ESG.',
  DESCRIPTION_S_Score:
    'This is an aggregated metric providing a summary overview of all metrics wihtin the Social pillar of ESG.',
  DESCRIPTION_G_Score:
    'This is an aggregated metric providing a summary overview of all metrics wihtin the Governance pillar of ESG.',
  DESCRIPTION_ESG_TS:
    'This is an aggregated metric providing a summary overview of all ESG metrics',
  DESCRIPTION_ESG_R:
    'This is an aggregated metric providing your industry rank based on Motive RealScore.',
  'DESCRIPTION_YN US':
    'Displays if  your company is a signatory to the United Nations Global Compact (UNGC). Typically looked to as a signal the comany has generally acknowledged the concept of corproate social responsibility. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU1:
    'Does the company reflect the idea that businesses should support and respect the protection of internationally proclaimed human rights, either by a direct policy or clear language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU2:
    'Monitors for Human Rights Does the company demonstrate a process and/or protocol to ensure they are not complicit in human rights abuses? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU3:
    'Does the company reflect the idea that businesses should uphold the freedom of association and the effective recognition of the right to collective bargaining, either by a direct policy or clear language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU4:
    'Does the company demonstrate a process and/or protocol to elimnate all forms of forced or compulsory labor? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU5:
    'Does the company demonstrate a process and/or protocol to contribute to the effective abolition of child labor? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU6:
    'Does the company reflect the idea that businesses should eliminate discrimination in respect of employment and occupation, either by a direct policy or clear language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU7:
    'Does the company reflect the idea that businesses should support a precautionary principle approach to environmental challenges, either by a direct policy or clear language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU8:
    'Does the company undertake initiatives and/or campaigns to promote greater environmental responsibility? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU9:
    'Does the company encourage the developemnt and diffusion of environmentally friendly technologies, either through development, purchase, use, or sale/distribution? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EU10:
    'Does the company reflect the idea that businesses should work agaisnt corruption in all its forms, including extortion and bribery, either by a direct policy or clear language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE1:
    'Does the company have a comprehensive environmental policy? If so, is the policy accessible to the public? If not, a a statement of comprehensive environemtnal policy accessible to the public? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE2:
    'Does the company have an environmental protection committee, or a similar committee by a related name, such as a sustaianbility or environmental impact committee? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE3:
    'Does the company have renewable energy targets? More details about the taregts are almost always apprciated, but the first point of order is in actually establishing targets. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE5:
    'Are wood, paper, and fiber products sourced from respsonsibly managed forests? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE6:
    'Does the company use recylced or recyclable raw material in it sproduction process? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE7:
    'Are sustainability plans and reporting integrated with business plans and reporting? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE8:
    'Does the company have any programs, protocols, and/or processes to protect biodiversity? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE9:
    'Does the company have an Environemtnal Management System (EMS) certification, such as (but not exclusively) ISO 14001? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE10:
    'Does the company have any waste manaegemnt programs? These coudl be any programs, processes, and/or protocols to minimize waste, divert waste from landfills, enhance recylcing, and so on. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE12:
    "Can the company's products be recycled? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.",
  DESCRIPTION_EE13:
    'Does the company have any programs, protocols, and/or processes to reduce hazardours waste? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE14:
    'Does the company actively and immediately report oil spills? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE15:
    'Has the company been involved in any environmental legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE16:
    'Does the company have a waster use and manageemnt plan or strategy? Is this plan or strategy publicly accessible? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE17:
    'Does the company publish quantitative water data, such as total water use, waste water emissions, and so on?  This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE18:
    'Does the company report on any water reuse and/or recylcing strategies? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE19:
    'Does the company publish quantitative air quality data, such as air emissions, air quality, particualte matter coutns, and so on? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE20:
    'Does the company have any programs, processes, and/or protocols to reduce air emissions? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE21:
    'Does the company disclose greenhosue gas (GHG) emissions? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE22:
    'Does the company disclose to the CDP initiative (https://www.cdp.net/en)? The CDP initiative is widely considered one of the more robust environmental data reporting frameworks. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE24:
    'Does the company have plans to reduce greenhouse gas (GHG) emissions from its buildings? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE25:
    'Does the company have plans to reduce greenhouse gas (GHG) emissions from its vehicles? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE27:
    'Is the company committed to stakeholder involvment? is this demonstrated in an explicit policy or language to this effect? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance',
  DESCRIPTION_EE28:
    'Does the company have a policy, program, and/or intiative to address the environmentla impacts of its products and/or services? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE32:
    'Does the company publicly report the findings and/or results of its environmental committee activities? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE34:
    'If the company reports current environmental achievements, are these compared to announced targets? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE35:
    'Does the company actually abide by ISO14001 or the EU-EMAS guidelines? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE36:
    'Does the company publish the nature and extent of its investments and/or expenditures in environmental improvements? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE37:
    'Does the company report quantitative waste-related data? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE38:
    'Does the company report quantitative hazardous waste-related data? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EE39:
    'Does the the company have an established environmental grievance mechanism and/or protocol? This would be a clear way for stakeholders to enagge the company relating to issues of environmental impact. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES1:
    'Do 20% or more of the members of the Board of Directors identify as women and/or as minorities? The 20% threshold is commonly used so as to ensure comparability across multiple markets, but please note that many investors and stakeholders are quite interested in absolute rather than relative figures. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES2:
    "Does the company provide clear training to employees related to the company's Supplier Code of Conduct? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance",
  DESCRIPTION_ES3:
    'Do 20% or more of temployees identify as women and/or as minorities? The 20% threshold is commonly used so as to ensure comparability across multiple markets, but please note that many investors and stakeholders are quite interested in absolute rather than relative figures. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES4:
    'Has the company been involved in any customer-related legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES5:
    'Does the company provide training to employees relevant to their repsective positions and responsbilities? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES6:
    'Does the company have any programs and/or protocols to manage access to medecines? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES7:
    'Does the company report the number of training hours or employees trained? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES8:
    'Does the company have any programs to assist and/or lead in the development of medecines for neglected diseases, if applicable? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES9:
    'Does the company have an established policy and/or program to identify, address,a dn eliminate discrimination? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES10:
    'Does the company operate and/or participate in any programs to improve acecss to healthcare? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES11:
    'Does the company have a policy, program, and/or initiative to improve supplier diversity? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES12:
    'Does the company have any programs and/or initiatives to improve access to food? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES13:
    'Has the company been involved in any human rights-related legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES14:
    'Does the company have any programs and/or initiatives to improve access to education? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES15:
    'Does the company publish accident and workplace injury rates? his is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES16:
    'Does the company have any programs and/or initiatives to enhance local community development? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES17:
    'Does the company provide safety training, either directly or through a thrid-party? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES18:
    'Does the company have any programs and/or initiatvies to promote access to financial services for disadvanatged people? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES20:
    'Does the company have a policy statement on customer data privacy? Is this stateemnt clear and accessible? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES21:
    'Are employees permitted to join trade unions? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES22:
    'Does the company have a policy statement on supplier and/or partner data privacy? Is this statement clear and accessible? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES23:
    'Does the company have a clear and accessible policy statement not to engage in, or benefit from, forced lanor? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES24:
    'Has the company established policies and/or protocols to respect the privacy of current and former employees? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES25:
    'Has the company attained any particular Health and Safety Certifications? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES26:
    'Does the company ensure personal consent prior to processing personal data? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES27:
    'Has the company been involved in any employee health and safety-related legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES28:
    'Does the company have a Supplier Code of Conduct? Is this code clear and accessible? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES29:
    'Does the company report employee volunteer efforts? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES30:
    'Does the company conduct inspections of suppliers, either directly or by means of a third-party? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES31:
    "Does the company reports it's employee turnover rate? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.",
  DESCRIPTION_ES32:
    'Does the company report its inspection of suppliers and any violations of its Supplier Code of Conduct? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES33:
    'Has the company established any programs and/or protocols to monitor the implementation of its polciies on human rights? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES34:
    'Has the company attained a Fair Trade Certification? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES35:
    'Does the company have inclusive employment policies and/or programs to include employment of disabled individuals? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES36:
    'Has the company been involved in supply chain-related legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES37:
    'Does the company have a clear compensation policy for employees? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES38:
    'Does the company provide, produe, or otherwise support organic foods? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES39:
    'Does the company used biological-based fertilizers and insecticides, when applicable? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES40:
    'Does the company communicate chemical constituents in its products? Is this communication clear and accessible? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES41:
    'Does this company undertake, or knowingly cause to be undertaken, testing of products on animals? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES42:
    'Does this company abide by applicable national regulations when testing on animals, if applicable? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES43:
    'Does the company have any programs and/or initiatives designed to optimize quality assurance and product safety? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES44:
    'Has the company attained a particular Quality Management System (QMS) certification? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES45:
    'Does the company make charitable donations exceeding 50,000 pound sterling? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES46:
    'Does the company have a clear policy not to engage, nor knowingly benefit from, child labor? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES47:
    'Is a senior manager responsible for community programs? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES48:
    'Does the company abide by International Labour Organization (ILO) Core Labour Standards? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES49:
    'Is the company a signatory to the United Nations Global Compact, the SA8000, and/or the OECD guidelines? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES51:
    'Does the company publicly support the Universal Declaration on Human Rights? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES52:
    'Is the company a signatory to the Voluntary Principles on Security and Human Rights? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES54:
    'Does the company provide employees tarining on human rights? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES56:
    'Does the company report on its human rights polciy implementation and performance? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES19:
    'Does the company have specific programs and targets to reduce health and safety incidents? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES63:
    'Does the company use animals in product manufacturing? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES64:
    'Does the company integrate social sustaianbility plans and reporting in its business activities and reporting? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES65:
    'Has the company established a work-life balance policy statement? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES66:
    'Does the company provide medical insurance to its employees? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES67:
    'Does the company have programs and/or initiatives to enhance access to affordable housing? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES68:
    'Does the company allow third-party access to personal information? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES69:
    'Does the company measure customer satisfaction? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES70:
    'Does the company measure employee satisfaction? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_ES71:
    'Does the the company have an established social grievance mechanism and/or protocol? This would be a clear way for stakeholders to engage the company relating to issues of social, personal, and community impact. This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG1:
    'Does the company have a clear anti-corruption policy?  This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG2:
    'Does the company provide anti-corruption training to employees? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG4:
    'Has the company been involved in corruption-related legal proceedings, regarldess of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG5:
    'Does the company have a committee to recommend the nomination and/or compensation of members of the Board of Directors? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG6:
    'Does the company publish information about Board of Director compensation? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG7:
    'Does the company publish a Sustainability Report? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG8:
    'Does the company disclose accounting policies and financial transparency? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG9:
    "Does the company's accoutning comply with IFRS or GAAP in financial statements? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.",
  DESCRIPTION_EG10:
    'Does the company have a clear anti-competitive / anti-trust policy statement? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG11:
    'Has the company been involved in any anti-competitive legal proceedings, regardless of fianl verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG12:
    'Are the roles of CEO and Chairperson of the Board of Directors separate? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG13:
    'Does the company have an Audit and Risk Committee to monitor internal and/or external auditors? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG14:
    "Are the company's CSR reports externally verified? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.",
  DESCRIPTION_EG15:
    'Does the company have an internal team for respsonsible investment? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG16:
    'Is executive compensation linked ot ESG performance? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG17:
    'Does the company have a policy statement on responsible marketing? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG19:
    'Has the company been involved in any patent infringement legal proceedings, regardless of final verdict(s)? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG20:
    'Does the company conduct antu-corruption audits? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG21:
    'Has the company established clear whistle-blower procedures and protocols? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG22:
    'Does the company have clear procedures to manage bribery-related investigations? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG24:
    'Does the company clearly communicate its anti-corruption policy to employees? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG25:
    'Is the Chairperson of the Board of Directors an independent director? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG26:
    'Do all members of the Audit Committee of the Board of Directors have financial background expertise? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG27:
    'Are the majority of members of the Board of Directors independent directors? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG29:
    'Does the company have a clear Code of Ethics for senior executives? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG28:
    'Does the company have a formal Corporate Governance policy statement? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG30:
    'Does the company have a policy statement addressing political contributions? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG31:
    'Does the company have a clear policy statement addressing insider trading? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
  DESCRIPTION_EG33:
    'Are members of the Board of Directors stockowners? This is a qualitative metric which serves as a signal to stakeholders and shareholders rather than an objective determination of performance.',
});
