import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';
import PasswordInput from 'src/components/Inputs/PasswordInput';
import TextInput from 'src/components/Inputs/TextInput';
import UserMessage from 'src/components/UserMessage';

import './i18n.ts';
import {
  ButtonWrapper,
  InputsWrapper,
  InputWrapper,
  LogoWrapper,
  MotiveLogo,
  PersonIcon,
  TextLink,
  TextLinkWrapper,
  Wrapper,
} from './styles';
import { ILoginViewProps } from './types';

const Login = (props: ILoginViewProps) => {
  const { loading, userMessage, onSubmit, onForgotPassword } = props;
  const [loginDetails, setLoginDetails] = useState({ email: '', password: '' });

  const handleEmailChange = (value: string) => {
    setLoginDetails({ ...loginDetails, email: value });
  };

  const handlePasswordChange = (value: string) => {
    setLoginDetails({ ...loginDetails, password: value });
  };

  const handleSubmit = () => {
    onSubmit({
      ...loginDetails,
      email: loginDetails.email.toLowerCase().replace(/\s+/g, ''),
    });
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === 13 && loginDetails.email !== '' && loginDetails.password !== '') {
        handleSubmit();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <Wrapper>
      <InputsWrapper>
        <LogoWrapper>
          <MotiveLogo src={Logo} alt="Motive Logo" />
        </LogoWrapper>
        {userMessage.messageType !== null && (
          <UserMessage
            messageType={userMessage.messageType}
            message={userMessage.message}
            isLogin={true}
          />
        )}
        <InputWrapper isErrorActive={userMessage.messageType !== null}>
          <TextInput
            isDisabled={false}
            error=""
            id={i18next.t('LOGIN_INPUT:EMAIL').toLowerCase()}
            name={i18next.t('LOGIN_INPUT:EMAIL')}
            placeholder={i18next.t('LOGIN_INPUT:EMAIL')}
            value={loginDetails.email}
            onChange={handleEmailChange}
            adornment={<PersonIcon />}
          />
        </InputWrapper>
        <InputWrapper isErrorActive={false}>
          <PasswordInput
            isDisabled={false}
            error=""
            id={i18next.t('LOGIN_INPUT:PASSWORD').toLowerCase()}
            name={i18next.t('LOGIN_INPUT:PASSWORD')}
            placeholder={i18next.t('LOGIN_INPUT:PASSWORD')}
            value={loginDetails.password}
            onChange={handlePasswordChange}
          />
        </InputWrapper>
        <TextLinkWrapper>
          <TextLink onClick={onForgotPassword}>{i18next.t('LOGIN_INPUT:FORGOT_PASSWORD')}</TextLink>
        </TextLinkWrapper>
        <ButtonWrapper>
          <TextButton
            isDisabled={loginDetails.email === '' || loginDetails.password === ''}
            isLoading={loading}
            text={i18next.t('LOGIN_INPUT:LOGIN')}
            onSubmit={handleSubmit}
          />
        </ButtonWrapper>
      </InputsWrapper>
    </Wrapper>
  );
};

export default Login;
