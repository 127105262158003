import i18next from 'i18next';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { SIZES } from 'src/helpers/devices';

import './i18n';
import { Container, TextContainer, TextLink, Text, WrapperText, Separator } from './styles';
import { IFooterProps } from './types';

const Footer = (props: IFooterProps) => {
  const { handleTrackEvent } = props;

  const handleTrackEventPrivacy = () => {
    handleTrackEvent('Redirect to Privacy Policy');
  };

  const handleTrackEventTermsAndConditions = () => {
    handleTrackEvent('Redirect to Terms And Conditions');
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.bigPhone}px)`,
  });

  return (
    <Container>
      <TextContainer>
        <Text>{i18next.t('FOOTER:COPYRIGHT')}</Text>
        <Separator>|</Separator>
      </TextContainer>
      {!isMobile && (
        <>
          <TextContainer>
            <TextLink
              data-testid="footer-privacy-policy-link"
              href="https://app.termly.io/document/privacy-policy/db40303d-0dae-4c8e-b682-7967e3f1e389"
              target="_blank"
              onClick={handleTrackEventPrivacy}
            >
              {i18next.t('FOOTER:PRIVACY')}
            </TextLink>
            <Separator>|</Separator>
          </TextContainer>
          <TextContainer>
            <TextLink
              data-testid="footer-terms-condition-link"
              href="https://app.termly.io/document/terms-of-use-for-website/c2efd8f8-1336-4b24-9d05-6492103679ff"
              target="_blank"
              onClick={handleTrackEventTermsAndConditions}
            >
              {i18next.t('FOOTER:TERMS_AND_CONDITION')}
            </TextLink>
          </TextContainer>
        </>
      )}
      {isMobile && (
        <WrapperText>
          <TextContainer>
            <TextLink
              href="https://app.termly.io/document/privacy-policy/db40303d-0dae-4c8e-b682-7967e3f1e389"
              target="_blank"
              onClick={handleTrackEventPrivacy}
            >
              {i18next.t('FOOTER:PRIVACY')}
            </TextLink>
            <Separator>|</Separator>
          </TextContainer>
          <TextContainer>
            <TextLink
              href="https://app.termly.io/document/terms-of-use-for-website/c2efd8f8-1336-4b24-9d05-6492103679ff"
              target="_blank"
              onClick={handleTrackEventTermsAndConditions}
            >
              {i18next.t('FOOTER:TERMS_AND_CONDITION')}
            </TextLink>
          </TextContainer>
        </WrapperText>
      )}
    </Container>
  );
};

export default Footer;
