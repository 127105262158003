import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { PillarStates } from 'src/enums/pillarStates';
import { getPillarState, isPercentage } from 'src/helpers/data';
import { filterNotTrackedPillarValues } from 'src/helpers/pillarsScores';

const pillarDetailsSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.data.pillarValues,
      (state: IReduxState) => state.data.companyData,
      (state: IReduxState) => state.data.selectedExploreTab,
    ],

    (pillarValues, data, selectedExploreTab) => {
      if (pillarValues.length === 0 || data.length === 0 || selectedExploreTab === '') {
        return [];
      }
      const states = [
        PillarStates.NEGATIVE,
        PillarStates.NEUTRAL,
        PillarStates.POSITIVE,
        PillarStates.NOINFORMATION,
      ];

      const values = pillarValues.find((scores) => scores.group === selectedExploreTab)!;
      if (!values) {
        return [];
      }

      const scores = values.data.map((score) => {
        const scoreValue = data.find((pillar) => pillar.identifier === score.pillarCode);
        if (
          scoreValue!.value === 'Not Available' ||
          scoreValue!.value === 'Not Applicable' ||
          scoreValue!.value === 'In-conclusive'
        ) {
          return {
            state: PillarStates.NOINFORMATION,
            dataItem: scoreValue,
          };
        }
        if (scoreValue!.value === 'Yes' || scoreValue!.value === 'No') {
          const pillarFiltered = score.values.filter(
            (value) =>
              value.toString() !== 'Not Available' &&
              value.toString() !== 'Not Applicable' &&
              value.toString() !== 'In-conclusive',
          );
          return {
            percentageYes: Math.round(
              (pillarFiltered.filter((value) => value.toString() === 'Yes').length * 100) /
                pillarFiltered.length,
            ),
            percentageNo: Math.round(
              (pillarFiltered.filter((value) => value.toString() === 'No').length * 100) /
                pillarFiltered.length,
            ),
            state: scoreValue!.value === 'Yes' ? PillarStates.POSITIVE : PillarStates.NEGATIVE,
            dataItem: scoreValue,
          };
        }
        const pillarFiltered = score.values.filter((value) => filterNotTrackedPillarValues(value));
        let sortedValue = [];
        if (isPercentage(pillarFiltered[0].toString())) {
          const sortedPercentageValue =
            score.sortBy === 'asc'
              ? pillarFiltered.sort((a: any, b: any) => a.slice(0, -1) - b.slice(0, -1))
              : pillarFiltered.sort((a: any, b: any) => b.slice(0, -1) - a.slice(0, -1));
          sortedValue = sortedPercentageValue;
        } else {
          sortedValue =
            score.sortBy === 'asc'
              ? pillarFiltered.sort((a: any, b: any) => a - b)
              : pillarFiltered.sort((a: any, b: any) => b - a);
        }
        const datapos = sortedValue.findIndex((a) => a.toString() === scoreValue!.value);

        return {
          maxValue: sortedValue[0],
          minValue: sortedValue[sortedValue.length - 1],
          dataPosition: datapos + 1,
          state: getPillarState(Math.trunc((datapos + 1) * 100) / sortedValue.length),
          dataItem: scoreValue,
        };
      });

      let filteredByPillar: any = [];

      states.forEach((state) => {
        const filteredData = scores;
        let pillars: any = { state: state, data: [] };
        filteredData.forEach((element) => {
          if (element.state === state) {
            pillars.data.push(element);
          }
        });
        if (pillars.data.length !== 0) {
          filteredByPillar.push(pillars);
        }
      });

      return filteredByPillar;
    },
  );
export default pillarDetailsSelector;
