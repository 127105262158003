import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'CHANGE_PASSWORD_INPUT', {
  TITLE: 'Change your password',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  SAVE: 'Save',
  USER: 'User',
  COMPANY: 'Associated company',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  EMAIL: 'Email',
});
