import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';

import { DefaultTheme } from 'src/styles/themes/default';

import { IHelpModuleWrapperProps, ITooltipFooterProps } from './types';

interface HelpTooltipsProps {
  modalWidth: number;
}

export const HelpWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ModuleWrapper = styled.div<IHelpModuleWrapperProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor && props.enabled ? props.backgroundColor : 'transparent'};
  border-radius: 16px;
  position: relative;
  box-shadow: ${(props) =>
    props.enabled ? `0 0 36px -10px ${props.theme.palette.lightSecondary}` : props.backgroundColor};
  z-index: ${(props) => (props.enabled ? 1400 : 1200)};
  ${(props) => props.enabled && 'pointer-events: none'}
`;

export const HelpBackground = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(44, 68, 92, 0.9);
  position: fixed;
  z-index: 1300;
  top: 0;
  left: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const HelpTooltip = muiStyled(
  ({ className, ...props }: TooltipProps & HelpTooltipsProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)((props) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: DefaultTheme.palette.secondary,
      borderRadius: '20px',
      maxWidth: `${props.modalWidth}px`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: DefaultTheme.palette.secondary,
      transform: 'translate(0px, 25px) !important',
    },
  };
});

export const NavigateButton = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.tertiary};
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.quaternary};
  }
`;

export const TooltipFooter = styled.div<ITooltipFooterProps>`
  display: flex;
  justify-content: ${(props) => (props.isFirstModule ? 'flex-end' : 'space-between')};
`;
