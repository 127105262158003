import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const industriesScoresSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.score.industriesScores,
      (state: IReduxState) => state.user.user?.company.industry,
    ],
    (industriesScores, userIndustry) =>
      industriesScores?.find((element) => element.industry === userIndustry)?.data || null,
  );

export default industriesScoresSelector;
