import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

export const getUserSettings = (state: IReduxState) => {
  return state.user.userSettings;
};

const settingsSelector = () =>
  createSelector([(state: IReduxState) => state.user.userSettings], (settings) => settings);

export default settingsSelector;
