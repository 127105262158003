import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Materialities, MaterialityType } from 'src/enums/pillarScores';

import MaterialityDetails from '../MaterialityPerformanceModule/components/MaterialityDetails';
import '../MaterialityRankingModule/i18n';
import './i18n';
import {
  Wrapper,
  LoadingWrapper,
  LoadingIndicator,
  Materiality,
  TabsWrapper,
  MaterialityName,
  MaterialityPerformance,
  TitleWrapper,
  Title,
  Subtitle,
  ExplanaitionWrapper,
  Explanaition,
} from './styles';
import { IMaterialityPerformanceModuleProps } from './types';

const MaterialityPerformanceMobileModule = (props: IMaterialityPerformanceModuleProps) => {
  const { allMaterialities, positiveMaterialities, userCompany, industryScores, handleTabChange } =
    props;
  const [selectedMateriality, setSelectedMateriality] = useState<Materialities | null>(null);

  const loadingModule = !allMaterialities || !industryScores;

  const handleTabClick = (materiality: Materialities) => {
    setSelectedMateriality(materiality);
    handleTabChange(materiality);
  };

  useEffect(() => {
    setSelectedMateriality(allMaterialities ? allMaterialities[0] : null);
  }, [allMaterialities]);
  return (
    <Wrapper>
      {loadingModule && !positiveMaterialities && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {!loadingModule && positiveMaterialities && (
        <>
          <TitleWrapper>
            <Title>
              {i18next.t('MATERIALITY_PERFORMANCE_MODULE:TITLE', { company: userCompany.name })}
            </Title>
            <Subtitle> {i18next.t('MATERIALITY_PERFORMANCE_MODULE:SUBTITLE')} </Subtitle>
          </TitleWrapper>
          <TabsWrapper>
            {allMaterialities.map((materiality) => {
              const isPositive = positiveMaterialities.includes(materiality);
              return (
                <>
                  <Materiality
                    materialityType={
                      isPositive ? MaterialityType.POSITIVE : MaterialityType.NEGATIVE
                    }
                    isSelected={selectedMateriality === materiality}
                    onClick={() => handleTabClick(materiality)}
                  >
                    <MaterialityName>
                      {i18next.t(`SALES_MATERIALITY:${materiality}`)}
                    </MaterialityName>
                    <MaterialityPerformance>
                      {i18next.t(`MATERIALITY_PERFORMANCE_MODULE:POSITIVE_${isPositive}`)}
                    </MaterialityPerformance>
                  </Materiality>
                  {selectedMateriality && selectedMateriality === materiality && (
                    <MaterialityDetails
                      industryScores={industryScores}
                      companyScore={userCompany.lastUpdatedScore!}
                      materiality={selectedMateriality}
                      materialityType={
                        positiveMaterialities.includes(selectedMateriality)
                          ? MaterialityType.POSITIVE
                          : MaterialityType.NEGATIVE
                      }
                    />
                  )}
                </>
              );
            })}
          </TabsWrapper>
        </>
      )}
      {!loadingModule && !userCompany.lastUpdatedScore && (
        <>
          <TitleWrapper>
            <Title>
              {i18next.t('MATERIALITY_PERFORMANCE_MODULE:TITLE_WIHOUT_SCORES', {
                company: userCompany.name,
              })}
            </Title>
            <Subtitle>
              {i18next.t('MATERIALITY_PERFORMANCE_MODULE:SUBTITLE_WIHOUT_SCORES')}{' '}
            </Subtitle>
          </TitleWrapper>
          <TabsWrapper>
            {allMaterialities.map((materiality) => {
              return (
                <>
                  <Materiality
                    materialityType={MaterialityType.NEUTRAL}
                    isSelected={selectedMateriality === materiality}
                    onClick={() => handleTabClick(materiality)}
                  >
                    <MaterialityName>
                      {i18next.t(`SALES_MATERIALITY:${materiality}`)}
                    </MaterialityName>
                  </Materiality>
                  {selectedMateriality && selectedMateriality === materiality && (
                    <MaterialityDetails
                      industryScores={industryScores}
                      materiality={selectedMateriality}
                      companyScore={null}
                      materialityType={MaterialityType.NEUTRAL}
                    />
                  )}
                </>
              );
            })}
          </TabsWrapper>

          <ExplanaitionWrapper>
            <Explanaition>{i18next.t(`MATERIALITY_PERFORMANCE_MODULE:EXPLANAITION`)}</Explanaition>
          </ExplanaitionWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default MaterialityPerformanceMobileModule;
