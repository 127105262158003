import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const TipWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TipHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const TipContent = styled.div`
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  line-height: 20px;
  font-size: 14px;
`;

export const TipTitle = styled.div`
  margin-left: 10px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  line-height: 22px;
`;

export const IconWrapper = styled(ReactSVG)`
  width: 30px;
  height: 30px;
`;
