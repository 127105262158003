import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'NEWS', {
  TITLE: 'News',
  RATING_TITLE: 'Sustainability never sleeps.',
  RATING_SUBTITLE:
    'Your Motive Newsfeed is carefully crafted to help you stay ahead of what matters.',
  BUTTON_LOAD_MORE: 'Load more news',
});
i18next.addResources(locales.EN, 'NEWS_RATING_DRAWIN', {
  TEXT: 'Drawing from',
  TEXT_2: '200,000+ sources',
  TEXT_3: 'across',
  TEXT_4: '150+ countries.',
});
i18next.addResources(locales.EN, 'NEWS_RATING_SCAN', {
  TEXT: 'Scan over',
  TEXT_2: '25 million news articles',
  TEXT_3: 'each day',
});
i18next.addResources(locales.EN, 'NEWS_RATING_CURATED', {
  TEXT: 'Curated   ',
  TEXT_2: '200+ Sustainability',
  TEXT_3: 'and',
  TEXT_4: 'ESG topic filters,',
  TEXT_5: 'revised weekly.',
});
i18next.addResources(locales.EN, 'NEWS_RATING_NEW_ARTICULE', {
  TEXT: '183 million',
  TEXT_2: 'applicable news articles analyzed in 2021, up 11% from 2020.',
});
