import * as userConstants from 'src/redux/constants/user';

import { HelpModules } from 'src/enums/help';
import { Materialities, PillarScores } from 'src/enums/pillarScores';
import { IDataItem, IGroupPillarValues } from 'src/helpers/types/data';
import { ICompany } from 'src/types/company';
import { IInsight } from 'src/types/insights';
import { INew } from 'src/types/new';
import { IScoresByIndustry } from 'src/types/score';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

export const redirectToLogin = () => {
  return {
    type: userConstants.USER_REDIRECT_TO_LOGIN,
  };
};

export const redirectToESGMotive = () => {
  return {
    type: userConstants.USER_REDIRECT_TO_ESGMOTIVE,
  };
};

export const loginRequested = (email: string, password: string) => {
  return {
    type: userConstants.USER_ON_LOGIN_REQUESTED,
    email,
    password,
  };
};

export const loginSucceded = (authToken: string) => {
  return {
    type: userConstants.USER_ON_LOGIN_SUCCEEDED,
    authToken,
  };
};

export const loginFailed = (errorMessage: string) => {
  return {
    type: userConstants.USER_ON_LOGIN_FAILED,
    errorMessage,
  };
};

export const signUpRequested = (
  firstName: string,
  lastName: string,
  email: string,
  company: string,
) => {
  return {
    type: userConstants.USER_ON_SIGNUP_REQUESTED,
    firstName,
    lastName,
    email,
    company,
  };
};

export const signUpSucceded = (isSendEmail: boolean) => {
  return {
    type: userConstants.USER_ON_SIGNUP_SUCCEEDED,
    isSendEmail,
  };
};

export const signUpFailed = (errorMessage: string) => {
  return {
    type: userConstants.USER_ON_SIGNUP_FAILED,
    errorMessage,
  };
};

export const initializeLogin = () => {
  return {
    type: userConstants.USER_ON_INITIALIZE_LOGIN_VIEW,
  };
};

export const initializeSignUpView = () => {
  return {
    type: userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW,
  };
};

export const initializeSignUpViewFailed = (errorMessage: string) => {
  return {
    type: userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW_FAILED,
    errorMessage,
  };
};

export const initializeSignUpViewSucceeded = (companies: ICompany[]) => {
  return {
    type: userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW_SUCCEEDED,
    companies,
  };
};

export const initializeChangePassword = (emailHash: string) => {
  return {
    type: userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW,
    emailHash,
  };
};

export const initializeChangePasswordSucceded = (user: IUser) => {
  return {
    type: userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_SUCCEDED,
    user,
  };
};

export const changePassword = (newPassword: string, jwtHash: string) => {
  return {
    type: userConstants.USER_ON_CHANGE_PASSWORD_REQUESTED,
    newPassword,
    jwtHash,
  };
};

export const onChangePasswordFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_CHANGE_PASSWORD_FAILED,
  errorMessage,
});

export const onChangePasswordSucceded = (user: IUser) => ({
  type: userConstants.USER_ON_CHANGE_PASSWORD_SUCCEDED,
  user,
});

export const initializeChangePasswordFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_CHAGE_PASSWORD_FAILED,
  errorMessage,
});

export const signOut = () => ({
  type: userConstants.USER_ON_SIGN_OUT,
});

export const getUserFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_GET_USER_FAILED,
  errorMessage,
});

export const initializeSummaryView = () => ({
  type: userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW,
});

export const initializeSummaryViewSucceeded = (
  news: INew[],
  industryRealScores: number[],
  insights: IInsight[],
) => ({
  type: userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED,
  news,
  industryRealScores,
  insights,
});

export const initializeSummaryViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED,
  errorMessage,
});

export const initializeBenchmarkView = () => ({
  type: userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW,
});

export const initializeBenchmarkViewSucceeded = (
  searchedCompanies: ICompany[],
  userSettings: IUserSettings,
) => ({
  type: userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_SUCCEEDED,
  searchedCompanies,
  userSettings,
});

export const initializeBenchmarkViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_FAILED,
  errorMessage,
});

export const onChangeSettings = (settings: IUserSettings) => ({
  type: userConstants.USER_ON_CHANGE_SETTINGS,
  settings,
});

export const onChangeSettingsSucceeded = (newSettings: IUserSettings) => ({
  type: userConstants.USER_ON_CHANGE_SETTINGS_SUCCEEDED,
  newSettings,
});

export const onChangeSettingsFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_CHANGE_SETTINGS_FAILED,
  errorMessage,
});

export const onHelpModeClose = (currentRoute: string, currentModule: HelpModules) => ({
  type: userConstants.USER_ON_HELP_CLOSE,
  currentRoute,
  currentModule,
});

export const onHelpModeNext = (currentRoute: string, currentModule?: HelpModules) => ({
  type: userConstants.USER_ON_HELP_NEXT,
  currentRoute,
  currentModule,
});

export const onHelpModeBack = (currentRoute: string, currentModule?: HelpModules) => ({
  type: userConstants.USER_ON_HELP_BACK,
  currentRoute,
  currentModule,
});

export const initializeInsightsView = () => ({
  type: userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW,
});

export const initializeInsightViewSucceeded = (insights: IInsight[]) => ({
  type: userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW_SUCCEEDED,
  insights,
});

export const initializeInsightViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW_FAILED,
  errorMessage,
});

export const initializeNewsView = () => ({
  type: userConstants.USER_ON_INITIALIZE_NEWS_VIEW,
});

export const initializeNewsViewSucceeded = (news: INew[], limit: number, offset: number) => ({
  type: userConstants.USER_ON_INITIALIZE_NEWS_VIEW_SUCCEEDED,
  news,
  limit,
  offset,
});

export const initializeNewsViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_NEWS_VIEW_FAILED,
  errorMessage,
});

export const onLoadMoreNews = () => ({
  type: userConstants.USER_ON_LOAD_MORE_NEWS,
});

export const onLoadMoreNewsSucceeded = (
  news: INew[],
  hasMore: boolean,
  offset: number,
  limit: number,
) => ({
  type: userConstants.USER_ON_LOAD_MORE_NEWS_SUCCEEDED,
  news,
  hasMore,
  offset,
  limit,
});

export const onLoadMoreNewsFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_LOAD_MORE_NEWS_FAILED,
  errorMessage,
});
export const onSendContactUsRequested = (
  userEmail: string,
  userCompany: string,
  reasonContactUs: string,
  message: string,
) => ({
  type: userConstants.USER_ON_SEND_CONTACT_US_REQUESTED,
  userEmail,
  userCompany,
  reasonContactUs,
  message,
});

export const onSendContactUsSucceeded = () => ({
  type: userConstants.USER_ON_SEND_CONTACT_US_SUCCEEDED,
});

export const onSendContactUsFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_SEND_CONTACT_US_FAILED,
  errorMessage,
});

export const onClickOpenContactUs = (open: boolean) => ({
  type: userConstants.USER_ON_OPEN_CONTACT_US,
  open,
});

export const onClickCloseContactUs = (open: boolean) => ({
  type: userConstants.USER_ON_CLOSE_CONTACT_US,
  open,
});

export const onClickSliderMenu = (isMenuOpen: boolean) => ({
  type: userConstants.USER_ON_CLICK_MENU_SLIDER,
  isMenuOpen,
});

export const closeUserMessage = () => ({
  type: userConstants.USER_CLOSE_USER_MESSAGE,
});

export const handleErrorUserMessage = (errorMessage: string) => ({
  type: userConstants.USER_ON_HANDLE_ERROR_MESSAGE,
  errorMessage,
});

export const initializeExploreView = () => ({
  type: userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW,
});

export const initializeExploreViewSucceeded = (
  industryRealScores: number[],
  userSettings: IUserSettings,
  pillarScoresResult: IScoresByIndustry[],
) => ({
  type: userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED,
  industryRealScores,
  userSettings,
  pillarScoresResult,
});

export const initializeExploreViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_FAILED,
  errorMessage,
});

export const onTrackEvent = (event: string, data: any = {}) => ({
  type: userConstants.USER_ON_TRACK_EVENT,
  event,
  data,
});

export const initializeSalesView = () => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_VIEW,
});

export const initializeSalesViewSucceeded = (
  searchedCompanies: ICompany[],
  userSettings: IUserSettings,
) => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_VIEW_SUCCEEDED,
  searchedCompanies,
  userSettings,
});

export const initializeSalesViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_VIEW_FAILED,
  errorMessage,
});

export const onPillarsPerformanceRequested = (pillars: PillarScores[]) => ({
  type: userConstants.USER_ON_PILLARS_PERFORMANCE_REQUESTED,
  pillars,
});

export const onPillarsPerformanceSucceeded = (pillarScoresResult: IScoresByIndustry[]) => ({
  type: userConstants.USER_ON_PILLARS_PERFORMANCE_SUCCEEDED,
  pillarScoresResult,
});

export const onPillarsPerformanceFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_PILLARS_PERFORMANCE_FAILED,
  errorMessage,
});

export const onScoresCompetitorsRequested = (pillars: PillarScores[]) => ({
  type: userConstants.USER_ON_SCORES_COMPETITOR_REQUESTED,
  pillars,
});

export const onScoresCompetitorsSucceeded = (industriesScores: IScoresByIndustry[]) => ({
  type: userConstants.USER_ON_SCORES_COMPETITOR_SUCCEEDED,
  industriesScores,
});

export const onScoresCompetitorsFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_SCORES_COMPETITOR_FAILED,
  errorMessage,
});

export const onInitializeSalesInsightView = (industry: string, companyName: string) => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED,
  industry,
  companyName,
});

export const onInitializeSalesInsightViewSucceeded = (
  materialityRanking: Materialities[],
  searchedCompanies: ICompany[],
) => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_SUCCEEDED,
  materialityRanking,
  searchedCompanies,
});

export const onInitializeSalesInsightViewFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED,
  errorMessage,
});

export const onSubmitTargetSales = (queryParams: string) => ({
  type: userConstants.USER_ON_SUBMIT_TARGET_SALES_REQUESTED,
  queryParams,
});

export const onSubmitTargetSalesSucceeded = (companies: ICompany[]) => ({
  type: userConstants.USER_ON_SUBMIT_TARGET_SALES_SUCCEEDED,
  companies,
});

export const onSubmitTargetSalesFailed = (errorMessage: string) => ({
  type: userConstants.USER_ON_SUBMIT_TARGET_SALES_FAILED,
  errorMessage,
});

export const onSalesBack = () => ({
  type: userConstants.USER_ON_SALES_BACK,
});

export const onRedirectForgotPassword = () => ({
  type: userConstants.USER_ON_CLICK_FORGOT_PASSWORD,
});

export const forgotPasswordRequested = (email: string) => {
  return {
    type: userConstants.USER_ON_FORGOT_PASSWORD_REQUESTED,
    email,
  };
};

export const onForgotPasswordSucceded = (isSendEmail: boolean) => {
  return {
    type: userConstants.USER_ON_FORGOT_PASSWORD_SUCCEEDED,
    isSendEmail,
  };
};

export const onForgotPasswordFailed = (errorMessage: string) => {
  return {
    type: userConstants.USER_ON_FORGOT_PASSWORD_FAILED,
    errorMessage,
  };
};

export const initializeForgotPasswordView = () => {
  return {
    type: userConstants.USER_ON_INITIALIZE_FORGOT_PASSWORD_VIEW,
  };
};

export const setSelectedExploreTab = (selectedExploreTab: string) => {
  return {
    type: userConstants.USER_ON_CHANGE_DATA_TAB,
    selectedExploreTab,
  };
};

export const setSelectedExploreTabSucceded = (
  data: IDataItem[],
  pillarValues: IGroupPillarValues[],
) => {
  return {
    type: userConstants.USER_ON_CHANGE_DATA_TAB_SUCCEDED,
    data,
    pillarValues,
  };
};

export const setSelectedExploreTabFailed = (errorMessage: string) => {
  return {
    type: userConstants.USER_ON_CHANGE_DATA_TAB_FAILED,
    errorMessage,
  };
};
