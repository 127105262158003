import { AnyAction } from 'redux';
import { takeLatest, call, all, put, select } from 'redux-saga/effects';

import * as appActions from 'src/redux/actions/app';
import * as userActions from 'src/redux/actions/user';
import * as appConstants from 'src/redux/constants/app';
import * as userConstants from 'src/redux/constants/user';
import * as userService from 'src/redux/services/user';
import { editSettings } from 'src/redux/services/user';

import { getAuthToken } from 'src/helpers/user';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

import isUserLoggedSelector from '../selectors/user/isUserLogged';
import userDataSelector from '../selectors/user/userData';

function* loginUser(action: AnyAction) {
  try {
    const loginResponse: string = yield call(userService.loginUser, action.email, action.password);
    yield put(userActions.loginSucceded(loginResponse));
  } catch (error: any) {
    yield put(userActions.loginFailed(error.data.message));
  }
}

function* signUpUser(action: AnyAction) {
  try {
    yield call(
      userService.signUpUser,
      action.firstName,
      action.lastName,
      action.email,
      action.company,
    );
    yield put(userActions.signUpSucceded(true));
  } catch (error: any) {
    yield put(userActions.loginFailed(error.data.message));
  }
}

function* getUser() {
  if (getAuthToken()) {
    const user: IUser = yield call(userService.getUser);
    return user;
  }
  return null;
}

function* initializePrivateRoute() {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector());
    const user: IUser = yield select(userDataSelector());
    let userLogging = { ...user };
    if (!isUserLogged) {
      userLogging = yield call(getUser);
    }
    yield put(appActions.initializePrivateRouteSucceded(userLogging));
  } catch (error: any) {
    yield put(appActions.initializePrivateRouteFailed(error));
  }
}

function* initializePublicRoute(action: AnyAction) {
  try {
    const isUserLogged: boolean = yield select(isUserLoggedSelector());
    const user: IUser = yield select(userDataSelector());
    let userLogging = { ...user };
    if (!isUserLogged) {
      userLogging = yield call(getUser);
    }
    yield put(appActions.initializePublicRouteSucceded(userLogging, action.restricted));
  } catch (error: any) {
    yield put(appActions.initializePublicRouteFailed(error));
  }
}

function* initializeChangePassword(action: AnyAction) {
  try {
    const user: IUser = yield call(userService.getUserByHash, action.emailHash);
    yield put(userActions.initializeChangePasswordSucceded(user));
  } catch (error: any) {
    yield put(userActions.initializeChangePasswordFailed(error.data.message));
  }
}

function* saveNewPassword(action: AnyAction) {
  try {
    const editUser: IUser = yield call(
      userService.changePassword,
      action.newPassword,
      action.jwtHash,
    );
    yield put(userActions.onChangePasswordSucceded(editUser));
  } catch (error: any) {
    yield put(userActions.onChangePasswordFailed(error.data.message));
  }
}

function* changeSettings(action: AnyAction) {
  try {
    const newSettings: IUserSettings = yield call(editSettings, action.settings);
    yield put(userActions.onChangeSettingsSucceeded(newSettings));
  } catch (error: any) {
    yield put(userActions.onChangeSettingsFailed(error.data.message));
  }
}

function* sendEmailContactUs(action: AnyAction) {
  try {
    yield call(
      userService.sendContactUs,
      action.userEmail,
      action.userCompany,
      action.reasonContactUs,
      action.message,
    );
    yield put(userActions.onSendContactUsSucceeded());
  } catch (error: any) {
    yield put(userActions.onSendContactUsFailed(error.data.message));
  }
}

function* sendEmailChangePassword(action: AnyAction) {
  try {
    yield call(userService.sendForgotPasswordEmail, action.email);
    yield put(userActions.onForgotPasswordSucceded(true));
  } catch (error: any) {
    yield put(userActions.onForgotPasswordFailed(error.data.message));
  }
}

export default all([
  takeLatest(appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_REQUESTED, initializePrivateRoute),
  takeLatest(appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_REQUESTED, initializePublicRoute),
  takeLatest(userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW, initializeChangePassword),
  takeLatest(userConstants.USER_ON_LOGIN_SUCCEEDED, getUser),
  takeLatest(userConstants.USER_ON_LOGIN_REQUESTED, loginUser),
  takeLatest(userConstants.USER_ON_SIGNUP_REQUESTED, signUpUser),
  takeLatest(userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW, getUser),
  takeLatest(userConstants.USER_ON_CHANGE_PASSWORD_REQUESTED, saveNewPassword),
  takeLatest(userConstants.USER_ON_CHANGE_SETTINGS, changeSettings),
  takeLatest(userConstants.USER_ON_SEND_CONTACT_US_REQUESTED, sendEmailContactUs),
  takeLatest(userConstants.USER_ON_FORGOT_PASSWORD_REQUESTED, sendEmailChangePassword),
]);
