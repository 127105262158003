import {
  ArgumentAxis,
  CommonAxisSettings,
  ConstantLine,
  DataPrepareSettings,
  Label,
  Series,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';
import React from 'react';

import PillarsMetadata from 'src/assets/PillarsMetadata';
import { formatScoreByPillar } from 'src/helpers/scores';
import { DefaultTheme } from 'src/styles/themes/default';

import { GraphWrapper } from './styles';
import { IPillarScoreChartData, IPillarScoreChartProps } from './types';

const customizePoint = (point: any) => {
  if (point.value > 0) {
    return { color: '#32CC6F', hoverStyle: { color: '#32CC6F' } };
  }
  if (point.value < 0) {
    return { color: '#F79C33', hoverStyle: { color: '#F79C33' } };
  }
  return {};
};

const renderCompanyLabel = (
  point: any,
  userCompanyName: string,
  chartData: IPillarScoreChartData[],
) => {
  let opacity = '100%';
  const companyScore = chartData.find((data) => data.company === point.valueText)?.score;
  if (companyScore === null) {
    opacity = '30%';
  }
  if (point.valueText === userCompanyName) {
    return (
      <text
        opacity={opacity}
        fill={DefaultTheme.palette.secondary}
        fontFamily={DefaultTheme.fontFamilies.bold}
      >
        {point.valueText}
      </text>
    );
  }
  return (
    <text
      opacity={opacity}
      fill={DefaultTheme.palette.common.grey}
      fontFamily={DefaultTheme.fontFamilies.regular}
    >
      {point.valueText}
    </text>
  );
};

const PillarScoreChart = (props: IPillarScoreChartProps) => {
  const { pillar, chartData, userCompany } = props;

  const pillarMetadata = PillarsMetadata.find(
    (pillarMetadata) => pillarMetadata.Identifier === pillar,
  );

  return (
    <GraphWrapper
      id="pillar-score-ranking-chart"
      animation={{ enabled: false }}
      rotated
      customizePoint={customizePoint}
      dataSource={chartData}
    >
      <DataPrepareSettings
        sortingMethod={(a: IPillarScoreChartData, b: IPillarScoreChartData) => {
          if (a.score === null) {
            return -1;
          }
          if (b.score === null) {
            return 1;
          }
          if (pillarMetadata?.SortBy === 'desc') {
            if (a.score > b.score) {
              return 1;
            }
          } else {
            if (a.score < b.score) {
              return 1;
            }
          }
          return -1;
        }}
      />
      <CommonAxisSettings tick={{ width: 0 }} />
      <Series
        argumentField="company"
        type="bar"
        cornerRadius="2px"
        showInLegend={false}
        valueField="score"
      />
      <Tooltip
        enabled
        zIndex={1400}
        color={DefaultTheme.palette.secondary}
        customizeTooltip={(point: any) => ({
          text: formatScoreByPillar(pillar, point.value),
        })}
        font={{
          color: DefaultTheme.palette.common.white,
          family: DefaultTheme.fontFamilies.bold,
          size: 22,
        }}
      />
      <CommonAxisSettings grid={{ color: DefaultTheme.palette.quaternary, opacity: 0.5 }} />
      <ValueAxis
        position="top"
        tickInterval={pillarMetadata?.ValueType === 'boolean' ? 1 : undefined}
        label={{
          customizeText: (point: any) => formatScoreByPillar(pillar, point.value),
        }}
      >
        <ConstantLine
          width={1}
          value={0}
          label={{ text: '' }}
          color={DefaultTheme.palette.tertiary}
        />
      </ValueAxis>
      <ArgumentAxis>
        <Label
          render={(point: any) => renderCompanyLabel(point, userCompany?.name || '', chartData)}
          alignment="left"
          visible
        />
      </ArgumentAxis>
    </GraphWrapper>
  );
};

export default PillarScoreChart;
