import i18next from 'i18next';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { SIZES } from 'src/helpers/devices';

import './i18n';
import {
  ContentWrapper,
  Wrapper,
  WrapperText,
  Border,
  HeaderWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  MaterialityWrapper,
  Materiality,
  TextTabletWrapper,
  LoadingIndicator,
  LoadingWrapper,
  MaterialityIndex,
  MaterialityName,
} from './styles';
import { IMaterialityRankginModuleProps } from './types';

const MaterialityRankingModule = (props: IMaterialityRankginModuleProps) => {
  const { materiality, targetName, targetIndustry } = props;
  const isBigTablet = useMediaQuery({
    query: `(max-width: ${SIZES.bigTablet}px)`,
  });

  return (
    <Wrapper>
      {!isBigTablet && (
        <HeaderWrapper>
          <WrapperText>{i18next.t('MATERIALITY_RANKING_MODULE:COMPANY_NAME')}</WrapperText>
          {targetName}
          <Border />
          <WrapperText>{i18next.t('MATERIALITY_RANKING_MODULE:COMPANY_INDUSTRY')}</WrapperText>
          {targetIndustry}
        </HeaderWrapper>
      )}
      {isBigTablet && (
        <HeaderWrapper>
          <TextTabletWrapper>
            <WrapperText>{i18next.t('MATERIALITY_RANKING_MODULE:COMPANY_NAME')}</WrapperText>
            {targetName}
          </TextTabletWrapper>
          <TextTabletWrapper>
            <WrapperText>{i18next.t('MATERIALITY_RANKING_MODULE:COMPANY_INDUSTRY')}</WrapperText>
            {targetIndustry}
          </TextTabletWrapper>
        </HeaderWrapper>
      )}
      <ContentWrapper>
        <TitleWrapper>
          <Title> {i18next.t('MATERIALITY_RANKING_MODULE:TITLE')} </Title>
          <Subtitle> {i18next.t('MATERIALITY_RANKING_MODULE:SUBTITLE')} </Subtitle>
        </TitleWrapper>
        <MaterialityWrapper>
          {!materiality && (
            <LoadingWrapper data-testid="loading-block">
              <LoadingIndicator />
            </LoadingWrapper>
          )}
          {materiality &&
            materiality.map((element, index) => (
              <Materiality>
                <MaterialityIndex>{index + 1}</MaterialityIndex>
                <MaterialityName>{i18next.t(`SALES_MATERIALITY:${element}`)}</MaterialityName>
              </Materiality>
            ))}
        </MaterialityWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default MaterialityRankingModule;
