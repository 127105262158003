import { CardMedia } from '@mui/material';
import { CardActionArea } from '@mui/material';
import styled from 'styled-components';

interface ICardWrapperProps {
  includeBorder: boolean;
}
export const MediaLink = styled.a`
  text-decoration: none;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: white !important;

    background-color: white;
  }
`;
export const CardWrapper = styled.div<ICardWrapperProps>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: ${(props) =>
    props.includeBorder ? `1px solid ${props.theme.palette.quinternary}` : 'none'};
  &:hover {
    color: white !important;

    background-color: white;
  }
`;

export const CardContentWrapper = styled.div`
  flex: 3;
`;

export const CardMediaWrapper = styled(CardMedia)`
  height: 100px;
  width: 132px;
  border-radius: 10px;
  margin-right: 15px;
  border: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const NewsItemDescription = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  line-height: 20px;
  font-size: 14px;
`;

export const WrapperCardActionArea = styled(CardActionArea)`
  color: white !important;
  background-color: white !important;
  border-radius: 10px !important;
  &:hover {
    color: white;
    background-color: white;
  }
`;
export const NewsItemTitle = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  ${WrapperCardActionArea}:hover & {
    color: ${(props) => props.theme.palette.common.grey};
  }
`;
