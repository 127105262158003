import { capitalize } from '@mui/material/utils';
import React from 'react';

import { getTipIconByType } from 'src/helpers/insights';

import { IconWrapper, TipContent, TipHeaderWrapper, TipTitle, TipWrapper } from './styles';
import { ITipProps } from './types';

const Tip = (props: ITipProps) => {
  const { tip } = props;

  return (
    <TipWrapper>
      <TipHeaderWrapper>
        <IconWrapper src={getTipIconByType(tip.category)} />
        <TipTitle>{capitalize(tip.category)}</TipTitle>
      </TipHeaderWrapper>
      <TipContent>{tip.description}</TipContent>
    </TipWrapper>
  );
};

export default Tip;
