import { Button } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import MotiveLogoSmall from 'src/assets/logos/logo-small.svg';

import '../Header/i18n';
import MobileMenu from '../MobileMenu';
import {
  HamburguerMenu,
  MotiveLogo,
  ProfileIcon,
  Wrapper,
  WrapperMenu,
  WrapperMenuItem,
} from './styles';
import { IHeaderMobileProps } from './types';

const HeaderMobile = (props: IHeaderMobileProps) => {
  const { onLogout, menuRoutes, currentPage, onClickopenContactUs } = props;
  const [isProfileOpen, setIsProfileOpen] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleProfileOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsProfileOpen(event.currentTarget);
  };

  const handleProfileClose = () => {
    setIsProfileOpen(null);
  };

  return (
    <>
      <MobileMenu
        isExpanded={isMenuOpen}
        currentPage={currentPage}
        menuRoutes={menuRoutes}
        onExpandChange={() => setIsMenuOpen(!isMenuOpen)}
        onClickopenContactUs={onClickopenContactUs}
      />
      <Wrapper>
        <HamburguerMenu onClick={() => setIsMenuOpen(true)} />
        <MotiveLogo src={MotiveLogoSmall} />
        <Button data-testid="header-mobile-profile-button" onClick={handleProfileOpen}>
          <ProfileIcon data-testid="header-mobile-profile-icon" />
        </Button>
        <WrapperMenu
          anchorEl={isProfileOpen}
          open={isProfileOpen !== null}
          onClose={handleProfileClose}
        >
          <WrapperMenuItem data-testid="header-mobile-logout-button" onClick={onLogout}>
            {i18next.t('HEADER:LOGOUT')}
          </WrapperMenuItem>
        </WrapperMenu>
      </Wrapper>
    </>
  );
};

export default HeaderMobile;
