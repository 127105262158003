import i18next from 'i18next';
import React from 'react';

import { getScoreProviderIcon } from 'src/helpers/scoreProvider';
import { ScoreProviders } from 'src/types/score';

import './i18n';
import {
  CardsWrapper,
  HelpWrapper,
  IconWrapper,
  ScoreProviderDescription,
  ScoreProviderWrapper,
  Title,
  WrapperScoreProviderDescription,
  WrapperTitle,
} from './styles';

const Help = () => {
  const scoreProviders = [
    ScoreProviders.SUSTAINALYTICS,
    ScoreProviders.REFINITIV,
    ScoreProviders.ARABESQUE,
    ScoreProviders.MSCI,
    ScoreProviders.SP,
    ScoreProviders.CDP,
    ScoreProviders.ISS,
  ];

  return (
    <HelpWrapper>
      <WrapperTitle>
        <Title data-testid="help-title"> {i18next.t('RATING_COVERAGE_HELP:TITLE')} </Title>
      </WrapperTitle>
      <CardsWrapper>
        {scoreProviders.map((provider) => {
          return (
            <ScoreProviderWrapper
              key={provider}
              data-testid="help-pillar-score-block"
              target="_blank"
              href={i18next.t(`RATING_COVERAGE_HELP_${provider}:LINK`)}
            >
              <IconWrapper src={getScoreProviderIcon(provider)} />
              <WrapperScoreProviderDescription>
                <ScoreProviderDescription data-testid="help-pillar-score-description">
                  {i18next.t(`RATING_COVERAGE_HELP:LEARN_MORE`)}
                </ScoreProviderDescription>
              </WrapperScoreProviderDescription>
            </ScoreProviderWrapper>
          );
        })}
      </CardsWrapper>
    </HelpWrapper>
  );
};

export default Help;
