import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'REAL_SCORE_MODULE', {
  REAL_SCORE: 'My RealScore™',
  INDUSTRY: 'Industry: {{industry}}',
  INDUSTRY_RANK: 'Industry Rank',
  ENVIRONMENT_SCORE: 'Environment Score',
  SOCIAL_SCORE: 'Social Score',
  GOVERNANCE_SCORE: 'Governance Score',
});
