import arrayUnique from 'array-unique';

import PillarsMetadata from 'src/assets/PillarsMetadata';
import { Materialities, PillarScores } from 'src/enums/pillarScores';
import { PillarScoreToScoreKey } from 'src/enums/pillarScores';
import { ICompanyGlobalScores, IIndustryScores } from 'src/types/score';

import { toCurrency } from './currency';
import { formatNumber } from './data';
import { isPositiveNumberPillar } from './pillarsScores';

export const getScoreByPillar = (pillar: PillarScores | null, scores: ICompanyGlobalScores) => {
  if (pillar === null) {
    return null;
  }
  const pillarMetadata = PillarsMetadata.find((metadata) => metadata.Identifier === pillar);
  const score = scores[PillarScoreToScoreKey[pillar] as keyof ICompanyGlobalScores];
  if (pillarMetadata?.ValueType === 'boolean') {
    if (score === null) {
      return null;
    }
    return score ? 1 : -1;
  }
  switch (pillar) {
    case PillarScores.ENVIRONMENT:
    case PillarScores.GOVERNANCE:
    case PillarScores.SOCIAL:
    case PillarScores.GHG_INTENSITY:
    case PillarScores.WASTE_INTENSITY:
    case PillarScores.HAZARDOUS_WASTE_INTENSITY:
    case PillarScores.WATER_EFFICIENCY:
    case PillarScores.TRAINING_COSTS_PER_EMPLOYEE:
    case PillarScores.TOTAL_ENERGY_INTENSITY:
    case PillarScores.TOTAL_SCOPE_EMISSIONS:
    case PillarScores.INJURY_INTENSITY:
      return score !== null && typeof score === 'number'
        ? Math.round(Number(score.toFixed(0)))
        : null;
    case PillarScores.WASTE_MANAGEMENT:
    case PillarScores.ANNUAL_EMPLOYEE_TURNOVER:
    case PillarScores.LOST_DAYS_DUE_TO_INJURY:
    case PillarScores.MANAGERIAL_POSITIONS_HELD_BY_WOMEN:
      return Math.round(Number(score));
    default:
      return null;
  }
};

export const formatScoreByPillar = (
  pillar: PillarScores | null,
  score: number | null | boolean,
) => {
  const pillarMetadata = PillarsMetadata.find((metadata) => metadata.Identifier === pillar);
  if (pillarMetadata?.ValueType === 'boolean') {
    if (score === 1 || score === true) {
      return 'Yes';
    } else if (score === -1 || score === false) {
      return 'No';
    }
    return 'N/A';
  }

  return `${formatValueScoreByPillar(pillar, score)} ${getUnitScoreByPillar(pillar)}`;
};

export const getUnitScoreByPillar = (pillar: PillarScores | null) => {
  switch (pillar) {
    case PillarScores.WATER_EFFICIENCY:
      return ` cu. m/$MM`;
    case PillarScores.WASTE_MANAGEMENT:
    case PillarScores.ANNUAL_EMPLOYEE_TURNOVER:
    case PillarScores.LOST_DAYS_DUE_TO_INJURY:
    case PillarScores.MANAGERIAL_POSITIONS_HELD_BY_WOMEN:
      return ` %`;
    case PillarScores.INJURY_INTENSITY:
      return ` Injuries/MM hrs. worked`;
    case PillarScores.GHG_INTENSITY:
    case PillarScores.WASTE_INTENSITY:
    case PillarScores.HAZARDOUS_WASTE_INTENSITY:
      return ` Tons/$MM`;
    case PillarScores.TOTAL_ENERGY_INTENSITY:
      return ` GJ/$MM`;
    case PillarScores.TOTAL_SCOPE_EMISSIONS:
      return ` Tons`;
    case PillarScores.TRAINING_COSTS_PER_EMPLOYEE:
      return ` $/employee`;
    default:
      return '';
  }
};

export const formatValueScoreByPillar = (
  pillar: PillarScores | null,
  score: number | null | boolean,
) => {
  if (!score?.toString() || score === -1) {
    return 'N/A';
  }

  switch (pillar) {
    case PillarScores.ENVIRONMENT:
    case PillarScores.GOVERNANCE:
    case PillarScores.SOCIAL:
      return score ? score.toString() : '0';
    case PillarScores.WASTE_MANAGEMENT:
    case PillarScores.ANNUAL_EMPLOYEE_TURNOVER:
    case PillarScores.LOST_DAYS_DUE_TO_INJURY:
    case PillarScores.MANAGERIAL_POSITIONS_HELD_BY_WOMEN:
    case PillarScores.WATER_EFFICIENCY:
    case PillarScores.INJURY_INTENSITY:
    case PillarScores.GHG_INTENSITY:
    case PillarScores.WASTE_INTENSITY:
    case PillarScores.HAZARDOUS_WASTE_INTENSITY:
    case PillarScores.TOTAL_ENERGY_INTENSITY:
    case PillarScores.TOTAL_SCOPE_EMISSIONS:
      return formatNumber(score?.toString() || '0');
    case PillarScores.TRAINING_COSTS_PER_EMPLOYEE:
      return toCurrency((score as number) || 0);
    default:
      return score?.toString();
  }
};

export const getPillarScoresByMateriality = (materialities: Materialities[]) => {
  return arrayUnique(
    PillarsMetadata.filter((pillar) => pillar.Materiality?.some((m) => materialities.includes(m))),
  );
};

export const getMaterialityIsPositiveOrNegative = (
  materialities: Materialities[],
  companyScore: ICompanyGlobalScores,
  industryScores: IIndustryScores[],
) => {
  return materialities.filter((materiality) => {
    const materialityPillars = getPillarScoresByMateriality([materiality]);
    let countOfPositivePillars = 0;
    materialityPillars.forEach((pillar) => {
      const score =
        companyScore[PillarScoreToScoreKey[pillar.Identifier] as keyof ICompanyGlobalScores];
      const pillarIndustryScores = industryScores.find(
        (scores) => scores.pillar === pillar.Identifier,
      )!.industryScores;
      if (pillar.ValueType === 'boolean') {
        if (score === true) {
          countOfPositivePillars++;
        }
      }
      if (pillar.ValueType === 'number') {
        if (
          isPositiveNumberPillar(
            score as number,
            pillarIndustryScores.filter((score) => score !== null) as number[],
            pillar.SortBy,
          )
        ) {
          countOfPositivePillars++;
        }
      }
    });
    return countOfPositivePillars >= materialityPillars.length / 2;
  });
};

export const getAverageScore = (scores: (number | boolean)[], valueType: string) => {
  if (valueType === 'boolean') {
    const scoresTrue = scores.filter((element) => element === true || element === 1).length;
    const scoresFalse = scores.filter((element) => element === false || element === -1).length;

    if (scoresTrue === 0 && scoresFalse === 0) {
      return null;
    }
    return scoresTrue >= scoresFalse;
  }

  return scores[Math.floor(scores.length / 2)]
    ? Math.round(Number((scores[Math.floor(scores.length / 2)] as number).toFixed(2)))
    : -1;
};
