import { all } from 'redux-saga/effects';

import benchmark from './sagas/benchmark';
import explore from './sagas/explore';
import insights from './sagas/insights';
import news from './sagas/news';
import sales from './sagas/sales';
import signup from './sagas/signup';
import summary from './sagas/summary';
import user from './sagas/user';

export default function* rootSaga() {
  yield all([user, benchmark, summary, insights, news, explore, sales, signup]);
}
