import i18next from 'i18next';
import React from 'react';

import { UserMessageType } from 'src/enums/userMessage';

import './i18n';
import {
  IConSuccess,
  MessageWrapper,
  WrapperIcon,
  IConFail,
  Title,
  Message,
  WrapperHeader,
} from './styles';
import { IUserMessageProps } from './types';

const UserMessage = (props: IUserMessageProps) => {
  const { messageType, message, isLogin = false } = props;

  return (
    <MessageWrapper messageType={messageType} isLogin={isLogin}>
      <WrapperHeader>
        <WrapperIcon>
          {messageType === UserMessageType.SUCCESS ? (
            <IConSuccess data-testid={'user-message-icon-success'}></IConSuccess>
          ) : (
            <IConFail data-testid={'user-message-icon-error'}></IConFail>
          )}
        </WrapperIcon>
        <Title data-testid={'user-message-title'}>
          {i18next.t(`USER_MESSAGE:TITLE_${messageType}`)}
        </Title>
      </WrapperHeader>
      <Message data-testid={'user-message-text'}>{message}</Message>
    </MessageWrapper>
  );
};

export default UserMessage;
