export const USER_REDIRECT_TO_LOGIN = 'USER_REDIRECT_TO_LOGIN';

export const USER_REDIRECT_TO_SIGNUP = 'USER_REDIRECT_TO_SIGNUP';

export const USER_ON_INITIALIZE_LOGIN_VIEW = 'USER_ON_INITIALIZE_LOGIN_VIEW';

export const USER_ON_INITIALIZE_SIGNUP_VIEW = 'USER_ON_INITIALIZE_SIGNUP_VIEW';

export const USER_ON_INITIALIZE_SIGNUP_VIEW_SUCCEEDED = 'USER_ON_INITIALIZE_SIGNUP_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_SIGNUP_VIEW_FAILED = 'USER_ON_INITIALIZE_SIGNUP_VIEW_FAILED';

export const USER_ON_LOGIN_REQUESTED = 'USER_ON_LOGIN_REQUESTED';

export const USER_ON_LOGIN_SUCCEEDED = 'USER_ON_LOGIN_SUCCEEDED';

export const USER_ON_LOGIN_FAILED = 'USER_ON_LOGIN_FAILED';

export const USER_ON_SIGN_OUT = 'USER_ON_SIGN_OUT';

export const USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW = 'USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW';

export const USER_ON_INITIALIZE_CHANGE_PASSWORD_SUCCEDED =
  'USER_ON_INITIALIZE_CHANGE_PASSWORD_SUCCEDED';

export const USER_ON_CHANGE_PASSWORD_REQUESTED = 'USER_ON_CHANGE_PASSWORD_REQUESTED';

export const USER_ON_CHANGE_PASSWORD_SUCCEDED = 'USER_ON_CHANGE_PASSWORD_SUCCEDED';

export const USER_ON_CHANGE_PASSWORD_FAILED = 'USER_ON_CHANGE_PASSWORD_FAILED';

export const USER_ON_INITIALIZE_CHAGE_PASSWORD_FAILED = 'USER_ON_INITIALIZE_CHAGE_PASSWORD_FAILED';

export const USER_ON_GET_USER_FAILED = 'USER_ON_GET_USER_FAILED';

export const USER_ON_INITIALIZE_SUMMARY_VIEW = 'USER_ON_INITIALIZE_SUMMARY_VIEW';

export const USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED = 'USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED';

export const USER_ON_INITIALIZE_BENCHMARK_VIEW = 'USER_ON_INITIALIZE_BENCHMARK_VIEW';

export const USER_ON_INITIALIZE_BENCHMARK_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_BENCHMARK_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_BENCHMARK_VIEW_FAILED = 'USER_ON_INITIALIZE_BENCHMARK_VIEW_FAILED';

export const USER_ON_CHANGE_SETTINGS = 'USER_ON_CHANGE_SETTINGS';

export const USER_ON_CHANGE_SETTINGS_SUCCEEDED = 'USER_ON_CHANGE_SETTINGS_SUCCEEDED';

export const USER_ON_CHANGE_SETTINGS_FAILED = 'USER_ON_CHANGE_SETTINGS_FAILED';

export const USER_ON_HELP_CLOSE = 'USER_ON_HELP_CLOSE';

export const USER_ON_HELP_NEXT = 'USER_ON_HELP_NEXT';

export const USER_ON_HELP_BACK = 'USER_ON_HELP_BACK';

export const USER_ON_INITIALIZE_INSIGHTS_VIEW = 'USER_ON_INITIALIZE_INSIGHTS_VIEW';

export const USER_ON_INITIALIZE_INSIGHTS_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_INSIGHTS_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_INSIGHTS_VIEW_FAILED = 'USER_ON_INITIALIZE_INSIGHTS_VIEW_FAILED';

export const USER_ON_INITIALIZE_NEWS_VIEW = 'USER_ON_INITIALIZE_NEWS_VIEW';

export const USER_ON_INITIALIZE_NEWS_VIEW_SUCCEEDED = 'USER_ON_INITIALIZE_NEWS_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_NEWS_VIEW_FAILED = 'USER_ON_INITIALIZE_NEWS_VIEW_FAILED';

export const USER_ON_LOAD_MORE_NEWS = 'USER_ON_LOAD_MORE_NEWS';

export const USER_ON_LOAD_MORE_NEWS_SUCCEEDED = 'USER_ON_LOAD_MORE_NEWS_SUCCEEDED';

export const USER_ON_LOAD_MORE_NEWS_FAILED = 'USER_ON_LOAD_MORE_NEWS_FAILED';

export const USER_ON_SEND_CONTACT_US_REQUESTED = 'USER_ON_SEND_CONTACT_US_REQUESTED';

export const USER_ON_SEND_CONTACT_US_SUCCEEDED = 'USER_ON_SEND_CONTACT_US_SUCCEEDED';

export const USER_ON_SEND_CONTACT_US_FAILED = 'USER_ON_SEND_CONTACT_US_FAILED';

export const USER_ON_OPEN_CONTACT_US = 'USER_ON_OPEN_CONTACT_US';

export const USER_ON_CLOSE_CONTACT_US = 'USER_ON_CLOSE_CONTACT_US';

export const USER_ON_CLICK_MENU_SLIDER = 'USER_ON_CLICK_MENU_SLIDER';

export const USER_CLOSE_USER_MESSAGE = 'USER_CLOSE_USER_MESSAGE';

export const USER_ON_HANDLE_ERROR_MESSAGE = 'USER_ON_HANDLE_ERROR_MESSAGE';

export const USER_ON_INITIALIZE_EXPLORE_VIEW = 'USER_ON_INITIALIZE_EXPLORE_VIEW';

export const USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_EXPLORE_VIEW_FAILED = 'USER_ON_INITIALIZE_EXPLORE_VIEW_FAILED';

export const USER_ON_TRACK_EVENT = 'USER_ON_TRACK_EVENT';

export const USER_ON_INITIALIZE_SALES_VIEW = 'USER_ON_INITIALIZE_SALES_VIEW';

export const USER_ON_INITIALIZE_SALES_VIEW_SUCCEEDED = 'USER_ON_INITIALIZE_SALES_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_SALES_VIEW_FAILED = 'USER_ON_INITIALIZE_SALES_VIEW_FAILED';

export const USER_ON_PILLARS_PERFORMANCE_REQUESTED = 'USER_ON_PILLARS_PERFORMANCE_REQUESTED';

export const USER_ON_PILLARS_PERFORMANCE_SUCCEEDED = 'USER_ON_PILLARS_PERFORMANCE_SUCCEEDED';

export const USER_ON_PILLARS_PERFORMANCE_FAILED = 'USER_ON_PILLARS_PERFORMANCE_FAILED';

export const USER_ON_SCORES_COMPETITOR_REQUESTED = 'USER_ON_SCORES_COMPETITOR_REQUESTED';

export const USER_ON_SCORES_COMPETITOR_SUCCEEDED = 'USER_ON_SCORES_COMPETITOR_SUCCEEDED';

export const USER_ON_SCORES_COMPETITOR_FAILED = 'USER_ON_SCORES_COMPETITOR_FAILED';

export const USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED =
  'USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED';

export const USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_SUCCEEDED =
  'USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_SUCCEEDED';

export const USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED =
  'USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED';

export const USER_ON_SUBMIT_TARGET_SALES_REQUESTED = 'USER_ON_SUBMIT_TARGET_SALES_REQUESTED';

export const USER_ON_SUBMIT_TARGET_SALES_SUCCEEDED = 'USER_ON_SUBMIT_TARGET_SALES_SUCCEEDED';

export const USER_ON_SUBMIT_TARGET_SALES_FAILED = 'USER_ON_SUBMIT_TARGET_SALES_FAILED';

export const USER_ON_SALES_BACK = 'USER_ON_SALES_BACK';

export const USER_ON_SIGNUP_REQUESTED = 'USER_ON_SIGNUP_REQUESTED';

export const USER_ON_SIGNUP_SUCCEEDED = 'USER_ON_SIGNUP_SUCCEEDED';

export const USER_ON_SIGNUP_FAILED = 'USER_ON_SIGNUP_FAILED';

export const USER_REDIRECT_TO_ESGMOTIVE = 'USER_REDIRECT_TO_ESGMOTIVE';

export const USER_ON_CLICK_FORGOT_PASSWORD = 'USER_ON_CLICK_FORGOT_PASSWORD';

export const USER_ON_FORGOT_PASSWORD_REQUESTED = 'USER_ON_FORGOT_PASSWORD_REQUESTED';

export const USER_ON_FORGOT_PASSWORD_SUCCEEDED = 'USER_ON_FORGOT_PASSWORD_SUCCEEDED';

export const USER_ON_FORGOT_PASSWORD_FAILED = 'USER_ON_FORGOT_PASSWORD_FAILED';

export const USER_ON_INITIALIZE_FORGOT_PASSWORD_VIEW = 'USER_ON_INITIALIZE_FORGOT_PASSWORD_VIEW';

export const USER_ON_CHANGE_DATA_TAB = 'USER_ON_CHANGE_DATA_TAB';

export const USER_ON_CHANGE_DATA_TAB_SUCCEDED = 'USER_ON_CHANGE_DATA_TAB_SUCCEDED';

export const USER_ON_CHANGE_DATA_TAB_FAILED = 'USER_ON_CHANGE_DATA_TAB_FAILED';
