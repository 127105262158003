import i18next from 'i18next';
import React from 'react';

import './i18n';
import {
  ExportButton,
  HelpIcon,
  WrapperButton,
  WrapperHelp,
  WrapperText,
  Title,
  Subtitle,
  BackArrow,
  WrapperSubtitle,
  WrapperTitle,
} from './styles';
import { IHeaderModuleProps } from './types';

const HeaderModule = (props: IHeaderModuleProps) => {
  const { backArrow, handleBackArrowClick, handleOpenHelp, handleExportData } = props;

  return (
    <>
      <WrapperText>
        <WrapperTitle>
          {backArrow && <BackArrow onClick={handleBackArrowClick} />}
          <Title data-testid={'title-explore-page'}>{i18next.t('HEADER_EXPLORE:TITLE')}</Title>
        </WrapperTitle>
        {!backArrow && (
          <WrapperSubtitle>
            <Subtitle data-testid={'subtitle-explore-page'}>
              {i18next.t('HEADER_EXPLORE:SUBTITLE')}
            </Subtitle>
            <WrapperHelp>
              <HelpIcon data-testid={'help-icon-explore-page'} onClick={handleOpenHelp} />
            </WrapperHelp>
          </WrapperSubtitle>
        )}
      </WrapperText>
      <WrapperButton>
        <ExportButton data-testid={'button-export-explore-page'} onClick={handleExportData}>
          {i18next.t('HEADER_EXPLORE:BUTTON_EXPORT')}{' '}
        </ExportButton>
      </WrapperButton>
    </>
  );
};

export default HeaderModule;
