import i18next from 'i18next';
import React from 'react';

import { ICompanyScore } from 'src/types/score';

import './i18n';
import {
  ContentWrapper,
  RatingCoverageWrapper,
  RatingCoverageLabel,
  RatingCoverageNumber,
  RatingCoveragesWrapper,
  Border,
  Title,
  TitleWrapper,
  RatingProviderWrapper,
} from './styles';
import { IRatingCoverageModuleProps } from './types';

const RatingCoverageModule = (props: IRatingCoverageModuleProps) => {
  const { scores } = props;

  return (
    <RatingCoverageWrapper>
      <TitleWrapper>
        <Title>{i18next.t('RATING_COVERAGE_EXPLORE_MODULE:TITLE')}</Title>
      </TitleWrapper>
      <ContentWrapper>
        <RatingCoveragesWrapper>
          {scores.map((score: ICompanyScore, i) => {
            return (
              <>
                <RatingProviderWrapper>
                  <RatingCoverageLabel
                    data-testid={`label-score-rating-coverage-module-explore-page-${score.provider}`}
                  >
                    {i18next.t(`RATING_COVERAGE_EXPLORE_MODULE:${score.provider}`)}
                    <RatingCoverageNumber
                      data-testid={`value-score-rating-coverage-module-explore-page-${score.provider}`}
                    >
                      {' '}
                      {score.scores[0].score}
                    </RatingCoverageNumber>
                  </RatingCoverageLabel>
                </RatingProviderWrapper>
                <Border isLast={i === scores.length - 1} />
              </>
            );
          })}
        </RatingCoveragesWrapper>
      </ContentWrapper>
    </RatingCoverageWrapper>
  );
};

export default RatingCoverageModule;
