import { Skeleton } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { IInsightWrapperProps } from './types';

export const InsightsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    padding: 25px 0;
  }
  padding: 25px 25px 0 25px;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

export const Title = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-size: 28px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  padding-right: 5px;
`;

export const ContentWrapper = styled.div``;

export const InsightWrapper = styled.div<IInsightWrapperProps>`
  padding: 20px 0;
  border-bottom: ${(props) =>
    props.includeBorder ? `2px solid ${props.theme.palette.quinternary}` : 'none'};
`;

export const LoadingWrapper = styled.div`
  padding-top: 20px;
`;

export const LoadingSkeleton = styled(Skeleton)`
  transform: none !important;
`;

export const WrapperViewAll = styled.p`
  color: ${(props) => props.theme.palette.tertiary};
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.lightSecondary};
  }
`;
export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;
