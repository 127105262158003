import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'FORGOT_PASSWORD', {
  MESSAGE: 'Please enter your email address to reset your password',
  BUTTON_COPY: 'SUBMIT',
  EMAIL: 'Email',
  EMAIL_ERROR: 'Incorrect email format',
  CANCEL: 'Back to sign in',
});
