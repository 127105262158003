import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const userForgotPasswordSelector = () =>
  createSelector(
    [(state: IReduxState) => state.user.isSendEmailForgotPassword],
    (isSendEmailForgotPassword) => isSendEmailForgotPassword,
  );

export default userForgotPasswordSelector;
