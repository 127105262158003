import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'HEADER_SALES', {
  TITLE: 'Sales Assistant',
  SUBTITLE: 'your sustainability competitive advantage',
  BUTTON_EXPORT: 'DOWNLOAD',
  TITLE_MODAL_EXPORT: 'Download sales',
});
