import { AnyAction } from 'redux';

import { HelpModules, HelpWalktrough } from 'src/enums/help';
import { MenuRoutes } from 'src/enums/routes';
import IUser from 'src/types/user';
import IUserSettings, { DEFAULT_USER_SETTINGS } from 'src/types/userSettings';

import * as appConstants from '../constants/app';
import * as userConstants from '../constants/user';

export interface IUserReducerState {
  user: IUser | null;
  helpModule: HelpModules | null;
  contactUsForm: {
    open: boolean;
    loading: boolean;
    success: boolean;
  };
  userSettings: IUserSettings;
  isMenuOpen: boolean;
  thankYouPage: boolean;
  loading: boolean;
  isSendEmailSignedUp: boolean;
  isSendEmailForgotPassword: boolean;
}

const defaultState: IUserReducerState = {
  user: null,
  helpModule: null,
  isMenuOpen: true,
  contactUsForm: {
    open: false,
    loading: false,
    success: false,
  },
  userSettings: DEFAULT_USER_SETTINGS,
  thankYouPage: false,
  loading: false,
  isSendEmailSignedUp: false,
  isSendEmailForgotPassword: false,
};

const setLoading = (state: IUserReducerState, loading: boolean) => {
  return {
    ...state,
    loading,
  };
};

const setUser = (state: IUserReducerState, user: IUser) => ({
  ...state,
  user,
});

const setUserSettings = (state: IUserReducerState, userSettings: IUserSettings) => ({
  ...state,
  userSettings,
});

const setCloseHelp = (state: IUserReducerState) => ({
  ...state,
  helpModule: null,
});

const setNextHelpModule = (
  state: IUserReducerState,
  currentRoute: MenuRoutes,
  currentModule?: HelpModules,
) => {
  let nextModule: HelpModules | null = null;
  const routeWalktrough = HelpWalktrough.find((module) => module.route === currentRoute);
  if (!routeWalktrough) {
    return {
      ...state,
      helpModule: null,
    };
  }
  if (currentModule === undefined) {
    return {
      ...state,
      helpModule: routeWalktrough.walktrough[0],
    };
  }
  const currentModuleIndex = routeWalktrough.walktrough.findIndex(
    (module) => module === currentModule,
  );
  if (currentModuleIndex < routeWalktrough.walktrough.length - 1) {
    nextModule = routeWalktrough.walktrough[currentModuleIndex + 1];
  }
  return {
    ...state,
    helpModule: nextModule,
  };
};

const setRedirectThankYouPage = (state: IUserReducerState, isPasswordChange: boolean) => {
  return {
    ...state,
    thankYouPage: isPasswordChange,
  };
};

const setBackHelpModule = (
  state: IUserReducerState,
  currentRoute: MenuRoutes,
  currentModule?: HelpModules,
) => {
  let nextModule: HelpModules | null = null;
  const routeWalktrough = HelpWalktrough.find((module) => module.route === currentRoute);
  if (!routeWalktrough) {
    return {
      ...state,
      helpModule: null,
    };
  }
  const currentModuleIndex = routeWalktrough.walktrough.findIndex(
    (module) => module === currentModule,
  );
  if (currentModuleIndex > 0) {
    nextModule = routeWalktrough.walktrough[currentModuleIndex - 1];
  }
  return {
    ...state,
    helpModule: nextModule,
  };
};

const setContactUs = (
  state: IUserReducerState,
  open: boolean,
  loading: boolean,
  success: boolean,
) => {
  return {
    ...state,
    contactUsForm: {
      open,
      loading,
      success,
    },
  };
};

const setOpenMenuSlider = (state: IUserReducerState, isMenuOpen: boolean) => {
  return {
    ...state,
    isMenuOpen,
  };
};

const setIsSendEmailSignedUp = (state: IUserReducerState, isSendEmailSignedUp: boolean) => {
  return { ...state, isSendEmailSignedUp };
};

const setIsSendEmailForgotPassword = (
  state: IUserReducerState,
  isSendEmailForgotPassword: boolean,
) => {
  return { ...state, isSendEmailForgotPassword };
};

const userReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW:
    case userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_VIEW:
    case userConstants.USER_ON_INITIALIZE_FORGOT_PASSWORD_VIEW:
      return setLoading(state, false);
    case userConstants.USER_ON_INITIALIZE_LOGIN_VIEW:
      return setIsSendEmailForgotPassword(setLoading(state, false), false);
    case userConstants.USER_ON_LOGIN_REQUESTED:
    case userConstants.USER_ON_SIGNUP_REQUESTED:
      return setLoading(state, true);
    case userConstants.USER_ON_LOGIN_FAILED:
    case userConstants.USER_ON_SIGNUP_FAILED:
    case userConstants.USER_ON_FORGOT_PASSWORD_FAILED:
      return setLoading(state, false);
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
    case userConstants.USER_ON_INITIALIZE_CHANGE_PASSWORD_SUCCEDED:
      return setUser(state, action.user);
    case userConstants.USER_ON_HELP_CLOSE:
      return setCloseHelp(state);
    case userConstants.USER_ON_HELP_NEXT:
      return setNextHelpModule(state, action.currentRoute, action.currentModule);
    case userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_SALES_VIEW_SUCCEEDED:
      return setUserSettings(state, action.userSettings);
    case userConstants.USER_ON_CHANGE_SETTINGS:
      return setUserSettings(state, action.settings);
    case userConstants.USER_ON_HELP_BACK:
      return setBackHelpModule(state, action.currentRoute, action.currentModule);
    case userConstants.USER_ON_CHANGE_PASSWORD_SUCCEDED:
      return setRedirectThankYouPage(state, true);
    case userConstants.USER_ON_OPEN_CONTACT_US:
      return setContactUs(state, true, false, false);
    case userConstants.USER_ON_CLOSE_CONTACT_US:
      return setContactUs(state, false, false, false);
    case userConstants.USER_ON_SEND_CONTACT_US_REQUESTED:
      return setContactUs(state, true, true, false);
    case userConstants.USER_ON_SEND_CONTACT_US_SUCCEEDED:
      return setContactUs(state, true, false, true);
    case userConstants.USER_ON_SEND_CONTACT_US_FAILED:
      return setContactUs(state, true, false, false);
    case userConstants.USER_ON_CLICK_MENU_SLIDER:
      return setOpenMenuSlider(state, action.isMenuOpen);
    case userConstants.USER_ON_SIGNUP_SUCCEEDED:
      return setIsSendEmailSignedUp(state, action.isSendEmail);
    case userConstants.USER_ON_FORGOT_PASSWORD_SUCCEEDED:
      return setIsSendEmailForgotPassword(state, action.isSendEmail);
    default:
      return state;
  }
};

export default userReducer;
