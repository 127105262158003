import { Search } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Paper, TextField } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const SearchBarWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
  }
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBar = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    max-width: 500px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  flex: 4;
  margin-right: 20px;
  min-width: 200px;
`;

export const CurrentCompetitors = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(3, 150px);
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    grid-template-columns: repeat(2, 150px);
  }
  @media (max-width: ${SIZES.mediumPhone}px) {
    grid-template-columns: repeat(1, 100%);
  }
  flex: 10;
  display: flex;
`;

export const CurrentCompetitor = styled.div`
  @media (max-width: ${SIZES.bigDesktop}px) {
    max-width: 120px;
  }
  @media (max-width: ${SIZES.mediumPhone}px) {
    max-width: none;
  }
  max-width: 200px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d3e6e7;
  border-radius: 100px;
  padding: 5px 10px;
  margin-right: 10px;
`;

export const CompetitorName = styled.p`
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.secondary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchTextField = styled(TextField)`
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 30px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  .MuiIconButton-root {
    transform: none;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-radius: 30px;
      border-color: transparent !important;
    }
  }
`;

export const SearchIcon = styled(Search)`
  width: 25px !important;
  height: 25px !important;
  margin-left: 10px;
  fill: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const DropdownBox = styled(Paper)`
  border-radius: 10px !important;
`;

export const DropdownOption = styled.li`
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const RemoveIcon = styled(CancelIcon)`
  width: 20px !important;
  height: 20px !important;
  fill: ${(props) => props.theme.palette.lightSecondary} !important;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    fill: ${(props) => props.theme.palette.secondary};
  }
`;

export const AutocompleteInput = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 4px !important;
  }
`;
