import { InputLabel, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(Select)`
  border-radius: 10px !important;
  font-family: ${(props) => props.theme.fontFamilies.regular} !important;
  font-size: 14px !important;
  color: ${(props) => props.theme.palette.secondary} !important;
  .MuiInput-root {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.quinternary} !important;
  }
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.tertiary};
  }
`;

export const InputOption = styled(MenuItem)`
  font-size: 14px !important;
  color: ${(props) => props.theme.palette.common.grey} !important;
  font-family: ${(props) => props.theme.fontFamilies.regular} !important;
`;

export const Label = styled(InputLabel)`
  color: ${(props) => props.theme.palette.common.grey} !important;
  font-family: ${(props) => props.theme.fontFamilies.regular} !important;
`;
