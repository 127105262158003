import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SIGNUP_INPUT', {
  NAME: 'Name',
  LAST_NAME: 'Last Name',
  EMAIL: 'Email',
  COMPANY: 'Company Name',
  SIGNUP: 'sign up',
  NO_RESULTS: 'No results',
  EMAIL_ERROR: 'Incorrect email format',
});
