import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import background from '../../assets/changePassword/backgraundChangePassword.png';
import { ITextWrapperProps } from './types';

interface IUserPasswordChange {
  isPasswordChange: boolean;
}
export const Wrapper = styled.div<IUserPasswordChange>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.isPasswordChange ? 'flex-start' : 'center')};
  padding: 0 20px;
  background-image: url(${background});
  background-size: cover;
`;

export const LogoWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
  }
  max-width: 200px;
  align-self: center;
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
`;

export const DataLabel = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 3px;
`;

export const DataText = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 20px;
  word-break: break-all;
`;

export const InputsWrapper = styled.div`
  width: 568px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const InputWrapper = styled.div`
  margin: 15px 0;
`;

export const UserData = styled.p`
  margin-bottom: 20px;
`;

export const TextWrapper = styled.div<ITextWrapperProps>`
  margin: ${(props) => (props.isTablet ? '0 0 30px 0' : '30px 0')};
`;

export const ButtonWrapper = styled.div``;

export const Title = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-size: 20px;
  margin-bottom: 10px;
`;
