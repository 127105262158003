import { Icon } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import logoSmall from 'src/assets/logos/logo-small.svg';
import doubleArrow from 'src/assets/menu/back-icon.svg';

import { IHelpIconProps, ISVGIconProps } from './types';

interface IOpenModal {
  isMenuOpen: boolean;
}

interface IRouteSelected {
  isSelected: boolean;
}

export const Drawer = styled(MuiDrawer)<IOpenModal>`
    width: ${(props) => (props.isMenuOpen ? '140px' : '3%')};
    min-width: ${(props) => (props.isMenuOpen ? '140px' : '50px')};
    max-width: 140px;
    transition: 0.3s;
   .MuiDrawer-paper {
      transition: 0.3s;
      width: ${(props) => (props.isMenuOpen ? '140px' : '3%')};
      max-width: 140px;
      min-width: ${(props) => (props.isMenuOpen ? '140px' : '50px')};
      height: 100vh;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      background-color:${(props) => props.theme.palette.secondary};
    },
`;

export const ArrowIcon = styled.div`
  width: 10px;
  height: 12px;
  background-image: url(${doubleArrow});
  background-repeat: no-repeat;

  cursor: pointer;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const ArrowIconReverse = styled.div`
  width: 10px;
  height: 12px;
  background-image: url(${doubleArrow});
  background-repeat: no-repeat;
  cursor: pointer;
  color: ${(props) => props.theme.palette.common.grey};
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`;

export const IconButtonWrapper = styled.div<IOpenModal>`
  align-self: ${(props) => (props.isMenuOpen ? 'flex-end' : 'center')};
  margin-bottom: 20px;
`;

export const MenuItem = styled(Link)<IOpenModal & IRouteSelected>`
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-decoration: none;
  position: relative;
  height: 12px;
  padding: ${(props) => (props.isMenuOpen ? '10px 10px' : '10px 0')};
  left: ${(props) => (props.isMenuOpen ? '0' : '-10px')};
  &:hover {
    ${(props) =>
      !props.isSelected
        ? `
    padding: ${props.isMenuOpen ? '10px  0px 10px 30px' : '10px  0px 10px 20px'};
    margin: 0 0 20px -20px; 
    background-color: ${props.theme.palette.lightSecondary};
    border-top-right-radius: 100px;
    width: ${props.isMenuOpen ? '100px' : '35px'};
    border-bottom-right-radius: 100px`
        : ''}
  }
`;
export const MenuLink = styled.p<IRouteSelected>`
  text-transform: capitalize;
  padding-top: 3px;
  font-family: ${(props) =>
    props.isSelected ? props.theme.fontFamilies.bold : props.theme.fontFamilies.regular};
  font-size: 12px;
  color: ${(props) => props.theme.palette.common.white};
`;

export const MenuIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  margin-right: 5px;
  display: flex !important;
  align-items: center;
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Help = styled.p<IHelpIconProps>`
  cursor: ${(props) => (props.enabled ? 'pointer' : '')};
  text-decoration: none;
  margin-bottom: 15px;
  padding-top: 15px;
  color: ${(props) =>
    props.enabled ? props.theme.palette.tertiary : props.theme.palette.common.grey};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  ${(props) =>
    props.enabled &&
    `
  &:hover {
    color: ${props.theme.palette.quaternary};
  }
  `}
`;

export const ContactUs = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 15px;
  border-bottom: 2px solid ${(props) => props.theme.palette.lightSecondary};
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamilies.regular};

  color: ${(props) => props.theme.palette.tertiary};
  &:hover {
    color: ${(props) => props.theme.palette.quaternary};
  }
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
  border-top: 2px solid ${(props) => props.theme.palette.lightSecondary};
  padding-top: 20px;
`;

export const SelectedRouteOverlay = styled.div<IOpenModal>`
  height: 12px;
  width: ${(props) => (props.isMenuOpen ? '110px' : '35px')};
  left: -20px;
  background-color: ${(props) => props.theme.palette.lightSecondary};
  position: absolute;
  padding: 10px 10px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  z-index: -1;
`;

export const SVGIcon = styled(ReactSVG)<ISVGIconProps>`
  ${(props) => !props.enabled && `filter: grayscale(100%) brightness(40%)`}
  ${(props) =>
    props.enabled &&
    `
  &:hover {
    filter: brightness(120%);
    cursor: pointer;
  }
  `}
`;

export const IconLogoSmall = styled.div`
  background-image: url(${logoSmall});
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const WrapperIconContacUs = styled.div`
  height: 25px;
  width: 25px;
  padding: 10px 0;
  border-bottom: 2px solid ${(props) => props.theme.palette.lightSecondary};
`;

export const WrapperIconTutorial = styled.div`
  height: 25px;
  width: 25px;
  padding: 10px 0;
  border-bottom: 2px solid ${(props) => props.theme.palette.lightSecondary};
`;

export const WrapperLogoSmall = styled.div`
  height: 25px;
  width: 25px;
  padding-top: 10px;
`;
