import i18next from 'i18next';
import React from 'react';

import TargetHelpPNG from 'src/assets/sales/targetHelp.png';

import './i18n';
import {
  HelpImage,
  HelpWrapper,
  ImageWrapper,
  TextWrapper,
  Title,
  Text,
  WrapperTitle,
} from './styles';

const TargetHelp = () => {
  return (
    <HelpWrapper>
      <WrapperTitle>
        <Title data-testid={'title-target-help-sales-page'}>
          {' '}
          {i18next.t('TARGET_SALES_HELP:TITLE')}{' '}
        </Title>
      </WrapperTitle>
      <ImageWrapper data-testid={'image-target-help-sales-page'}>
        <HelpImage src={TargetHelpPNG} />
      </ImageWrapper>
      <TextWrapper>
        <Text data-testid={'text-target-help-sales-page'}>
          {i18next.t('TARGET_SALES_HELP:TEXT')}
        </Text>
      </TextWrapper>
    </HelpWrapper>
  );
};

export default TargetHelp;
