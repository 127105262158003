import i18next from 'i18next';
import React, { useState } from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';
import TextInput from 'src/components/Inputs/TextInput';
import { validateEmail } from 'src/helpers/validators/email';

import AutocompleteInput from './components/AutoCompleteCompany';
import ModalSuccessSignUp from './components/ModalSuccess';
import './i18n.ts';
import {
  Wrapper,
  InputsWrapper,
  LogoWrapper,
  MotiveLogo,
  InputWrapper,
  ButtonWrapper,
  SignUpMessageWrapper,
} from './styles';
import { ISignUpDetails, ISignUpViewProps } from './types';

const SignUp = (props: ISignUpViewProps) => {
  const { loading, onSubmit, onSuccessSubmit, searchCompanies, onSearchCompanies, isSignedUp } =
    props;
  const [signUpDetails, setSignUpDetails] = useState<ISignUpDetails>({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });
  const [emailError, setEmailError] = useState('');

  const handleNameChange = (value: string) => {
    setSignUpDetails({ ...signUpDetails, firstName: value });
  };

  const handleLastNameChange = (value: string) => {
    setSignUpDetails({ ...signUpDetails, lastName: value });
  };

  const handleEmailChange = (value: string) => {
    setSignUpDetails({ ...signUpDetails, email: value });
  };

  const handleInputChangeCompany = (searchValue: string) => {
    setSignUpDetails({ ...signUpDetails, company: searchValue });
    onSearchCompanies(searchValue);
  };

  const handleSubmit = () => {
    if (validateEmail(signUpDetails.email)) {
      onSubmit({
        ...signUpDetails,
        email: signUpDetails.email.toLowerCase().replace(/\s+/g, ''),
      });
    } else {
      setEmailError(i18next.t('SIGNUP_INPUT:EMAIL_ERROR'));
    }
  };

  return (
    <Wrapper>
      {!isSignedUp && (
        <>
          <InputsWrapper>
            <LogoWrapper>
              <MotiveLogo src={Logo} alt="Motive Logo" />
            </LogoWrapper>
            <InputWrapper isErrorActive={!isSignedUp}>
              <TextInput
                isDisabled={false}
                error=""
                id={i18next.t('SIGNUP_INPUT:NAME').toLowerCase()}
                name={i18next.t('SIGNUP_INPUT:NAME')}
                placeholder={i18next.t('SIGNUP_INPUT:NAME')}
                value={signUpDetails.firstName}
                onChange={handleNameChange}
              />
            </InputWrapper>
            <InputWrapper isErrorActive={false}>
              <TextInput
                isDisabled={false}
                error=""
                id={i18next.t('SIGNUP_INPUT:LAST_NAME').toLowerCase()}
                name={i18next.t('SIGNUP_INPUT:LAST_NAME')}
                placeholder={i18next.t('SIGNUP_INPUT:LAST_NAME')}
                value={signUpDetails.lastName}
                onChange={handleLastNameChange}
              />
            </InputWrapper>
            <InputWrapper isErrorActive={!isSignedUp}>
              <TextInput
                isDisabled={false}
                error={emailError}
                id={i18next.t('SIGNUP_INPUT:EMAIL').toLowerCase()}
                name={i18next.t('SIGNUP_INPUT:EMAIL')}
                placeholder={i18next.t('SIGNUP_INPUT:EMAIL')}
                value={signUpDetails.email}
                onChange={handleEmailChange}
              />
            </InputWrapper>
            <InputWrapper isErrorActive={!isSignedUp}>
              <AutocompleteInput
                id="search-companies"
                pathLabel={i18next.t('SIGNUP_INPUT:COMPANY')}
                onInputChange={handleInputChangeCompany}
                onChange={handleInputChangeCompany}
                options={searchCompanies.map((element) => element.name)}
                pathNoOptionText={i18next.t('SIGNUP_INPUT:NO_RESULTS')}
                valueAutocomplete={signUpDetails.company}
                freeSolo={true}
              />
            </InputWrapper>
            <ButtonWrapper>
              <TextButton
                isDisabled={
                  signUpDetails.firstName === '' ||
                  signUpDetails.lastName === '' ||
                  signUpDetails.email === '' ||
                  signUpDetails.company === ''
                }
                isLoading={loading}
                text={i18next.t('SIGNUP_INPUT:SIGNUP')}
                onSubmit={handleSubmit}
              />
            </ButtonWrapper>
          </InputsWrapper>
        </>
      )}
      {isSignedUp && (
        <>
          <SignUpMessageWrapper>
            <ModalSuccessSignUp onSubmitSuccessModal={onSuccessSubmit} />
          </SignUpMessageWrapper>
        </>
      )}
    </Wrapper>
  );
};
export default SignUp;
