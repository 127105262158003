import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import industryRankSelector from 'src/redux/selectors/company/industryRank';
import pillarDetailsSelector from 'src/redux/selectors/company/pillarDetails';
import pillarsRankSelector from 'src/redux/selectors/company/pillarsRank';
import selectedExploreTabSelector from 'src/redux/selectors/company/selectedExploreTab';
import companyDataSelector from 'src/redux/selectors/data/companyData';
import pillarValuesSelector from 'src/redux/selectors/data/pillarValues';
import settingsSelector from 'src/redux/selectors/user/settings';
import userDataSelector from 'src/redux/selectors/user/userData';

import { PillarScores } from 'src/enums/pillarScores';
import { useTypedSelector } from 'src/hooks/typeSelector';
import Explore from 'src/views/Explore';

import LayoutContainer from './Layout';

const ExploreContainer = () => {
  const dispatch = useDispatch();
  const industryRank = useTypedSelector(industryRankSelector());
  const pillarsRank = useTypedSelector(
    pillarsRankSelector([PillarScores.GOVERNANCE, PillarScores.ENVIRONMENT, PillarScores.SOCIAL]),
  );
  const userSettings = useTypedSelector(settingsSelector());
  const user = useTypedSelector(userDataSelector());
  const data = useTypedSelector(companyDataSelector());
  const pillarValues = useTypedSelector(pillarValuesSelector());
  const filteredData = useTypedSelector(pillarDetailsSelector());
  const selectedTab = useTypedSelector(selectedExploreTabSelector());

  const handleTrackEvent = (event: string) => {
    dispatch(userActions.onTrackEvent(event));
  };

  const onClickDontShowAgain = (isChecked: boolean) => {
    dispatch(userActions.onChangeSettings({ ...userSettings, dontShowModalExplore: isChecked }));
  };

  const onInitializeExploreView = useCallback(() => {
    dispatch(userActions.initializeExploreView());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeExploreView();
  }, [onInitializeExploreView]);

  const onSetSelectedExploreTab = (selectedExploreTab: string) => {
    dispatch(userActions.setSelectedExploreTab(selectedExploreTab));
  };

  return (
    <LayoutContainer>
      <Explore
        scores={user?.company.lastUpdatedScore || null}
        userSettings={userSettings}
        data={data}
        pillarValues={pillarValues}
        filteredData={filteredData}
        industryRank={industryRank}
        pillarsRank={pillarsRank}
        ratingCoverage={user?.company.scores || []}
        selectedTab={selectedTab}
        handleTrackEvent={handleTrackEvent}
        onClickDontShowAgain={onClickDontShowAgain}
        onSetSelectedExploreTab={onSetSelectedExploreTab}
      />
    </LayoutContainer>
  );
};

export default ExploreContainer;
