import styled from 'styled-components';

import { ILottiePlayerContainerProps } from './types';

export const LottieAnimationContainer = styled.div<ILottiePlayerContainerProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: 100%;
  z-index: 1;
  position: absolute;
  pointer-events: none;
`;
