import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import userLoadingSelector from 'src/redux/selectors/user/userLoading';
import userMessageSelector from 'src/redux/selectors/userMessage/userMessage';

import { useTypedSelector } from 'src/hooks/typeSelector';
import Login from 'src/views/Login';
import { LoginDetails } from 'src/views/Login/types';

const LoginContainer = () => {
  const dispatch = useDispatch();

  const loading: boolean = useTypedSelector(userLoadingSelector());
  const messageUser = useTypedSelector(userMessageSelector());

  const onInitializeLoginView = useCallback(() => {
    dispatch(userActions.initializeLogin());
  }, [dispatch]);

  useEffect(() => {
    onInitializeLoginView();
  }, [onInitializeLoginView]);

  const onSubmit = (details: LoginDetails) => {
    dispatch(userActions.loginRequested(details.email, details.password));
  };

  const onForgotPassword = () => {
    dispatch(userActions.onRedirectForgotPassword());
  };

  return (
    <Login
      loading={loading}
      onSubmit={onSubmit}
      userMessage={messageUser}
      onForgotPassword={onForgotPassword}
    />
  );
};

export default LoginContainer;
