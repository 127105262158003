import i18next from 'i18next';
import React from 'react';

import LottiePlayer from 'src/components/LottiePlayer';

import AnimationPath from '../../animation/notFound/Motive-not-found-page.json';
import './i18n';
import {
  Subtitle,
  Title,
  IconLink,
  Wrapper,
  WrapperLogo,
  ImageMotiveLogo,
  WrapperAnimation,
  WrapperText,
  Message,
  WrapperHeader,
  WrapperBody,
} from './styles';

const NotFound = () => {
  return (
    <Wrapper>
      <WrapperHeader>
        <WrapperLogo>
          <IconLink href="/login">
            <ImageMotiveLogo />
          </IconLink>
        </WrapperLogo>
      </WrapperHeader>
      <WrapperBody>
        <WrapperAnimation>
          <LottiePlayer animationJsonPath={AnimationPath} loop autoplay />
        </WrapperAnimation>
        <WrapperText>
          <Title>{i18next.t('NOT_FOUND:TITLE')}</Title>
          <Subtitle>{i18next.t('NOT_FOUND:SUBTITILE')}</Subtitle>
          <Message>{i18next.t('NOT_FOUND:MESSAGE')}</Message>
        </WrapperText>
      </WrapperBody>
    </Wrapper>
  );
};

export default NotFound;
