import React, { ReactNode } from 'react';
import styled from 'styled-components';

import Footer from 'src/components/Layout/Footer';

interface ILayoutFooterProps {
  isPrivate?: boolean;
  children: ReactNode;
  handleTrackEvent: (event: string) => void;
}

const GlobalContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ViewContainer = styled.div`
  width: 100%;
`;

const LayoutFooter = (props: ILayoutFooterProps) => {
  const { children } = props;
  return (
    <GlobalContainer>
      <ViewContainer>
        {children}
        <Footer handleTrackEvent={() => {}} />
      </ViewContainer>
    </GlobalContainer>
  );
};
export default LayoutFooter;
