import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowDropUpIcon } from 'src/assets/sales/arrow_green.svg';
import { ReactComponent as ArrowDropDownIcon } from 'src/assets/sales/arrow_grey.svg';

export const DataGridWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 380px;
`;

export const ArrowUpIcon = styled(ArrowDropUpIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.palette.quaternary};
`;

export const ArrowDownIcon = styled(ArrowDropDownIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;
