import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { PillarScores, PillarScoreToScoreKey } from 'src/enums/pillarScores';
import { getPillarState } from 'src/helpers/data';
import { ICompanyGlobalScores } from 'src/types/score';

const pillarsRankSelector = (pillars: PillarScores[]) =>
  createSelector(
    [
      (state: IReduxState) => state.score.industriesScores,
      (state: IReduxState) => state.user.user,
      (state: IReduxState) => state.company.industryRealScores,
    ],
    (industryScores, loggedUser, totalCompanies) => {
      if (!loggedUser || !industryScores || !totalCompanies) {
        return [
          {
            position: 0,
            total: 0,
          },
        ];
      }

      const userScores = industryScores.find(
        (industry) => industry.industry === loggedUser.company.industry,
      )!.data;

      return pillars.map((pillar) => {
        const sortedValues = userScores
          .find((element) => element.pillar === pillar)!
          .industryScores.sort((a, b) => (b as number) - (a as number));
        const position = sortedValues.findIndex(
          (pillarScore) =>
            pillarScore ===
            loggedUser.company.lastUpdatedScore![
              PillarScoreToScoreKey[pillar] as keyof ICompanyGlobalScores
            ],
        );

        return {
          position: position + 1,
          total: totalCompanies.length,
          pillar,
          state: getPillarState(Math.trunc(((position + 1) * 100) / totalCompanies.length)),
        };
      });
    },
  );

export default pillarsRankSelector;
