export const sectorIndustry = [
  {
    sector: 'Health Care',
    industry: [
      'Advanced Medical Equipment',
      'Biotechnology',
      'Birth Control Products',
      'Healthcare Facilities',
      'Managed Health Care',
      'Medical Equipment / Supplies / Distribution',
      'Personal Services',
      'Pharmaceuticals - Diversified',
      'Pharmaceuticals - Generic / Specialty',
    ],
  },
  {
    sector: 'Communication Services',
    industry: [
      'Advertising / Marketing',
      'Broadcasting',
      'Entertainment Production',
      'Integrated Telecommunications Services',
      'Media Diversified',
      'Publishing',
      'Wireless Telecommunications Services',
    ],
  },
  {
    sector: 'Industrials',
    industry: [
      'Aerospace / Defense',
      'Aerospace and defense electronics',
      'Air Freight / Courier Services',
      'Airlines',
      'Airport Services',
      'Business Support / Supplies',
      'Commercial Printing Services',
      'Construction - Supplies / Fixtures',
      'Construction / Agricultural Machinery',
      'Diversified Trading / Distributing',
      'Electrical Components / Equipment',
      'Engineering / Construction',
      'Environmental Services',
      'Heavy Electrical Equipment',
      'Highways / Railtracks',
      'Industrial Conglomerates',
      'Industrial Machinery / Equipment',
      'Marine Port Services',
      'Marine Transportation',
      'Office Equipment',
      'Rails / Roads - Freights',
      'Rails / Roads - Passengers',
    ],
  },
  {
    sector: 'Utilities',
    industry: [
      'Alternative Electric Utilities',
      'Hydroelectric and tidal utilities',
      'Utilities - Electric',
      'Utilities - Multiline',
      'Utilities - Natural Gas',
      'Utilities - Water / Others',
      'Wind Electric Utilities',
    ],
  },
  {
    sector: 'Materials',
    industry: [
      'Aluminum',
      'Chemicals - Agricultural',
      'Chemicals - Commodity',
      'Chemicals - Diversified',
      'Chemicals - Specialty',
      'Construction Materials',
      'Forest / Wood Products',
      'Mining / Metals - Specialty',
      'Non-Paper Containers / Packaging',
      'Paper Packaging',
      'Paper Products',
      'Precious Metals / Minerals',
      'Steel',
    ],
  },
  {
    sector: 'Consumer Discretionary',
    industry: [
      'Apparel / Accessories',
      'Appliances / Tools / Housewares',
      'Auto / Truck / Motorcycle Parts',
      'Auto / Truck Manufacturers',
      'Casinos / Gaming',
      'Consumer Electronics',
      'Footwear',
      'Home Furnishing',
      'Homebuilding',
      'Hotels / Motels / Cruise Lines',
      'Integrated Hardware and Software',
      'Leisure / Recreation',
      'Leisure Products',
      'Restaurants',
      'Retail - Apparel / Accessories',
      'Retail - Computers / Electronics',
      'Retail - Department Stores',
      'Retail - Discount Stores',
      'Retail - Internet / Catalog Order',
      'Retail - Specialty',
      'School, College and University',
      'Textiles / Leather Goods',
      'Tires / Rubber Products',
    ],
  },
  {
    sector: 'Financials',
    industry: [
      'Banks',
      'Blockchain and Cryptocurrency',
      'Consumer Financial Services',
      'Financial Services - Diversified',
      'Financial Technology (Fintech)',
      'Financials - Specialty',
      'Insurance - Life / Health',
      'Insurance - Multiline',
      'Insurance - Property / Casualty',
      'Investment Services',
      'Reinsurance',
      'REIT - Residential / Commercial',
    ],
  },
  {
    sector: 'Consumer Staples',
    industry: [
      'Beverages - Brewers',
      'Beverages - Distillers / Wineries',
      'Beverages - Non-Alcoholic',
      'Fishing / Farming',
      'Food Distribution / Convenience Stores',
      'Food Processing',
      'Household Products',
      'Personal Products',
      'Retail - Drugs',
      'Tobacco',
      'Vegan and Vegetarian Food Manufacturing',
    ],
  },
  {
    sector: 'Energy',
    industry: [
      'Coal',
      'Integrated Oil / Gas',
      'Oil / Gas Drilling',
      'Oil / Gas Exploration / Production',
      'Oil / Gas Refining / Marketing',
      'Oil Related Services / Equipment',
    ],
  },
  {
    sector: 'Information Technology',
    industry: [
      'Communications Equipment',
      'Computer Hardware',
      'IT Services / Consulting',
      'Machine Learning and Artificial Intelligence (AI) Services',
      'Security Software',
      'Semiconductor Equipment / Testing',
      'Semiconductors',
      'Software',
    ],
  },
  {
    sector: 'Real Estate',
    industry: ['Real Estate Operations'],
  },
];
