import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import * as userActions from 'src/redux/actions/user';
import userChangePasswordSelector from 'src/redux/selectors/user/userChangePassword';
import userDataSelector from 'src/redux/selectors/user/userData';

import { SIZES } from 'src/helpers/devices';
import { useTypedSelector } from 'src/hooks/typeSelector';
import useQueryString from 'src/hooks/useQueryString';
import { IPasswordDetails } from 'src/types/passwordUser';
import ChangePassword from 'src/views/ChangePassword';

const ChangePasswordContainer = () => {
  const dispatch = useDispatch();
  const { token }: any = useQueryString();
  const userData = useTypedSelector(userDataSelector());
  const isPasswordChange = useTypedSelector(userChangePasswordSelector());
  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.bigTablet}px)`,
  });

  const onInitializeChangePasswordView = useCallback(() => {
    dispatch(userActions.initializeChangePassword(token));
  }, [dispatch, token]);

  useEffect(() => {
    onInitializeChangePasswordView();
  }, [onInitializeChangePasswordView]);

  const onSubmit = (details: IPasswordDetails) => {
    dispatch(userActions.changePassword(details.password, token));
  };

  const redirectToLogin = useCallback(() => {
    dispatch(userActions.redirectToLogin());
  }, [dispatch]);

  useEffect(() => {
    if (isPasswordChange) {
      setTimeout(() => redirectToLogin(), 3000);
    }
  }, [isPasswordChange, redirectToLogin]);

  if (!userData) return null;

  return (
    <ChangePassword
      user={userData}
      isTablet={isTablet}
      loading={false}
      onSubmit={onSubmit}
      isPasswordChange={isPasswordChange}
      onSubmitSuccessModal={redirectToLogin}
    />
  );
};

export default ChangePasswordContainer;
