import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import history from 'src/helpers/history';

import companyReducer from './company';
import dataReducer from './data';
import insightsReducer from './insights';
import newsReducer from './news';
import scoreReducer from './score';
import userReducer from './user';
import userMessageReducer from './userMessage';

const rootReducer = combineReducers({
  router: connectRouter(history),
  user: userReducer,
  company: companyReducer,
  news: newsReducer,
  insights: insightsReducer,
  data: dataReducer,
  userMessage: userMessageReducer,
  score: scoreReducer,
});

export default rootReducer;
