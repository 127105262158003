import styled from 'styled-components';

import { FOOTER_HEIGHT, SIZES } from 'src/helpers/devices';

import background from '../../assets/changePassword/backgraundChangePassword.png';

interface IErrorActive {
  isErrorActive: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - (${FOOTER_HEIGHT}px));
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
`;

export const InputsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    height: auto;
    width: 80%;
    padding: 20px;
  }

  width: 50%;
  height: 70%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  border-radius: 15px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const LogoWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 225px;
  }
  width: 60%;
  align-self: center;
  margin-bottom: 20px;
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputWrapper = styled.div<IErrorActive>`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin: ${(props) => (props.isErrorActive ? '30px 0' : '10px 0 30px')};
  }
  margin: 15px 0;
`;

export const ButtonWrapper = styled.div`
  margin: 15px 0;
`;

export const SignUpMessageWrapper = styled.div`
  display: flex;
`;
