import i18next from 'i18next';
import React from 'react';

import NewsCards from 'src/components/NewsCards';
import history from 'src/helpers/history';
import { MAX_NEWS_MODULE } from 'src/helpers/news';

import './i18n';
import {
  LoadingSkeleton,
  NewsListWrapper,
  Title,
  LoadingWrapper,
  WrapperViewAll,
  WrapperHeader,
} from './styles';
import { INewsModuleProps } from './types';

const NewsModule = (props: INewsModuleProps) => {
  const { news } = props;

  const onViewAllClick = () => {
    history.push('/news');
  };

  return (
    <NewsListWrapper>
      <WrapperHeader>
        <Title>{i18next.t('NEWS_MODULE:TITLE')}</Title>
        <WrapperViewAll onClick={onViewAllClick}>
          {i18next.t('NEWS_MODULE:VIEW_ALL')}
        </WrapperViewAll>
      </WrapperHeader>
      {!news && (
        <LoadingWrapper data-testid="loading-skeleton-block">
          {Array.from({ length: MAX_NEWS_MODULE }, (v, i) => i).map((i) => {
            return <LoadingSkeleton key={i} height={160} animation="wave" />;
          })}
        </LoadingWrapper>
      )}
      {news && <NewsCards userNews={news.slice(0, 10)} />}
    </NewsListWrapper>
  );
};

export default NewsModule;
