import React from 'react';

import { CloseIcon, ModalWrapper, ModelContent } from './styles';
import { IModalProps } from './types';

const Modal = (props: IModalProps) => {
  const { open, width, onClose, children } = props;

  return (
    <ModalWrapper data-testid="modal-block" open={open} onClose={onClose}>
      <ModelContent width={width}>
        <>
          <CloseIcon data-testid="modal-close-icon" onClick={onClose} />
          {children}
        </>
      </ModelContent>
    </ModalWrapper>
  );
};

export default Modal;
