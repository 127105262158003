import i18next from 'i18next';
import React from 'react';

import { MaterialityType, PillarScoreToScoreKey } from 'src/enums/pillarScores';
import { pillarScoreTexts, sortIndustryScores } from 'src/helpers/pillarsScores';
import {
  formatScoreByPillar,
  getAverageScore,
  getPillarScoresByMateriality,
} from 'src/helpers/scores';
import { ICompanyGlobalScores } from 'src/types/score';

import PillarDetails from '../PillarDetails';
import './i18n';
import {
  ContentWrapper,
  DetailsWrapper,
  Explanaition,
  ExplanaitionWrapper,
  MaterialityDetailsWrapper,
  MaterialityPillarDetailsWrapper,
  PillarDetailsWrapper,
  PillarName,
  PillarScore,
  PillarsWrapper,
  PillarWrapper,
  Subtitle,
  Title,
  TitleInsight,
  TitleWrapper,
} from './styles';
import { IMaterialityDetailsProps } from './types';

const MaterialityDetails = (props: IMaterialityDetailsProps) => {
  const { industryScores, companyScore, materiality, materialityType } = props;
  const materialityPillars = getPillarScoresByMateriality([materiality]);

  return (
    <MaterialityDetailsWrapper materialityType={materialityType}>
      <Title>{i18next.t('MATERIALITY_DETAILS:TITLE')}</Title>
      <ContentWrapper>
        {companyScore && (
          <>
            <PillarsWrapper>
              {materialityPillars.map((pillarMetadata) => {
                const score =
                  companyScore[
                    PillarScoreToScoreKey[pillarMetadata.Identifier] as keyof ICompanyGlobalScores
                  ]!;
                return (
                  <PillarWrapper>
                    <PillarName>
                      {
                        pillarScoreTexts.find(
                          (pillar) => pillar.value === pillarMetadata.Identifier,
                        )!.text
                      }
                    </PillarName>
                    <PillarScore>
                      {formatScoreByPillar(pillarMetadata.Identifier, score)}
                    </PillarScore>
                  </PillarWrapper>
                );
              })}
            </PillarsWrapper>
            <DetailsWrapper>
              <TitleWrapper>
                <TitleInsight>{i18next.t('MATERIALITY_DETAILS:INSIGHTS_TITLE')}</TitleInsight>
                <Subtitle>{i18next.t('MATERIALITY_DETAILS:INSIGHTS_SUBTITLE')}</Subtitle>
              </TitleWrapper>
              <MaterialityPillarDetailsWrapper>
                {materialityPillars.map((pillarMetadata) => {
                  const score =
                    companyScore[
                      PillarScoreToScoreKey[pillarMetadata.Identifier] as keyof ICompanyGlobalScores
                    ]!;
                  return (
                    <PillarDetailsWrapper>
                      <PillarDetails
                        pillarMetadata={pillarMetadata}
                        companyScore={score as number}
                        industryScores={
                          industryScores.find(
                            (industryScore) => industryScore.pillar === pillarMetadata.Identifier,
                          )!.industryScores
                        }
                      />
                    </PillarDetailsWrapper>
                  );
                })}
              </MaterialityPillarDetailsWrapper>
              <ExplanaitionWrapper>
                <Explanaition>
                  {i18next.t(
                    `MATERIALITY_DETAILS:EXPLANAITION_${
                      materialityType === MaterialityType.POSITIVE
                    }`,
                  )}
                </Explanaition>
              </ExplanaitionWrapper>
            </DetailsWrapper>
          </>
        )}
        {!companyScore && (
          <>
            <PillarsWrapper>
              {materialityPillars.map((pillarMetadata) => {
                const industryScore = industryScores
                  .find((industryScore) => industryScore.pillar === pillarMetadata.Identifier)!
                  .industryScores.filter((score) => score !== null);

                const scores = sortIndustryScores(industryScore, pillarMetadata);
                return (
                  <PillarWrapper>
                    <PillarName>
                      {
                        pillarScoreTexts.find(
                          (pillar) => pillar.value === pillarMetadata.Identifier,
                        )!.text
                      }
                    </PillarName>
                    <PillarScore>
                      {formatScoreByPillar(
                        pillarMetadata.Identifier,
                        getAverageScore(scores, pillarMetadata.ValueType),
                      )}
                    </PillarScore>
                  </PillarWrapper>
                );
              })}
            </PillarsWrapper>
            <DetailsWrapper>
              <TitleWrapper>
                <TitleInsight>{i18next.t('MATERIALITY_DETAILS:INSIGHTS_TITLE')}</TitleInsight>
                <Subtitle>{i18next.t('MATERIALITY_DETAILS:INSIGHTS_SUBTITLE')}</Subtitle>
              </TitleWrapper>
              <MaterialityPillarDetailsWrapper>
                {materialityPillars.map((pillarMetadata) => {
                  return (
                    <PillarDetailsWrapper>
                      <PillarDetails
                        pillarMetadata={pillarMetadata}
                        companyScore={null}
                        industryScores={
                          industryScores.find(
                            (industryScore) => industryScore.pillar === pillarMetadata.Identifier,
                          )!.industryScores
                        }
                      />
                    </PillarDetailsWrapper>
                  );
                })}
              </MaterialityPillarDetailsWrapper>
            </DetailsWrapper>
          </>
        )}
      </ContentWrapper>
    </MaterialityDetailsWrapper>
  );
};

export default MaterialityDetails;
