import i18next from 'i18next';
import React, { useState } from 'react';

import UserMessage from 'src/components/UserMessage';
import { MenuRoutes } from 'src/enums/routes';

import './i18n';
import {
  CompanyLogo,
  CompanyLogoWrapper,
  HeaderWrapper,
  ProfileIcon,
  RealScoreNumber,
  RealScoreText,
  RealScoreWrapper,
  UserProfileWrapper,
  WrapperCompanyName,
  ButtonProfile,
  WrapperMenuItem,
  WrapperMenu,
} from './styles';
import { IHeaderProps } from './types';

const Header = (props: IHeaderProps) => {
  const { realScore, companyLogo, companyName, currentPage, userMessage, isTablet, onLogout } =
    props;
  const [isMenuOpen, setIsMenuOpen] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsMenuOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(null);
  };
  return (
    <>
      <HeaderWrapper>
        {companyLogo !== '' ? (
          <CompanyLogoWrapper>
            <CompanyLogo data-testid="header-company-logo" src={companyLogo} />
          </CompanyLogoWrapper>
        ) : (
          <WrapperCompanyName>{companyName}</WrapperCompanyName>
        )}

        <UserProfileWrapper>
          {currentPage !== MenuRoutes.SUMMARY && !isTablet && realScore && (
            <RealScoreWrapper data-testid="header-real-score-block">
              <RealScoreText>{i18next.t('HEADER:MY_REAL_SCORE')}</RealScoreText>
              <RealScoreNumber>{realScore.toFixed(0)}</RealScoreNumber>
            </RealScoreWrapper>
          )}
          {!isTablet && (
            <ButtonProfile data-testid="header-profile-button" onClick={handleMenuOpen}>
              <ProfileIcon data-testid="header-profile-icon" isMenuOpen={isMenuOpen !== null} />
            </ButtonProfile>
          )}
          <WrapperMenu anchorEl={isMenuOpen} open={isMenuOpen !== null} onClose={handleCloseMenu}>
            <WrapperMenuItem data-testid="header-logout-button" onClick={onLogout}>
              {i18next.t('HEADER:LOGOUT')}
            </WrapperMenuItem>
          </WrapperMenu>
        </UserProfileWrapper>
      </HeaderWrapper>
      {userMessage.messageType !== null && (
        <UserMessage messageType={userMessage.messageType} message={userMessage.message} />
      )}
    </>
  );
};

export default Header;
