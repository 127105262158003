import styled from 'styled-components';

export const WrapperCheckbox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: center;
`;

export const WrapperLabel = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 16px;
  color: #627486;
  cursor: pointer;
`;
