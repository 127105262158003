import { Autocomplete, InputAdornment } from '@mui/material';
import React from 'react';

import { DropdownBox, DropdownOption, IconSubmit, SearchTextField } from './styles';
import { IAutocompleteInputInput } from './types';

const AutocompleteInput = (props: IAutocompleteInputInput) => {
  const {
    id,
    valueAutocomplete,
    pathNoOptionText,
    options,
    pathPlaceholder,
    disabled = false,
    freeSolo = false,
    adornmentPath = null,
    onChange,
    onInputChange,
    onSubmit = () => {},
  } = props;

  const handleSubmit = () => {
    onSubmit(valueAutocomplete);
  };

  return (
    <Autocomplete
      id={id}
      disabled={disabled}
      data-testid="autocomplete"
      autoComplete
      freeSolo={freeSolo}
      disableClearable={true}
      inputValue={valueAutocomplete}
      getOptionLabel={(option: any) => option}
      noOptionsText={pathNoOptionText}
      options={options}
      includeInputInList
      filterSelectedOptions
      PaperComponent={(props: any) => <DropdownBox elevation={8} {...props} />}
      onChange={(event, selectedCompany: unknown) => {
        onChange('');
      }}
      onInputChange={(event, searchValue) => {
        onInputChange(searchValue);
      }}
      renderInput={(params) => (
        <SearchTextField
          {...params}
          placeholder={pathPlaceholder}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: adornmentPath ? (
              <InputAdornment position="end">
                <IconSubmit onClick={handleSubmit} adornmentPath={adornmentPath} />
              </InputAdornment>
            ) : (
              <></>
            ),
          }}
        />
      )}
      renderOption={(props, option: any) => {
        return (
          <DropdownOption data-testid="option" {...props}>
            {option}
          </DropdownOption>
        );
      }}
    />
  );
};

export default AutocompleteInput;
