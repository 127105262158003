import { SelectChangeEvent } from '@mui/material';
import i18next from 'i18next';
import React from 'react';

import PillarScoreChart from 'src/components/Charts/PillarScoreChart';
import { IPillarScoreChartData } from 'src/components/Charts/PillarScoreChart/types';
import SelectInput from 'src/components/Inputs/SelectInput';
import { PillarScores } from 'src/enums/pillarScores';
import { BenchmarkPillars, pillarScoreTexts } from 'src/helpers/pillarsScores';
import { getScoreByPillar } from 'src/helpers/scores';

import './i18n';
import {
  HeaderWrapper,
  LoadingIndicator,
  LoadingWrapper,
  SelectPillarWrapper,
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';
import { IPillarScoreRankingModuleProps } from './types';

const PillarScoreRankingModule = (props: IPillarScoreRankingModuleProps) => {
  const { userCompany, competitors, selectPillarScore, onPillarScoreChange } = props;

  const pillarsToShow = pillarScoreTexts.filter((pillar) =>
    BenchmarkPillars.includes(pillar.value as PillarScores),
  );

  const handlePillarScoreChange = (event: SelectChangeEvent<unknown>) => {
    onPillarScoreChange(event.target.value as PillarScores);
  };

  let chartData: IPillarScoreChartData[] = [];
  if (userCompany.lastUpdatedScore) {
    chartData = chartData.concat({
      company: userCompany.name,
      score: getScoreByPillar(selectPillarScore, userCompany.lastUpdatedScore),
    });
  }
  chartData = chartData.concat(
    competitors?.map((competitor) => ({
      company: competitor.name,
      score: getScoreByPillar(selectPillarScore, competitor.lastUpdatedScore!),
    })) || [],
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        <TitleWrapper>
          <Title> {i18next.t('PILLAR_SCORE_RANKING_MODULE:TITLE')} </Title>
          <Subtitle> {i18next.t('PILLAR_SCORE_RANKING_MODULE:SUBTITLE')} </Subtitle>
        </TitleWrapper>
        <SelectPillarWrapper>
          <SelectInput
            id="pillar-scores-select"
            name="pillar-scores-select"
            value={selectPillarScore}
            onChange={handlePillarScoreChange}
            options={pillarsToShow}
          />
        </SelectPillarWrapper>
      </HeaderWrapper>
      {!competitors && (
        <LoadingWrapper data-testid="loading-block">
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {competitors && (
        <PillarScoreChart
          pillar={selectPillarScore}
          userCompany={userCompany}
          chartData={chartData}
        />
      )}
    </Wrapper>
  );
};

export default PillarScoreRankingModule;
