import { CompetitorsType } from 'src/enums/competitorsType';
import { ICompany } from 'src/types/company';
import { IScoresByIndustry } from 'src/types/score';

import * as companyConstants from '../constants/company';

export const onSearchCompanyRequested = (search: string) => ({
  type: companyConstants.ON_SEARCH_COMPANY_BY_QUERY_REQUESTED,
  search,
});

export const onSearchCompanySucceeded = (companies: ICompany[]) => ({
  type: companyConstants.ON_SEARCH_COMPANY_BY_QUERY_SUCCEDED,
  companies,
});

export const onSearchCompanyFailed = (errorMessage: string) => ({
  type: companyConstants.ON_SEARCH_COMPANY_BY_QUERY_FAILED,
  errorMessage,
});

export const onAddCompetitorRequested = (
  newCompetitor: ICompany,
  competitorType: CompetitorsType,
) => ({
  type: companyConstants.ON_ADD_COMPETITOR_REQUESTED,
  newCompetitor,
  competitorType,
});

export const onAddCompetitorFailed = (errorMessage: string) => ({
  type: companyConstants.ON_ADD_COMPETITOR_FAILED,
  errorMessage,
});

export const onRemoveCompetitorRequested = (
  competitor: ICompany,
  competitorType: CompetitorsType,
) => ({
  type: companyConstants.ON_REMOVE_COMPETITOR_REQUESTED,
  competitor,
  competitorType,
});

export const onAddCompetitorSucceeded = (industriesScores: IScoresByIndustry[]) => ({
  type: companyConstants.ON_ADD_COMPETITOR_SUCCEEDED,
  industriesScores,
});
