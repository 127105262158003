import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

interface IOpenModal {
  openMenu: boolean;
}

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  box-sizing: border-box;
`;

export const HeaderWrapper = styled.div<IOpenModal>`
  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    align-items: baseline;
  }
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.openMenu ? `1px solid ${props.theme.palette.quaternary} ` : 'none'};
  align-items: center;
`;

export const TitleWrapper = styled.div`
  flex: 1;
  padding-right: 5px;
`;

export const SelectPillarWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
    margin-bottom: 10px;
  }
  flex: 0.32;
`;

export const Title = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 24px;
  }
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 14px;
  }
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 20px;
`;
export const WrapperMenu = styled.div`
  width: 100%;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
  bottom: 1px;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.palette.quinternary};
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const WrapperMenuItems = styled.div`
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, auto);
  padding: 30px;
  grid-gap: 20px;
`;

export const WrapperButtonSubmit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  padding: 20px;
`;

export const ButtonSubmit = styled.button`
  background-color: #32cc6f;
  color: ${(props) => props.theme.palette.common.white};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 16px;
  border: none;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  cursor: pointer;
`;

export const ButtonSelect = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.common.white};
  border: 1px solid ${(props) => props.theme.palette.quinternary};
  padding: 12px 3px 12px 14px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 14px;
  color: ${(props) => props.theme.palette.secondary};
  cursor: pointer;
`;

export const DataGridWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  margin-top: 20px;
`;

export const WrapperButton = styled.div`
  flex: 1;
`;

export const ArrowDownIcon = styled(KeyboardArrowDownIcon)`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.palette.tertiary};
`;

export const WrapperText = styled.div`
  flex: 9;
  display: flex;
  align-items: center;
`;
