import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const searchedCompaniesOnlySelector = () =>
  createSelector([(state: IReduxState) => state.company.searchedCompanies], (searchedCompanies) => {
    return searchedCompanies;
  });

export default searchedCompaniesOnlySelector;
