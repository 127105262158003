import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import hasMoreNewsSelector from 'src/redux/selectors/news/hasMoreNews';
import userNewsSelector from 'src/redux/selectors/news/userNews';

import { useTypedSelector } from 'src/hooks/typeSelector';
import News from 'src/views/News';

import LayoutContainer from './Layout';

const NewsContainer = () => {
  const dispatch = useDispatch();
  const userNews = useTypedSelector(userNewsSelector());
  const hasMoreNews = useTypedSelector(hasMoreNewsSelector());

  const onInitializeNewsView = useCallback(() => {
    dispatch(userActions.initializeNewsView());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeNewsView();
  }, [onInitializeNewsView]);

  const onLoadMoreNews = () => {
    dispatch(userActions.onLoadMoreNews());
    handleTrackEvent('Click load more news');
  };

  const handleTrackEvent = (event: string) => {
    dispatch(userActions.onTrackEvent(event));
  };

  return (
    <LayoutContainer>
      <News news={userNews} hasMore={hasMoreNews} onLoadMoreNews={onLoadMoreNews} />
    </LayoutContainer>
  );
};
export default NewsContainer;
