import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 260px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 15px;
`;

export const DataLabel = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-size: 12px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 3px;
`;

export const DataText = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  word-break: break-all;
`;

export const Title = styled.div`
  margin: 20px 0;
  font-size: 28px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const WrapperInput = styled.div`
  width: 100%;
  margin: 10px 0;
`;

export const WrapperSelect = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const WrapperMessageInput = styled.div`
  width: 100%;
  height: 150px;
  margin: 20px 0;
`;

export const WrapperButton = styled.div`
  width: 100%;
  margin: 20px 0 0;
`;

export const WrapperTextSuccess = styled.div`
  width: 60%;
  margin: 20px 0 0;
  text-align: center;
`;

export const WrapperAnimation = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
