import { AnyAction } from 'redux';

import * as userConstants from 'src/redux/constants/user';

import { IDataItem, IGroupPillarValues, IPillarValues } from 'src/helpers/types/data';

export interface IDataReducerState {
  companyData: IDataItem[];
  pillarValues: IGroupPillarValues[];
  selectedExploreTab: string;
}

const defaultState: IDataReducerState = {
  companyData: [],
  pillarValues: [],
  selectedExploreTab: '',
};

const setCompanyData = (state: IDataReducerState, companyData: IDataItem[]) => {
  return {
    ...state,
    companyData: [...state.companyData, ...companyData],
  };
};

const getPillarValuesSucceeded = (state: IDataReducerState, pillarValues: IPillarValues[]) => ({
  ...state,
  pillarValues: [...state.pillarValues, ...pillarValues],
});

const setSelectedExploreTab = (state: IDataReducerState, selectedExploreTab: string) => ({
  ...state,
  selectedExploreTab,
});

const dataReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_CHANGE_DATA_TAB_SUCCEDED:
      return getPillarValuesSucceeded(setCompanyData(state, action.data), action.pillarValues);
    case userConstants.USER_ON_CHANGE_DATA_TAB:
      return setSelectedExploreTab(state, action.selectedExploreTab);
    default:
      return state;
  }
};

export default dataReducer;
