import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';
import PasswordInput from 'src/components/Inputs/PasswordInput';

import ModalSuccessChangePassword from './components/ModalSuccess';
import './i18n.ts';
import {
  ButtonWrapper,
  DataLabel,
  DataText,
  InputsWrapper,
  InputWrapper,
  LogoWrapper,
  MotiveLogo,
  TextWrapper,
  Title,
  UserData,
  Wrapper,
} from './styles';
import { IChangePasswordProps } from './types';

const ChangePassword = (props: IChangePasswordProps) => {
  const { user, isTablet, loading, isPasswordChange, onSubmit, onSubmitSuccessModal } = props;
  const [newPasswordDetails, setNewPasswordDetails] = useState({
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');

  const handlePasswordChange = (value: string) => {
    setNewPasswordDetails({ ...newPasswordDetails, password: value });
  };
  const handleConfirmPasswordChange = (value: string) => {
    setNewPasswordDetails({ ...newPasswordDetails, confirmPassword: value });
  };

  const handleSubmit = () => {
    if (newPasswordDetails.confirmPassword === newPasswordDetails.password) {
      setError('');
      onSubmit(newPasswordDetails);
    } else {
      setError(i18next.t('CHANGE_PASSWORD_INPUT:PASSWORD_NOT_MATCH'));
    }
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (
        event.keyCode === 13 &&
        newPasswordDetails.password !== '' &&
        newPasswordDetails.confirmPassword !== ''
      ) {
        handleSubmit();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <Wrapper isPasswordChange={isPasswordChange}>
      {isPasswordChange && (
        <ModalSuccessChangePassword onSubmitSuccessModal={onSubmitSuccessModal} />
      )}
      {!isPasswordChange && (
        <InputsWrapper>
          {!isTablet && (
            <LogoWrapper>
              <MotiveLogo src={Logo} alt="Motive Logo" />
            </LogoWrapper>
          )}
          <TextWrapper isTablet={isTablet}>
            <UserData>
              <DataLabel>{i18next.t('CHANGE_PASSWORD_INPUT:USER')}</DataLabel>
              <DataText>
                {user.firstName} {user.lastName}
              </DataText>
            </UserData>
            <UserData>
              <DataLabel>{i18next.t('CHANGE_PASSWORD_INPUT:EMAIL')}</DataLabel>
              <DataText>{user.email}</DataText>
            </UserData>
            <UserData>
              <DataLabel>{i18next.t('CHANGE_PASSWORD_INPUT:COMPANY')}</DataLabel>
              <DataText>{user.company.name}</DataText>
            </UserData>
          </TextWrapper>
          <Title>{i18next.t('CHANGE_PASSWORD_INPUT:TITLE')} </Title>
          <InputWrapper>
            <PasswordInput
              isDisabled={false}
              error=""
              id={i18next.t('CHANGE_PASSWORD_INPUT:PASSWORD').toLowerCase()}
              name={i18next.t('CHANGE_PASSWORD_INPUT:PASSWORD')}
              placeholder={i18next.t('CHANGE_PASSWORD_INPUT:PASSWORD')}
              value={newPasswordDetails.password}
              onChange={handlePasswordChange}
            />
          </InputWrapper>
          <InputWrapper>
            <PasswordInput
              isDisabled={false}
              error={error}
              id={i18next.t('CHANGE_PASSWORD_INPUT:CONFIRM_PASSWORD').toLowerCase()}
              name={i18next.t('CHANGE_PASSWORD_INPUT:CONFIRM_PASSWORD')}
              placeholder={i18next.t('CHANGE_PASSWORD_INPUT:CONFIRM_PASSWORD')}
              value={newPasswordDetails.confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </InputWrapper>
          <ButtonWrapper>
            <TextButton
              isDisabled={
                newPasswordDetails.password === '' || newPasswordDetails.confirmPassword === ''
              }
              isLoading={loading}
              text={i18next.t('CHANGE_PASSWORD_INPUT:SAVE')}
              onSubmit={handleSubmit}
            />
          </ButtonWrapper>
        </InputsWrapper>
      )}
    </Wrapper>
  );
};

export default ChangePassword;
