import React from 'react';
import TextTruncate from 'react-text-truncate';

import {
  MediaLink,
  CardWrapper,
  CardMediaWrapper,
  CardContentWrapper,
  NewsItemTitle,
  NewsItemDescription,
  WrapperCardActionArea,
} from './styles';
import { INewsCardsProps } from './types';

const NewsCards = (props: INewsCardsProps) => {
  const { userNews } = props;
  return (
    <>
      {userNews.map((newsItem, i) => {
        return (
          <WrapperCardActionArea
            key={`${newsItem.title}-${newsItem.id}`}
            data-testid={`news-card-container-${newsItem.id}`}
          >
            <MediaLink
              href={newsItem.link}
              target="_blank"
              data-testid={`media-link-${newsItem.id}`}
            >
              <CardWrapper data-testid="new-item" includeBorder={i < userNews.length - 1}>
                {newsItem.thumbnail && (
                  <CardMediaWrapper
                    image={newsItem.thumbnail}
                    data-testid={`news-card-thumbnail-${newsItem.id}`}
                  />
                )}
                <CardContentWrapper>
                  <NewsItemTitle>
                    <TextTruncate line={3} element="p" truncateText="..." text={newsItem.title} />
                  </NewsItemTitle>
                  <NewsItemDescription>
                    <TextTruncate
                      line={3}
                      element="span"
                      truncateText="…"
                      text={newsItem.description}
                    />
                  </NewsItemDescription>
                </CardContentWrapper>
              </CardWrapper>
            </MediaLink>
          </WrapperCardActionArea>
        );
      })}
    </>
  );
};

export default NewsCards;
