import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as companyActions from 'src/redux/actions/company';
import * as userActions from 'src/redux/actions/user';
import companyCompetitorsSelector from 'src/redux/selectors/company/competitors';
import searchedCompaniesSelector from 'src/redux/selectors/company/searchedCompanies';
import searchedCompaniesWithoutCompetitorSelector from 'src/redux/selectors/company/searchedCompaniesWithoutCompetitor';
import industriesScoresSelector from 'src/redux/selectors/scores/industriesScores';
import positiveCompetitorsMaterialitiesSelector from 'src/redux/selectors/scores/positiveCompetitorsMaterialities';
import positiveMaterialitiesSelector from 'src/redux/selectors/scores/positiveMaterialities';
import userMaterialitiesSelector from 'src/redux/selectors/scores/userMaterialities';
import helpModuleSelector from 'src/redux/selectors/user/helpModule';
import settingsSelector from 'src/redux/selectors/user/settings';
import userDataSelector from 'src/redux/selectors/user/userData';

import { CompetitorsType } from 'src/enums/competitorsType';
import { HelpModules } from 'src/enums/help';
import { MenuRoutes } from 'src/enums/routes';
import { useTypedSelector } from 'src/hooks/typeSelector';
import useQueryString, { createQueryString } from 'src/hooks/useQueryString';
import { ICompany } from 'src/types/company';
import Sales from 'src/views/Sales';
import { ISubmitValues } from 'src/views/Sales/components/SalesTarget/types';

import LayoutContainer from './Layout';

const SalesContainer = () => {
  const dispatch = useDispatch();
  const { targetName, targetIndustry } = useQueryString();
  const positiveMaterialities = useTypedSelector(positiveMaterialitiesSelector());
  const materialities = useTypedSelector(userMaterialitiesSelector());
  const user = useTypedSelector(userDataSelector())!;
  const userSettings = useTypedSelector(settingsSelector());
  const competitors = useTypedSelector(companyCompetitorsSelector(CompetitorsType.SALES));
  const searchedCompetitorsWithoutCompetitor = useTypedSelector(
    searchedCompaniesWithoutCompetitorSelector(),
  );
  const searchedCompetitors = useTypedSelector(searchedCompaniesSelector(CompetitorsType.SALES));

  const industryScores = useTypedSelector(industriesScoresSelector());
  const positiveCompetitorsMaterialities = useTypedSelector(
    positiveCompetitorsMaterialitiesSelector(),
  );
  const helpModule = useTypedSelector(helpModuleSelector());

  const onNextHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeNext(MenuRoutes.SALES, currentModule));
  };

  const onBackHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeBack(MenuRoutes.SALES, currentModule));
  };

  const onCloseHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeClose(MenuRoutes.SALES, currentModule));
  };
  const onInitializeSalesView = useCallback(() => {
    dispatch(userActions.initializeSalesView());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeSalesView();
  }, [onInitializeSalesView]);

  const handleTrackEvent = (event: string, data: any = {}) => {
    dispatch(userActions.onTrackEvent(event, data));
  };

  const onAddCompetitor = (company: ICompany) => {
    if (competitors!.length < 5) {
      const newUserSettings = { ...userSettings };
      newUserSettings.salesCompetitors.push(company);
      dispatch(userActions.onChangeSettings(newUserSettings));
      dispatch(companyActions.onAddCompetitorRequested(company, CompetitorsType.SALES));
    } else {
      dispatch(
        companyActions.onAddCompetitorFailed(
          'You can only add up to 5 companies. Please remove one in order to add a new one',
        ),
      );
    }
  };

  const onRemoveCompetitor = (company: ICompany) => {
    const newUserSetting = {
      ...userSettings,
      salesCompetitors: userSettings.salesCompetitors.filter(
        (element: ICompany) => element.id !== company.id,
      ),
    };
    dispatch(userActions.onChangeSettings(newUserSetting));
    dispatch(companyActions.onRemoveCompetitorRequested(company, CompetitorsType.SALES));
  };

  const onSearchCompetitor = (search: string) => {
    dispatch(companyActions.onSearchCompanyRequested(search));
  };

  const onSalesBack = () => {
    dispatch(userActions.onSalesBack());
  };

  const onSubmit = (values: ISubmitValues) => {
    const targetCompanyName = searchedCompetitorsWithoutCompetitor.find(
      (element) => element.name === values.companyName,
    );
    const queryParams = createQueryString({
      targetName: targetCompanyName
        ? encodeURIComponent(targetCompanyName.name)
        : values.companyName,
      targetIndustry: targetCompanyName
        ? encodeURIComponent(targetCompanyName.industry)
        : values.industry,
    });
    dispatch(userActions.onSubmitTargetSales(queryParams));
  };

  const initializeSalesInsightsView = useCallback(() => {
    dispatch(
      userActions.onInitializeSalesInsightView(targetIndustry as string, targetName as string),
    );
  }, [dispatch, targetIndustry, targetName]);

  useEffect(() => {
    if (targetIndustry && targetIndustry !== '') {
      initializeSalesInsightsView();
    }
  }, [targetIndustry, initializeSalesInsightsView]);
  return (
    <LayoutContainer>
      <Sales
        targetName={targetName}
        targetIndustry={targetIndustry}
        handleTrackEvent={handleTrackEvent}
        allMaterialities={materialities}
        positiveMaterialities={positiveMaterialities}
        userCompany={user.company}
        competitors={competitors as ICompany[]}
        searchedCompetitors={searchedCompetitors}
        onSearchCompetitor={onSearchCompetitor}
        onAddCompetitor={onAddCompetitor}
        onRemoveCompetitor={onRemoveCompetitor}
        industryScores={industryScores}
        positiveCompetitorsMaterialities={positiveCompetitorsMaterialities}
        onSubmit={onSubmit}
        onSalesBack={onSalesBack}
        searchedCompetitorsWithoutCompetitor={searchedCompetitorsWithoutCompetitor}
        helpModule={helpModule}
        onCloseHelp={onCloseHelp}
        onNextHelp={onNextHelp}
        onBackHelp={onBackHelp}
      />
    </LayoutContainer>
  );
};
export default SalesContainer;
