import i18next from 'i18next';
import React from 'react';

import AnimationPath from 'src/animation/exportBenchmark/Motive-icon-clock.json';
import LottiePlayer from 'src/components/LottiePlayer';
import Modal from 'src/components/Modal';

import './i18n.ts';
import { Title, Wrapper, WrapperImage, WrapperText } from './styles';
import { IModalExportProps } from './types';

const ModalExport = (props: IModalExportProps) => {
  const { title, open, onClose } = props;

  return (
    <Modal width={480} open={open} onClose={onClose}>
      <Wrapper>
        <Title data-testid={'title-modal-export-component'}>{title}</Title>
        <WrapperImage>
          <LottiePlayer animationJsonPath={AnimationPath} loop autoplay />
        </WrapperImage>
        <WrapperText data-testid={'message-modal-export-component'}>
          {i18next.t('MODAL_EXPORT:MESSAGE')}
        </WrapperText>
        <WrapperText data-testid={'contact-message-modal-export-component'}>
          {i18next.t('MODAL_EXPORT:MESSAGE_CONTACT')}
        </WrapperText>
      </Wrapper>
    </Modal>
  );
};

export default ModalExport;
