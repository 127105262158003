import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SALES_TARGET', {
  TARGET_NAME_COPY:
    'Who is your prospective client? Discover which elements of sustainability matter the most to them.',
  TARGET_INDUSTRY_COPY:
    'Hmmm…it seems this name shows up more than once. Let’s make sure we are returning the correct prospective client. Please select the client’s operating sector and industry.',
  INPUT_LABEL_SECTOR: 'Enter Sector Industry',
  INPUT_LABEL_COMPANY: 'Enter company name',
  INPUT_LABEL_INDUSTRY: 'Enter target Industry',
  SUBMIT_COPY: 'Your searched name was not found. Press enter or submit to search by industry',
});
