import i18next from 'i18next';
import React, { useState } from 'react';

import { PillarScores } from 'src/enums/pillarScores';
import { getTipIconByPillarScore } from 'src/helpers/insights';

import './i18n';
import {
  CardsWrapper,
  HelpWrapper,
  IconWrapper,
  PillarDescription,
  PillarHeader,
  PillarName,
  PillarScoreWrapper,
  Title,
  WrapperText,
  WrapperTitle,
} from './styles';

const Help = () => {
  const [hoveredPillar, setHoveredPillar] = useState<PillarScores | null>(null);

  const pillarsScores = [
    PillarScores.INDUSTRY_RANK,
    PillarScores.ENVIRONMENT,
    PillarScores.SOCIAL,
    PillarScores.GOVERNANCE,
  ];
  return (
    <HelpWrapper>
      <WrapperTitle>
        <Title data-testid="help-title"> {i18next.t('REAL_SCORE_HELP:TITLE')} </Title>
      </WrapperTitle>
      <CardsWrapper>
        {pillarsScores.map((pillarScore) => {
          return (
            <PillarScoreWrapper
              key={pillarScore}
              data-testid="help-pillar-score-block"
              onMouseEnter={() => setHoveredPillar(pillarScore)}
              onMouseLeave={() => setHoveredPillar(null)}
            >
              {hoveredPillar === null || hoveredPillar !== pillarScore ? (
                <>
                  <IconWrapper src={getTipIconByPillarScore(pillarScore)} />
                  <PillarName>{i18next.t(`REAL_SCORE_HELP_${pillarScore}:TITLE`)}</PillarName>
                </>
              ) : (
                <>
                  <PillarHeader>
                    <PillarName>{i18next.t(`REAL_SCORE_HELP_${pillarScore}:TITLE`)}</PillarName>
                    <IconWrapper src={getTipIconByPillarScore(pillarScore)} />
                  </PillarHeader>
                  <PillarDescription data-testid="help-pillar-score-description">
                    {i18next.t(`REAL_SCORE_HELP_${pillarScore}:DESCRIPTION`)}
                  </PillarDescription>
                </>
              )}
            </PillarScoreWrapper>
          );
        })}
      </CardsWrapper>
      <WrapperText>{i18next.t(`REAL_SCORE_HELP:MESSAGE`)}</WrapperText>
    </HelpWrapper>
  );
};

export default Help;
