import i18next from 'i18next';
import React from 'react';

import contactUsIcon from 'src//assets/menu/motive-contact.svg';
import Logo from 'src/assets/logos/logo-white.svg';
import tutorialIcon from 'src/assets/menu/motive-tutorial.svg';
import { hasHelpWalktrough } from 'src/enums/help';

import './i18n';
import {
  Help,
  MenuIcon,
  MenuItem,
  MenuItemsWrapper,
  MenuLink,
  MotiveLogo,
  SidebarFooter,
  Drawer,
  ArrowIcon,
  ArrowIconReverse,
  IconButtonWrapper,
  SelectedRouteOverlay,
  ContactUs,
  SVGIcon,
  IconLogoSmall,
  WrapperIconContacUs,
  WrapperIconTutorial,
  WrapperLogoSmall,
} from './styles';
import { ISidebarMenuProps } from './types';

const SidebarMenu = (props: ISidebarMenuProps) => {
  const {
    menuRoutes,
    currentPage,
    handleTrackEvent,
    onHelpClick,
    onClickopenContactUs,
    onClickOpenMenu,
    isMenuOpen,
  } = props;

  const handleDrawerChange = () => {
    handleTrackEvent(isMenuOpen ? 'Sidebar menu close' : 'Sidebar menu open');
    onClickOpenMenu(!isMenuOpen);
  };

  return (
    <Drawer variant="persistent" anchor="left" open={true} isMenuOpen={isMenuOpen}>
      <MenuItemsWrapper>
        <IconButtonWrapper isMenuOpen={isMenuOpen}>
          {isMenuOpen ? (
            <ArrowIcon onClick={handleDrawerChange} />
          ) : (
            <ArrowIconReverse onClick={handleDrawerChange} />
          )}
        </IconButtonWrapper>
        {menuRoutes.map(({ icon: Icon, route, value, label }) => {
          return (
            <MenuItem
              key={value}
              data-testid={`menu-button-${value.toLowerCase()}`}
              to={route}
              isMenuOpen={isMenuOpen}
              isSelected={currentPage === route}
            >
              <MenuIcon>
                <Icon />
              </MenuIcon>
              {isMenuOpen && (
                <MenuLink data-testid="menu-link" isSelected={currentPage === route}>
                  {label}
                </MenuLink>
              )}
              {currentPage === route && <SelectedRouteOverlay isMenuOpen={isMenuOpen} />}
            </MenuItem>
          );
        })}
      </MenuItemsWrapper>
      <SidebarFooter>
        {isMenuOpen ? (
          <>
            <ContactUs onClick={onClickopenContactUs}>
              {i18next.t('SIDEBAR_MENU:CONTACT_US')}
            </ContactUs>
            <Help
              enabled={hasHelpWalktrough(currentPage)}
              onClick={hasHelpWalktrough(currentPage) ? onHelpClick : () => {}}
            >
              {i18next.t('SIDEBAR_MENU:HELP')}
            </Help>
            <MotiveLogo data-testid="motive-logo" src={Logo} alt="Motive Logo" />
          </>
        ) : (
          <>
            <WrapperIconContacUs onClick={onClickopenContactUs}>
              <SVGIcon src={contactUsIcon} enabled={true} />
            </WrapperIconContacUs>
            <WrapperIconTutorial onClick={hasHelpWalktrough(currentPage) ? onHelpClick : () => {}}>
              <SVGIcon src={tutorialIcon} enabled={hasHelpWalktrough(currentPage)} />
            </WrapperIconTutorial>
            <WrapperLogoSmall>
              <IconLogoSmall />
            </WrapperLogoSmall>
          </>
        )}
      </SidebarFooter>
    </Drawer>
  );
};

export default SidebarMenu;
