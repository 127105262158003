import { LoadingButton } from '@mui/lab';
import styled from 'styled-components';

export const Button = styled(LoadingButton)`
  color: ${(props) =>
    props.disabled ? props.theme.palette.secondary : props.theme.palette.common.white}!important;
  background-color: #32cc6f !important;
  min-height: 45px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? '25%' : '100%')};
  .MuiLoadingButton-loadingIndicator {
    color: ${(props) => props.theme.palette.common.white} !important;
  }
`;
