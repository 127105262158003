import i18next from 'i18next';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import environmentIcon from 'src/assets/explore/environment.svg';
import governanceIcon from 'src/assets/explore/governance.svg';
import socialIcon from 'src/assets/explore/social.svg';
import LottiePlayer from 'src/components/LottiePlayer';
import Modal from 'src/components/Modal';
import ModalExplainer from 'src/components/ModalExplainer';
import { DataGroup } from 'src/enums/data';
import { PillarScores } from 'src/enums/pillarScores';
import { PillarStates } from 'src/enums/pillarStates';
import { SIZES } from 'src/helpers/devices';
import { handleScoreColors } from 'src/helpers/exploreTabsColors';
import { ScoreProviders } from 'src/types/score';

import AnimationPath from '../../animation/explore/Motive-icon-explore-mobile.json';
import ModalExport from '../../components/ModalExport';
import Header from './components/Header';
import Help from './components/Help';
import PillarTabsModule from './components/PillarTabs';
import RatingCoverageModule from './components/RatingCoverage';
import RealScoreModule from './components/RealScore';
import TabsScoresModule from './components/TabsScores';
import './i18n';
import {
  ExploreWrapper,
  HeaderWrapper,
  RatingCoverageWrapper,
  RealScoreWrapper,
  ScoresWrapper,
  Subtitle,
  Title,
  GridWrapper,
  AnimationWrapper,
  ExplanationText,
} from './styles';
import { IExploreProps } from './types';

const Explore = (props: IExploreProps) => {
  const {
    industryRank,
    ratingCoverage,
    scores,
    userSettings,
    filteredData,
    pillarValues,
    selectedTab,
    onSetSelectedExploreTab,
    handleTrackEvent,
    onClickDontShowAgain,
    pillarsRank,
    data,
  } = props;
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [isOpenModalModalExplainer, setIsOpenModalModalExplainer] = useState(true);

  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.mediumTablet}px)`,
  });

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
    handleTrackEvent('Open Explore help');
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
    handleTrackEvent('Close Explore help');
  };

  const handleCloseModalExport = () => {
    setIsExportModalOpen(false);
  };

  const handleExportData = () => {
    handleTrackEvent('Export explore open');
    setIsExportModalOpen(true);
  };

  const handleCloseModalExplainer = () => {
    setIsOpenModalModalExplainer(false);
  };

  const handleTabClick = (tabName: string) => {
    handleTrackEvent(`Change to explore ${tabName} tab`);
    onSetSelectedExploreTab(tabName);
  };

  const handleIndustryRank = (pillarToFind: PillarScores) => {
    const pillar = pillarsRank.find((element) => element.pillar === pillarToFind);
    return `${pillar!.position} / ${pillar!.total}`;
  };

  const handleTabState = (pillarToFind: PillarScores) => {
    const pillar = pillarsRank.find((element) => element.pillar === pillarToFind);
    return pillar!.state as PillarStates;
  };

  return (
    <ExploreWrapper>
      <Modal open={isHelpOpen} onClose={handleCloseHelp} width={773}>
        <Help />
      </Modal>
      <ModalExport
        open={isExportModalOpen}
        title={i18next.t('EXPLORE:TITLE_MODAL_EXPORT')}
        onClose={handleCloseModalExport}
      />
      {!userSettings.dontShowModalExplore && (
        <ModalExplainer
          onClose={handleCloseModalExplainer}
          open={isOpenModalModalExplainer}
          message={i18next.t('EXPLORE:MESSAGE_MODAL_EXPLANIER')}
          onClickDontShowAgain={onClickDontShowAgain}
        />
      )}
      {isTablet && (
        <>
          <AnimationWrapper>
            <LottiePlayer width={500} animationJsonPath={AnimationPath} loop autoplay />
          </AnimationWrapper>
          <Title>{i18next.t('EXPLORE:MOBILE_TITLE')}</Title>
          <Subtitle>{i18next.t('EXPLORE:MOBILE_SUBTITLE')}</Subtitle>{' '}
        </>
      )}
      {!isTablet && scores && pillarsRank.length > 1 && (
        <>
          <HeaderWrapper>
            <Header
              backArrow={selectedTab !== ''}
              handleBackArrowClick={() => {
                onSetSelectedExploreTab('');
              }}
              handleExportData={handleExportData}
              handleOpenHelp={handleOpenHelp}
            />
          </HeaderWrapper>
          <GridWrapper displayRealScore={selectedTab === ''}>
            {selectedTab === '' && (
              <>
                <RealScoreWrapper>
                  <RealScoreModule industryRank={industryRank} realScore={scores.realScore} />
                </RealScoreWrapper>
                <RatingCoverageWrapper>
                  {ratingCoverage && (
                    <RatingCoverageModule
                      scores={ratingCoverage.filter(
                        (score) => score.provider !== ScoreProviders.CDP,
                      )}
                    />
                  )}
                </RatingCoverageWrapper>
              </>
            )}
            {selectedTab !== '' && (
              <ExplanationText>{i18next.t('EXPLORE:EXPLANATION_TEXT')}</ExplanationText>
            )}

            <ScoresWrapper>
              {!selectedTab ? (
                <PillarTabsModule
                  tabs={[
                    {
                      tabName: i18next.t('EXPLORE:ENVIRONMENT'),
                      icon: environmentIcon,
                      scoreValue: scores.environmentScore,
                      state: handleTabState(PillarScores.ENVIRONMENT),
                      industryRank: handleIndustryRank(PillarScores.ENVIRONMENT),
                      tabValue: DataGroup.ENVIRONMENT,
                    },
                    {
                      tabName: i18next.t('EXPLORE:SOCIAL'),
                      icon: socialIcon,
                      scoreValue: scores.socialScore,
                      state: handleTabState(PillarScores.SOCIAL),
                      industryRank: handleIndustryRank(PillarScores.SOCIAL),
                      tabValue: DataGroup.SOCIAL,
                    },
                    {
                      tabName: i18next.t('EXPLORE:GOVERNANCE'),
                      icon: governanceIcon,
                      scoreValue: scores.governanceScore,
                      state: handleTabState(PillarScores.GOVERNANCE),
                      industryRank: handleIndustryRank(PillarScores.GOVERNANCE),
                      tabValue: DataGroup.GOVERNANCE,
                    },
                  ]}
                  handleTabClick={handleTabClick}
                />
              ) : (
                <TabsScoresModule
                  data={data}
                  selectedTab={selectedTab}
                  filteredData={filteredData}
                  tabs={[
                    {
                      icon: environmentIcon,
                      scoreValue: scores.environmentScore,
                      tabValue: DataGroup.ENVIRONMENT,
                      tabName: i18next.t('EXPLORE:ENVIRONMENT'),
                      pillar: DataGroup.ENVIRONMENT,
                      scoreColors: handleScoreColors(handleTabState(PillarScores.ENVIRONMENT)),
                      state: handleTabState(PillarScores.ENVIRONMENT),
                    },
                    {
                      icon: socialIcon,
                      scoreValue: scores.socialScore,
                      tabValue: DataGroup.SOCIAL,
                      tabName: i18next.t('EXPLORE:SOCIAL'),
                      pillar: DataGroup.SOCIAL,
                      scoreColors: handleScoreColors(handleTabState(PillarScores.SOCIAL)),
                      state: handleTabState(PillarScores.SOCIAL),
                    },
                    {
                      icon: governanceIcon,
                      scoreValue: scores.governanceScore,
                      tabValue: DataGroup.GOVERNANCE,
                      tabName: i18next.t('EXPLORE:GOVERNANCE'),
                      pillar: DataGroup.GOVERNANCE,
                      scoreColors: handleScoreColors(handleTabState(PillarScores.GOVERNANCE)),
                      state: handleTabState(PillarScores.GOVERNANCE),
                    },
                  ]}
                  pillarValues={pillarValues}
                  handleTabClick={handleTabClick}
                  handleTrackEvent={handleTrackEvent}
                  positiveValues={[]}
                  negativeValues={[]}
                  totalValues={[]}
                />
              )}
            </ScoresWrapper>
          </GridWrapper>
        </>
      )}
    </ExploreWrapper>
  );
};

export default Explore;
