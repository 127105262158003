import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.septenary};
  border-radius: 15px;
`;

export const Title = styled.div`
  margin-top: 10px;
  font-size: 28px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const WrapperImage = styled.div`
  width: 100px;
  height: 100px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const WrapperText = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.common.grey};

  font-family: ${(props) => props.theme.fontFamilies.regular};
`;
