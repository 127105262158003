import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const isSendEmailSignedUpSelector = () =>
  createSelector(
    [(state: IReduxState) => state.user.isSendEmailSignedUp],
    (isSendEmailSignedUp) => isSendEmailSignedUp,
  );

export default isSendEmailSignedUpSelector;
