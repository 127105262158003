import i18next from 'i18next';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import ModalExplainer from 'src/components/ModalExplainer';
import ModuleHelp from 'src/components/ModuleHelp';
import SearchBarModule from 'src/components/SearchBarModule';
import { HelpModules } from 'src/enums/help';
import { PillarScores } from 'src/enums/pillarScores';
import { trackEvent } from 'src/helpers/analytics';
import { SIZES } from 'src/helpers/devices';
import { DefaultTheme } from 'src/styles/themes/default';

import PillarsScoresTable from '../../animation/helpModule/Module-Pillar-Score-Comparison.json';
import PillarsScoresGraphic from '../../animation/helpModule/Module-Pillar-Score.json';
import SearchBarHelp from '../../animation/helpModule/Module-Ranking.json';
import ModalExport from '../../components/ModalExport';
import PillarScoreTableModule from './components/PilarScoreTableModule';
import PillarScoreRankingModule from './components/PillarScoreRankingModule';
import RealScoreRankingModule from './components/RealScoreRankingModule';
import './i18n';
import {
  BenchmarkWrapper,
  ExportButton,
  HeaderWrapper,
  PillarScoresRankingWrapper,
  PillarScoresTableWrapper,
  RealScoreRankingWrapper,
  SearchBarWrapper,
  Subtitle,
  Title,
  WrapperButton,
  WrapperText,
} from './styles';
import { IBenchmarkProps } from './types';

const Benchmark = (props: IBenchmarkProps) => {
  const {
    user,
    userSettings,
    competitors,
    searchedCompetitors,
    helpModule,
    onHandleErrorMessage,
    onChangeSettings,
    onAddCompetitor,
    onRemoveCompetitor,
    onSearchCompetitor,
    onCloseHelp,
    onNextHelp,
    onBackHelp,
    onClickDontShowAgain,
  } = props;
  const [isOpenModalExport, setIsOpenModalExport] = useState(false);
  const [isOpenModalExplainer, setIsOpenModalExplainer] = useState(true);

  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.desktop}px)`,
  });

  const onChangePillarScoresTable = (newFilteredPillars: PillarScores[]) => {
    onChangeSettings({ ...userSettings, filteredPillarScores: newFilteredPillars });
  };

  const onChangePillarScoresRank = (pillarScore: PillarScores) => {
    onChangeSettings({ ...userSettings, pillarScoreRank: pillarScore });
  };

  const handleExportData = () => {
    trackEvent('Export benchmark open', {
      user: {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      },
      company: user.company.name,
    });
    setIsOpenModalExport(true);
  };

  const handleCloseModalExport = () => {
    setIsOpenModalExport(false);
  };

  const handleCloseModalExplainer = () => {
    setIsOpenModalExplainer(false);
  };

  return (
    <BenchmarkWrapper>
      <ModalExport
        open={isOpenModalExport}
        title={i18next.t('BENCHMARK:TITLE_MODAL_EXPORT')}
        onClose={handleCloseModalExport}
      />
      {!userSettings.dontShowModalBenchmark && (
        <ModalExplainer
          onClose={handleCloseModalExplainer}
          open={isOpenModalExplainer}
          message={i18next.t('BENCHMARK:MESSAGE_MODAL_EXPLANIER')}
          onClickDontShowAgain={onClickDontShowAgain}
        />
      )}
      <HeaderWrapper>
        <WrapperText>
          <Title>{i18next.t('BENCHMARK:TITLE')}</Title>
          <Subtitle>{i18next.t('BENCHMARK:SUBTITLE')}</Subtitle>
        </WrapperText>
        <WrapperButton>
          <ExportButton onClick={handleExportData}>
            {i18next.t('BENCHMARK:BUTTON_EXPORT')}{' '}
          </ExportButton>
        </WrapperButton>
      </HeaderWrapper>
      <SearchBarWrapper>
        <ModuleHelp
          isFirst
          onClose={() => onCloseHelp(HelpModules.BENCHMARK_SEARCH)}
          onNext={() => onNextHelp(HelpModules.BENCHMARK_SEARCH)}
          onBack={() => onBackHelp(HelpModules.BENCHMARK_SEARCH)}
          enabled={helpModule === HelpModules.BENCHMARK_SEARCH}
          animationPath={SearchBarHelp}
          isAnimation={true}
          arrow={false}
          tooltipPosition="bottom"
          backgroundColor={DefaultTheme.palette.common.white}
          title={i18next.t('BENCHMARK_HELP_SEARCH:TITLE')}
          description={i18next.t('BENCHMARK_HELP_SEARCH:DESCRIPTION')}
        >
          <SearchBarModule
            currentCompetitors={competitors || []}
            searchCompetitors={searchedCompetitors}
            onAddCompetitor={onAddCompetitor}
            onRemoveCompetitor={onRemoveCompetitor}
            onSearchCompetitor={onSearchCompetitor}
          />
        </ModuleHelp>
      </SearchBarWrapper>
      <RealScoreRankingWrapper>
        <ModuleHelp
          onClose={() => onCloseHelp(HelpModules.BENCHMARK_REALSCORE)}
          onNext={() => onNextHelp(HelpModules.BENCHMARK_REALSCORE)}
          onBack={() => onBackHelp(HelpModules.BENCHMARK_REALSCORE)}
          enabled={helpModule === HelpModules.BENCHMARK_REALSCORE}
          tooltipPosition="left"
          backgroundColor={DefaultTheme.palette.common.white}
          title={i18next.t('BENCHMARK_REALSCORE:TITLE')}
          description={i18next.t('BENCHMARK_REALSCORE:DESCRIPTION')}
        >
          <RealScoreRankingModule userCompany={user.company} competitors={competitors} />
        </ModuleHelp>
      </RealScoreRankingWrapper>
      <PillarScoresRankingWrapper>
        <ModuleHelp
          onClose={() => onCloseHelp(HelpModules.BENCHMARK_PILLAR_SCORE)}
          onNext={() => onNextHelp(HelpModules.BENCHMARK_PILLAR_SCORE)}
          onBack={() => onBackHelp(HelpModules.BENCHMARK_PILLAR_SCORE)}
          enabled={helpModule === HelpModules.BENCHMARK_PILLAR_SCORE}
          animationPath={PillarsScoresGraphic}
          isAnimation={true}
          tooltipPosition="right"
          backgroundColor={DefaultTheme.palette.common.white}
          title={i18next.t('BENCHMARK_PILLAR_SCORE:TITLE')}
          description={i18next.t('BENCHMARK_PILLAR_SCORE:DESCRIPTION')}
        >
          <PillarScoreRankingModule
            userCompany={user.company}
            competitors={competitors}
            selectPillarScore={userSettings.pillarScoreRank}
            onPillarScoreChange={onChangePillarScoresRank}
          />
        </ModuleHelp>
      </PillarScoresRankingWrapper>
      <PillarScoresTableWrapper>
        <ModuleHelp
          isLast
          onClose={() => onCloseHelp(HelpModules.BENCHMARK_PILLAR_SCORE_TABLE)}
          onNext={() => onNextHelp(HelpModules.BENCHMARK_PILLAR_SCORE_TABLE)}
          onBack={() => onBackHelp(HelpModules.BENCHMARK_PILLAR_SCORE_TABLE)}
          enabled={helpModule === HelpModules.BENCHMARK_PILLAR_SCORE_TABLE}
          animationPath={PillarsScoresTable}
          isAnimation={true}
          tooltipPosition="top"
          arrow={false}
          backgroundColor={DefaultTheme.palette.common.white}
          title={i18next.t('BENCHMARK_PILLAR_SCORE_TABLE:TITLE')}
          description={i18next.t('BENCHMARK_PILLAR_SCORE_TABLE:DESCRIPTION')}
        >
          <PillarScoreTableModule
            userCompany={user.company}
            pillarScore={userSettings.filteredPillarScores}
            competitors={competitors}
            onSetPillarScore={onChangePillarScoresTable}
            isTablet={isTablet}
            handleErrorMessage={onHandleErrorMessage}
          />
        </ModuleHelp>
      </PillarScoresTableWrapper>
    </BenchmarkWrapper>
  );
};

export default Benchmark;
