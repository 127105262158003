import { PillarScores } from 'src/enums/pillarScores';

import { ICompany } from './company';

export default interface IUserSettings {
  filteredPillarScores: PillarScores[];
  pillarScoreRank: PillarScores;
  benchmarkCompetitors: ICompany[];
  salesCompetitors: ICompany[];
  dontShowModalExplore: boolean;
  dontShowModalBenchmark: boolean;
}

export const DEFAULT_USER_SETTINGS: IUserSettings = {
  filteredPillarScores: [],
  pillarScoreRank: PillarScores.ENVIRONMENT,
  benchmarkCompetitors: [],
  salesCompetitors: [],
  dontShowModalExplore: true,
  dontShowModalBenchmark: true,
};
