import i18next from 'i18next';
import React from 'react';

import './i18n';
import {
  ContentWrapper,
  Wrapper,
  Score,
  ScoreLabel,
  ScoreNumber,
  ScoresWrapper,
  Border,
} from './styles';
import { IRealScoreModuleProps } from './types';

const RealScoreModule = (props: IRealScoreModuleProps) => {
  const { industryRank, realScore } = props;

  return (
    <Wrapper>
      <ContentWrapper>
        <ScoresWrapper>
          <Score>
            <ScoreLabel data-testid="label-realscore-module-explore-page">
              {i18next.t(`REAL_SCORE_EXPLORE_MODULE:REAL_SCORE`)}
            </ScoreLabel>
            <ScoreNumber data-testid="realscore-module-explore-page">
              {realScore?.toFixed(0)}
            </ScoreNumber>
          </Score>
          <Border />
          <Score>
            <ScoreLabel data-testid="label-realscore-module-rating-coverage-explore-page">
              {i18next.t(`REAL_SCORE_EXPLORE_MODULE:INDUSTRY_RANK`)}
            </ScoreLabel>
            <ScoreNumber data-testid="realscore-module-rating-coverage-explore-page">
              {industryRank.position}/{industryRank.total}
            </ScoreNumber>
          </Score>
        </ScoresWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default RealScoreModule;
