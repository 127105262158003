import { Skeleton } from '@mui/material';
import styled from 'styled-components';

export const NewsListWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 25px 20px 0 20px;
  box-sizing: border-box;
`;

export const Title = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-size: 28px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const LoadingWrapper = styled.div`
  padding-bottom: 20px;
`;

export const LoadingSkeleton = styled(Skeleton)`
  transform: none !important;
  margin-top: 20px !important;
`;

export const WrapperViewAll = styled.p`
  color: ${(props) => props.theme.palette.tertiary};
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.lightSecondary};
  }
`;

export const WrapperHeader = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;
