import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as companyActions from 'src/redux/actions/company';
import * as userActions from 'src/redux/actions/user';
import companyCompetitorsSelector from 'src/redux/selectors/company/competitors';
import searchedCompaniesSelector from 'src/redux/selectors/company/searchedCompanies';
import helpModuleSelector from 'src/redux/selectors/user/helpModule';
import settingsSelector from 'src/redux/selectors/user/settings';
import userDataSelector from 'src/redux/selectors/user/userData';

import { CompetitorsType } from 'src/enums/competitorsType';
import { HelpModules } from 'src/enums/help';
import { MenuRoutes } from 'src/enums/routes';
import { useTypedSelector } from 'src/hooks/typeSelector';
import { ICompany } from 'src/types/company';
import IUserSettings from 'src/types/userSettings';
import Benchmark from 'src/views/Benchmark';

import LayoutContainer from './Layout';

const BenchmarkContainer = () => {
  const dispatch = useDispatch();
  const user = useTypedSelector(userDataSelector());
  const userSettings = useTypedSelector(settingsSelector());
  const competitors = useTypedSelector(companyCompetitorsSelector(CompetitorsType.BENCHMARK));
  const searchedCompetitors = useTypedSelector(
    searchedCompaniesSelector(CompetitorsType.BENCHMARK),
  );
  const helpModule = useTypedSelector(helpModuleSelector());

  const onNextHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeNext(MenuRoutes.BENCHMARK, currentModule));
  };

  const onBackHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeBack(MenuRoutes.BENCHMARK, currentModule));
  };

  const onCloseHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeClose(MenuRoutes.BENCHMARK, currentModule));
  };

  const onAddCompetitor = (company: ICompany) => {
    if (competitors!.length < 5) {
      const newUserSettings = { ...userSettings };
      newUserSettings.benchmarkCompetitors.push(company);
      dispatch(userActions.onChangeSettings(newUserSettings));
      dispatch(companyActions.onAddCompetitorRequested(company, CompetitorsType.BENCHMARK));
    } else {
      dispatch(
        companyActions.onAddCompetitorFailed(
          'You can only add up to 5 companies. Please remove one in order to add a new one',
        ),
      );
    }
  };
  const onHandleErrorMessage = (message: string) => {
    dispatch(userActions.handleErrorUserMessage(message));
  };

  const onRemoveCompetitor = (company: ICompany) => {
    const newUserSetting = {
      ...userSettings,
      benchmarkCompetitors: userSettings.benchmarkCompetitors.filter(
        (element: ICompany) => element.id !== company.id,
      ),
    };
    dispatch(userActions.onChangeSettings(newUserSetting));
    dispatch(companyActions.onRemoveCompetitorRequested(company, CompetitorsType.BENCHMARK));
  };

  const onSearchCompetitor = (search: string) => {
    dispatch(companyActions.onSearchCompanyRequested(search));
  };

  const onChangeSettings = (settings: IUserSettings) => {
    dispatch(userActions.onChangeSettings(settings));
  };

  const onInitializeBenchmarkView = useCallback(() => {
    dispatch(userActions.initializeBenchmarkView());
  }, [dispatch]);

  const onClickDontShowAgain = (isChecked: boolean) => {
    dispatch(userActions.onChangeSettings({ ...userSettings, dontShowModalBenchmark: isChecked }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeBenchmarkView();
  }, [onInitializeBenchmarkView]);

  return (
    <LayoutContainer>
      <Benchmark
        user={user!}
        competitors={competitors as ICompany[]}
        searchedCompetitors={searchedCompetitors}
        userSettings={userSettings}
        helpModule={helpModule}
        onCloseHelp={onCloseHelp}
        onNextHelp={onNextHelp}
        onBackHelp={onBackHelp}
        onSearchCompetitor={onSearchCompetitor}
        onAddCompetitor={onAddCompetitor}
        onRemoveCompetitor={onRemoveCompetitor}
        onChangeSettings={onChangeSettings}
        onHandleErrorMessage={onHandleErrorMessage}
        onClickDontShowAgain={onClickDontShowAgain}
      />
    </LayoutContainer>
  );
};
export default BenchmarkContainer;
