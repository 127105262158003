import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { SIZES } from 'src/helpers/devices';

import PillarDetails from '../pillarDetails';
import '../pillarDetails/i18n';
import {
  IconScore,
  Tab,
  TabList,
  WrapperIconScore,
  WrapperText,
  Text,
  ValueScore,
  WrapperTabContent,
} from './styles';
import { ITabsScoresModuleProps } from './types';

const TabsScoresModule = (props: ITabsScoresModuleProps) => {
  const { data, filteredData, selectedTab, handleTabClick, handleTrackEvent, pillarValues, tabs } =
    props;

  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.bigTablet}px)`,
  });

  return (
    <>
      <TabList>
        {tabs.map((element) => {
          return (
            <Tab
              onClick={() => {
                handleTabClick(element.tabValue);
              }}
              isSelected={selectedTab === element.tabValue}
              color={
                tabs.find((state) => state.pillar === element.tabValue)!.scoreColors.firstColor
              }
            >
              <WrapperTabContent>
                <WrapperIconScore>
                  <IconScore src={element.icon} />
                </WrapperIconScore>
                <WrapperText isSelected={selectedTab === element.tabValue}>
                  {isTablet && selectedTab === element.tabValue && <Text>{element.tabName}</Text>}
                  {!isTablet && <Text>{element.tabName}</Text>}
                  {selectedTab === element.tabValue && (
                    <ValueScore>{element.scoreValue}</ValueScore>
                  )}
                </WrapperText>
              </WrapperTabContent>
            </Tab>
          );
        })}
      </TabList>
      <PillarDetails
        data={data}
        tabs={tabs}
        filteredData={filteredData}
        selectedTab={selectedTab}
        pillarValues={pillarValues}
        isTablet={isTablet}
        handleTrackEvent={handleTrackEvent}
      />
    </>
  );
};
export default TabsScoresModule;
