import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'MATERIALITY_DETAILS', {
  EXPLANAITION_true:
    'Your company performs better than most others in your industry across this sustainability pillar. As this pillar is important to your client, you should bring as much focus as possible to this pillar.',
  EXPLANAITION_false:
    'Your company performs worse than most others in your industry across this sustainability pillar. As this pillar is important to your client, you should avoid this discussion, if possible.',
  INSIGHTS_SUBTITLE: 'on the total number of companies in your industry',
  INSIGHTS_TITLE: 'Insights',
  TITLE: 'Company data Pillars',
});
