import React from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import contactUsSelector from 'src/redux/selectors/user/contactUs';
import userDataSelector from 'src/redux/selectors/user/userData';

import { useTypedSelector } from 'src/hooks/typeSelector';
import ContactUsForm from 'src/views/ContactUsForm';

const ContactUsContainer = () => {
  const dispatch = useDispatch();
  const user = useTypedSelector(userDataSelector());
  const contactUs = useTypedSelector(contactUsSelector());

  const handleCloseContactUs = () => {
    dispatch(userActions.onClickCloseContactUs(false));
  };

  const handleSubmitContactUs = (reasonContactUs: string, message: string) => {
    dispatch(
      userActions.onSendContactUsRequested(
        user!.email,
        user!.company.name,
        reasonContactUs,
        message,
      ),
    );
  };

  if (!user || !contactUs) return null;

  return (
    <>
      {contactUs.open && (
        <ContactUsForm
          onClose={handleCloseContactUs}
          user={user}
          onSubmit={handleSubmitContactUs}
          isLoading={contactUs.loading}
          isEmailSend={contactUs.success}
        />
      )}
    </>
  );
};
export default ContactUsContainer;
