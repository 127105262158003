import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

import { SIZES } from 'src/helpers/devices';

const ScoreProviderLogoStyles = css`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 100px;
`;

export const HelpWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    overflow: auto;
    max-height: 600px;
  }

  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => props.theme.palette.septenary};
  border-radius: 20px;
`;

export const Title = styled.p`
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTitle = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 90%;
    height: 50px;
    top: 0;
    display: flex;
    align-items: flex-end;
    position: fixed;
    z-index: 1;
    background-color: ${(props) => props.theme.palette.septenary};
  }
`;

export const CardsWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-top: 40px;
  }

  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 20px;
`;

export const ScoreProviderWrapper = styled.a`
  @media (max-width: ${SIZES.mediumTablet}px) {
    height: 100px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: ${SIZES.bigPhone}px) {
    height: 50px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  position: relative;
`;

export const IconWrapper = styled(ReactSVG)`
  ${ScoreProviderLogoStyles}
  div {
    ${ScoreProviderLogoStyles}
    svg {
      ${ScoreProviderLogoStyles}
    }
  }
`;

export const ScoreProviderDescription = styled.p`
  width: 100%;
  cursor: pointer;
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 15px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 10px;
  ${ScoreProviderWrapper}:hover & {
    padding: 20px;
    background-color: ${(props) => props.theme.palette.quinternary};
    align-items: baseline;
    justify-content: flex-start;
  }
`;

export const WrapperScoreProviderDescription = styled.div`
  width: 100%;
  opacity: 0;
  display: flex;
  height: 0;
  text-align: center;
  position: absolute;
  bottom: 40px;
  ${ScoreProviderWrapper}:hover & {
    bottom: 20px;
    height: 30px;
    opacity: 1;
    transition: 0.3s ease;
  }
`;
