import i18next from 'i18next';
import React, { useState } from 'react';

import greendropdown from 'src/assets/explore/greendropdown.svg';
import orangedropdown from 'src/assets/explore/orangedropdown.svg';
import reddropdown from 'src/assets/explore/reddropdown.svg';
import selecteddropdown from 'src/assets/explore/selecteddropdown.svg';
import BooleanGraphic from 'src/components/Charts/BooleanGraphic';
import LinearChart from 'src/components/Charts/LinearChart';
import { PillarStates } from 'src/enums/pillarStates';
import { formatNumber, isNumber, isPercentage } from 'src/helpers/data';
import {
  handleScoreColors,
  NegativeTab,
  NeutralTab,
  PositiveTab,
} from 'src/helpers/exploreTabsColors';
import { filterNotTrackedPillarValues, parseValueAccordingToType } from 'src/helpers/pillarsScores';
import { IGroupPillarValues } from 'src/helpers/types/data';

import { IFilteredData } from '../TabsScores/types';
import {
  InfoDropDown,
  DropDown,
  PillarWrapper,
  DetailsWrapper,
  TextWrapper,
  GraphicWrapper,
  WrapperData,
  LabelData,
  ValueData,
  WrapperContent,
  LoadingIndicator,
  LoadingWrapper,
  WrapperTable,
  PillarDescription,
  PillarDescriptionWrapper,
} from './styles';
import { IPillarDetails } from './types';

const PillarDetails = (props: IPillarDetails) => {
  const { data, filteredData, tabs, selectedTab, pillarValues, isTablet, handleTrackEvent } = props;
  const [pillarToShow, setPillarToShow] = useState('');
  let positiveValues = [];
  let negativeValues = [];
  let totalValues: any[] = [];

  const handleDropDownIcon = (values: any, id: string) => {
    const selectedPillar = values.data.find((value: any) => value.dataItem!.identifier === id);

    if (pillarToShow === selectedPillar.dataItem!.identifier) {
      return <InfoDropDown src={selecteddropdown} />;
    }
    switch (tabs.find((tab) => tab.tabValue === selectedTab)!.state) {
      case PillarStates.NEUTRAL:
        return <InfoDropDown src={orangedropdown} />;
      case PillarStates.NEGATIVE:
        return <InfoDropDown src={reddropdown} />;
      default:
        return <InfoDropDown src={greendropdown} />;
    }
  };

  const loading =
    data.filter((element: any) => element.group === selectedTab).length === 0 ||
    filteredData.length === 0;

  const handleDropDown = (value: string) => {
    if (pillarToShow === value) {
      handleTrackEvent(
        `Close explore ${i18next.t(`TABS_SCORE_EXPLORE_MODULE:${value}`)} pillar details`,
      );
      return setPillarToShow('');
    } else {
      handleTrackEvent(
        `Change to explore ${i18next.t(`TABS_SCORE_EXPLORE_MODULE:${value}`)} pillar details`,
      );
    }
    setPillarToShow(value);
  };

  const getPillarPercentages = (pillars: any[]) => {
    negativeValues = [];
    positiveValues = [];
    pillars.forEach((value) => {
      if (value) {
        if (value === 'Yes') {
          positiveValues.push(value);
        } else if (value === 'No') {
          negativeValues.push(value);
        }
      }
    });

    const percentages = {
      positivePercentage: Math.round((positiveValues.length * 100) / pillars.length),
      negativePercentage: Math.round((negativeValues.length * 100) / pillars.length),
    };

    return percentages;
  };

  const getIndustryPillars = (group: string, id: string) => {
    totalValues = [];
    let pillarData: any[] = [];
    pillarValues.forEach((pillar: IGroupPillarValues) => {
      if (pillar.group === group && pillar.data) {
        pillarData.push(pillar.data);
      }
    });

    pillarData.forEach((data) =>
      data?.forEach((value: any) => {
        if (value.pillarCode === id) {
          value.values.forEach((score: any) => {
            if (filterNotTrackedPillarValues(score)) {
              totalValues.push(score);
            }
          });
        }
      }),
    );

    return totalValues;
  };

  const handlePillarIdentifierColor = (state: PillarStates, id: string) => {
    if (pillarToShow === id) {
      switch (state) {
        case PillarStates.NEGATIVE:
          return NegativeTab.firstRed;
        case PillarStates.POSITIVE:
          return PositiveTab.firstGreen;
        case PillarStates.NEUTRAL:
          return NeutralTab.firstOrange;
        default:
          return '';
      }
    } else {
      return '#2C445C';
    }
  };

  return (
    <WrapperTable>
      {loading && (
        <LoadingWrapper data-testid={`loading-tabsScores-explore-page`}>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {!loading &&
        filteredData.map((dataset: IFilteredData) => {
          return (
            <WrapperContent scoreColors={handleScoreColors(dataset.state)}>
              {dataset.data.map((values: any, index: number) => {
                return (
                  <>
                    <WrapperData>
                      <PillarWrapper>
                        <TextWrapper>
                          <LabelData
                            color={handlePillarIdentifierColor(
                              dataset.state,
                              values.dataItem!.identifier,
                            )}
                            data-testid={`label-tab-selected-tabsscores-module-explore-page-${
                              values.dataItem!.identifier
                            }`}
                          >
                            {i18next.t(`TABS_SCORE_EXPLORE_MODULE:${values.dataItem!.identifier}`)}
                          </LabelData>
                          <ValueData
                            color={handlePillarIdentifierColor(
                              dataset.state,
                              values.dataItem!.identifier,
                            )}
                            data-testid={`value-tab-selected-tabsscores-module-explore-page-${
                              values.dataItem!.identifier
                            }`}
                          >
                            {parseValueAccordingToType(values.dataItem!)}
                          </ValueData>
                        </TextWrapper>
                        <DropDown
                          onClick={() => {
                            handleDropDown(values.dataItem!.identifier);
                          }}
                        >
                          {handleDropDownIcon(dataset, values.dataItem!.identifier)}
                        </DropDown>
                      </PillarWrapper>
                    </WrapperData>
                    {isTablet && (
                      <DetailsWrapper
                        scoreColors={handleScoreColors(dataset.state)}
                        display={
                          pillarToShow !== '' && values.dataItem!.identifier === pillarToShow
                        }
                      >
                        <PillarDescriptionWrapper>
                          <PillarDescription>
                            {i18next.t(
                              `TABS_SCORE_EXPLORE_PILLAR_DETAILS:DESCRIPTION_${pillarToShow}`,
                            )}
                          </PillarDescription>
                        </PillarDescriptionWrapper>
                        {isPercentage(values.dataItem!.value) && (
                          <GraphicWrapper>
                            <LinearChart
                              valueRange={{
                                max: formatNumber(
                                  Math.round(values.maxValue.slice(0, -1)).toString(),
                                ),
                                min: formatNumber(
                                  Math.round(values.minValue.slice(0, -1)).toString(),
                                ),
                              }}
                              scoreValue={formatNumber(
                                Math.round(values.dataItem!.value.slice(0, -1)).toString(),
                              )}
                              unitValue={'%'}
                              state={values.state}
                            />
                          </GraphicWrapper>
                        )}
                        {isNumber(values.dataItem!.value) && (
                          <GraphicWrapper>
                            <LinearChart
                              valueRange={{
                                max: Math.round(values.maxValue).toString(),
                                min: Math.round(values.minValue).toString(),
                              }}
                              scoreValue={Math.round(values.dataItem!.value).toString()}
                              unitValue={values.dataItem!.unit}
                              state={values.state}
                            />
                          </GraphicWrapper>
                        )}
                        {(values.dataItem!.value === 'No' || values.dataItem!.value === 'Yes') && (
                          <GraphicWrapper>
                            <BooleanGraphic
                              value={values.dataItem!.value}
                              percentages={getPillarPercentages(
                                getIndustryPillars(
                                  values.dataItem!.group,
                                  values.dataItem!.identifier,
                                ),
                              )}
                            />
                          </GraphicWrapper>
                        )}
                      </DetailsWrapper>
                    )}

                    {index % 2 !== 0 && !isTablet && (
                      <>
                        <DetailsWrapper
                          scoreColors={handleScoreColors(dataset.state)}
                          display={
                            pillarToShow !== '' && values.dataItem!.identifier === pillarToShow
                          }
                        >
                          <PillarDescriptionWrapper>
                            <PillarDescription>
                              {i18next.t(
                                `TABS_SCORE_EXPLORE_PILLAR_DETAILS:DESCRIPTION_${pillarToShow}`,
                              )}
                            </PillarDescription>
                          </PillarDescriptionWrapper>
                          {isPercentage(values.dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: formatNumber(
                                    Math.round(values.maxValue.slice(0, -1)).toString(),
                                  ),
                                  min: formatNumber(
                                    Math.round(values.minValue.slice(0, -1)).toString(),
                                  ),
                                }}
                                scoreValue={formatNumber(
                                  Math.round(values.dataItem!.value.slice(0, -1)).toString(),
                                )}
                                unitValue={'%'}
                                state={values.state}
                              />
                            </GraphicWrapper>
                          )}
                          {isNumber(values.dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: Math.round(values.maxValue).toString(),
                                  min: Math.round(values.minValue).toString(),
                                }}
                                scoreValue={Math.round(values.dataItem!.value).toString()}
                                unitValue={values.dataItem!.unit}
                                state={values.state}
                              />
                            </GraphicWrapper>
                          )}
                          {(values.dataItem!.value === 'No' ||
                            values.dataItem!.value === 'Yes') && (
                            <GraphicWrapper>
                              <BooleanGraphic
                                value={values.dataItem!.value}
                                percentages={getPillarPercentages(
                                  getIndustryPillars(
                                    values.dataItem!.group,
                                    values.dataItem!.identifier,
                                  ),
                                )}
                              />
                            </GraphicWrapper>
                          )}
                        </DetailsWrapper>
                        <DetailsWrapper
                          scoreColors={handleScoreColors(dataset.state)}
                          display={
                            pillarToShow !== '' &&
                            dataset.data[index - 1].dataItem.identifier === pillarToShow
                          }
                        >
                          <PillarDescriptionWrapper>
                            <PillarDescription>
                              {i18next.t(
                                `TABS_SCORE_EXPLORE_PILLAR_DETAILS:DESCRIPTION_${pillarToShow}`,
                              )}
                            </PillarDescription>
                          </PillarDescriptionWrapper>
                          {isPercentage(dataset.data[index - 1].dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: formatNumber(
                                    Math.round(
                                      dataset.data[index - 1].maxValue.slice(0, -1),
                                    ).toString(),
                                  ),
                                  min: formatNumber(
                                    Math.round(
                                      dataset.data[index - 1].minValue.slice(0, -1),
                                    ).toString(),
                                  ),
                                }}
                                scoreValue={formatNumber(
                                  Math.round(
                                    dataset.data[index - 1].dataItem!.value.slice(0, -1),
                                  ).toString(),
                                )}
                                unitValue={'%'}
                                state={dataset.data[index - 1].state}
                              />
                            </GraphicWrapper>
                          )}
                          {isNumber(dataset.data[index - 1].dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: Math.round(dataset.data[index - 1].maxValue).toString(),
                                  min: Math.round(dataset.data[index - 1].minValue).toString(),
                                }}
                                scoreValue={Math.round(
                                  dataset.data[index - 1].dataItem!.value,
                                ).toString()}
                                unitValue={dataset.data[index - 1].dataItem!.unit}
                                state={dataset.data[index - 1].state}
                              />
                            </GraphicWrapper>
                          )}
                          {(dataset.data[index - 1].dataItem!.value === 'No' ||
                            dataset.data[index - 1].dataItem!.value === 'Yes') && (
                            <GraphicWrapper>
                              <BooleanGraphic
                                value={dataset.data[index - 1].dataItem!.value}
                                percentages={getPillarPercentages(
                                  getIndustryPillars(
                                    dataset.data[index - 1].dataItem!.group,
                                    dataset.data[index - 1].dataItem!.identifier,
                                  ),
                                )}
                              />
                            </GraphicWrapper>
                          )}
                        </DetailsWrapper>
                      </>
                    )}
                    {dataset.data.length === index + 1 &&
                      dataset.data.length % 2 !== 0 &&
                      !isTablet && (
                        <DetailsWrapper
                          scoreColors={handleScoreColors(dataset.state)}
                          display={
                            pillarToShow !== '' && values.dataItem!.identifier === pillarToShow
                          }
                        >
                          <PillarDescriptionWrapper>
                            <PillarDescription>
                              {i18next.t(
                                `TABS_SCORE_EXPLORE_PILLAR_DETAILS:DESCRIPTION_${pillarToShow}`,
                              )}
                            </PillarDescription>
                          </PillarDescriptionWrapper>
                          {isPercentage(values.dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: formatNumber(
                                    Math.round(values.maxValue.slice(0, -1)).toString(),
                                  ),
                                  min: formatNumber(
                                    Math.round(values.minValue.slice(0, -1)).toString(),
                                  ),
                                }}
                                scoreValue={formatNumber(
                                  Math.round(values.dataItem!.value.slice(0, -1)).toString(),
                                )}
                                unitValue={'%'}
                                state={values.state}
                              />
                            </GraphicWrapper>
                          )}
                          {isNumber(values.dataItem!.value) && (
                            <GraphicWrapper>
                              <LinearChart
                                valueRange={{
                                  max: Math.round(values.maxValue).toString(),
                                  min: Math.round(values.minValue).toString(),
                                }}
                                scoreValue={Math.round(values.dataItem!.value).toString()}
                                unitValue={values.dataItem!.unit}
                                state={values.state}
                              />
                            </GraphicWrapper>
                          )}
                          {(values.dataItem!.value === 'No' ||
                            values.dataItem!.value === 'Yes') && (
                            <GraphicWrapper>
                              <BooleanGraphic
                                value={values.dataItem!.value}
                                percentages={getPillarPercentages(
                                  getIndustryPillars(
                                    values.dataItem!.group,
                                    values.dataItem!.identifier,
                                  ),
                                )}
                              />
                            </GraphicWrapper>
                          )}
                        </DetailsWrapper>
                      )}
                  </>
                );
              })}
            </WrapperContent>
          );
        })}
    </WrapperTable>
  );
};
export default PillarDetails;
