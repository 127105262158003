import { MenuRoutes } from './routes';

export enum HelpModules {
  SUMMARY_REAL_SCORE = 'Real Score',
  SUMMARY_RATING_COVERAGE = 'Rating Coverage',
  SUMMARY_INSIGHTS = 'Insights',
  SUMMARY_NEWS = 'News',
  BENCHMARK_SEARCH = 'Search Bar',
  BENCHMARK_PILLAR_SCORE = 'Pillars Score',
  BENCHMARK_REALSCORE = 'Total RealScore',
  BENCHMARK_PILLAR_SCORE_TABLE = 'Pillars Score Table',
  SALES_MATERIALITY_RANKING = 'Materiality Ranking',
  SALES_MATERIALITY_PERFORMANCE = 'Materiality Performance',
  SALES_HOW_COMPETITOR_PERFORM = 'Competitive Benchmarking',
}

export const HelpWalktrough = [
  {
    route: MenuRoutes.SUMMARY,
    walktrough: [
      HelpModules.SUMMARY_REAL_SCORE,
      HelpModules.SUMMARY_RATING_COVERAGE,
      HelpModules.SUMMARY_INSIGHTS,
      HelpModules.SUMMARY_NEWS,
    ],
  },
  {
    route: MenuRoutes.BENCHMARK,
    walktrough: [
      HelpModules.BENCHMARK_SEARCH,
      HelpModules.BENCHMARK_REALSCORE,
      HelpModules.BENCHMARK_PILLAR_SCORE,
      HelpModules.BENCHMARK_PILLAR_SCORE_TABLE,
    ],
  },
  {
    route: MenuRoutes.SALES,
    walktrough: [
      HelpModules.SALES_MATERIALITY_RANKING,
      HelpModules.SALES_MATERIALITY_PERFORMANCE,
      HelpModules.SALES_HOW_COMPETITOR_PERFORM,
    ],
  },
];

export const hasHelpWalktrough = (route: string) => {
  return HelpWalktrough.map((module) => module.route.toString()).includes(route);
};
