import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { Materialities, MaterialityType } from 'src/enums/pillarScores';

import '../MaterialityRankingModule/i18n';
import MaterialityDetails from './components/MaterialityDetails';
import './i18n';
import {
  Wrapper,
  TabsWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  LoadingWrapper,
  LoadingIndicator,
  MaterialityName,
  Materiality,
  MaterialityPerformance,
  ContentWrapper,
  ExplanaitionWrapper,
  Explanaition,
} from './styles';
import { IMaterialityPerformanceModuleProps } from './types';

const MaterialityPerformanceModule = (props: IMaterialityPerformanceModuleProps) => {
  const { allMaterialities, positiveMaterialities, userCompany, industryScores, handleTabChange } =
    props;
  const [selectedMateriality, setSelectedMateriality] = useState<Materialities | null>(null);

  const loadingModule = !allMaterialities || !industryScores;

  const handleTabClick = (materiality: Materialities) => {
    setSelectedMateriality(materiality);
    handleTabChange(materiality);
  };

  useEffect(() => {
    setSelectedMateriality(allMaterialities ? allMaterialities[0] : null);
  }, [allMaterialities]);
  return (
    <Wrapper>
      {loadingModule && !positiveMaterialities && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      {!loadingModule && positiveMaterialities && (
        <>
          <TitleWrapper>
            <Title>
              {i18next.t('MATERIALITY_PERFORMANCE_MODULE:TITLE', { company: userCompany.name })}
            </Title>
            <Subtitle> {i18next.t('MATERIALITY_PERFORMANCE_MODULE:SUBTITLE')} </Subtitle>
          </TitleWrapper>
          <ContentWrapper>
            <TabsWrapper>
              {allMaterialities.map((materiality) => {
                const isPositive = positiveMaterialities.includes(materiality);
                return (
                  <Materiality
                    materialityType={
                      isPositive ? MaterialityType.POSITIVE : MaterialityType.NEGATIVE
                    }
                    isSelected={selectedMateriality === materiality}
                    onClick={() => handleTabClick(materiality)}
                  >
                    <MaterialityName isUserCompanyHaveScores={!userCompany.lastUpdatedScore}>
                      {i18next.t(`SALES_MATERIALITY:${materiality}`)}
                    </MaterialityName>
                    <MaterialityPerformance>
                      {i18next.t(`MATERIALITY_PERFORMANCE_MODULE:POSITIVE_${isPositive}`)}
                    </MaterialityPerformance>
                  </Materiality>
                );
              })}
            </TabsWrapper>
            {selectedMateriality && (
              <MaterialityDetails
                industryScores={industryScores}
                companyScore={userCompany.lastUpdatedScore!}
                materiality={selectedMateriality}
                materialityType={
                  positiveMaterialities.includes(selectedMateriality)
                    ? MaterialityType.POSITIVE
                    : MaterialityType.NEGATIVE
                }
              />
            )}
          </ContentWrapper>
        </>
      )}
      {!loadingModule && !userCompany.lastUpdatedScore && (
        <>
          <TitleWrapper>
            <Title>{i18next.t('MATERIALITY_PERFORMANCE_MODULE:TITLE_WITHOUT_SCORES')}</Title>
            <Subtitle>
              {i18next.t('MATERIALITY_PERFORMANCE_MODULE:SUBTITLE_WITHOUT_SCORES')}
            </Subtitle>
          </TitleWrapper>
          <ContentWrapper>
            <TabsWrapper>
              {allMaterialities.map((materiality) => {
                return (
                  <Materiality
                    materialityType={MaterialityType.NEUTRAL}
                    isSelected={selectedMateriality === materiality}
                    onClick={() => handleTabClick(materiality)}
                  >
                    <MaterialityName isUserCompanyHaveScores={!userCompany.lastUpdatedScore}>
                      {i18next.t(`SALES_MATERIALITY:${materiality}`)}
                    </MaterialityName>
                  </Materiality>
                );
              })}
            </TabsWrapper>
            {selectedMateriality && (
              <MaterialityDetails
                industryScores={industryScores}
                companyScore={null}
                materiality={selectedMateriality}
                materialityType={MaterialityType.NEUTRAL}
              />
            )}
            <ExplanaitionWrapper>
              <Explanaition>
                {i18next.t(`MATERIALITY_PERFORMANCE_MODULE:EXPLANAITION`)}
              </Explanaition>
            </ExplanaitionWrapper>
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default MaterialityPerformanceModule;
