import { Materialities } from 'src/enums/pillarScores';
import { ICompany } from 'src/types/company';
import { IPositiveMateriality } from 'src/types/score';

export interface IHowCompetitorPerformModuleProps {
  competitors: ICompany[] | null;
  searchedCompetitors: ICompany[];
  userCompany: ICompany;
  materialities: Materialities[] | null;
  positiveCompetitorsMaterialities: IPositiveMateriality[] | null;
  positiveMaterialities: Materialities[] | null;
  isMobile: boolean;
  onAddCompetitor: (company: ICompany) => void;
  onRemoveCompetitor: (company: ICompany) => void;
  onSearchCompetitor: (search: string) => void;
  onTrackEvent: (event: string, data?: any) => void;
}

export enum CompanyPerformViewTypes {
  TABLE = 'Table',
  CHART = 'Chart',
}
