import i18next from 'i18next';
import React from 'react';

import '../../i18n';
import { NavigateButton, TooltipFooter } from '../../styles';
import {
  CloseIcon,
  TooltipDescription,
  TooltipHeader,
  TooltipTitle,
  TooltipWrapper,
} from './styles';
import { ITextTooltipProps } from './types';

const TextTooltip = (props: ITextTooltipProps) => {
  const { title, description, isLast, isFirst, onClose, onNext, onBack } = props;

  return (
    <TooltipWrapper>
      <TooltipHeader>
        <TooltipTitle data-testid="module-help-tooltip-title">{title}</TooltipTitle>
        <CloseIcon data-testid="module-help-tooltip-close" onClick={onClose} />
      </TooltipHeader>
      <TooltipDescription data-testid="module-help-tooltip-description">
        {description}
      </TooltipDescription>
      <TooltipFooter isFirstModule={isFirst}>
        {!isFirst && (
          <NavigateButton data-testid="module-help-tooltip-back" onClick={onBack}>
            {i18next.t('MODULE_HELP:BACK')}
          </NavigateButton>
        )}
        <NavigateButton data-testid="module-help-tooltip-next" onClick={onNext}>
          {isLast ? i18next.t('MODULE_HELP:GOT_IT') : i18next.t('MODULE_HELP:NEXT')}
        </NavigateButton>
      </TooltipFooter>
    </TooltipWrapper>
  );
};

export default TextTooltip;
