import styled from 'styled-components';

import { IIndicatorProps, IScoreProp } from './types';

export const Wrapper = styled.div`
  width: 100%;
`;

export const IndicatorLabel = styled.div<IIndicatorProps>`
  flex: 1;
  display: flex;
  justify-content: ${(props) => (props.isStart ? 'flex-start' : 'flex-end')};
`;

export const BackgroundWrapper = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: ${(props) => props.theme.palette.common.grey};
  border-radius: 10px;
`;

export const LinearGraphic = styled.div`
  flex: 8;
  display: flex;
  position: relative;
  border: 2px solid ${(props) => props.theme.palette.common.white};
  margin: 0 20px;
`;

export const Label = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  white-space: nowrap;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.white};
`;

export const ScorePosition = styled.div<IScoreProp>`
  background-color: ${(props) => props.theme.palette.common.white};
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  bottom: 10px;
  box-shadow: 0px 4px 4px #2c445c;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.color};
  ${(props) => {
    if (props.scoreValue === props.valueRange.max) {
      return { left: '-35px', right: 'unset' };
    }
    if (props.scoreValue === props.valueRange.min) {
      return { right: '-35px', left: 'unset' };
    }
    if (props.valueRange.max < props.valueRange.min) {
      const rangeDifference = Math.abs(Number(props.valueRange.min) - Number(props.valueRange.max));
      const scoreDifference = Math.abs(Number(props.valueRange.min) - Number(props.scoreValue));

      return {
        right: `calc(${
          Math.round((scoreDifference * 100) / rangeDifference).toString() + '%'
        } - 70px)`,
        left: 'unset',
      };
    }
    const rangeDifference = Math.abs(Number(props.valueRange.max) - Number(props.valueRange.min));
    const scoreDifference = Math.abs(Number(props.valueRange.max) - Number(props.scoreValue));

    return {
      left: `calc(${
        Math.round((scoreDifference * 100) / rangeDifference).toString() + '%'
      } - 50px)`,
      right: 'unset',
    };
  }}
  &:after {
    content: '';
    position: absolute;
    top: 95%;
    left: 50%;
    margin-left: -12px;
    border-width: 12px;
    border-style: solid;
    border-color: ${(props) => props.theme.palette.common.white} transparent transparent transparent;
  }
`;

export const WrapperCompare = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelCompare = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const ScorePositionWrapper = styled.div`
  max-width: 50%;
  display: flex;
  box-sizing: border-box;
`;
