import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    padding: 30px 20px 20px;
  }
  min-height: 300px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.septenary};
  border-radius: 15px;
`;

export const Title = styled.div`
  margin: 10px 0;
  font-size: 28px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const WrapperText = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.common.grey};

  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const WrapperCheckBox = styled.div`
  width: 100%;
  margin-top: 10px;
`;
