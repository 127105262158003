import { Paper, TextField } from '@mui/material';
import styled from 'styled-components';

interface IPathAdornment {
  adornmentPath: string;
}

export const SearchTextField = styled(TextField)`
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  .MuiIconButton-root {
    transform: none;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-radius: 30px;
      border-color: transparent !important;
    }
  }
`;

export const DropdownBox = styled(Paper)`
  border-radius: 10px !important;
`;

export const DropdownOption = styled.li`
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const IconSubmit = styled.div<IPathAdornment>`
  height: 15px;
  width: 15px;
  background-image: url(${(props) => props.adornmentPath});
  background-repeat: no-repeat;
  margin-right: 5px;
  cursor: pointer;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
`;
