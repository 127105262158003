import { Modal } from '@mui/material';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  width: 100%;
  position: relative;
  .MuiBackdrop-root {
    background-color: rgba(44, 68, 92, 0.9);
  }
`;

export const ModelContent = styled.div`
  width: 90% !important;
  height: 80% !important;
  outline: none;
  width: max-content;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
