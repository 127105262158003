import { CircularProgress } from '@mui/material';
import { Chart } from 'devextreme-react';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  box-sizing: border-box;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;

export const Title = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 24px;
  }
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 14px;
  }
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 20px;
`;

export const GraphWrapper = styled(Chart)`
  flex: 1;
  margin-top: 20px;
  .dxc-arg-axis {
    display: none;
  }
  .dxc-val-axis {
    display: none;
  }
  .dxc-arg-elements {
    padding-right: 10px;
  }
  .dxc-val-elements {
    text {
      font-size: 12px !important;
      font-family: ${(props) => props.theme.fontFamilies.bold} !important;
      fill: ${(props) => props.theme.palette.quaternary} !important;
    }
  }
`;
