import { Button, Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as Person } from 'src/assets/menu/person-icon.svg';
import { SIZES } from 'src/helpers/devices';

import { IProfileIconProps } from './types';

export const HeaderWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    position: static;
  }
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1250;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const CompanyLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CompanyLogoWrapper = styled.div`
  width: 150px;
  height: 50px;
  cursor: pointer;
`;

export const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RealScoreWrapper = styled.div`
  width: 225px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.lightSecondary};
  border-radius: 50px;
  color: ${(props) => props.theme.palette.common.white};
  padding: 5px 20px;
`;

export const RealScoreText = styled.div`
  flex: 1;
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const RealScoreNumber = styled.div`
  font-size: 30px;
  padding-top: 3px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;
export const ButtonProfile = styled(Button)`
  &:hover {
    background-color: ${(props) => props.theme.palette.common.white}!important;
  }
`;
export const ProfileIcon = styled(Person)<IProfileIconProps>`
  width: 36px;
  height: 36px;
  color: ${(props) =>
    props.isMenuOpen ? props.theme.palette.secondary : props.theme.palette.lightSecondary};
  cursor: pointer;
  ${ButtonProfile}:hover & {
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.palette.quaternary};
    background-color: ${(props) => props.theme.palette.quaternary};
  }
`;
export const WrapperMenuItem = styled(MenuItem)`
  font-size: 18px !important;
  line-height: 22px !important;
  font-family: ${(props) => props.theme.fontFamilies.bold}!important;
  color: ${(props) => props.theme.palette.secondary}!important;
`;
export const WrapperCompanyName = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.grey};
`;

export const WrapperMenu = styled(Menu)`
  .MuiMenu-list {
    width: 82px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
`;
