import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    margin-top: 10px;
  }
  display: flex;
  flex-direction: column;
  flex: 9;
`;

export const HeaderWrapper = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  font-size: 22px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const TextTabletWrapper = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    margin-bottom: 10px;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 22px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperText = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    display: flex;
  }
  margin-right: 5px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const Border = styled.div`
  height: 22px;
  border-left: 2px solid ${(props) => props.theme.palette.quaternary};
  margin: 0 40px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-bottom: 10px;
  margin-right: 5px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 20px;
`;

export const MaterialityWrapper = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    gap: 10px;
  }
  @media (max-width: ${SIZES.smallTablet}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  width: 100%;
  margin: 20px 0 10px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export const Materiality = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    min-width: 170px;
  }
  @media (max-width: ${SIZES.bigTablet}px) {
    min-width: 130px;
  }
  min-width: 180px;
  height: 56px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.theme.palette.quinternary};
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const MaterialityIndex = styled.div`
  width: 28px;
  height: 24px;
  background-color: ${(props) => props.theme.palette.quaternary};
  color: ${(props) => props.theme.palette.lightSecondary};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MaterialityName = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
