import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const LogoWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
  }
  max-width: 200px;
  align-self: center;
  margin-bottom: 20px;
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputsWrapper = styled.div`
  @media (max-width: ${SIZES.bigDesktop}px) {
    margin: 20px 0;
  }
  @media (max-width: ${SIZES.mediumTablet}px) {
    margin: 20px 20px;
    width: 100%;
  }

  width: 700px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const TextWrapper = styled.div`
  margin-bottom: 40px;
  text-align: start;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 24px;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 50px;
  text-align: center;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 38px;
`;

export const SubtitleWrapper = styled.div`
  margin-bottom: 40px;
  text-align: start;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 24px;
`;

export const WelcomeWrapper = styled.div`
  margin-bottom: 40px;
  text-align: start;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 28px;
`;
