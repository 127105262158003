import { AnyAction } from 'redux';

import { IInsight } from 'src/types/insights';

import * as userConstants from '../constants/user';

export interface IInisightsReducerState {
  userInsights: IInsight[] | null;
  loading: boolean;
}

const defaultState: IInisightsReducerState = {
  userInsights: null,
  loading: false,
};

const setInsightLoading = (state: IInisightsReducerState, loading: boolean) => {
  return {
    ...state,
    loading,
  };
};

const setUserInsights = (state: IInisightsReducerState, userInsights: IInsight[] | null) => ({
  ...state,
  userInsights,
});

const insightsReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW:
    case userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW:
      return setInsightLoading(state, true);
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW_SUCCEEDED:
      return setInsightLoading(setUserInsights(state, action.insights), false);
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED:
      return setInsightLoading(state, false);
    default:
      return state;
  }
};

export default insightsReducer;
