import i18next from 'i18next';
import React from 'react';

import Logo from 'src/assets/logos/logo.png';
import TextButton from 'src/components/Buttons/TextButton';

import './i18n.ts';
import { InputsWrapper, LogoWrapper, MotiveLogo, TextWrapper } from './styles';
import { IModalSuccessProps } from './types';

const ModalSuccessChangePassword = (props: IModalSuccessProps) => {
  const { onSubmitSuccessModal } = props;
  return (
    <InputsWrapper>
      <LogoWrapper data-testid={'logo-motive-modal-success'}>
        <MotiveLogo src={Logo} alt="Motive Logo" />
      </LogoWrapper>
      <TextWrapper data-testid={'message-modal-success'}>
        {i18next.t('CHANGE_PASSWORD_MODAL_SUCCESS:MESSAGE')}
      </TextWrapper>
      <TextButton
        onSubmit={onSubmitSuccessModal}
        isDisabled={false}
        isLoading={false}
        text={i18next.t('CHANGE_PASSWORD_MODAL_SUCCESS:BUTTON_COPY')}
      />
    </InputsWrapper>
  );
};
export default ModalSuccessChangePassword;
