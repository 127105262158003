import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as userActions from 'src/redux/actions/user';
import industryRankSelector from 'src/redux/selectors/company/industryRank';
import userInsightsSelector from 'src/redux/selectors/insights/userInsights.selector';
import userNewsSelector from 'src/redux/selectors/news/userNews';
import helpModuleSelector from 'src/redux/selectors/user/helpModule';
import userDataSelector from 'src/redux/selectors/user/userData';

import { HelpModules } from 'src/enums/help';
import { MenuRoutes } from 'src/enums/routes';
import { useTypedSelector } from 'src/hooks/typeSelector';
import Summary from 'src/views/Summary';

import LayoutContainer from './Layout';

const SummaryContainer = () => {
  const dispatch = useDispatch();

  const helpModule = useTypedSelector(helpModuleSelector());
  const news = useTypedSelector(userNewsSelector());
  const user = useTypedSelector(userDataSelector());
  const industryRank = useTypedSelector(industryRankSelector());
  const insights = useTypedSelector(userInsightsSelector());

  const onNextHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeNext(MenuRoutes.SUMMARY, currentModule));
  };

  const onBackHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeBack(MenuRoutes.SUMMARY, currentModule));
  };

  const onCloseHelp = (currentModule: HelpModules) => {
    dispatch(userActions.onHelpModeClose(MenuRoutes.SUMMARY, currentModule));
  };

  const onInitializeSummaryView = useCallback(() => {
    dispatch(userActions.initializeSummaryView());
  }, [dispatch]);

  const handleTrackEvent = (event: string) => {
    dispatch(userActions.onTrackEvent(event));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    onInitializeSummaryView();
  }, [onInitializeSummaryView]);

  return (
    <LayoutContainer>
      <Summary
        news={news}
        scores={user?.company.lastUpdatedScore || null}
        industryRank={industryRank}
        insights={insights}
        company={user?.company || null}
        helpModule={helpModule}
        onCloseHelp={onCloseHelp}
        onNextHelp={onNextHelp}
        onBackHelp={onBackHelp}
        handleTrackEvent={handleTrackEvent}
      />
    </LayoutContainer>
  );
};

export default SummaryContainer;
