import styled from 'styled-components';

interface IPercentage {
  percentage: number;
  color: string;
  value: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const BooleanGraphicWrapper = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ValueTitles = styled.div`
  display: flex;
  width: 97%;
  margin-bottom: 5px;
  justify-content: space-between;
`;

export const ValueBars = styled.div`
  display: flex;
  width: 100%;
  background-color: #6a7a8c;
  border-radius: 10px;
`;

export const ValueTitle = styled.div`
  color: #6a7a8c;
  font-size: 14px;
  display: flex;
`;

export const YesBar = styled.div<IPercentage>`
  width: ${(props) => props.percentage + '%'};
  background-color: ${(props) => (props.value === 'Yes' ? props.color : '#6A7A8C')};
  text-align: start;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  justify-content: flex-start;
  color: ${(props) => props.theme.palette.common.white};
  display: flex;
`;

export const NoBar = styled.div<IPercentage>`
  width: ${(props) => props.percentage + '%'};
  text-align: end;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  color: ${(props) => props.theme.palette.common.white};
  display: flex;
  padding: 10px;
  background-color: ${(props) => (props.value === 'No' ? props.color : '#6A7A8C')};
`;

export const GraphicTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 16px;
  padding-bottom: 10px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.secondary};
`;
