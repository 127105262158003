import IUserSettings from 'src/types/userSettings';

import { get, post, put } from './api';

export async function loginUser(email: string, password: string) {
  return post('auth/login', { email, password });
}

export async function signUpUser(
  firstName: string,
  lastName: string,
  email: string,
  company: string,
) {
  return post('auth/sign-up', { firstName, lastName, email, company });
}

export async function getUser() {
  return get('users/me');
}

export async function getUserByHash(emailHash: string) {
  return get('users/me', {}, { Authorization: `Bearer ${emailHash}` });
}

export async function changePassword(newPassword: string, emailHash: string) {
  return post(`auth/password`, { newPassword }, { Authorization: `Bearer ${emailHash}` });
}

export async function editSettings(newSettings: IUserSettings) {
  return put(`users/settings/me`, {
    ...newSettings,
    benchmarkCompetitors: newSettings.benchmarkCompetitors.map((element) => element.id),
    salesCompetitors: newSettings.salesCompetitors.map((element) => element.id),
  });
}

export async function getUserSettings() {
  return get(`users/settings/me`);
}

export async function sendContactUs(
  userEmail: string,
  userCompany: string,
  reason: string,
  message: string,
) {
  return post('users/contact-us', { userEmail, userCompany, reason, message });
}

export async function sendForgotPasswordEmail(email: string) {
  return post('auth/forgot-password', { email });
}
