import { capitalize } from '@mui/material/utils';
import React, { useState } from 'react';
import TextTruncate from 'react-text-truncate';

import VideoPlayerModal from 'src/components/VideoPlayerModal';

import {
  ImageVideo,
  VideoContent,
  VideoHeaderWrapper,
  VideoTitle,
  VideoWrapper,
  PlayIcon,
  WrapperPlayIcon,
  WrapperText,
  WrapperImageVideo,
} from './styles';
import { IVideoProps } from './types';

const Video = (props: IVideoProps) => {
  const { video } = props;
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  return (
    <VideoWrapper>
      <VideoPlayerModal
        url={video.link}
        controls={true}
        open={isPlayerOpen}
        onClose={() => {
          setIsPlayerOpen(false);
        }}
      />
      <VideoHeaderWrapper>
        <VideoTitle data-testid={'title-video-insight-summary'}>
          {capitalize(video.title)}
        </VideoTitle>
      </VideoHeaderWrapper>
      <VideoContent>
        <WrapperImageVideo data-testid={'preview-image-video-insight-summary'}>
          <ImageVideo
            onClick={() => {
              setIsPlayerOpen(true);
            }}
            image={video.videoImage}
          >
            <WrapperPlayIcon data-testid={'icon-play-video-insight-summary'}>
              <PlayIcon />
            </WrapperPlayIcon>
          </ImageVideo>
        </WrapperImageVideo>
        <WrapperText data-testid={'description-video-insight-summary'}>
          <TextTruncate
            line={5}
            element="p"
            truncateText="..."
            text={capitalize(video.description)}
          />
        </WrapperText>
      </VideoContent>
    </VideoWrapper>
  );
};

export default Video;
