import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { ISelectedProps } from './types';

export const IconScore = styled.img`
  width: 100%;
`;
export const Tab = styled.div<ISelectedProps>`
  @media (max-width: ${SIZES.smallDesktop}px) {
    flex: ${(props) => (props.isSelected ? '3' : '1')};
  }
  @media (max-width: ${SIZES.bigTablet}px) {
    flex: ${(props) => (props.isSelected ? '4' : '1')};
  }
  flex: ${(props) => (props.isSelected ? '4' : '1')};
  padding: 0 20px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.color};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: unset;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
  box-shadow: ${(props) => props.theme.boxShadow.module};
`;

export const TabList = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const WrapperIconScore = styled.div`
  width: 30px;
  height: 30px;
`;

export const WrapperText = styled.div<ISelectedProps>`
  @media (max-width: ${SIZES.bigTablet}px) {
    padding-left: ${(props) => (props.isSelected ? '10px' : 'unset')};
  }
  width: ${(props) => (props.isSelected ? '100%' : 'unset')};
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
export const Text = styled.p`
  font-size: 22px;
  line-height: 18px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;
export const ValueScore = styled.p`
  font-size: 30px;
  line-height: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTabContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
