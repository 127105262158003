import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';

import { MAX_NEWS_MODULE } from 'src/helpers/news';
import { IFilterResponse } from 'src/types/http';
import { IInsight } from 'src/types/insights';
import IUser from 'src/types/user';

import userDataSelector from '../selectors/user/userData';
import { getInsightsBy } from '../services/insights';
import { getForCompany } from '../services/news';
import { getIndustryRealScores } from '../services/score';

function* initializeSummary() {
  try {
    const user: IUser = yield select(userDataSelector());

    const [newsResult, industryScores, insights]: [IFilterResponse, number[], IInsight[]] =
      yield all([
        call(getForCompany, user.company.id, user.company.industry, MAX_NEWS_MODULE, 0),
        call(getIndustryRealScores, user.company.industry),
        call(getInsightsBy, user.company.id, user.company.industry, 6),
      ]);
    yield put(
      userActions.initializeSummaryViewSucceeded(newsResult.items, industryScores, insights),
    );
  } catch (error: any) {
    yield put(userActions.initializeSummaryViewFailed(error.data.message));
  }
}

export default all([takeLatest(userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW, initializeSummary)]);
