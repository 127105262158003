import styled from 'styled-components';

export const HelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.septenary};
`;

export const Title = styled.p`
  font-size: 28px;
  line-height: 38px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const WrapperTitle = styled.div`
  margin-bottom: 20px;
`;

export const ImageWrapper = styled.div`
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 10px;
`;

export const HelpImage = styled.img`
  width: 100%;
`;

export const TextWrapper = styled.div`
  margin-top: 20px;
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const BoldText = styled.p`
  display: contents;
  color: ${(props) => props.theme.palette.lightSecondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;
