import styled from 'styled-components';

import { FOOTER_HEIGHT, HEADER_HEIGHT, SIZES } from 'src/helpers/devices';

export const InsightGrid = styled.div`
  padding: 20px;
  display: grid;
  overflow: auto;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 2fr);
  grid-template-rows: repeat(3, auto);
  grid-template-areas:
    'header header headerComments'
    'bodyPageTips bodyPageTips bodyPageComments';
`;

export const HeaderWrapperComment = styled.div`
  grid-area: headerComments;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
`;

export const WrapperText = styled.div`
  flex: 9;
  display: flex;
  align-items: baseline;
`;

export const Title = styled.p`
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-right: 5px;
`;

export const Subtitle = styled.p`
  flex: 1;
  font-size: 16px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const HeaderWrapper = styled.div`
  padding: 0 0 10px;
  grid-area: header;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  line-height: 40px;
`;

export const InsightsWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.septenary};
  min-height: calc(100vh - (${HEADER_HEIGHT}px + ${FOOTER_HEIGHT}px));
  overflow: auto;
`;

export const InsightWrapperComment = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    padding: 0 20px 10px;
  }
  grid-area: bodyPageComments;
`;

export const InsightWrapperTip = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    padding: 0 20px 10px;
  }
  grid-area: bodyPageTips;
`;

export const WrapperComments = styled.div`
  padding: 20px 0;
  background-color: ${(props) => props.theme.palette.septenary};
  border-bottom: 1px solid #d4dfe2;
  &:nth-child(1) {
    padding: 0 0 20px;
  }
`;

export const WrapperTips = styled.div`
  padding: 20px;
  margin-top: 20px;
  box-shadow: ${(props) => props.theme.boxShadow.module};
  border-radius: 15px;
  background-color: ${(props) => props.theme.palette.common.white};

  &:nth-child(1) {
    margin-top: 0;
  }
`;
