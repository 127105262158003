import CancelIcon from '@mui/icons-material/Cancel';
import { Modal } from '@mui/material';
import styled from 'styled-components';

import { IModalContentProps } from './types';

export const ModalWrapper = styled(Modal)`
  position: relative;
  .MuiBackdrop-root {
    background-color: rgba(44, 68, 92, 0.9);
  }
`;

export const CloseIcon = styled(CancelIcon)`
  z-index: 3;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const ModelContent = styled.div<IModalContentProps>`
  outline: none;
  width: 90%;
  max-height: 90%;
  max-width: ${(props) => props.width}px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 20px;
  overflow: scroll;
  overflow-y: initial;
  overflow-x: auto;
`;
