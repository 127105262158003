import i18next from 'i18next';
import React from 'react';

import Computer from 'src/assets/news/motive_icons_news_computer.svg';
import Filter from 'src/assets/news/motive_icons_news_filter.svg';
import Folder from 'src/assets/news/motive_icons_news_folder.svg';
import Search from 'src/assets/news/motive_icons_news_seach.svg';
import NewsCards from 'src/components/NewsCards';

import './i18n';
import {
  HeaderNewsWrapper,
  WrapperText,
  Title,
  NewsGrid,
  NewsWrapper,
  HeaderRatingNewsWrapper,
  WrapperRatingNews,
  WrapperNews,
  WrapperButtonLoadMore,
  ButtonLoadMore,
  WrapperRating,
  Text,
  WrapperRatingInfo,
  WrapperImage,
  WrapperRatingText,
  ImageRating,
  BoldText,
  WrapperRatingSubtitle,
  Subtitle,
  WrapperTablet,
} from './styles';
import { INewsProps, IRatingModules } from './types';

const News = (props: INewsProps) => {
  const { news, onLoadMoreNews, hasMore } = props;
  const firstModule: IRatingModules[] = [
    {
      textComponent: (
        <Text>
          {i18next.t('NEWS_RATING_DRAWIN:TEXT')}{' '}
          <BoldText> {i18next.t('NEWS_RATING_DRAWIN:TEXT_2')} </BoldText>{' '}
          {i18next.t('NEWS_RATING_DRAWIN:TEXT_3')}{' '}
          <BoldText>{i18next.t('NEWS_RATING_DRAWIN:TEXT_4')} </BoldText>
        </Text>
      ),
      image: Computer,
    },
    {
      textComponent: (
        <Text>
          {i18next.t('NEWS_RATING_SCAN:TEXT')}{' '}
          <BoldText> {i18next.t('NEWS_RATING_SCAN:TEXT_2')} </BoldText>{' '}
          {i18next.t('NEWS_RATING_SCAN:TEXT_3')}
        </Text>
      ),
      image: Filter,
    },
  ];
  const secondModules: IRatingModules[] = [
    {
      textComponent: (
        <Text>
          {i18next.t('NEWS_RATING_CURATED:TEXT')}{' '}
          <BoldText>{i18next.t('NEWS_RATING_CURATED:TEXT_2')}</BoldText>{' '}
          {i18next.t('NEWS_RATING_CURATED:TEXT_3')}{' '}
          <BoldText>{i18next.t('NEWS_RATING_CURATED:TEXT_4')} </BoldText>{' '}
          {i18next.t('NEWS_RATING_CURATED:TEXT_5')}
        </Text>
      ),
      image: Folder,
    },
    {
      textComponent: (
        <Text>
          <BoldText>{i18next.t('NEWS_RATING_NEW_ARTICULE:TEXT')}</BoldText>{' '}
          {i18next.t('NEWS_RATING_NEW_ARTICULE:TEXT_2')}
        </Text>
      ),
      image: Search,
    },
  ];
  const allModules = [firstModule, secondModules];
  return (
    <NewsWrapper>
      <NewsGrid>
        <HeaderNewsWrapper>
          <WrapperText>
            <Title>{i18next.t('NEWS:TITLE')}</Title>
          </WrapperText>
        </HeaderNewsWrapper>
        <HeaderRatingNewsWrapper>
          <WrapperText>
            <Title>{i18next.t('NEWS:RATING_TITLE')}</Title>
          </WrapperText>
        </HeaderRatingNewsWrapper>
        <WrapperRatingNews>
          <WrapperRatingSubtitle>
            <Subtitle>{i18next.t('NEWS:RATING_SUBTITLE')}</Subtitle>
          </WrapperRatingSubtitle>
          {allModules.map((element) => {
            return (
              <WrapperTablet>
                {element.map((module) => (
                  <WrapperRating>
                    <WrapperRatingInfo>
                      <WrapperImage>
                        <ImageRating src={module.image} />
                      </WrapperImage>
                      <WrapperRatingText>{module.textComponent}</WrapperRatingText>
                    </WrapperRatingInfo>{' '}
                  </WrapperRating>
                ))}
              </WrapperTablet>
            );
          })}
        </WrapperRatingNews>
        <WrapperNews>
          <NewsCards userNews={news} />
          <WrapperButtonLoadMore>
            <ButtonLoadMore disabled={!hasMore} onClick={onLoadMoreNews}>
              {i18next.t('NEWS:BUTTON_LOAD_MORE')}
            </ButtonLoadMore>
          </WrapperButtonLoadMore>
        </WrapperNews>
      </NewsGrid>
    </NewsWrapper>
  );
};

export default News;
