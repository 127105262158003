import { AnyAction } from 'redux';

import { Materialities } from 'src/enums/pillarScores';
import { IScoresByIndustry } from 'src/types/score';

import * as companyConstants from '../constants/company';
import * as userConstants from '../constants/user';

export interface IScoreReducerState {
  userMateriality: Materialities[] | null;
  industriesScores: IScoresByIndustry[] | null;
  loading: boolean;
}

const defaultState: IScoreReducerState = {
  userMateriality: null,
  industriesScores: null,
  loading: false,
};

const setLoading = (state: IScoreReducerState, loading: boolean) => {
  return {
    ...state,
    loading,
  };
};

const setMateriality = (state: IScoreReducerState, userMateriality: Materialities[]) => {
  return {
    ...state,
    userMateriality,
    loading: false,
  };
};

const setIndustriesScores = (state: IScoreReducerState, industriesScores: IScoresByIndustry[]) => ({
  ...state,
  industriesScores,
});

const addIndustriesScores = (state: IScoreReducerState, industriesScores: IScoresByIndustry[]) => {
  return { ...state, industriesScores: [...(state.industriesScores || []), ...industriesScores] };
};

const resetMaterialities = (state: IScoreReducerState) => {
  return { ...state, userMateriality: null, industriesScores: null };
};

const scoreReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_SALES_VIEW:
    case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED:
    case companyConstants.ON_ADD_COMPETITOR_REQUESTED:
      return setLoading(state, true);
    case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_SUCCEEDED:
      return setLoading(setMateriality(state, action.materialityRanking), false);
    case userConstants.USER_ON_PILLARS_PERFORMANCE_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED:
      return setIndustriesScores(state, action.pillarScoresResult);
    case userConstants.USER_ON_INITIALIZE_SALES_VIEW_FAILED:
    case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED:
      return setLoading(state, false);
    case userConstants.USER_ON_SCORES_COMPETITOR_SUCCEEDED:
    case companyConstants.ON_ADD_COMPETITOR_SUCCEEDED:
      return addIndustriesScores(state, action.industriesScores);
    case userConstants.USER_ON_SUBMIT_TARGET_SALES_REQUESTED:
    case userConstants.USER_ON_SALES_BACK:
      return resetMaterialities(state);
    default:
      return state;
  }
};

export default scoreReducer;
