import CompetitorsSVG from 'src/assets/insights/competitors.svg';
import ConsumersSVG from 'src/assets/insights/consumers.svg';
import EnvironmentSVG from 'src/assets/insights/environment.svg';
import GovernanceSVG from 'src/assets/insights/governance.svg';
import IndustrySVG from 'src/assets/insights/industry.svg';
import InvestorsSVG from 'src/assets/insights/investors.svg';
import MotiveUpdateSVG from 'src/assets/insights/motive-update.svg';
import NewsSVG from 'src/assets/insights/news.svg';
import RegulationsSVG from 'src/assets/insights/regulations.svg';
import SocialSVG from 'src/assets/insights/social.svg';
import UrgentSVG from 'src/assets/insights/urgent.svg';
import { PillarScores } from 'src/enums/pillarScores';
import { TipsCategory } from 'src/types/insights';

export const getTipIconByType = (type: TipsCategory) => {
  switch (type) {
    case TipsCategory.COMPETITORS:
      return CompetitorsSVG;
    case TipsCategory.ENVIRONMENT:
      return EnvironmentSVG;
    case TipsCategory.GOVERNANCE:
      return GovernanceSVG;
    case TipsCategory.SOCIAL:
      return SocialSVG;
    case TipsCategory.NEWS:
      return NewsSVG;
    case TipsCategory.MOTIVEUPDATE:
      return MotiveUpdateSVG;
    case TipsCategory.REGULATIONSFRAMEWORKS:
      return RegulationsSVG;
    case TipsCategory.URGENT:
      return UrgentSVG;
    case TipsCategory.INDUSTRY:
      return IndustrySVG;
    case TipsCategory.INVESTORS:
      return InvestorsSVG;
    case TipsCategory.CONSUMERS:
      return ConsumersSVG;
    default:
      return null;
  }
};

export const getTipIconByPillarScore = (pillarScore: PillarScores) => {
  switch (pillarScore) {
    case PillarScores.INDUSTRY_RANK:
      return IndustrySVG;
    case PillarScores.ENVIRONMENT:
      return EnvironmentSVG;
    case PillarScores.SOCIAL:
      return SocialSVG;
    case PillarScores.GOVERNANCE:
      return GovernanceSVG;
    default:
      return null;
  }
};
