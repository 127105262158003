import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';

const useQueryString = () => {
  return QueryString.parse(useLocation().search);
};

export const createQueryString = (object: any) => {
  return QueryString.stringify(object);
};
export default useQueryString;
