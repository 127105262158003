export const COLORS = {
  shadowGreen: '#91c2aa',
  deepCoveBlue: '#06223F',
  lightCoveBlue: '#2C445C',
  cornflowerBlue: '#AEDCDE',
  dodgerBlue: '#3AC4FC',
  borderBlue: '#dceded',
  borderLightBlue: '#AEDCDE4D',
  white: '#ffffff',
  black: '#222831',
  grey: '#6A7A8C',
  catskillWhite: '#EFF8F8',
};
