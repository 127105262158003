import { GridColumns } from '@mui/x-data-grid';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { IChartDataScore, IScoreChartData } from 'src/components/Charts/PillarScoreChart/types';
import Table from 'src/components/Table';
import { PillarScores } from 'src/enums/pillarScores';
import { BenchmarkPillars, pillarScoreTexts } from 'src/helpers/pillarsScores';
import { formatScoreByPillar, getScoreByPillar } from 'src/helpers/scores';

import CheckBoxInput from '../../../../components/Inputs/CheckBoxInput';
import './i18n';
import {
  ArrowDownIcon,
  ButtonSubmit,
  DataGridWrapper,
  HeaderWrapper,
  SelectPillarWrapper,
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
  WrapperButton,
  WrapperButtonSubmit,
  WrapperMenu,
  WrapperMenuItems,
  ButtonSelect,
  WrapperText,
} from './styles';
import { IPillarScoreTableModuleProps } from './types';

const NOT_APPLICABLE = '_';

const PillarScoreTableModule = (props: IPillarScoreTableModuleProps) => {
  const { userCompany, competitors, pillarScore, isTablet, handleErrorMessage, onSetPillarScore } =
    props;
  const [selectPillarScore, setSelectedPillarScore] = useState<PillarScores[]>(pillarScore);
  const [openMenu, setOpenMenu] = useState(false);

  const pillarsToShow = pillarScoreTexts.filter((pillar) =>
    BenchmarkPillars.includes(pillar.value as PillarScores),
  );

  const handlePillarScoreChange = (value: PillarScores) => {
    if (selectPillarScore.includes(value)) {
      setSelectedPillarScore(selectPillarScore.filter((element) => element !== value));
    } else {
      if (selectPillarScore.length === 7) {
        handleErrorMessage(i18next.t('PILLAR_SCORE_TABLE_MODULE:ERROR_MESSAGE'));
        return;
      }
      setSelectedPillarScore([...selectPillarScore, value]);
    }
  };

  let chartData: IScoreChartData[] = [];
  if (competitors) {
    competitors.forEach((competitor) => {
      const scoreCompetitor: IChartDataScore[] = pillarScore.map((pillar) => {
        const score = getScoreByPillar(pillar, competitor.lastUpdatedScore!);
        return {
          scoreName: pillar,
          score: score !== null ? score : NOT_APPLICABLE,
        };
      });
      chartData = chartData.concat([
        { id: competitor.id, company: competitor.name, data: scoreCompetitor },
      ]);
    });
  }

  if (userCompany.lastUpdatedScore) {
    const userData: IScoreChartData = {
      id: userCompany.id,
      company: userCompany.name,
      data: pillarScore.map((pillar) => {
        const score = getScoreByPillar(pillar, userCompany.lastUpdatedScore!);
        return { scoreName: pillar, score: score !== null ? score : NOT_APPLICABLE };
      }),
    };
    chartData.unshift(userData);
  }

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const columns: GridColumns = pillarScore.map((element) => {
    const value = pillarsToShow!.find((option) => option.value === element);
    return {
      field: value !== undefined ? value.value!.toString() : '',
      headerName: value !== undefined ? value?.text : '',
      flex: 1,
      align: 'center',
      sortable: false,
      description: value?.text || '',
      minWidth: isTablet ? 250 : undefined,
      renderCell: (cellValues) => {
        const rowValue = cellValues.row.data.find(
          (element: IChartDataScore) => element.scoreName === value!.value,
        );

        return rowValue.score === NOT_APPLICABLE
          ? NOT_APPLICABLE
          : formatScoreByPillar(rowValue.scoreName, rowValue.score);
      },
    };
  });
  columns.unshift({
    field: 'company',
    headerName: 'Company',
    flex: 1,
    align: 'center',
    minWidth: isTablet ? 250 : undefined,
    description: 'Company',
    sortable: false,
  });

  const handleColumnsTable = () => {
    onSetPillarScore(selectPillarScore);
    setOpenMenu(false);
  };

  useEffect(() => {
    setSelectedPillarScore(pillarScore);
  }, [pillarScore]);

  return (
    <Wrapper>
      <HeaderWrapper openMenu={!openMenu}>
        <TitleWrapper>
          <Title> {i18next.t('PILLAR_SCORE_TABLE_MODULE:TITLE')} </Title>
          {!openMenu && <Subtitle> {i18next.t('PILLAR_SCORE_TABLE_MODULE:SUBTITLE')} </Subtitle>}
        </TitleWrapper>
        <SelectPillarWrapper>
          <ButtonSelect onClick={handleOpenMenu}>
            <WrapperText>{i18next.t('PILLAR_SCORE_TABLE_MODULE:PLACEHOLDER')}</WrapperText>
            <WrapperButton>
              <ArrowDownIcon />
            </WrapperButton>
          </ButtonSelect>
        </SelectPillarWrapper>
      </HeaderWrapper>
      {openMenu && (
        <WrapperMenu>
          <WrapperMenuItems>
            {pillarsToShow.map((inputs) => {
              return (
                <CheckBoxInput
                  value={inputs.value! as PillarScores}
                  label={inputs.text}
                  isChecked={selectPillarScore.includes(inputs.value! as PillarScores)}
                  onChange={handlePillarScoreChange}
                />
              );
            })}
          </WrapperMenuItems>
          <WrapperButtonSubmit>
            <ButtonSubmit onClick={handleColumnsTable}>
              {i18next.t('PILLAR_SCORE_TABLE_MODULE:BUTTON_SUBMIT')}
            </ButtonSubmit>
          </WrapperButtonSubmit>
        </WrapperMenu>
      )}
      {!openMenu && (
        <DataGridWrapper>
          <Table
            columns={columns}
            error={null}
            rows={chartData}
            rowHeight={isTablet ? 100 : 60}
            loading={false}
            autoHeight={true}
            selectionModel={chartData.filter((r) => r.id === userCompany.id).map((r) => r.id)}
          />
        </DataGridWrapper>
      )}
    </Wrapper>
  );
};

export default PillarScoreTableModule;
