import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: ${(props) => props.theme.boxShadow.module};
  padding: 20px;
  box-sizing: border-box;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.palette.quaternary};
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleWrapper = styled.div`
  width: 50%;
  padding-right: 5px;
`;

export const SelectPillarWrapper = styled.div`
  width: 50%;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 24px;
  }
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.desktop}px) {
    font-size: 14px;
  }
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  margin-bottom: 20px;
`;
