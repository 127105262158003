import { Chart } from 'devextreme-react';
import styled from 'styled-components';

export const GraphWrapper = styled(Chart)`
  flex: 1;
  height: 100%;
  &:first-child {
    height: 100%;
  }
  .dxc-arg-axis {
    display: none;
  }
  .dxc-val-axis {
    display: none;
  }
  .dxc-arg-elements {
    padding-right: 10px;
  }
  .dxc-val-elements {
    text {
      font-size: 12px !important;
      font-family: ${(props) => props.theme.fontFamilies.bold} !important;
      fill: ${(props) => props.theme.palette.quaternary} !important;
    }
  }
`;
