import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { getMaterialityIsPositiveOrNegative } from 'src/helpers/scores';

// Positivity for pillars business rules:
// For yes-no fields, is positive if is "Yes"
// For number fields, is positive if in the percentile 50 of all of the industry scores
// // For example: a company that is 9 out of 20 companies of the industry in a particular pillar, will be considered positive because (9*100)/20 = 45% (which is < 50%)

// Positivity for whole materiality
// Is positive if at least 50% of the pillars that correspond to that materiality are positive

const positiveCompetitorsMaterialitiesSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.score.industriesScores,
      (state: IReduxState) => state.score.userMateriality,
      (state: IReduxState) => state.user.userSettings.salesCompetitors,
    ],
    (industryScores, materialities, salesCompetitors) => {
      if (!salesCompetitors || !industryScores || !materialities) {
        return null;
      }

      return salesCompetitors
        .map((competitor) => {
          const industryScore = industryScores.find(
            (score) => score.industry === competitor.industry,
          );
          if (!industryScore || !competitor.lastUpdatedScore) {
            return null;
          }
          return {
            industry: competitor.industry,
            positiveMateriality: getMaterialityIsPositiveOrNegative(
              materialities,
              competitor.lastUpdatedScore,
              industryScore!.data,
            ),
          };
        })
        .filter((element) => element !== null) as any;
    },
  );

export default positiveCompetitorsMaterialitiesSelector;
