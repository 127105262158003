import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentTitle = styled.p`
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 22px;
`;

export const CommentContent = styled.p`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin: 10px 0;
  }
  color: #026f9b;
  line-height: 28px;
  font-family: ${(props) => props.theme.fontFamilies.regularItalic};
  font-size: 18px;
  margin-bottom: 10px;
`;

export const CommentSignature = styled.p`
  color: ${(props) => props.theme.palette.common.grey};
  font-family: ${(props) => props.theme.fontFamilies.regularItalic};
  font-size: 16px;
  align-self: flex-end;
`;
