import { IPillarScoreMetadata, Materialities, PillarScores } from 'src/enums/pillarScores';

export default [
  {
    Identifier: PillarScores.ENVIRONMENT,
    ValueType: 'number',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.SOCIAL,
    ValueType: 'number',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.GOVERNANCE,
    ValueType: 'number',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.GHG_INTENSITY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.GHG],
  },
  {
    Identifier: PillarScores.WASTE_MANAGEMENT,
    ValueType: 'number',
    SortBy: 'desc',
    Materiality: [Materialities.WASTE],
  },
  {
    Identifier: PillarScores.WATER_EFFICIENCY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.WATER],
  },
  {
    Identifier: PillarScores.ANNUAL_EMPLOYEE_TURNOVER,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.EMPLOYMENT],
  },
  {
    Identifier: PillarScores.RENEWABLE_ENERGY_TARGETS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.ENERGY],
  },
  {
    Identifier: PillarScores.TRAINING_COSTS_PER_EMPLOYEE,
    ValueType: 'number',
    SortBy: 'desc',
    Materiality: [Materialities.HEALTH],
  },
  {
    Identifier: PillarScores.SUSTAINABILITY_PLANS_INTEGRATED,
    ValueType: 'boolean',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.BIODIVERSITY_CONSERVATION_PROGRAMS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.BIODIVERSITY],
  },
  {
    Identifier: PillarScores.BOARD_OF_DIRECTORS_WOMAN_OR_MINORITIES,
    ValueType: 'boolean',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.WOMAN_OR_MINORITIES_REPRESENT,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.EMPLOYMENT],
  },
  {
    Identifier: PillarScores.SUPPLIER_CODE_OF_CONDUCT,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.SUPPLY_CHAIN],
  },
  {
    Identifier: PillarScores.PROVIDES_TRAINING_ON_SUPPLIER_CODE,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.SUPPLY_CHAIN],
  },
  {
    Identifier: PillarScores.CONDUCTS_INSPECTION_OF_SUPPLIERS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.SUPPLY_CHAIN],
  },
  {
    Identifier: PillarScores.MONITORS_IMPLEMENTATION_OF_HR_POLICIES,
    ValueType: 'boolean',
    SortBy: 'desc',
  },
  {
    Identifier: PillarScores.EXECUTIVE_COMPENSATION_LINKED_TO_ESG_PERFORMANCE,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.INTEGRATED_ESG],
  },
  {
    Identifier: PillarScores.TOTAL_SCOPE_EMISSIONS,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.GHG],
  },
  {
    Identifier: PillarScores.PARTICIPATES_IN_CARBON_DISCLOSURE_PROJECT,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.GHG],
  },
  {
    Identifier: PillarScores.HAS_A_WATER_MANAGEMENT_STRATEGY,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.WATER],
  },
  {
    Identifier: PillarScores.EMPLOYS_WATER_RECYCLING_REUSE,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.WATER],
  },
  {
    Identifier: PillarScores.HAS_AN_ENVIRONMENTAL_PROTECTION_POLICY_COMMITTEE,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.BIODIVERSITY],
  },
  {
    Identifier: PillarScores.SIGNATORY_TO_UN_GLOBAL_COMPACT,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.INTEGRATED_ESG, Materialities.BIODIVERSITY],
  },
  {
    Identifier: PillarScores.HAZARDOUS_WASTE_INTENSITY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.WASTE],
  },
  {
    Identifier: PillarScores.FOLLOWS_ISO14001_OR_EMAS_GUIDELINES,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.NATURAL_RESOURCES],
  },
  {
    Identifier: PillarScores.RECYCLED_OR_RECYCLABLE_RAW_MATERIALS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.NATURAL_RESOURCES],
  },
  {
    Identifier: PillarScores.STATEMENT_OF_COMPREHENSIVE_ENVIRONMENT_POLICY,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.NATURAL_RESOURCES],
  },
  {
    Identifier: PillarScores.TOTAL_ENERGY_INTENSITY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.ENERGY],
  },
  {
    Identifier: PillarScores.REPORTS_ON_COMMUNITY_DEVELOPMENT_PROGRAMS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.COMMUNITY],
  },
  {
    Identifier: PillarScores.HAS_PROGRAMS_FOR_EDUCATION_ACCESS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.COMMUNITY],
  },
  {
    Identifier: PillarScores.HAS_PROGRAMS_FOR_HEALTH_CARE_ACCESS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.COMMUNITY],
  },
  {
    Identifier: PillarScores.LOST_DAYS_DUE_TO_INJURY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.HEALTH],
  },
  {
    Identifier: PillarScores.INJURY_INTENSITY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.HEALTH],
  },
  {
    Identifier: PillarScores.MANAGERIAL_POSITIONS_HELD_BY_WOMEN,
    ValueType: 'number',
    SortBy: 'desc',
    Materiality: [Materialities.EMPLOYMENT],
  },
  {
    Identifier: PillarScores.VOLUNTARY_PRINCIPLES_ON_SECURITY_AND_HUMAN_RIGHTS_SIGNATORY,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.HUMAN_RIGHTS],
  },
  {
    Identifier: PillarScores.SUPPORTS_UNIVERSAL_DECLARATION_OF_HUMAN_RIGHTS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.HUMAN_RIGHTS],
  },
  {
    Identifier: PillarScores.HUMAN_RIGHTS_TRAINING_FOR_EMPLOYEES,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.HUMAN_RIGHTS],
  },
  {
    Identifier: PillarScores.POLICY_ON_CUSTOMER_DATA_PRIVACY,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.DATA_PROTECTION],
  },
  {
    Identifier: PillarScores.POLICY_ON_SUPPLIER_DATA_PRIVACY,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.DATA_PROTECTION],
  },
  {
    Identifier: PillarScores.PREVENTS_THIRD_PARTY_ACCESS_TO_PERSONAL_INFORMATION,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.DATA_PROTECTION],
  },
  {
    Identifier: PillarScores.EXTERNAL_VERIFICATION_OF_CSR_ESG_REPORTING,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.INTEGRATED_ESG],
  },
  {
    Identifier: PillarScores.QUALITY_MANAGEMENT_SYSTEM_CERTIFICATIONS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.QUALITY_SAFETY],
  },
  {
    Identifier: PillarScores.PRODUCT_SAFETY_AND_QUALITY_ASSURANCE_INITIATIVES,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.QUALITY_SAFETY],
  },
  {
    Identifier: PillarScores.COMMUNICATES_CHEMICAL_CONSTITUENTS_IN_PRODUCTS,
    ValueType: 'boolean',
    SortBy: 'desc',
    Materiality: [Materialities.QUALITY_SAFETY],
  },
  {
    Identifier: PillarScores.WASTE_INTENSITY,
    ValueType: 'number',
    SortBy: 'asc',
    Materiality: [Materialities.WASTE],
  },
] as IPillarScoreMetadata[];
