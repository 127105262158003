import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import { ITabProps } from '../TabsScores/types';

export const DropDown = styled.div`
  padding-left: 20px;
  display: flex;
  align-self: center;
  width: 20px;
  height: 20px;
`;

export const InfoDropDown = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const GraphicWrapper = styled.div`
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 20px;
`;

export const LabelData = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 20px;
  max-width: 330px;
  align-self: center;
`;

export const WrapperData = styled.div`
  @media (max-width: ${SIZES.smallDesktop}px) {
    width: 100%;
    box-sizing: border-box;
  }
  width: 45%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  flex-direction: column;
  padding: 10px 0;
`;

export const WrapperContent = styled.div<ITabProps>`
  @media (max-width: ${SIZES.smallDesktop}px) {
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: ${(props) => props.scoreColors.thirdColor};
  border: 1px solid ${(props) => props.scoreColors.fourthColor};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ValueData = styled.p`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.color};
  font-size: 24px;
  line-height: 40px;
  align-self: center;
  white-space: nowrap;
`;

export const PillarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const DetailsWrapper = styled.div<ITabProps>`
  display: ${(props) => (props.display ? 'flex' : 'none')};
  height: 100%;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${(props) => props.scoreColors.fifthColor};
`;

export const WrapperTable = styled.div`
  width: 100%;
  min-height: 300px;
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 0 0 16px 16px;
  position: relative;
  z-index: 3;
  padding: 20px;
  box-sizing: border-box;
`;

export const LoadingIndicator = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.lightSecondary} !important;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
`;

export const PillarDescriptionWrapper = styled.div`
  padding: 20px;
  width: 95%;
  display: flex;
  justify-content: center;
`;

export const PillarDescription = styled.div`
  color: ${(props) => props.theme.palette.secondary};
  font-size: 14px;
  line-height: 26px;
  display: flex;
  width: 100%;
  align-self: center;
`;
