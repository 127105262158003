import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const competitorsIndustryScoresSelector = () =>
  createSelector(
    [(state: IReduxState) => state.score.industriesScores],
    (competitorsIndustryScores) => competitorsIndustryScores,
  );

export default competitorsIndustryScoresSelector;
