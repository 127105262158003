import { AnyAction } from 'redux';

import { INew } from 'src/types/new';

import * as userConstants from '../constants/user';

export interface INewsReducerState {
  userNews: INew[];
  offset: number;
  limit: number | null;
  hasMoreNews: boolean;
  loading: boolean;
}

const defaultState: INewsReducerState = {
  userNews: [],
  offset: 0,
  hasMoreNews: true,
  limit: null,
  loading: false,
};

const setNewsLoading = (state: INewsReducerState, loading: boolean) => {
  return {
    ...state,
    loading,
  };
};

const setUserNews = (state: INewsReducerState, userNews: INew[]) => {
  return {
    ...state,
    userNews: userNews,
  };
};

const setMoreUserNews = (
  state: INewsReducerState,
  userNews: INew[],
  hasMoreNews: boolean | undefined,
) => {
  return {
    ...state,
    userNews: [...state.userNews, ...userNews],
    hasMoreNews: hasMoreNews !== undefined ? hasMoreNews : state.hasMoreNews,
  };
};

const setPageNumber = (state: INewsReducerState, offset: number, limit: number | null) => ({
  ...state,
  offset,
  limit,
});

const initializeNewsView = (state: INewsReducerState, userNews: INew[]) => {
  return {
    ...state,
    userNews: [...state.userNews, ...userNews],
    hasMoreNews: true,
  };
};

const newsReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW:
    case userConstants.USER_ON_INITIALIZE_NEWS_VIEW:
      return setNewsLoading(setUserNews(state, []), true);
    case userConstants.USER_ON_LOAD_MORE_NEWS:
      return setNewsLoading(state, true);
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED:
    case userConstants.USER_ON_LOAD_MORE_NEWS_SUCCEEDED:
      return setNewsLoading(
        setMoreUserNews(
          setPageNumber(state, action.offset, action.limit),
          action.news,
          action.hasMore,
        ),
        false,
      );
    case userConstants.USER_ON_INITIALIZE_NEWS_VIEW_SUCCEEDED:
      return setNewsLoading(
        initializeNewsView(setPageNumber(state, action.offset, action.limit), action.news),
        false,
      );
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED:
    case userConstants.USER_ON_INITIALIZE_NEWS_VIEW_FAILED:
      return setNewsLoading(state, false);
    default:
      return state;
  }
};

export default newsReducer;
