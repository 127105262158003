import i18next from 'i18next';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import * as userActions from 'src/redux/actions/user';
import menuSliderSelector from 'src/redux/selectors/user/menuSlider';
import userDataSelector from 'src/redux/selectors/user/userData';
import userMessageSelector from 'src/redux/selectors/userMessage/userMessage';

import { ReactComponent as benchmarkIconActive } from 'src/assets/menu/icon-benchmark-active.svg';
import { ReactComponent as benchmarkIconInactive } from 'src/assets/menu/icon-benchmark-inactive.svg';
import { ReactComponent as exploreIconActive } from 'src/assets/menu/icon-explore-active.svg';
import { ReactComponent as exploreIconInactive } from 'src/assets/menu/icon-explore-inactive.svg';
import { ReactComponent as summaryIconActive } from 'src/assets/menu/icon-summary-active.svg';
import { ReactComponent as summaryIconInactive } from 'src/assets/menu/icon-summary-inactive.svg';
import { ReactComponent as insightsIconActive } from 'src/assets/menu/motive_icons_insights-active.svg';
import { ReactComponent as insightsIconInactive } from 'src/assets/menu/motive_icons_insights-inactive.svg';
import { ReactComponent as newsIconActive } from 'src/assets/menu/motive_icons_news-active.svg';
import { ReactComponent as newsIconInactive } from 'src/assets/menu/motive_icons_news-inactive.svg';
import { ReactComponent as salesIconInactive } from 'src/assets/menu/motive_icons_sales inactive.svg';
import { ReactComponent as salesIConActive } from 'src/assets/menu/motive_icons_sales-active.svg';
import Footer from 'src/components/Layout/Footer';
import Header from 'src/components/Layout/Header';
import HeaderMobile from 'src/components/Layout/HeaderMobile';
import SidebarMenu from 'src/components/Layout/SidebarMenu';
import { IMenuRoute } from 'src/components/Layout/SidebarMenu/types';
import { MenuRoutes } from 'src/enums/routes';
import { trackEvent } from 'src/helpers/analytics';
import { SIZES } from 'src/helpers/devices';
import history from 'src/helpers/history';
import { getUserRoleMenuRoute } from 'src/helpers/userRoutes';
import { useTypedSelector } from 'src/hooks/typeSelector';

interface ILayoutContainerProps {
  isPrivate?: boolean;
  children: ReactNode;
}

const GlobalContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ViewContainer = styled.div`
  width: 100%;
`;

const LayoutContainer = (props: ILayoutContainerProps) => {
  const { children, isPrivate = true } = props;
  const dispatch = useDispatch();
  const user = useTypedSelector(userDataSelector());
  const isMenuOpen = useTypedSelector(menuSliderSelector());
  const messageUser = useTypedSelector(userMessageSelector());

  const isTablet = useMediaQuery({
    query: `(max-width: ${SIZES.desktop}px)`,
  });
  const currentRoute = history.location.pathname.slice(1);

  const menuRoutes: IMenuRoute[] = [
    {
      value: MenuRoutes.SUMMARY,
      route: MenuRoutes.SUMMARY,
      label: MenuRoutes.SUMMARY,
      icon: currentRoute === MenuRoutes.SUMMARY ? summaryIconActive : summaryIconInactive,
    },
    {
      value: MenuRoutes.BENCHMARK,
      route: MenuRoutes.BENCHMARK,
      label: MenuRoutes.BENCHMARK,
      icon: currentRoute === MenuRoutes.BENCHMARK ? benchmarkIconActive : benchmarkIconInactive,
    },
    {
      value: MenuRoutes.INSIGHTS,
      route: MenuRoutes.INSIGHTS,
      label: MenuRoutes.INSIGHTS,
      icon: currentRoute === MenuRoutes.INSIGHTS ? insightsIconActive : insightsIconInactive,
    },
    {
      value: MenuRoutes.NEWS,
      route: MenuRoutes.NEWS,
      label: MenuRoutes.NEWS,
      icon: currentRoute === MenuRoutes.NEWS ? newsIconActive : newsIconInactive,
    },
    {
      value: MenuRoutes.EXPLORE,
      route: MenuRoutes.EXPLORE,
      label: i18next.t('HEADER_EXPLORE:TITLE'),
      icon: currentRoute === MenuRoutes.EXPLORE ? exploreIconActive : exploreIconInactive,
    },
    {
      value: MenuRoutes.SALES,
      route: MenuRoutes.SALES,
      label: MenuRoutes.SALES,
      icon: currentRoute === MenuRoutes.SALES ? salesIConActive : salesIconInactive,
    },
  ];

  const onLogout = () => {
    trackEvent('Log Out', {
      user: {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
      },
      company: user?.company.name,
    });
    dispatch(userActions.signOut());
  };

  const onHelpClick = () => {
    dispatch(userActions.onHelpModeNext(currentRoute));
  };

  const handleTrackEvent = (event: string) => {
    dispatch(userActions.onTrackEvent(event));
  };

  const handleOpenContactUs = () => {
    dispatch(userActions.onClickOpenContactUs(true));
  };

  const handleOpenMenu = (openMenu: boolean) => {
    dispatch(userActions.onClickSliderMenu(openMenu));
  };

  const closeUserMessage = useCallback(() => {
    dispatch(userActions.closeUserMessage());
  }, [dispatch]);

  useEffect(() => {
    if (messageUser !== null && messageUser.messageType !== null) {
      setTimeout(() => closeUserMessage(), 5000);
    }
  }, [messageUser, closeUserMessage]);

  return (
    <GlobalContainer>
      {user && !isTablet && (
        <SidebarMenu
          menuRoutes={getUserRoleMenuRoute(user, menuRoutes)}
          currentPage={currentRoute}
          onHelpClick={onHelpClick}
          handleTrackEvent={handleTrackEvent}
          onClickopenContactUs={handleOpenContactUs}
          onClickOpenMenu={handleOpenMenu}
          isMenuOpen={isMenuOpen}
        />
      )}
      <ViewContainer>
        {isTablet && user && (
          <HeaderMobile
            onLogout={onLogout}
            currentPage={currentRoute}
            menuRoutes={getUserRoleMenuRoute(user, menuRoutes)}
            onClickopenContactUs={handleOpenContactUs}
          />
        )}
        {user && (
          <Header
            companyLogo={user.company.logo!}
            companyName={user.company.name!}
            currentPage={currentRoute}
            realScore={user.company.lastUpdatedScore?.realScore || null}
            onLogout={onLogout}
            isTablet={isTablet}
            userMessage={messageUser}
          />
        )}
        {isPrivate && !user ? null : children}
        <Footer handleTrackEvent={handleTrackEvent} />
      </ViewContainer>
    </GlobalContainer>
  );
};

export default LayoutContainer;
