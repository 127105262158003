import * as companyConstants from 'src/redux/constants/company';
import * as userConstants from 'src/redux/constants/user';

import { CompetitorsType } from 'src/enums/competitorsType';
import { PillarScores } from 'src/enums/pillarScores';
import { sectorIndustry } from 'src/enums/sectorIndustry';
import {
  identify,
  trackEvent,
  trackFilteredPillarsScores,
  trackHelpModule,
  trackPageView,
} from 'src/helpers/analytics';
import { pillarScoreTexts } from 'src/helpers/pillarsScores';
import { ICompany } from 'src/types/company';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

import * as appConstants from '../constants/app';
import { getCompetitorsData } from '../selectors/company/competitors';
import { getUserSettings } from '../selectors/user/settings';
import { getUserData } from '../selectors/user/userData';

const analyticsMiddleware = (state: any) => (next: any) => (action: any) => {
  const user: IUser | null = getUserData(state.getState());
  const benchmarkCompetitors: ICompany[] | null = getCompetitorsData(
    state.getState(),
    CompetitorsType.BENCHMARK,
  );
  const salesCompetitors: ICompany[] | null = getCompetitorsData(
    state.getState(),
    CompetitorsType.SALES,
  );
  const userSettings: IUserSettings | null = getUserSettings(state.getState());
  switch (action.type) {
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_SUCCEDED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_SUCCEDED:
      if (action.user) {
        identify(action.user);
      }
      break;
    case appConstants.APP_ON_ROUTE_CHANGE:
      trackPageView(action.location);
      break;
    case userConstants.USER_ON_HELP_NEXT:
      trackHelpModule(action.currentRoute, action.currentModule, true, user!);
      break;
    case userConstants.USER_ON_HELP_BACK:
      trackHelpModule(action.currentRoute, action.currentModule, false, user!);
      break;

    case userConstants.USER_ON_HELP_CLOSE:
      trackEvent(`Tutorial ${action.currentRoute} close`, {
        user: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
        company: user?.company.name,
        currentModule: action.currentModule,
      });
      break;
    case companyConstants.ON_ADD_COMPETITOR_REQUESTED:
      let allCompetitors;
      switch (action.competitorType) {
        case CompetitorsType.SALES:
          allCompetitors = salesCompetitors?.map((element) => element.name);
          break;
        default:
          allCompetitors = benchmarkCompetitors?.map((element) => element.name);
          break;
      }
      trackEvent(`Add competitor in ${action.competitorType}`, {
        user: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
        company: user?.company.name,
        newCompetitor: action.newCompetitor.name,
        allCompetitors,
      });
      break;
    case companyConstants.ON_REMOVE_COMPETITOR_REQUESTED:
      let removeAllCompetitors;
      switch (action.competitorType) {
        case CompetitorsType.SALES:
          removeAllCompetitors = salesCompetitors
            ?.filter((element) => element.name !== action.competitor.name)
            .map((element) => element.name);
          break;
        default:
          removeAllCompetitors = benchmarkCompetitors
            ?.filter((element) => element.name !== action.competitor.name)
            .map((element) => element.name);
          break;
      }

      trackEvent(`Remove competitor in ${action.competitorType}`, {
        user: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
        company: user?.company.name,
        deleteCompetitor: action.competitor.name,
        allCompetitors: removeAllCompetitors,
      });
      break;
    case userConstants.USER_ON_CHANGE_SETTINGS:
      if (userSettings.pillarScoreRank !== action.settings.pillarScoreRank) {
        trackEvent('Change Pillars Score Rank', {
          user: {
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
          },
          company: user?.company.name,
          pillarsScoreRank: pillarScoreTexts.find(
            (element) => element.value === action.settings.pillarScoreRank,
          )?.text,
          oldPillarsScoreRank: pillarScoreTexts.find(
            (element) => element.value === userSettings.pillarScoreRank,
          )?.text,
        });
        break;
      }
      const removeFilteredPillarScore = userSettings.filteredPillarScores.filter(
        (pillars: PillarScores) => !action.settings.filteredPillarScores.includes(pillars),
      );
      if (removeFilteredPillarScore.length > 0) {
        trackFilteredPillarsScores(
          removeFilteredPillarScore,
          action.settings.filteredPillarScores,
          true,
          user!,
        );
        break;
      }
      const addFilteredPillarScore = action.settings.filteredPillarScores.filter(
        (pillars: PillarScores) => !userSettings.filteredPillarScores.includes(pillars),
      );
      if (addFilteredPillarScore.length > 0) {
        trackFilteredPillarsScores(
          addFilteredPillarScore,
          action.settings.filteredPillarScores,
          false,
          user!,
        );
      }
      break;
    case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED:
      trackEvent('Sales target searched', {
        user: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
        company: user?.company.name,
        targetCompany: decodeURIComponent(action.companyName),
        targetIndustry: decodeURIComponent(action.industry),
        targetSector:
          sectorIndustry.find((sectorInformation) =>
            sectorInformation.industry.includes(decodeURIComponent(action.industry)),
          )?.sector || '',
      });
      break;
    case userConstants.USER_ON_TRACK_EVENT:
      trackEvent(action.event, {
        user: {
          name: `${user?.firstName} ${user?.lastName}`,
          email: user?.email,
        },
        company: user?.company.name,
        ...action.data,
      });
      break;
    default:
      break;
  }
  next(action);
};

export default analyticsMiddleware;
