import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'USER_MESSAGE', {
  EXPIRE_TOKEN: 'Token expired',
  INVALID_USER: 'Invalid user',
  SERVER_ERROR: 'There was an error in the server!',
  FAIL_CONTACT_US: 'The email was not sent correctly. Please try again!',
});
