import i18next from 'i18next';
import React, { useState } from 'react';

import Modal from 'src/components/Modal';

import CheckBoxInput from '../Inputs/CheckBoxInput';
import './i18n.ts';
import { Title, Wrapper, WrapperText, WrapperCheckBox } from './styles';
import { IModalExplainerProps } from './types';

const ModalExplainer = (props: IModalExplainerProps) => {
  const { open, message, onClose, onClickDontShowAgain } = props;

  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);

  const handleChecBoxChecked = () => {
    setIsCheckBoxChecked(!isCheckBoxChecked);
  };

  const handleOnClose = () => {
    onClickDontShowAgain(isCheckBoxChecked);
    onClose();
  };

  return (
    <Modal width={480} open={open} onClose={handleOnClose}>
      <Wrapper>
        <Title data-testid={'modal-explanier-title'}>{i18next.t('MODAL_EXPLANIER:TITLE')}</Title>
        <WrapperText data-testid={'modal-explanier-message'}>{message}</WrapperText>
        <WrapperCheckBox>
          <CheckBoxInput
            isChecked={isCheckBoxChecked}
            label={i18next.t('MODAL_EXPLANIER:LABEL_CHECKBOX')}
            onChange={handleChecBoxChecked}
            value={isCheckBoxChecked}
            key={'modal-explanier-select-input'}
          />
        </WrapperCheckBox>
      </Wrapper>
    </Modal>
  );
};

export default ModalExplainer;
