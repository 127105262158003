import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';

import { PillarScores } from 'src/enums/pillarScores';
import { IDataItem, IGroupPillarValues, IPillarValues } from 'src/helpers/types/data';
import { IIndustryScores } from 'src/types/score';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

import selectedExploreTabSelector from '../selectors/company/selectedExploreTab';
import companyDataSelector from '../selectors/data/companyData';
import pillarValuesSelector from '../selectors/data/pillarValues';
import userDataSelector from '../selectors/user/userData';
import { getPillarValues, getData } from '../services/data';
import { getIndustryRealScores, getScoresByIndustry } from '../services/score';
import { getUserSettings } from '../services/user';

function* initializeExplore() {
  try {
    const user: IUser = yield select(userDataSelector());
    const [industryScores, ESGScores, userSettings]: [number[], IIndustryScores[], IUserSettings] =
      yield all([
        call(getIndustryRealScores, user.company.industry),
        call(
          getScoresByIndustry,
          [PillarScores.GOVERNANCE, PillarScores.ENVIRONMENT, PillarScores.SOCIAL],
          user.company.industry,
        ),
        call(getUserSettings),
      ]);
    yield put(
      userActions.initializeExploreViewSucceeded(industryScores, userSettings, [
        {
          industry: user.company.industry,
          data: ESGScores,
        },
      ]),
    );
  } catch (error: any) {
    yield put(userActions.initializeExploreViewFailed(error.data.message));
  }
}

function* getPillars(action: AnyAction) {
  try {
    const user: IUser = yield select(userDataSelector());
    const tabSelectedExplore: string = yield select(selectedExploreTabSelector());
    const companyData: IDataItem[] = yield select(companyDataSelector());
    const pillarValues: IGroupPillarValues[] = yield select(pillarValuesSelector());
    if (
      tabSelectedExplore !== '' &&
      (!companyData.find((data) => data.group === tabSelectedExplore) ||
        !pillarValues.find((pillarValue) => pillarValue.group === tabSelectedExplore))
    ) {
      const [companyData, pillarValues]: [IDataItem[], IPillarValues[]] = yield all([
        call(getData, action.selectedExploreTab),
        call(getPillarValues, user.company.industry, action.selectedExploreTab),
      ]);
      yield put(
        userActions.setSelectedExploreTabSucceded(companyData, [
          {
            group: action.selectedExploreTab,
            data: pillarValues,
          },
        ]),
      );
    }
  } catch (error: any) {
    yield put(userActions.setSelectedExploreTabFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW, initializeExplore),
  takeLatest(userConstants.USER_ON_CHANGE_DATA_TAB, getPillars),
]);
