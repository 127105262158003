import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';

import { MAX_NOTICE_IN_NEWS_PAGE } from 'src/configs/configs';
import { IFilterResponse } from 'src/types/http';
import IUser from 'src/types/user';

import offsetNewsSelector from '../selectors/news/offsetNews';
import userDataSelector from '../selectors/user/userData';
import { getForCompany } from '../services/news';

function* initializeNews() {
  try {
    const user: IUser = yield select(userDataSelector());
    const newsResult: IFilterResponse = yield call(
      getForCompany,
      user.company.id,
      user.company.industry,
      MAX_NOTICE_IN_NEWS_PAGE,
      0,
    );
    yield put(
      userActions.initializeNewsViewSucceeded(newsResult.items, MAX_NOTICE_IN_NEWS_PAGE, 5),
    );
  } catch (error: any) {
    yield put(userActions.initializeNewsViewFailed(error.data.message));
  }
}

function* setMoreNews() {
  try {
    const user: IUser = yield select(userDataSelector());
    const offset: number = yield select(offsetNewsSelector());
    const result: IFilterResponse = yield call(
      getForCompany,
      user.company.id,
      user.company.industry,
      MAX_NOTICE_IN_NEWS_PAGE,
      offset,
    );
    yield put(
      userActions.onLoadMoreNewsSucceeded(
        result.items,
        offset + MAX_NOTICE_IN_NEWS_PAGE < result.total,
        offset + MAX_NOTICE_IN_NEWS_PAGE,
        MAX_NOTICE_IN_NEWS_PAGE,
      ),
    );
  } catch (error: any) {
    yield put(userActions.onLoadMoreNewsFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_NEWS_VIEW, initializeNews),
  takeLatest(userConstants.USER_ON_LOAD_MORE_NEWS, setMoreNews),
]);
