import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox } from '@mui/material';
import React from 'react';

import { Label, WrapperCheckbox, WrapperLabel } from './styles';
import { ICheckboxInput } from './types';

const CheckBoxInput = (props: ICheckboxInput) => {
  const { value, label, isChecked, onChange } = props;

  const handleChange = () => {
    onChange(value);
  };

  return (
    <WrapperCheckbox>
      <Checkbox
        inputProps={{
          // @ts-ignore
          'data-testid': 'checkBoxInput',
        }}
        id={value.toString()}
        checked={isChecked}
        onChange={handleChange}
        icon={<RadioButtonUncheckedIcon />}
        checkedIcon={<RadioButtonCheckedIcon color={'action'} />}
      />
      <WrapperLabel>
        <Label htmlFor={value.toString()}>{label}</Label>
      </WrapperLabel>
    </WrapperCheckbox>
  );
};

export default CheckBoxInput;
