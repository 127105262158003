import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { CompetitorsType } from 'src/enums/competitorsType';

export const getCompetitorsData = (state: IReduxState, competitorsType: CompetitorsType) => {
  switch (competitorsType) {
    case CompetitorsType.SALES:
      return state.user.userSettings.salesCompetitors;
    default:
      return state.user.userSettings.benchmarkCompetitors;
  }
};

const companyCompetitorsSelector = (competitorsType: CompetitorsType) =>
  createSelector(
    [
      (state: IReduxState) => state.user.userSettings.benchmarkCompetitors,
      (state: IReduxState) => state.user.userSettings.salesCompetitors,
    ],
    (benchmarkCompetitors, salesCompetitors) => {
      switch (competitorsType) {
        case CompetitorsType.SALES:
          return salesCompetitors;
        default:
          return benchmarkCompetitors;
      }
    },
  );

export default companyCompetitorsSelector;
