import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'HEADER_EXPLORE', {
  TITLE: 'Explore & Validate',
  SUBTITLE: 'the data ESG rating agencies are collecting on you',
  TITLE_MODAL_EXPORT: 'Download explore',
  BUTTON_EXPORT: 'DOWNLOAD',
});
