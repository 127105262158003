import { get } from './api';

export const getForCompany = (
  companyId: string,
  industry: string,
  limit: number,
  offset: number,
) => {
  return get(
    `/news/company?company=${companyId}&industry=${industry}&limit=${limit}&offset=${offset}`,
  );
};
