import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'SALES', {
  TITLE_MODAL_EXPORT: 'Download sales',
});

i18next.addResources(locales.EN, 'TUTORIAL_SALES', {
  TITLE_FIRST_MODULE: 'What Matters Most to Your Client',
  DESCRIPTION_FIRST_MODULE:
    'The Motive Universal Materiality Filter analyzes your client’s structure, culture, and operations against 135+ distinct metrics and KPIs organized as 15 sustainability pillars. The top-5 sustainability pillars that matter most to your client are presented here.',
  TITLE_SECOND_MODULE: 'How Your Industry Performs On These Issues',
  DESCRIPTION_SECOND_MODULE:
    'Quickly see how you are performing across each of the top-5 sustainability pillars that matter most to your client and drill down into key metrics, as measured by market-leading ESG rating agencies, of your performance for each pillar. Positive indicators denote above industry-average performance and are stories that could be worth focusing on, while Negative indicators denote below industry-average performance and are stories that may be best left to later discussions (if necessary).',
  TITLE_THIRD_MODULE: 'Competitive Benchmarking',
  DESCRIPTION_THIRD_MODULE:
    'Understand your competitive landscape. Compare your company against up-to five competitors of your choice to see who demonstrates positive or negative performances across the top-5 sustainability pillars that matter most to your client. Enter competitor’s names in the search box and select from the auto-complete menu, and change your selections as often as you like. Get into the details of your competitive landscape. Switch to the graph-view and see how you compare against your selected competitors across the key metrics of each of the top-5 sustainability pillars that matter most to your client.',
});

i18next.addResources(locales.EN, 'TUTORIAL_SALES_WITHOUT_SCORES', {
  TITLE_FIRST_MODULE: 'What Matters Most to Your Client',
  DESCRIPTION_FIRST_MODULE:
    'The Motive Universal Materiality Filter compares your client’s structure, culture, and operations against 135+ distinct metrics and KPIs organized as 15 sustainability themes. The top-5 sustainability pillars that matter most to your client are presented here.',
  TITLE_SECOND_MODULE: 'How Your Industry Performs On These Issues',
  DESCRIPTION_SECOND_MODULE:
    'Quickly see how companies in your industry are performing across each of the top-5 sustainability pillars that matter most to your client and drill down into key metrics, as measured by market-leading ESG rating agencies, of industry performance for each pillar. Compare your internal data about your company’s own sustainability performance against these indicators. Performance which is above industry-average in any of these metrics is a Positive indicator and could be worth sharing with prospective clients, while performance below industry-average is a Negative indicator that may be best left to later discussions (if necessary).',
  TITLE_THIRD_MODULE: 'Competitive Benchmarking',
  DESCRIPTION_THIRD_MODULE:
    'Understand your competitive landscape. Compare up-to five competitors of your choice to see who demonstrates positive or negative performances across the top-5 sustainability pillars that matter most to your client. Enter competitor’s names in the search box and select from the auto-complete menu, and change your selections as often as you like. Get into the details of your competitive landscape. Switch to the graph-view and see how your selected competitors perform across the key metrics of each of the top-5 sustainability pillars that matter most to your client.',
});
