import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { CompetitorsType } from 'src/enums/competitorsType';

const searchedCompaniesSelector = (competitorsType: CompetitorsType) =>
  createSelector(
    [
      (state: IReduxState) => state.company.searchedCompanies,
      (state: IReduxState) => state.user.user?.company,
      (state: IReduxState) => {
        switch (competitorsType) {
          case CompetitorsType.SALES:
            return state.user.userSettings.salesCompetitors;
          default:
            return state.user.userSettings.benchmarkCompetitors;
        }
      },
    ],
    (searchedCompanies, userCompany, competitors) => {
      const currentCompetitorsIds = (competitors?.map((c) => c.id) || []) as string[];
      return searchedCompanies
        .filter(
          (company) =>
            company.id !== userCompany?.id &&
            !currentCompetitorsIds.includes(company.id) &&
            company.lastUpdatedScore,
        )
        .sort();
    },
  );

export default searchedCompaniesSelector;
