import { Tooltip } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import Modal from 'src/components/Modal';

import Help from './components/Help';
import './i18n';
import {
  ContentWrapper,
  HelpIcon,
  MultipleScoresWrapper,
  RatingCoverageWrapper,
  Score,
  ScoreLabel,
  ScoreNumber,
  ScoresWrapper,
  Title,
  TitleWrapper,
} from './styles';
import { IRatingCoverageModuleProps } from './types';

const RatingCoverageModule = (props: IRatingCoverageModuleProps) => {
  const { scores, handleTrackEvent } = props;
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const handleOpenHelp = () => {
    setIsHelpOpen(true);
    handleTrackEvent('Open Rating Coverage help');
  };

  const handleCloseHelp = () => {
    setIsHelpOpen(false);
    handleTrackEvent('Close Rating Coverage help');
  };

  return (
    <>
      <Modal open={isHelpOpen} onClose={handleCloseHelp} width={800}>
        <Help />
      </Modal>
      <RatingCoverageWrapper>
        <TitleWrapper>
          <Title data-testid="rating-coverage-title">
            {i18next.t('RATING_COVERAGE_MODULE:TITLE')}
          </Title>
          <HelpIcon onClick={handleOpenHelp} />
        </TitleWrapper>
        <ContentWrapper>
          <ScoresWrapper>
            {scores.map((scoreProvider) => {
              return (
                <Score>
                  <ScoreLabel>
                    {i18next.t(`RATING_COVERAGE_MODULE:${scoreProvider.provider}`)}
                  </ScoreLabel>
                  {scoreProvider.scores.length === 1 ? (
                    <ScoreNumber> {scoreProvider.scores[0].score} </ScoreNumber>
                  ) : (
                    scoreProvider.scores.map((score) => {
                      return (
                        <MultipleScoresWrapper>
                          <Tooltip title={score.scoreName} placement="bottom" arrow>
                            <ScoreNumber>
                              {score.scoreName[0].toUpperCase()}: {score.score}
                            </ScoreNumber>
                          </Tooltip>
                        </MultipleScoresWrapper>
                      );
                    })
                  )}
                </Score>
              );
            })}
          </ScoresWrapper>
        </ContentWrapper>
      </RatingCoverageWrapper>
    </>
  );
};

export default RatingCoverageModule;
