import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const LogoWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    width: 100%;
  }
  max-width: 200px;
  align-self: center;
  margin-bottom: 20px;
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputsWrapper = styled.div`
  width: 568px;
  height: auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const TextWrapper = styled.div`
  margin-bottom: 40px;
  text-align: center;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 24px;
`;
