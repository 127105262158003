import { DataGroup } from 'src/enums/data';

import { get } from './api';

export const getData = (group: DataGroup) => {
  return get('data/company/me', {
    group,
  });
};

export const getUserMateriality = (industry: string) => {
  return get(`data/industry/materiality?industry=${industry}`);
};

export const getPillarValues = (industry: string, group: DataGroup) => {
  return get(`data/industry/${encodeURIComponent(industry)}?group=${group}`);
};
