import styled from 'styled-components';

import { FOOTER_HEIGHT, SIZES } from 'src/helpers/devices';

export const Container = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    height: 68px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  height: ${FOOTER_HEIGHT}px;
  background-color: ${(props) => props.theme.palette.lightSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
`;

export const Separator = styled.p`
  font-size: 11px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.grey};
  margin: 0 5px;
`;

export const Text = styled.p`
  @media (max-width: ${SIZES.bigPhone}px) {
    font-size: 14px;
    line-height: 20px;
  }
  font-size: 11px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.common.white};
  text-decoration: none;
`;

export const TextLink = styled.a`
  @media (max-width: ${SIZES.bigPhone}px) {
    font-size: 14px;
    line-height: 20px;
  }

  font-size: 11px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.tertiary};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.quaternary};
  }
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: row;
`;
