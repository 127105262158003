import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'FOOTER', {
  COPYRIGHT: 'Motive Development Inc. Copyright ©2022',
  PRIVACY: 'Privacy Policy',
  TERMS_AND_CONDITION: 'Terms and Conditions',
  TRACK_PRIVACY: 'Redirect to Privacy Policy link',
  TRACK_TERMS_AND_CONDITION: 'Redirect to Terms And Condition link',
});
