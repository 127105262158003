import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

export const getUserData = (state: IReduxState) => {
  return state.user.user;
};

const userDataSelector = () =>
  createSelector([(state: IReduxState) => state.user.user], (user) => user);

export default userDataSelector;
