import { Help } from '@mui/icons-material';
import styled from 'styled-components';

import { ReactComponent as BackArrowIcon } from 'src/assets/menu/back-arrow.svg';
import { SIZES } from 'src/helpers/devices';

export const ExportButton = styled.button`
  max-width: 150px;
  min-width: 150px;
  min-height: 40px;
  flex: 1;
  border-radius: 10px;
  background-color: #32cc6f;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.white};
  &:hover {
    background-color: #2fbc67;
  }
`;

export const BackArrow = styled(BackArrowIcon)`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

export const HelpIcon = styled(Help)`
  display: flex;
  width: 15px;
  height: 15px;
  color: ${(props) => props.theme.palette.tertiary};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.lightSecondary};
  }
`;
export const WrapperHelp = styled.div`
  display: flex;
  align-self: flex-end;
  padding-left: 5px;
`;

export const WrapperButton = styled.div`
  flex: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const WrapperText = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    flex-direction: column;
    align-items: center;
  }
  flex: 9;
  display: flex;
  align-items: baseline;
`;
export const Title = styled.p`
  @media (max-width: ${SIZES.mediumTablet}px) {
    font-size: 25px;
    line-height: 34px;
    color: ${(props) => props.theme.palette.lightSecondary};
  }
  font-size: 28px;
  line-height: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-right: 5px;
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.mediumTablet}px) {
    padding-top: 20px;
    font-size: 18px;
    line-height: 26px;
    color: ${(props) => props.theme.palette.common.grey};
  }
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const WrapperSubtitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperTitle = styled.div`
  @media (max-width: ${SIZES.bigTablet}px) {
    width: 100%;
  }
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
