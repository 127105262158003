import { SelectChangeEvent } from '@mui/material';
import i18next from 'i18next';
import React, { useState } from 'react';

import TextInput from 'src/components/Inputs/TextInput';
import LottiePlayer from 'src/components/LottiePlayer';
import { ReasonContactUs } from 'src/enums/contactUs';

import AnimationPath from '../../animation/contactUs/Motive-icon-send-email.json';
import TextButton from '../../components/Buttons/TextButton';
import SelectInput from '../../components/Inputs/SelectInput';
import { ISelectOption } from '../../components/Inputs/SelectInput/types';
import Modal from '../../components/Modal';
import './i18n.ts';
import {
  Title,
  Wrapper,
  WrapperInput,
  WrapperButton,
  WrapperMessageInput,
  DataLabel,
  DataText,
  WrapperAnimation,
  WrapperTextSuccess,
  WrapperSelect,
} from './styles';
import { IModalExportProps } from './types';

const ContactUsForm = (props: IModalExportProps) => {
  const { user, isLoading, isEmailSend, onClose, onSubmit } = props;
  const OptionSelect: ISelectOption[] = [
    { value: ReasonContactUs.DATA_CONTENT, text: i18next.t('MODAL_CONTACTUS:DATA_CONTENT') },
    {
      value: ReasonContactUs.TECHNICAL_SUPPORT,
      text: i18next.t('MODAL_CONTACTUS:TECHNICAL_SUPPORT'),
    },
  ];
  const [valueForm, setValueForm] = useState({ contactReason: 0, message: '' });

  const handleSelectOption = (event: SelectChangeEvent<unknown>) => {
    setValueForm({ ...valueForm, contactReason: event.target.value as ReasonContactUs });
  };

  const handleMessageUser = (value: string) => {
    setValueForm({ ...valueForm, message: value });
  };

  const handleSubmit = () => {
    const reason = OptionSelect.find((element) => element.value === valueForm.contactReason);
    onSubmit(reason!.text, valueForm.message);
  };

  return (
    <Modal width={480} open={true} onClose={onClose}>
      <Wrapper>
        <Title>{i18next.t('MODAL_CONTACTUS:TITLE')}</Title>
        {isEmailSend && (
          <>
            <WrapperAnimation>
              <LottiePlayer animationJsonPath={AnimationPath} loop autoplay />
            </WrapperAnimation>
            <WrapperTextSuccess>
              <DataLabel>{i18next.t('MODAL_CONTACTUS:SUCCESS_MESSAGE')}</DataLabel>
            </WrapperTextSuccess>
          </>
        )}
        {!isEmailSend && (
          <>
            <WrapperInput>
              <DataLabel>{i18next.t('MODAL_CONTACTUS:EMAIL')}</DataLabel>
              <DataText>{user.email}</DataText>
            </WrapperInput>
            <WrapperInput>
              <DataLabel>{i18next.t('MODAL_CONTACTUS:COMPANY')}</DataLabel>
              <DataText>{user.company.name}</DataText>
            </WrapperInput>
            <WrapperSelect>
              <SelectInput
                id={i18next.t('MODAL_CONTACTUS:REASON').toLowerCase()}
                name={i18next.t('MODAL_CONTACTUS:REASON')}
                label={i18next.t('MODAL_CONTACTUS:REASON').toString()}
                onChange={handleSelectOption}
                options={OptionSelect}
                value={valueForm.contactReason}
                disabled={false}
              />
            </WrapperSelect>
            <WrapperMessageInput>
              <TextInput
                multiline
                labelColor="grey"
                isDisabled={false}
                id={i18next.t('MODAL_CONTACTUS:MESSAGE').toLowerCase()}
                name={i18next.t('MODAL_CONTACTUS:MESSAGE')}
                placeholder={i18next.t('MODAL_CONTACTUS:MESSAGE')}
                error=""
                value={valueForm.message}
                onChange={handleMessageUser}
              />
            </WrapperMessageInput>
            <WrapperButton>
              <TextButton
                isDisabled={valueForm.message.trim() === ''}
                isLoading={isLoading}
                text={i18next.t('MODAL_CONTACTUS:BUTTON_SEND')}
                onSubmit={handleSubmit}
              />
            </WrapperButton>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default ContactUsForm;
