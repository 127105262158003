import { Fade } from '@mui/material';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import ScrollLock from 'react-scrolllock';

import AnimationToolTip from './components/AnimationToolTip';
import TextTooltip from './components/TextTooltip';
import './i18n';
import { HelpBackground, HelpTooltip, HelpWrapper, ModuleWrapper } from './styles';
import { IModuleHelpProps } from './types';

const ModuleHelp = (props: IModuleHelpProps) => {
  const {
    children,
    description,
    title,
    enabled,
    backgroundColor,
    tooltipPosition = 'right',
    animationPath,
    arrow = true,
    isAnimation,
    modalWidth = 300,
    isLast = false,
    isFirst = false,
    onClose,
    onNext,
    onBack,
  } = props;

  const moduleRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (enabled) {
      const topOffset = tooltipPosition === 'top' ? 500 : 100;
      window.scrollTo({ top: moduleRef.current.offsetTop - topOffset, behavior: 'smooth' });
    }
  }, [enabled, moduleRef, tooltipPosition]);

  return (
    <ScrollLock isActive={enabled}>
      <HelpWrapper>
        {enabled && <HelpBackground data-testid="module-help-background" onClick={onClose} />}
        <HelpTooltip
          modalWidth={isAnimation ? 430 : modalWidth}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
          title={
            <>
              {isAnimation && (
                <AnimationToolTip
                  animationPath={animationPath}
                  title={title}
                  description={description}
                  isLast={isLast}
                  isFirst={isFirst}
                  onBack={onBack}
                  onClose={onClose}
                  onNext={onNext}
                />
              )}
              {!isAnimation && (
                <TextTooltip
                  title={title}
                  description={description}
                  isLast={isLast}
                  isFirst={isFirst}
                  onClose={onClose}
                  onNext={onNext}
                  onBack={onBack}
                />
              )}
            </>
          }
          placement={tooltipPosition}
          open={enabled}
          arrow={arrow}
        >
          <ModuleWrapper ref={moduleRef} enabled={enabled} backgroundColor={backgroundColor}>
            {children}
          </ModuleWrapper>
        </HelpTooltip>
      </HelpWrapper>
    </ScrollLock>
  );
};

export default ModuleHelp;
