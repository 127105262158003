import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const contactUsSelector = () =>
  createSelector(
    [(state: IReduxState) => state.user.contactUsForm],
    (contactUsForm) => contactUsForm,
  );

export default contactUsSelector;
