import { PillarStates } from 'src/enums/pillarStates';

export const isNumber = (text: string) => {
  return !Number.isNaN(Number(text));
};

export const formatNumber = (text: string) => {
  return new Intl.NumberFormat().format(Math.round(Number(text)));
};

export const isPercentage = (text: string) => {
  const inputText = parseFloat(text);
  return (
    (!Number.isNaN(inputText) || inputText >= 0 || inputText <= 100) &&
    text.trim().slice(-1) === '%'
  );
};

export const getPillarState = (value: number) => {
  if (value >= 0 && value <= 33) {
    return PillarStates.POSITIVE;
  }
  if (value >= 34 && value <= 66) {
    return PillarStates.NEUTRAL;
  }
  return PillarStates.NEGATIVE;
};
