import i18next from 'i18next';
import React from 'react';

import HelpPNG from 'src/assets/explore/help.png';

import './i18n';
import {
  HelpImage,
  HelpWrapper,
  ImageWrapper,
  TextWrapper,
  Title,
  Text,
  WrapperTitle,
} from './styles';

const Help = () => {
  return (
    <HelpWrapper>
      <WrapperTitle>
        <Title data-testid={'title-help-explore-page'}> {i18next.t('EXPLORE_HELP:TITLE')} </Title>
      </WrapperTitle>
      <ImageWrapper data-testid={'image-help-explore-page'}>
        <HelpImage src={HelpPNG} />
      </ImageWrapper>
      <TextWrapper>
        <Text data-testid={'text-help-explore-page'}>{i18next.t('EXPLORE_HELP:TEXT')}</Text>
      </TextWrapper>
    </HelpWrapper>
  );
};

export default Help;
