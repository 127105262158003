import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, `SALES_HELP`, {
  TITLE: 'Sales section',
  TEXT: 'This section provides three key elements.',
  FIRST: 'First,',
  TEXT_FIRST:
    'you can see the top-5 sustainability pillars which matter most to your prospective client.',
  SECOND: 'Second,',
  TEXT_SECOND:
    'you can see how your own company performs in each of these pillars and access key indicators of your performance in relation to ranges of performance within your own industry. This information helps you determine where and how you can provide a strategic advantage to your prospective client.',
  THIRD: 'Third,',
  TEXT_THIRD:
    'you can compare your performance against that of competitors you select and gain insight into how you can differentiate yourself in the sustainability pillars that matter most to your prospective clients.',
  TEXT_SECOND_WITHOUT_SCORE:
    'you can see the performance metrics, including medians and ranges, of companies in your industry across each of these pillars. This information helps you determine where and how you can provide a strategic advantage to your prospective client.',
  TEXT_THIRD_WITHOUT_SCORE:
    'you drill into the performance of competitors you select and gain insight into how you can differentiate yourself in the sustainability pillars that matter most to your prospective clients.',
});
