import i18next from 'i18next';
import React from 'react';

import history from 'src/helpers/history';
import { IComment, InsightTypes, ITip, IVideo } from 'src/types/insights';

import Comment from './components/Comment';
import Tip from './components/Tip';
import Video from './components/Videos';
import './i18n';
import {
  ContentWrapper,
  InsightsWrapper,
  InsightWrapper,
  LoadingSkeleton,
  LoadingWrapper,
  Title,
  TitleWrapper,
  WrapperHeader,
  WrapperViewAll,
} from './styles';
import { IInsightModuleProps } from './types';

const InsightsModule = (props: IInsightModuleProps) => {
  const { insights } = props;

  const onViewAllClick = () => {
    history.push('/insights');
  };

  return (
    <InsightsWrapper>
      <WrapperHeader>
        <TitleWrapper>
          <Title> {i18next.t('INSIGHTS_MODULE:TITLE')} </Title>
        </TitleWrapper>
        <WrapperViewAll onClick={onViewAllClick}>
          {i18next.t('INSIGHTS_MODULE:VIEW_ALL')}
        </WrapperViewAll>
      </WrapperHeader>
      <ContentWrapper>
        {!insights && (
          <LoadingWrapper>
            <LoadingSkeleton animation="wave" height={1500} />
          </LoadingWrapper>
        )}
        {insights &&
          insights.map((insight, i) => {
            return (
              <InsightWrapper
                key={i}
                data-testid={`insight-${insight.type}`}
                includeBorder={i < insights.length - 1}
              >
                {insight.type === InsightTypes.TIP && <Tip tip={insight.data as ITip} />}
                {insight.type === InsightTypes.COMMENT && (
                  <Comment comment={insight.data as IComment} />
                )}
                {insight.type === InsightTypes.VIDEO && <Video video={insight.data as IVideo} />}
              </InsightWrapper>
            );
          })}
      </ContentWrapper>
    </InsightsWrapper>
  );
};

export default InsightsModule;
