import { PillarStates } from 'src/enums/pillarStates';

export const PositiveTab = {
  firstGreen: '#2E8632',
  secondGreen: '#3D9641',
  detailsGreen: '#EDFAED',
  borderGreen: '#2E8632',
  bgdetailsGreen: '#DDF2DD',
};

export const NeutralTab = {
  firstOrange: '#F6610C',
  secondOrange: '#E26017',
  detailsOrange: '#FFF1E2',
  borderOrange: '#F6610C',
  bgdetailsOrange: '#F8E5D1',
};

export const NegativeTab = {
  firstRed: '#C52626',
  secondRed: '#D45151',
  detailsRed: '#FDF3F3',
  borderRed: '#C32626',
  bgdetailsRed: '#F2E5E5',
};

const NoInformationTab = {
  detailsGrey: '#FAFAFA',
};

export const handleScoreColors = (scoreState: PillarStates) => {
  switch (scoreState) {
    case PillarStates.POSITIVE:
      return {
        firstColor: PositiveTab.firstGreen,
        secondColor: PositiveTab.secondGreen,
        thirdColor: PositiveTab.detailsGreen,
        fourthColor: PositiveTab.borderGreen,
        fifthColor: PositiveTab.bgdetailsGreen,
      };
    case PillarStates.NEGATIVE:
      return {
        firstColor: NegativeTab.firstRed,
        secondColor: NegativeTab.secondRed,
        thirdColor: NegativeTab.detailsRed,
        fourthColor: NegativeTab.borderRed,
        fifthColor: NegativeTab.bgdetailsRed,
      };
    case PillarStates.NOINFORMATION:
      return {
        firstColor: NoInformationTab.detailsGrey,
        secondColor: NoInformationTab.detailsGrey,
        thirdColor: NoInformationTab.detailsGrey,
        fourthColor: NoInformationTab.detailsGrey,
        fifthColor: NoInformationTab.detailsGrey,
      };
    default:
      return {
        firstColor: NeutralTab.firstOrange,
        secondColor: NeutralTab.secondOrange,
        thirdColor: NeutralTab.detailsOrange,
        fourthColor: NeutralTab.borderOrange,
        fifthColor: NeutralTab.bgdetailsOrange,
      };
  }
};
