import Arabesque from 'src/assets/scoreProviders/arabesque.svg';
import CDP from 'src/assets/scoreProviders/cdp.svg';
import ISS from 'src/assets/scoreProviders/iss.svg';
import MSCI from 'src/assets/scoreProviders/msci.svg';
import Refinitiv from 'src/assets/scoreProviders/refinitiv.svg';
import SPGlobal from 'src/assets/scoreProviders/spglobal.svg';
import Sustanalytics from 'src/assets/scoreProviders/sustanalytics.svg';
import { ScoreProviders } from 'src/types/score';

export const getScoreProviderIcon = (provider: ScoreProviders) => {
  switch (provider) {
    case ScoreProviders.ARABESQUE:
      return Arabesque;
    case ScoreProviders.SUSTAINALYTICS:
      return Sustanalytics;
    case ScoreProviders.CDP:
      return CDP;
    case ScoreProviders.SP:
      return SPGlobal;
    case ScoreProviders.REFINITIV:
      return Refinitiv;
    case ScoreProviders.MSCI:
      return MSCI;
    case ScoreProviders.ISS:
      return ISS;
    default:
      return null;
  }
};
