import { Menu as MenuIcon, Person } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.palette.secondary};
  padding: 5px 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  z-index: 1250;
`;

export const HamburguerMenu = styled(MenuIcon)`
  width: 40px !important;
  height: 40px !important;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
`;

export const MotiveLogo = styled(ReactSVG)``;

export const ProfileIcon = styled(Person)`
  width: 40px !important;
  height: 40px !important;
  color: ${(props) => props.theme.palette.common.white};
  cursor: pointer;
`;
export const WrapperMenuItem = styled(MenuItem)`
  font-size: 18px !important;
  line-height: 22px !important;
  font-family: ${(props) => props.theme.fontFamilies.bold}!important;
  color: ${(props) => props.theme.palette.secondary}!important;
`;

export const WrapperMenu = styled(Menu)`
  .MuiMenu-list {
    width: 82px;
    height: 29px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
`;
