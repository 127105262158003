import i18next from 'i18next';
import React, { useRef, useState } from 'react';

import { ICompany } from 'src/types/company';

import './i18n';
import {
  AutocompleteInput,
  CompetitorName,
  CurrentCompetitor,
  CurrentCompetitors,
  DropdownBox,
  DropdownOption,
  RemoveIcon,
  SearchBar,
  SearchBarWrapper,
  SearchIcon,
  SearchTextField,
} from './styles';
import { ISearchBarModuleProps } from './types';

const SearchBarModule = (props: ISearchBarModuleProps) => {
  const {
    currentCompetitors,
    searchCompetitors,
    onAddCompetitor,
    onRemoveCompetitor,
    onSearchCompetitor,
  } = props;
  const [value, setValue] = useState('');
  const searchInput = useRef<any>(null);

  return (
    <SearchBarWrapper data-testid="search-bar-block">
      <SearchBar>
        <AutocompleteInput
          id="search-companies"
          data-testid="autocomplete"
          autoComplete
          sx={{ height: '46px' }}
          disableClearable={true}
          inputValue={value}
          popupIcon={<SearchIcon data-testid="search-icon" />}
          getOptionLabel={(option: any) => option.name}
          noOptionsText={i18next.t('SEARCH_BAR_MODULE:NO_OPTIONS')}
          options={searchCompetitors}
          includeInputInList
          filterSelectedOptions
          PaperComponent={(props: any) => <DropdownBox elevation={8} {...props} />}
          onFocus={() => {
            setValue('');
            onSearchCompetitor('');
          }}
          onChange={(event, selectedCompany: unknown) => {
            if (selectedCompany) {
              onAddCompetitor(selectedCompany as ICompany);
              setValue('');
              searchInput.current.blur();
            }
          }}
          onInputChange={(event, searchValue) => {
            if (!event || event?.type === 'blur') return;
            if (!currentCompetitors.map((c) => c.name).includes(searchValue)) {
              setValue(searchValue);
              onSearchCompetitor(searchValue);
            }
          }}
          renderInput={(params) => (
            <SearchTextField
              {...params}
              inputRef={searchInput}
              placeholder={i18next.t('SEARCH_BAR_MODULE:INPUT_LABEL')}
              fullWidth
            />
          )}
          renderOption={(props, option: any) => {
            return (
              <DropdownOption data-testid="option" {...props}>
                {option.name}
              </DropdownOption>
            );
          }}
        />
      </SearchBar>
      <CurrentCompetitors>
        {currentCompetitors.map((competitor) => {
          return (
            <CurrentCompetitor
              key={competitor.id}
              data-testid={`competitor-label-${competitor.id}`}
            >
              <CompetitorName>{competitor.name}</CompetitorName>
              <RemoveIcon
                data-testid={`remove-competitor-button-${competitor.id}`}
                onClick={() => onRemoveCompetitor(competitor)}
              />
            </CurrentCompetitor>
          );
        })}
      </CurrentCompetitors>
    </SearchBarWrapper>
  );
};

export default SearchBarModule;
