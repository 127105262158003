import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as userActions from 'src/redux/actions/user';
import * as userConstants from 'src/redux/constants/user';

import { IInsight } from 'src/types/insights';
import IUser from 'src/types/user';

import userDataSelector from '../selectors/user/userData';
import { getInsightsBy } from '../services/insights';

function* initializeInsights() {
  try {
    const user: IUser = yield select(userDataSelector());
    const [insights]: [IInsight[]] = yield all([
      call(getInsightsBy, user.company.id, user.company.industry),
    ]);
    yield put(userActions.initializeInsightViewSucceeded(insights));
  } catch (error: any) {
    yield put(userActions.initializeInsightViewFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW, initializeInsights),
]);
