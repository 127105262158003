import React from 'react';

import { TabsWrapper, Step, TabsHeader } from './styles';
import { ITabProps, ITabsProps } from './types';

const Tabs = (props: ITabProps) => {
  const { tabs, children, valueTab, onChangeValueTab } = props;

  return (
    <TabsWrapper>
      <TabsHeader
        data-testid={'tabs-header-tabs-component'}
        value={valueTab}
        onChange={onChangeValueTab}
        id={valueTab}
      >
        {tabs.map((element: ITabsProps) => {
          return (
            <Step
              data-testid={`item-tab-tabs-component-${element.value}`}
              key={element.value}
              sx={{ width: 'auto', fontSize: '28px', marginRight: '5px', textTransform: 'none' }}
              isTabSelected={valueTab === element.value}
              value={element.value}
              label={element.text}
            />
          );
        })}
      </TabsHeader>
      {children}
    </TabsWrapper>
  );
};

export default Tabs;
