export const ON_SEARCH_COMPANY_BY_QUERY_REQUESTED = 'ON_SEARCH_COMPANY_BY_QUERY_REQUESTED';

export const ON_SEARCH_COMPANY_BY_QUERY_SUCCEDED = 'ON_SEARCH_COMPANY_BY_QUERY_SUCCEDED';

export const ON_SEARCH_COMPANY_BY_QUERY_FAILED = 'ON_SEARCH_COMPANY_BY_QUERY_FAILED';

export const ON_ADD_COMPETITOR_REQUESTED = 'ON_ADD_COMPETITOR_REQUESTED';

export const ON_REMOVE_COMPETITOR_REQUESTED = 'ON_REMOVE_COMPETITOR_REQUESTED';

export const ON_ADD_COMPETITOR_FAILED = 'ON_ADD_COMPETITOR_FAILED';

export const ON_ADD_COMPETITOR_SUCCEEDED = 'ON_ADD_COMPETITOR_SUCCEEDED';
