import { capitalize } from '@mui/material/utils';
import React, { useState } from 'react';
import TextTruncate from 'react-text-truncate';

import VideoPlayerModal from 'src/components/VideoPlayerModal';

import {
  ImageVideo,
  VideoContent,
  VideoHeaderWrapper,
  VideoTitle,
  VideoWrapper,
  PlayIcon,
  WrapperPlayIcon,
  WrapperText,
  WrapperDescription,
  WrapperImageVideo,
} from './styles';
import { IVideoProps } from './types';

const Video = (props: IVideoProps) => {
  const { video } = props;
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  return (
    <VideoWrapper>
      <VideoPlayerModal
        url={video.link}
        controls={true}
        open={isPlayerOpen}
        onClose={() => {
          setIsPlayerOpen(false);
        }}
      />
      <VideoContent>
        <WrapperImageVideo>
          <ImageVideo
            data-testid={'preview-image-video-insight-tip'}
            onClick={() => {
              setIsPlayerOpen(true);
            }}
            image={video.videoImage}
          >
            <WrapperPlayIcon>
              <PlayIcon data-testid={'icon-play-video-insight-tip'} />
            </WrapperPlayIcon>
          </ImageVideo>
        </WrapperImageVideo>
        <WrapperText>
          <VideoHeaderWrapper>
            <VideoTitle data-testid={'title-video-insight-tip'}>
              {capitalize(video.title)}
            </VideoTitle>
          </VideoHeaderWrapper>
          <WrapperDescription data-testid={'description-video-insight-tip'}>
            <TextTruncate
              line={5}
              element="p"
              truncateText="..."
              text={capitalize(video.description)}
            />
          </WrapperDescription>
        </WrapperText>
      </VideoContent>
    </VideoWrapper>
  );
};

export default Video;
