import Person from '@mui/icons-material/Person';
import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

import background from '../../assets/changePassword/backgraundChangePassword.png';

interface IErrorActive {
  isErrorActive: boolean;
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
`;

export const InputsWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    height: auto;
    width: 80%;
    padding: 20px;
  }

  width: 50%;
  height: 416px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  border-radius: 10px;
  padding: 40px;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const LogoWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 225px;
  }
  width: 60%;
  align-self: center;
  margin-bottom: 20px;
`;

export const MotiveLogo = styled.img`
  width: 100%;
  height: 100%;
`;

export const InputWrapper = styled.div<IErrorActive>`
  @media (max-width: ${SIZES.bigPhone}px) {
    margin: ${(props) => (props.isErrorActive ? '30px 0' : '10px 0 30px')};
  }
  margin: 20px 0;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 10px;
`;

export const PersonIcon = styled(Person)`
  color: ${(props) => props.theme.palette.secondary};
`;

export const TextLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const TextLink = styled.a`
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  color: ${(props) => props.theme.palette.tertiary};
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.quaternary};
  }
`;
