import { AnyAction } from 'redux';

import * as companyConstants from 'src/redux/constants/company';
import * as userConstants from 'src/redux/constants/user';

import { ICompany } from 'src/types/company';

export interface ICompanyReducerState {
  industryRealScores: number[] | null;
  searchedCompanies: ICompany[];
  loading: boolean;
}

const defaultState: ICompanyReducerState = {
  industryRealScores: null,
  searchedCompanies: [],
  loading: false,
};

const setIndustryRealScores = (state: ICompanyReducerState, industryRealScores: number[]) => ({
  ...state,
  industryRealScores,
});

const setCompanyLoading = (state: ICompanyReducerState, loading: boolean) => ({
  ...state,
  loading,
});

const setSearchedCompanies = (state: ICompanyReducerState, searchedCompanies: ICompany[]) => ({
  ...state,
  searchedCompanies,
});

const companyReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_SUCCEEDED:
      return setIndustryRealScores(state, action.industryRealScores);
    case userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW:
    case companyConstants.ON_SEARCH_COMPANY_BY_QUERY_REQUESTED:
      return setCompanyLoading(state, true);
    case userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_FAILED:
    case companyConstants.ON_SEARCH_COMPANY_BY_QUERY_FAILED:
      return setCompanyLoading(state, false);
    case userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_SUCCEEDED:
    case userConstants.USER_ON_INITIALIZE_SALES_VIEW_SUCCEEDED:
      return setSearchedCompanies(setCompanyLoading(state, false), action.searchedCompanies);
    case companyConstants.ON_SEARCH_COMPANY_BY_QUERY_SUCCEDED:
    case userConstants.USER_ON_INITIALIZE_SIGNUP_VIEW_SUCCEEDED:
    case userConstants.USER_ON_SUBMIT_TARGET_SALES_SUCCEEDED:
      return setSearchedCompanies(state, action.companies);

    default:
      return state;
  }
};

export default companyReducer;
