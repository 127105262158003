import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const industryRankSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.company.industryRealScores,
      (state: IReduxState) => state.user.user,
    ],
    (industryScores, loggedUser) => {
      if (!loggedUser || !industryScores) {
        return {
          position: 0,
          total: 0,
        };
      }

      const industryPosition = industryScores
        .sort((a, b) => b - a)
        .findIndex((score) => score === loggedUser.company.lastUpdatedScore!.realScore);

      return {
        position: industryPosition + 1,
        total: industryScores.length,
      };
    },
  );

export default industryRankSelector;
