import i18next from 'i18next';

import { locales } from 'src/configs/i18n';

i18next.addResources(locales.EN, 'HOW_COMPETITOR_PERFORM_MODULE', {
  TITLE: 'Competitive Benchmarking',
  MODULE_TITLE: 'Opportunities for Differentiation',
  MODULE_SUBTITLE:
    'Quickly see how your company performs across the top-5 sustainability pillars that matter most to your prospective client and compare with competitors of your choice.',
  MODULE_SUBTITLE_WITHOUT_SCORE:
    'Select your competitors and see how they perform across the top-5 sustainability pillars that matter most to your prospective client. Quickly determine how you can set yourself apart.',
  CHART_MODULE_TITLE: 'Key indicator performance',
  CHART_MODULE_SUBTITLE:
    'Compare your performance against selected competitors across distinct metrics.',
});
