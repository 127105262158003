import i18next from 'i18next';

import { locales } from 'src/configs/i18n';
import { ScoreProviders } from 'src/types/score';

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP`, {
  TITLE: 'Rating Coverage',
  LEARN_MORE: 'Learn more',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.SUSTAINALYTICS}`, {
  LINK: 'https://www.sustainalytics.com/',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.ARABESQUE}`, {
  LINK: 'https://www.arabesque.com/',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.MSCI}`, {
  LINK: 'https://www.msci.com/',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.REFINITIV}`, {
  LINK: 'https://www.refinitiv.com/en',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.SP}`, {
  LINK: 'https://www.spglobal.com/ratings/en/',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.CDP}`, {
  LINK: 'https://www.cdp.net/en',
});

i18next.addResources(locales.EN, `RATING_COVERAGE_HELP_${ScoreProviders.ISS}`, {
  LINK: 'https://www.issgovernance.com/esg/',
});
