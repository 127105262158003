import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const searchedCompaniesWithoutCompetitorSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.company.searchedCompanies,
      (state: IReduxState) => state.user.user?.company,
    ],
    (searchedCompanies, userCompany) => {
      return searchedCompanies
        .filter((company) => company.id !== userCompany?.id && company.lastUpdatedScore)
        .sort();
    },
  );

export default searchedCompaniesWithoutCompetitorSelector;
