import i18next from 'i18next';
import React from 'react';

import { NegativeTab, PositiveTab } from 'src/helpers/exploreTabsColors';

import './i18n';
import {
  BooleanGraphicWrapper,
  Container,
  GraphicTitle,
  NoBar,
  ValueBars,
  ValueTitle,
  ValueTitles,
  YesBar,
} from './styles';
import { IBooleanGraphicPops } from './types';

const BooleanGraphic = (props: IBooleanGraphicPops) => {
  const { percentages, value } = props;

  return (
    <Container>
      <BooleanGraphicWrapper>
        <GraphicTitle>{i18next.t('BOOLEAN_GRAPHIC:GRAPHIC_TITLE')}</GraphicTitle>
        <ValueTitles>
          <ValueTitle>{i18next.t('BOOLEAN_GRAPHIC:POSITIVE')}</ValueTitle>
          <ValueTitle>{i18next.t('BOOLEAN_GRAPHIC:NEGATIVE')}</ValueTitle>
        </ValueTitles>
        <ValueBars>
          <YesBar
            color={PositiveTab.firstGreen}
            percentage={percentages.positivePercentage}
            value={value}
          >
            {percentages.positivePercentage}%
          </YesBar>
          <NoBar
            color={NegativeTab.firstRed}
            percentage={percentages.negativePercentage}
            value={value}
          >
            {percentages.negativePercentage}%
          </NoBar>
        </ValueBars>
      </BooleanGraphicWrapper>
    </Container>
  );
};
export default BooleanGraphic;
