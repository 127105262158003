import i18next from 'i18next';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { SIZES } from 'src/helpers/devices';
import { IComment, InsightTypes, ITip, IVideo } from 'src/types/insights';

import Comment from '../Summary/components/InsightsModule/components/Comment';
import Tip from '../Summary/components/InsightsModule/components/Tip';
import VideoComment from '../Summary/components/InsightsModule/components/Videos';
import Tabs from './components/Tabs';
import Video from './components/Videos';
import './i18n';
import {
  InsightsWrapper,
  HeaderWrapper,
  WrapperText,
  Title,
  Subtitle,
  InsightGrid,
  HeaderWrapperComment,
  InsightWrapperTip,
  WrapperTips,
  InsightWrapperComment,
  WrapperComments,
} from './styles';
import { IInsightsProps } from './types';

const Insights = (props: IInsightsProps) => {
  const { userInsights } = props;
  const [selectedTab, setSelectedTab] = useState(InsightTypes.TIP);

  const handleChange = (event: React.SyntheticEvent, newValue: InsightTypes) => {
    setSelectedTab(newValue);
  };
  const tabs = [
    { value: InsightTypes.TIP, text: i18next.t('INSIGHTS:TITLE') },
    { value: InsightTypes.COMMENT, text: i18next.t('INSIGHTS:TITLE_COMMENTS') },
  ];
  const isMobile = useMediaQuery({
    query: `(max-width: ${SIZES.bigPhone}px)`,
  });
  const userTips =
    userInsights?.filter(
      (insights) =>
        insights.type === InsightTypes.TIP ||
        (insights.type === InsightTypes.VIDEO &&
          (insights.data as IVideo).subCategory === InsightTypes.TIP),
    ) || [];
  const userComments =
    userInsights?.filter(
      (insights) =>
        insights.type === InsightTypes.COMMENT ||
        (insights.type === InsightTypes.VIDEO &&
          (insights.data as IVideo).subCategory === InsightTypes.COMMENT),
    ) || [];

  const renderMobileInsights = () => {
    switch (selectedTab) {
      case InsightTypes.COMMENT:
        return userComments.map((comment) => {
          if (
            comment.type === InsightTypes.VIDEO &&
            (comment.data as IVideo).subCategory === InsightTypes.COMMENT
          ) {
            return (
              <InsightWrapperComment>
                <WrapperComments>
                  <VideoComment video={comment.data as IVideo} />
                </WrapperComments>
              </InsightWrapperComment>
            );
          }
          return (
            <InsightWrapperComment>
              <WrapperComments>
                <Comment comment={comment.data as IComment} disableTitle={true} />{' '}
              </WrapperComments>
            </InsightWrapperComment>
          );
        });
      default:
        return (
          <InsightWrapperTip>
            {userTips.map((tip) => {
              if (
                tip.type === InsightTypes.VIDEO &&
                (tip.data as IVideo).subCategory === InsightTypes.TIP
              ) {
                return (
                  <WrapperTips>
                    <Video video={tip.data as IVideo} />
                  </WrapperTips>
                );
              }
              return (
                <WrapperTips>
                  <Tip tip={tip.data as ITip} />
                </WrapperTips>
              );
            })}
          </InsightWrapperTip>
        );
    }
  };

  return (
    <InsightsWrapper>
      {isMobile && (
        <Tabs valueTab={selectedTab} onChangeValueTab={handleChange} tabs={tabs}>
          {renderMobileInsights()}
        </Tabs>
      )}
      {!isMobile && (
        <InsightGrid>
          <HeaderWrapper>
            <WrapperText>
              <Title>{i18next.t('INSIGHTS:TITLE')}</Title>
              <Subtitle>{i18next.t('INSIGHTS:SUBTITLE')}</Subtitle>
            </WrapperText>
          </HeaderWrapper>
          <HeaderWrapperComment>
            <WrapperText>
              <Title>{i18next.t('INSIGHTS:TITLE_COMMENTS')}</Title>
            </WrapperText>
          </HeaderWrapperComment>
          <InsightWrapperTip>
            {userTips.map((tip) => {
              if (
                tip.type === InsightTypes.VIDEO &&
                (tip.data as IVideo).subCategory === InsightTypes.TIP
              ) {
                return (
                  <WrapperTips>
                    <Video video={tip.data as IVideo} />
                  </WrapperTips>
                );
              }
              return (
                <WrapperTips>
                  <Tip tip={tip.data as ITip} />
                </WrapperTips>
              );
            })}
          </InsightWrapperTip>
          <InsightWrapperComment>
            {userComments.map((comment) => {
              if (
                comment.type === InsightTypes.VIDEO &&
                (comment.data as IVideo).subCategory === InsightTypes.COMMENT
              ) {
                return (
                  <WrapperComments>
                    <VideoComment video={comment.data as IVideo} />
                  </WrapperComments>
                );
              }
              return (
                <WrapperComments>
                  <Comment comment={comment.data as IComment} />
                </WrapperComments>
              );
            })}
          </InsightWrapperComment>
        </InsightGrid>
      )}
    </InsightsWrapper>
  );
};

export default Insights;
