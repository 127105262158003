import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as companyActions from 'src/redux/actions/company';
import * as userActions from 'src/redux/actions/user';
import searchedCompaniesOnlySelector from 'src/redux/selectors/company/searchedCompaniesOnly';
import isSendEmailSignedUpSelector from 'src/redux/selectors/user/isSendEmailSignedUp';
import userLoadingSelector from 'src/redux/selectors/user/userLoading';

import { useTypedSelector } from 'src/hooks/typeSelector';
import SignUp from 'src/views/Signup';
import { ISignUpDetails } from 'src/views/Signup/types';

import LayoutFooter from './LayoutFooter';

const SignUpContainer = () => {
  const dispatch = useDispatch();

  const loading: boolean = useTypedSelector(userLoadingSelector());
  const searchCompanies = useTypedSelector(searchedCompaniesOnlySelector());

  const isSendEmail = useTypedSelector(isSendEmailSignedUpSelector());

  const onSearchCompanies = (search: string) => {
    dispatch(companyActions.onSearchCompanyRequested(search));
  };

  const onInitializeSignUpView = useCallback(() => {
    dispatch(userActions.initializeSignUpView());
  }, [dispatch]);

  const redirectToESGMotive = useCallback(() => {
    dispatch(userActions.redirectToESGMotive());
  }, [dispatch]);

  useEffect(() => {
    onInitializeSignUpView();
  }, [onInitializeSignUpView]);

  const onSubmit = (details: ISignUpDetails) => {
    dispatch(
      userActions.signUpRequested(
        details.firstName,
        details.lastName,
        details.email,
        details.company,
      ),
    );
  };

  return (
    <LayoutFooter handleTrackEvent={() => {}}>
      <SignUp
        loading={loading}
        onSubmit={onSubmit}
        onSuccessSubmit={redirectToESGMotive}
        searchCompanies={searchCompanies}
        onSearchCompanies={onSearchCompanies}
        isSignedUp={isSendEmail}
      />
    </LayoutFooter>
  );
};
export default SignUpContainer;
