import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

import { getMaterialityIsPositiveOrNegative } from 'src/helpers/scores';

// Positivity for pillars business rules:
// For yes-no fields, is positive if is "Yes"
// For number fields, is positive if in the percentile 50 of all of the industry scores
// // For example: a company that is 9 out of 20 companies of the industry in a particular pillar, will be considered positive because (9*100)/20 = 45% (which is < 50%)

// Positivity for whole materiality
// Is positive if at least 50% of the pillars that correspond to that materiality are positive

const positiveMaterialitiesSelector = () =>
  createSelector(
    [
      (state: IReduxState) => state.score.industriesScores,
      (state: IReduxState) => state.score.userMateriality,
      (state: IReduxState) => state.user.user?.company,
    ],
    (industriesScores, materialities, userCompany) => {
      if (!userCompany || !industriesScores || !materialities || !userCompany.lastUpdatedScore) {
        return null;
      }
      const industryScore = industriesScores.find(
        (element) => element.industry === userCompany.industry,
      );
      if (!industryScore) {
        return null;
      }
      return getMaterialityIsPositiveOrNegative(
        materialities,
        userCompany.lastUpdatedScore,
        industryScore.data,
      );
    },
  );

export default positiveMaterialitiesSelector;
