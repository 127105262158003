import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  .MuiInputLabel-root {
    color: ${(props) => props.theme.palette.secondary};
    font-family: ${(props) => props.theme.fontFamilies.regular};
    font-size: 16px;
  }
  label.Mui-focused {
    color: ${(props) => props.theme.palette.secondary};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.palette.quinternary};
    border-radius: 15px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.palette.common.white} inset !important;
  }
`;

export const ToggleButton = styled(IconButton)`
  .css-i4bv87-MuiSvgIcon-root {
    color: ${(props) => props.theme.palette.lightSecondary};
  }
`;

export const EyeIcon = styled(Visibility)`
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const SlashedEyeIcon = styled(VisibilityOff)`
  color: ${(props) => props.theme.palette.lightSecondary};
`;

export const LockIcon = styled(Lock)`
  color: ${(props) => props.theme.palette.lightSecondary};
`;
