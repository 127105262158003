import arrayUnique from 'array-unique';
import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as companyActions from 'src/redux/actions/company';
import * as userActions from 'src/redux/actions/user';
import * as companyConstants from 'src/redux/constants/company';
import * as userConstants from 'src/redux/constants/user';

import { CompetitorsType } from 'src/enums/competitorsType';
import { Materialities } from 'src/enums/pillarScores';
import { getPillarScoresByMateriality } from 'src/helpers/scores';
import { ICompany } from 'src/types/company';
import { IFilterResponse } from 'src/types/http';
import { IIndustryScores, IScoresByIndustry } from 'src/types/score';
import IUser from 'src/types/user';
import IUserSettings from 'src/types/userSettings';

import companyCompetitorsSelector from '../selectors/company/competitors';
import competitorsIndustryScoresSelector from '../selectors/scores/competitorsIndustryScores';
import userMaterialitiesSelector from '../selectors/scores/userMaterialities';
import userDataSelector from '../selectors/user/userData';
import { getBy } from '../services/company';
import { getUserMateriality } from '../services/data';
import { getScoresByIndustry } from '../services/score';
import { getUserSettings } from '../services/user';

function* initializeSales() {
  try {
    const [searchCompaniesResult, userSettings]: [IFilterResponse, IUserSettings] = yield all([
      call(getBy, { name: '' }),
      call(getUserSettings),
    ]);
    yield put(userActions.initializeSalesViewSucceeded(searchCompaniesResult.items, userSettings));
  } catch (error: any) {
    yield put(userActions.initializeSalesViewFailed(error.data.message));
  }
}

function* initializeSalesInsight(action: AnyAction) {
  try {
    const [industryMateriality, searchCompaniesResult]: [Materialities[], IFilterResponse] =
      yield all([call(getUserMateriality, action.industry), call(getBy, { name: '' })]);
    yield put(
      userActions.onInitializeSalesInsightViewSucceeded(
        industryMateriality,
        searchCompaniesResult.items,
      ),
    );

    const pillarsAssociate = getPillarScoresByMateriality(industryMateriality).map(
      (metadata) => metadata.Identifier,
    );
    yield put(userActions.onPillarsPerformanceRequested(pillarsAssociate));
    yield put(userActions.onScoresCompetitorsRequested(pillarsAssociate));
  } catch (error: any) {
    yield put(userActions.onInitializeSalesInsightViewFailed(error.data.message));
  }
}

function* getPillarScoresByIndustry(action: AnyAction) {
  try {
    const user: IUser = yield select(userDataSelector());
    const industryScores: IIndustryScores[] = yield call(
      getScoresByIndustry,
      action.pillars,
      user.company.industry,
    );

    yield put(
      userActions.onPillarsPerformanceSucceeded([
        { industry: user.company.industry, data: industryScores },
      ]),
    );
  } catch (error: any) {
    yield put(userActions.onPillarsPerformanceFailed(error.data.message));
  }
}

function* getScoresCompetitorPerIndustry(action: AnyAction) {
  const competitors: ICompany[] = yield select(companyCompetitorsSelector(CompetitorsType.SALES));
  const user: IUser = yield select(userDataSelector());
  const competitorsIndustrys = competitors
    .map((element) => {
      return element.industry;
    })
    .filter((element) => element !== user.company.industry);
  if (competitors.length !== 0 && competitorsIndustrys.length !== 0) {
    try {
      const competitorsIndustryScore: IIndustryScores[][] = yield all(
        arrayUnique(competitorsIndustrys).map((industry) => {
          return call(getScoresByIndustry, action.pillars, industry);
        }),
      );

      const associteScoreWithCompetitor = competitorsIndustryScore.map((element, index) => {
        return { industry: competitorsIndustrys[index], data: element };
      });
      yield put(userActions.onScoresCompetitorsSucceeded(associteScoreWithCompetitor));
    } catch (error: any) {
      yield put(userActions.onScoresCompetitorsFailed(error.data.message));
    }
  }
}

function* getIndustryScoreForNewCompetitor(action: AnyAction) {
  const industryScore: IScoresByIndustry[] = yield select(competitorsIndustryScoresSelector());
  const haveIndustryScore = industryScore
    .map((element) => element.industry)
    .includes(action.newCompetitor.industry);

  if (!haveIndustryScore) {
    try {
      const userMateriality: Materialities[] = yield select(userMaterialitiesSelector());
      const pillarsAssociate = getPillarScoresByMateriality(userMateriality).map(
        (metadata) => metadata.Identifier,
      );
      const competitorsIndustryScore: IIndustryScores[] = yield call(
        getScoresByIndustry,
        pillarsAssociate,
        action.newCompetitor.industry,
      );
      yield put(
        companyActions.onAddCompetitorSucceeded([
          {
            industry: action.newCompetitor.industry,
            data: competitorsIndustryScore,
          },
        ]),
      );
    } catch (error: any) {
      yield put(companyActions.onAddCompetitorFailed(error.data.message));
    }
  }
}

function* resetSearchedCompetitors() {
  try {
    const searchCompaniesResult: IFilterResponse = yield call(getBy, { name: '' });
    yield put(userActions.onSubmitTargetSalesSucceeded(searchCompaniesResult.items));
  } catch (error: any) {
    yield put(userActions.onSubmitTargetSalesFailed(error.data.message));
  }
}

export default all([
  takeLatest(userConstants.USER_ON_INITIALIZE_SALES_VIEW, initializeSales),
  takeLatest(userConstants.USER_ON_PILLARS_PERFORMANCE_REQUESTED, getPillarScoresByIndustry),
  takeLatest(userConstants.USER_ON_SCORES_COMPETITOR_REQUESTED, getScoresCompetitorPerIndustry),
  takeLatest(companyConstants.ON_ADD_COMPETITOR_REQUESTED, getIndustryScoreForNewCompetitor),
  takeLatest(userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_REQUESTED, initializeSalesInsight),
  takeLatest(userConstants.USER_ON_SUBMIT_TARGET_SALES_REQUESTED, resetSearchedCompetitors),
]);
