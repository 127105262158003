import styled from 'styled-components';

import { SIZES } from 'src/helpers/devices';

export const BenchmarkWrapper = styled.div`
  @media (max-width: ${SIZES.mediumTablet}px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-template-areas:
      'header'
      'searchBar'
      'realScoreRanking'
      'pillarsGraph'
      'pillarsTable';
  }
  background-color: #fafafa;
  display: grid;
  overflow: auto;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  padding: 20px;
  grid-template-areas:
    'header header'
    'searchBar searchBar'
    'realScoreRanking pillarsGraph'
    'pillarsTable pillarsTable';
`;

export const HeaderWrapper = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    flex-direction: column;
  }
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
  border-bottom: 1px solid ${(props) => props.theme.palette.quinternary};
  line-height: 40px;
`;

export const Title = styled.p`
  font-size: 28px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  margin-right: 5px;
`;

export const Subtitle = styled.p`
  flex: 1;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.palette.secondary};
  font-family: ${(props) => props.theme.fontFamilies.regular};
`;

export const SearchBarWrapper = styled.div`
  grid-area: searchBar;
`;

export const RealScoreRankingWrapper = styled.div`
  grid-area: realScoreRanking;
  height: 350px;
  min-width: 350px;
`;

export const PillarScoresRankingWrapper = styled.div`
  grid-area: pillarsGraph;
  height: 350px;
  min-width: 350px;
`;

export const PillarScoresTableWrapper = styled.div`
  grid-area: pillarsTable;
  min-height: 600px;
`;

export const WrapperText = styled.div`
  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
  }
  flex: 9;
  display: flex;
  align-items: baseline;
`;

export const WrapperButton = styled.div`
  @media (max-width: ${SIZES.bigPhone}px) {
    width: 100%;
    margin-top: 10px;
    align-items: flex-start;
  }
  flex: 1;
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

export const ExportButton = styled.button`
  max-width: 150px;
  min-width: 150px;
  min-height: 40px;
  flex: 1;
  border-radius: 10px;
  background-color: #32cc6f;
  cursor: pointer;
  border: none;
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.palette.common.white};
  &:hover {
    background-color: #2fbc67;
  }
`;
