import { createSelector } from 'reselect';

import { IReduxState } from 'src/redux/store';

const selectedExploreTabSelector = () =>
  createSelector(
    [(state: IReduxState) => state.data.selectedExploreTab],
    (selectedExploreTab) => selectedExploreTab,
  );
export default selectedExploreTabSelector;
