import i18next from 'i18next';
import React from 'react';

import SalesHelpPNG from 'src/assets/sales/salesHelp.png';
import SalesHelpWithoutScorePNG from 'src/assets/sales/salesHelpWithoutScore.png';

import './i18n';
import {
  HelpImage,
  HelpWrapper,
  ImageWrapper,
  TextWrapper,
  Title,
  Text,
  WrapperTitle,
  BoldText,
} from './styles';
import { IHelpSalesProps } from './types';

const HelpSales = (props: IHelpSalesProps) => {
  const { hasScore } = props;

  return (
    <HelpWrapper>
      <WrapperTitle>
        <Title data-testid={'title-help-sales-page'}> {i18next.t('SALES_HELP:TITLE')} </Title>
      </WrapperTitle>
      <ImageWrapper data-testid={'image-help-sales-page'}>
        <HelpImage src={!hasScore ? SalesHelpPNG : SalesHelpWithoutScorePNG} />
      </ImageWrapper>
      <TextWrapper>
        <Text data-testid={'text-help-sales-page'}>
          {i18next.t('SALES_HELP:TEXT')} <BoldText>{i18next.t('SALES_HELP:FIRST')}</BoldText>{' '}
          {i18next.t('SALES_HELP:TEXT_FIRST')} <BoldText>{i18next.t('SALES_HELP:SECOND')}</BoldText>{' '}
          {i18next.t(`SALES_HELP:${!hasScore ? 'TEXT_SECOND' : 'TEXT_SECOND_WITHOUT_SCORE'}`)}{' '}
          <BoldText>{i18next.t('SALES_HELP:THIRD')}</BoldText>{' '}
          {i18next.t(`SALES_HELP:${!hasScore ? 'TEXT_THIRD' : 'TEXT_THIRD_WITHOUT_SCORE'}`)}
        </Text>
      </TextWrapper>
    </HelpWrapper>
  );
};

export default HelpSales;
