import { Close } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ScrollWrapper = styled.div``;

export const IconButtonWrapper = styled.div`
  align-self: flex-end;
  margin-bottom: 20px;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  color: ${(props) => props.theme.palette.common.grey};
`;

export const MenuBackdrop = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1260;
  top: 0;
  left: 0;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.secondary};
  width: 200px;
  height: 100vh;
  position: fixed;
  z-index: 1300;
  left: 0;
  top: 0;
  padding: 20px;
`;

export const MenuItem = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  text-decoration: none;
  position: relative;
`;

export const MenuLink = styled.p`
  text-transform: capitalize;
  font-family: ${(props) => props.theme.fontFamilies.regular};
  font-size: 16px;
  color: ${(props) => props.theme.palette.common.white};
`;

export const MenuIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`;

export const SelectedRouteOverlay = styled.div`
  height: 100%;
  width: 100%;
  left: -25px;
  background-color: ${(props) => props.theme.palette.lightSecondary};
  position: absolute;
  padding: 10px 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  z-index: -1;
`;

export const WrapperFooter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

export const FooterItem = styled.p`
  width: 100%;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 50px;
  padding-top: 20px;
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamilies.regular};

  color: ${(props) => props.theme.palette.tertiary};
  &:hover {
    color: ${(props) => props.theme.palette.quaternary};
  }
`;

export const SeparateMenu = styled.p`
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.palette.lightSecondary};
`;
