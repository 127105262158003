import i18next from 'i18next';
import React from 'react';

import { PillarStates } from 'src/enums/pillarStates';
import { formatNumber, isPercentage } from 'src/helpers/data';
import { NegativeTab, NeutralTab, PositiveTab } from 'src/helpers/exploreTabsColors';

import './i18n.ts';
import {
  BackgroundWrapper,
  IndicatorLabel,
  Label,
  LabelCompare,
  LinearGraphic,
  ScorePosition,
  ScorePositionWrapper,
  Wrapper,
  WrapperCompare,
} from './styles';
import { ILinearChartProp } from './types';

const LinearChart = (props: ILinearChartProp) => {
  const { valueRange, unitValue, scoreValue, state } = props;
  const parsedMax = isPercentage(valueRange.max) ? valueRange.max : formatNumber(valueRange.max);
  const parsedMin = isPercentage(valueRange.min) ? valueRange.min : formatNumber(valueRange.min);
  const parsedValue = isPercentage(scoreValue) ? scoreValue : formatNumber(scoreValue);

  const handleToolTipColor = (state: PillarStates) => {
    switch (state) {
      case PillarStates.NEGATIVE:
        return NegativeTab.firstRed;
      case PillarStates.POSITIVE:
        return PositiveTab.firstGreen;
      case PillarStates.NEUTRAL:
        return NeutralTab.firstOrange;
      default:
        return '';
    }
  };

  return (
    <Wrapper>
      <WrapperCompare>
        <LabelCompare>{i18next.t('LINEAR_GRAPHIC:BETTER')}</LabelCompare>
        <LabelCompare>{i18next.t('LINEAR_GRAPHIC:WORSE')}</LabelCompare>
      </WrapperCompare>
      <BackgroundWrapper>
        <IndicatorLabel isStart={true}>
          <Label>
            {parsedMax} {unitValue}
          </Label>
        </IndicatorLabel>
        <LinearGraphic>
          <ScorePositionWrapper>
            <ScorePosition
              color={handleToolTipColor(state)}
              scoreValue={scoreValue}
              valueRange={valueRange}
            >
              {parsedValue} {unitValue}
            </ScorePosition>
          </ScorePositionWrapper>
        </LinearGraphic>
        <IndicatorLabel isStart={false}>
          <Label>
            {parsedMin} {unitValue}
          </Label>
        </IndicatorLabel>
      </BackgroundWrapper>
    </Wrapper>
  );
};

export default LinearChart;
