import i18next from 'i18next';
import { AnyAction } from 'redux';

import * as companyConstants from 'src/redux/constants/company';
import * as userConstants from 'src/redux/constants/user';

import { UserMessageType } from 'src/enums/userMessage';

import * as appConstants from '../constants/app';
import './translation/i18n.ts';

export interface IUserMessageReducerState {
  messageType: UserMessageType | null;
  message: string;
}

const defaultState: IUserMessageReducerState = {
  messageType: null,
  message: '',
};

const setUserMessage = (
  state: IUserMessageReducerState,
  messageType: UserMessageType | null,
  message: string,
) => ({
  ...state,
  messageType,
  message,
});

const userMessageReducer = (state = defaultState, action: AnyAction) => {
  switch (action.type) {
    case userConstants.USER_ON_INITIALIZE_BENCHMARK_VIEW_FAILED:
    case companyConstants.ON_SEARCH_COMPANY_BY_QUERY_FAILED:
    case companyConstants.ON_ADD_COMPETITOR_FAILED:
    case userConstants.USER_ON_INITIALIZE_SUMMARY_VIEW_FAILED:
    case userConstants.USER_ON_INITIALIZE_EXPLORE_VIEW_FAILED:
    case userConstants.USER_ON_LOGIN_FAILED:
    case userConstants.USER_ON_SIGNUP_FAILED:
    case userConstants.USER_ON_INITIALIZE_INSIGHTS_VIEW_FAILED:
    case userConstants.USER_ON_INITIALIZE_NEWS_VIEW_FAILED:
    case userConstants.USER_ON_HANDLE_ERROR_MESSAGE:
    case userConstants.USER_ON_PILLARS_PERFORMANCE_FAILED:
    case userConstants.USER_ON_INITIALIZE_SALES_VIEW_FAILED:
    case userConstants.USER_ON_INITIALIZE_SALES_INSIGHT_VIEW_FAILED:
    case userConstants.USER_ON_SCORES_COMPETITOR_FAILED:
    case userConstants.USER_ON_SUBMIT_TARGET_SALES_FAILED:
    case userConstants.USER_ON_FORGOT_PASSWORD_FAILED:
      return setUserMessage(state, UserMessageType.FAIL, action.errorMessage);
    case appConstants.APP_ON_INITIALIZE_PRIVATE_ROUTE_FAILED:
    case appConstants.APP_ON_INITIALIZE_PUBLIC_ROUTE_FAILED:
      if (action.error.statusCode === 401) {
        return setUserMessage(state, UserMessageType.FAIL, i18next.t('USER_MESSAGE:EXPIRE_TOKEN'));
      }
      return setUserMessage(state, UserMessageType.FAIL, i18next.t('USER_MESSAGE:SERVER_ERROR'));
    case userConstants.USER_ON_INITIALIZE_CHAGE_PASSWORD_FAILED:
    case userConstants.USER_ON_GET_USER_FAILED:
      return setUserMessage(state, UserMessageType.FAIL, i18next.t('USER_MESSAGE:INVALID_USER'));
    case userConstants.USER_ON_SEND_CONTACT_US_FAILED:
      return setUserMessage(state, UserMessageType.FAIL, i18next.t('USER_MESSAGE:FAIL_CONTACT_US'));
    case userConstants.USER_CLOSE_USER_MESSAGE:
    case userConstants.USER_ON_LOGIN_REQUESTED:
    case userConstants.USER_ON_SIGNUP_REQUESTED:
    case userConstants.USER_ON_INITIALIZE_FORGOT_PASSWORD_VIEW:
      return setUserMessage(state, null, '');
    default:
      return state;
  }
};

export default userMessageReducer;
